import React, { Fragment, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { SmallLink, SmallVideo } from "../../assets/images/small";
import { checkIsGuru } from "../../utils/roleUser";
import { ModalMateriVideo } from "../modal";

function ItemFileVideo({ name, type, handleDeleteFile, item }) {
  const { t } = useTranslation();
  const { user } = useSelector(({ user }) => user);
  const [materiVideo, setMateriVideo] = useState(null);
  const getIcon = (type) => {
    if (type === "file") {
      return SmallVideo;
    } else {
      return SmallLink;
    }
  };
  return (
    <>
      <div className="iq-bg-primary rounded p-2 pointer my-1 d-flex align-items-md-center justify-content-between gap-8 flex-column flex-md-row">
        <div
          onClick={() => setMateriVideo(item)}
          className="flex-1 cursor-pointer"
        >
          <img
            src={getIcon(type)}
            width={24}
            height={24}
            alt="icon"
            className="img-fluid"
          />
          &nbsp;{name}
        </div>

        {checkIsGuru(user?.user_roleid) && (
          <Fragment>
            <div className="d-flex">
              {handleDeleteFile && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="button-tooltip-2">{t("hapus")}</Tooltip>
                  }
                >
                  <button
                    type="button"
                    className="btn btn-danger btn-sm float-right"
                    onClick={handleDeleteFile}
                  >
                    <i className="ri-delete-bin-line m-0"></i>
                  </button>
                </OverlayTrigger>
              )}
            </div>
          </Fragment>
        )}
      </div>
      {materiVideo && (
        <ModalMateriVideo
          dataVideo={materiVideo}
          handleClose={() => setMateriVideo(null)}
          show={true}
        />
      )}
    </>
  );
}

export default ItemFileVideo;
