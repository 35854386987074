import React from "react";
import ItemSummaryPlaylist from "../item/ItemSummaryPlaylist";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";

const MateriVideo = ({
  detail,
  materi,
  handlePlay,
  loading,
  handleMoreVideo,
  handleScrollToPlaylist,
}) => {
  const { t } = useTranslation();
  return (
    <div className="p-3 d-flex flex-column gap-3">
      {loading ? (
        <div className="my-5 text-center">
          <Spinner
            animation="border"
            role="status"
            variant="secondary"
            className="mx-auto"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <ItemSummaryPlaylist
            title="Trailer"
            handlePlay={() =>
              handlePlay({
                video_type: detail?.course_video_type,
                video: detail?.course_video,
              })
            }
          />
          {materi.slice(0, 3).map((item) => (
            <ItemSummaryPlaylist
              key={item.materi_id}
              time={item.materi_waktu}
              title={item.materi_title}
              lock={item.materi_lock}
              handlePlay={() => {
                if (item.materi_lock) {
                  handleMoreVideo();
                } else {
                  handlePlay({
                    video_type: item.materi_video_type,
                    video: item.materi_video,
                  });
                }
              }}
            />
          ))}
          {materi.length > 3 ? (
            <ItemSummaryPlaylist
              handlePlay={handleScrollToPlaylist}
              title={t("video_lainnya", { video: materi.length - 3 })}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default MateriVideo;
