import Calendar from "@fullcalendar/react";
import plgn from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { getKalender } from "../../../redux/actions/kalenderAction";
import { checkIsOrtu } from "../../../utils/roleUser";

const renderEvent = (args) => {
  const event = args.event;
  return (
    <OverlayTrigger overlay={<Tooltip id={event.id}>{event.title}</Tooltip>}>
      <p className="m-0 elipsis-1-line">
        <span className="fw-bold">{args.timeText} </span>
        {event.title}
      </p>
    </OverlayTrigger>
  );
};

const KalenderSection = () => {
  const { t } = useTranslation();
  const { user } = useSelector(({ user }) => user);
  const [dataKalender, setDataKalender] = useState([]);
  const { selectedAnak } = useSelector(({ anak }) => anak);
  const navigate = useNavigate();

  const showAlert = useCallback(
    (type, title, text) => {
      Swal.fire({
        title: title,
        text: text,
        icon: type,
        confirmButtonText: "Ok",
        confirmButtonColor: "#6fbd44",
      }).then(() => {
        navigate("/kelas");
      });
    },
    [navigate]
  );

  useEffect(() => {
    const checkOrtu = checkIsOrtu(user.user_roleid);
    if (checkOrtu && !selectedAnak)
      return showAlert("error", "Error", "Pilih data anak terlebih dahulu");
    let user_id = checkOrtu ? selectedAnak?.user_id : user?.user_id;
    getKalender({ user_id: user_id }).then((res) => {
      setDataKalender(
        res?.data.map((item) => ({
          ...item,
          color: getColorEvent(item.type),
          url: `/pelajaran/${item.topik.kelasmapel_id}?th=${item.tahunpelajaran_id}&smt=${item.semester_id}`,
        }))
      );
    });
  }, [user, selectedAnak, showAlert]);

  const getColorEvent = (type) => {
    switch (type) {
      case "tugas":
        return "#a110fb";
      case "mater":
        return "#3f9b4b";
      case "diskusi":
        return "#fc9919";
      default:
        return "#3f9b4b";
    }
  };
  return (
    <div className="iq-card fc-unthemed">
      <div className="iq-card-body">
        <Calendar
          plugins={[plgn, timeGridPlugin, listPlugin]}
          initialView="dayGridMonth"
          customButtons={{
            myCustomButton: {
              text: t("kembali"),
              click: () => navigate("/kelas"),
            },
          }}
          headerToolbar={{
            left: "prev,today,next",
            center: "title",
            right: "myCustomButton",
          }}
          eventBackgroundColor="#3f9b4b"
          eventColor="#3f9b4b"
          eventDisplay="block"
          events={dataKalender}
          eventContent={renderEvent}
        />
      </div>
    </div>
  );
};

export default KalenderSection;
