import React from "react";
import { useTranslation } from "react-i18next";

const SideContentPenilaian = ({
  jmlSiswa,
  jmlAnswer,
  siswa,
  selectedSiswa,
  handleSelect,
  objectPengerjaan,
}) => {
  const { t } = useTranslation();
  return (
    <div className="col-lg-4">
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title d-flex">
            <h4 className="card-title">{t("siswa")}</h4>
          </div>
          <h4 style={{ color: "green" }}>
            {jmlAnswer}/{jmlSiswa}
          </h4>
        </div>
        <div className="col-lg-12 p-0">
          <div className="iq-card">
            <div className="iq-card-body">
              <div className="row">
                <div className="col-sm-12">
                  <div
                    className="nav flex-column nav-pills text-center"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    {siswa?.map((item) => (
                      <button
                        onClick={() => handleSelect(item)}
                        className={`nav-link text-primary d-flex justify-content-between align-items-center ${
                          selectedSiswa === item?.user_id && "active"
                        }`}
                        key={item.user_id}
                      >
                        <div className="d-flex align-items-center gap-2">
                          {item?.status_pengerjaan && (
                            <i
                              className={`ri-checkbox-blank-circle-fill ${
                                item?.status_pengerjaan ===
                                "selesai-mengerjakan"
                                  ? "text-primary"
                                  : item?.status_pengerjaan ===
                                    "sedang-mengerjakan"
                                  ? "text-warning"
                                  : "text-secondary"
                              }`}
                            ></i>
                          )}
                          <span className="text-start">
                            {item?.user?.user_nama}
                          </span>
                        </div>
                        <span className="badge badge-primary">
                          {item?.user[objectPengerjaan]?.pengerjaan_nilai || 0}
                        </span>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideContentPenilaian;
