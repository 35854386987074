import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { IcH5P, LoadingGreen, PageLoader } from "../../assets/images";
import { getTopik } from "../../redux/actions/topikAction";
import { checkURLFormat } from "../../utils/validateData";
import SelectMultiple from "../input/SelectMultiple";
import { ItemFile, ItemFileVideo } from "../item";
import ModalDrive from "./ModalDrive";
import ModalMateriH5P from "./ModalMateriH5P";
import {
  addDiskusi,
  getDetailDiskusi,
  updDiskusi,
  uploadFileDiskusi,
} from "../../redux/actions/diskusiAction";
import { InputEditor } from "../input";

function ModalDiskusi({
  dataTopik,
  dataSiswa,
  show,
  handleClose,
  editDiskusi,
}) {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let fileInput = useRef(null);
  let fileH5PRef = useRef(null);
  const editorRef = useRef(null);
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const [uploadFileH5PLoading, setUploadFileH5PLoading] = useState(false);
  const [showDrive, setShowDrive] = useState(false);
  const [fileH5P, setFileH5P] = useState(null);
  const [params, setParams] = useState({
    topik_id: "",
    tahunpelajaran_id: "",
    semester_id: "",
    title: "",
    link: "",
    start_date: "",
    end_date: "",
    deskripsi: "",
    files: [],
    enable_start_date: false,
    enable_end_date: false,
    list_siswa: [],
    allSiswa: false,
    video_diskusi: [],
    h5p_diskusi: [],
  });
  const [newVideo, setNewVideo] = useState({
    video_name: "",
    video_type: "link",
    video_file: "",
  });

  useEffect(() => {
    let search = new URLSearchParams(location.search);
    let tahunpelajaran_id = search.get("th");
    let semester_id = search.get("smt");
    setParams((pref) => ({
      ...pref,
      tahunpelajaran_id,
      semester_id,
    }));
  }, [location.search]);

  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };
  const handleChange = ({ target: { value, name } }) => {
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };
  const handleChangeChecked = ({ target: { checked, name } }) => {
    setParams((pref) => ({
      ...pref,
      [name]: checked,
      [name.slice(7, name.length)]: "",
    }));
  };
  const handleChangeFile = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (files.length > 0) {
      var formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`files`, files[i]);
      }
      setUploadFileLoading(true);
      uploadFileDiskusi(formData)
        .then((res) => {
          setUploadFileLoading(false);
          setParams((pref) => ({
            ...pref,
            files: [...pref.files, ...res?.data?.data],
          }));
        })
        .catch((err) => {
          setUploadFileLoading(false);
        });
    }
  };
  const handleChangeFileH5P = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (files.length > 0) {
      var formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`files`, files[i]);
      }
      setUploadFileH5PLoading(true);
      uploadFileDiskusi(formData)
        .then((res) => {
          setUploadFileH5PLoading(false);
          setParams((pref) => ({
            ...pref,
            h5p_diskusi: [
              ...pref.h5p_diskusi,
              ...res?.data?.data?.map((item) => ({
                h5p_filename: item.filename,
                h5p_file: item.path,
              })),
            ],
          }));
        })
        .catch((err) => {
          setUploadFileH5PLoading(false);
        });
    }
  };
  const handleSubmit = (status) => {
    setSubmit(true);
    let data = {
      topik_id: params.topik_id,
      title: params.title,
      deskripsi: editorRef.current.getContent(),
      files: params.files,
      h5p_diskusi: params.h5p_diskusi,
      list_siswa: params.allSiswa ? [] : params.list_siswa,
      allSiswa: params.allSiswa,
      video_diskusi: params?.video_diskusi,
      diskusi_id: editDiskusi,
      status: status,
      tahunpelajaran_id: params?.tahunpelajaran_id,
      semester_id: params?.semester_id,
    };
    if (params?.link?.length > 0) data.link = params.link;
    if (params?.start_date?.length > 0) data.start_date = params.start_date;
    if (params?.end_date?.length > 0) data.end_date = params.end_date;
    if (editDiskusi) {
      updDiskusi(editDiskusi, data)
        .then(() => {
          showAlert(t("berhasil_simpan", { params: t("diskusi") }), true);
          handleClose();
          setSubmit(false);
          const data = {
            tahunpelajaran_id: params?.tahunpelajaran_id,
            semester_id: params?.semester_id,
          };
          dispatch(getTopik(id, data));
        })
        .catch((err) => {
          setError(err?.response?.data?.error);
          setSubmit(false);
        });
    } else {
      addDiskusi(data)
        .then(() => {
          showAlert(t("berhasil_simpan", { params: t("diskusi") }), true);
          handleClose();
          setSubmit(false);
          const data = {
            tahunpelajaran_id: params?.tahunpelajaran_id,
            semester_id: params?.semester_id,
          };
          dispatch(getTopik(id, data));
        })
        .catch((err) => {
          setError(err?.response?.data?.error);
          setSubmit(false);
        });
    }
  };
  const handleChangeSiswa = (name, value) => {
    if (name === "all" && value) {
      setParams((pref) => ({
        ...pref,
        list_siswa: dataSiswa?.map((item) => item?.user?.user_id?.toString()),
        allSiswa: true,
      }));
      return;
    } else if (name === "all") {
      setParams((pref) => ({ ...pref, list_siswa: [], allSiswa: false }));
      return;
    }
    let cek = params?.list_siswa?.filter(
      (item) => item.toString() === value.toString()
    );
    if (cek?.length > 0) {
      setParams((pref) => ({
        ...pref,
        list_siswa: params?.list_siswa?.filter(
          (item) => item.toString() !== value.toString()
        ),
        allSiswa: false,
      }));
    } else {
      setParams((pref) => ({
        ...pref,
        list_siswa: [...pref.list_siswa, value?.toString()],
        allSiswa: false,
      }));
    }
  };
  const handleDeleteFile = (idx) => {
    setParams((pref) => ({
      ...pref,
      files: pref?.files?.filter((item, i) => i !== idx),
    }));
  };
  const handleDeleteVideo = (idx) => {
    setParams((pref) => ({
      ...pref,
      video_diskusi: pref?.video_diskusi?.filter((item, i) => i !== idx),
    }));
  };
  const handleChangeVideo = ({ target: { name, value } }) => {
    setNewVideo((pref) => ({
      ...pref,
      [name]: value,
    }));
  };
  const handleCheckDataVideo = () => {
    if (newVideo?.video_name?.length <= 3) {
      showAlert(t("error_video_name"), false);
      return false;
    } else if (newVideo?.video_file?.length <= 0) {
      showAlert(t("error_video_link"), false);
      return false;
    } else if (!checkURLFormat(newVideo?.video_file)) {
      showAlert(t("error_url"), false);
      return false;
    }
    return true;
  };
  const handleSaveVideo = () => {
    let cek = handleCheckDataVideo();
    if (!cek) return;
    setParams((pref) => ({
      ...pref,
      video_diskusi: [...pref.video_diskusi, newVideo],
    }));
    setNewVideo({
      video_name: "",
      video_type: "link",
      video_file: "",
    });
  };
  const handleShowVideo = (value) => {
    setShowVideo(value);
    setNewVideo({
      video_name: "",
      video_type: "link",
      video_file: "",
    });
  };
  useEffect(() => {
    handleChangeSiswa("all", true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const formatDate = (value) => {
    let result = value;
    if (value) {
      result = result?.substr(0, 19)?.replace("T", " ");
    }
    return result;
  };
  useEffect(() => {
    if (editDiskusi) {
      getDetailDiskusi(editDiskusi).then((res) => {
        let data = res?.data?.data;
        setParams((pref) => ({
          ...pref,
          kelasmapel_id: data?.topik?.kelasmapel_id,
          start_date: formatDate(data?.start_date),
          end_date: formatDate(data?.end_date),
          title: data?.title,
          deskripsi: data?.deskripsi,
          files: data?.files,
          h5p_diskusi: data?.h5p_diskusi,
          enable_start_date: data?.start_date?.length > 0,
          enable_end_date: data?.end_date?.length > 0,
          list_siswa: data?.list_siswa?.map((item) => item.toString()),
          allSiswa: data?.allSiswa,
          kelasmapel_kelasdetailid:
            data?.topik?.kelas_mapel?.kelasmapel_kelasdetailid,
          link: data?.link || "",
          topik_id: data?.topik_id,
          diskusi_id: editDiskusi,
          video_diskusi: data?.video_diskusi,
        }));
      });
    }
  }, [editDiskusi]);

  const handleSelectDrive = (file) => {
    if (file) {
      setParams((pref) => ({
        ...pref,
        files: [
          ...pref.files,
          {
            filename: file?.drive_file_name,
            path: file?.drive_file_url,
            type: file?.drive_file_type,
          },
        ],
      }));
    }
    setShowDrive(false);
  };

  return (
    <div className="col-lg-12">
      <Modal
        enforceFocus={false}
        fullscreen={true}
        show={show}
        onHide={handleClose}
        id="modalDiskusi"
        backdrop="static"
      >
        <Modal.Header>
          <div className="d-flex align-items-center">
            <button
              className="border-0 bg-white btn-close m-0 p-2 shadow-none"
              onClick={() => {
                if (!submit) handleClose();
              }}
            ></button>
            <h4 className="modal-title">
              {editDiskusi ? "Edit" : t("tambah")} {t("diskusi")}
            </h4>
          </div>
          <Dropdown align="end">
            <Dropdown.Toggle
              variant="success"
              className="btn-primary border-0"
              id="dropdown-basic"
              disabled={submit}
            >
              {submit ? (
                <img src={PageLoader} alt="loader" style={{ width: 15 }} />
              ) : (
                t("simpan")
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item disabled={submit} onClick={() => handleSubmit("")}>
                {t("simpan")}
              </Dropdown.Item>
              <Dropdown.Item
                disabled={submit}
                onClick={() => handleSubmit("Draft")}
              >
                {t("simpan")} Draft
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Modal.Header>
        <Modal.Body className="p-0 overflow-x-hidden">
          <div className="row">
            <div className="col-lg-7 offset-md-1 pt-4">
              <div className="iq-card w-modal mb-4">
                <div className="iq-card-body">
                  <div className="w-100 mb-3">
                    <label htmlFor="title" className="col-12">
                      {t("judul")} <span className="text-danger">*</span> :
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      value={params.title}
                      name="title"
                      placeholder={t("judul")}
                      onChange={handleChange}
                    />
                    {error?.title && (
                      <div className="d-block invalid-feedback">
                        {error?.title}
                      </div>
                    )}
                  </div>
                  <div className="w-100 mb-3">
                    <label htmlFor="email">{t("desc")} :</label>
                    <InputEditor
                      editorRef={editorRef}
                      value={params.deskripsi}
                    />
                    {error?.deskripsi && (
                      <div className="d-block invalid-feedback">
                        {error?.deskripsi}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="iq-card w-modal mb-4">
                <div className="iq-card-body">
                  <div className="form-group row align-items-center">
                    <div className="d-flex flex-grow-1 align-items-center">
                      <div className="send-panel d-flex">
                        <div className="me-4 d-flex align-items-center justify-content-between flex-column">
                          <label
                            className="mb-0 rounded-circle border border-primary iq-bg-primary-hover text-primary square-48"
                            htmlFor="files"
                          >
                            <input
                              type="file"
                              name="files"
                              id="files"
                              accept="
                                image/jpeg, 
                                image/png, 
                                image/jpg, 
                                .pptx,
                                .xlsx,
                                .xls,
                                .doc,
                                .docx,
                                .ppt,
                                .pdf,
                                audio/*,
                                video/*
                                "
                              onChange={handleChangeFile}
                              multiple
                              ref={fileInput}
                              disabled={uploadFileLoading}
                              className="d-none"
                            />
                            <button
                              type="button"
                              onClick={() => {
                                fileInput?.current?.click();
                              }}
                              className="border-0 bg-transparent m-2 d-flex align-items-center"
                              disabled={uploadFileLoading}
                            >
                              {uploadFileLoading ? (
                                <img
                                  src={LoadingGreen}
                                  alt="loading"
                                  width={16}
                                  height={16}
                                />
                              ) : (
                                <i className="ri-upload-2-line m-0 text-primary"></i>
                              )}
                            </button>
                          </label>
                          <p className="fw-bold font-size-12 text-center m-0">
                            {t("unggah")}
                          </p>
                        </div>
                        <div className="me-4 d-flex align-items-center justify-content-between flex-column">
                          <label
                            className="mb-0 rounded-circle border border-primary iq-bg-primary-hover text-primary square-48"
                            htmlFor="h5p"
                          >
                            <input
                              type="file"
                              name="h5p"
                              id="h5p"
                              accept=".html"
                              onChange={handleChangeFileH5P}
                              multiple
                              ref={fileH5PRef}
                              disabled={uploadFileH5PLoading}
                              className="d-none"
                            />
                            <button
                              type="button"
                              onClick={() => {
                                fileH5PRef?.current?.click();
                              }}
                              className="border-0 bg-transparent m-2 d-flex align-items-center"
                              disabled={uploadFileH5PLoading}
                            >
                              {uploadFileH5PLoading ? (
                                <img
                                  src={LoadingGreen}
                                  alt="loading"
                                  width={16}
                                  height={16}
                                />
                              ) : (
                                <img
                                  src={IcH5P}
                                  alt="icon h5p"
                                  width={20}
                                  height="auto"
                                />
                              )}
                            </button>
                          </label>
                          <p className="fw-bold font-size-12 text-center m-0">
                            H5P
                          </p>
                        </div>
                        <div className="me-4 d-flex align-items-center justify-content-between flex-column">
                          <label className="mb-0 rounded-circle border border-primary iq-bg-primary-hover text-primary square-48">
                            <button
                              onClick={() => setShowDrive(true)}
                              className="m-2 bg-transparent border-0 text-primary"
                            >
                              <i className="ri-drive-line"></i>
                            </button>
                          </label>
                          <p className="fw-bold font-size-12 text-center m-0">
                            Drive
                          </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between flex-column">
                          <label className="mb-0 rounded-circle border border-primary iq-bg-primary-hover text-primary square-48">
                            <button
                              className="m-2 border-0 bg-transparent text-primary"
                              onClick={() => handleShowVideo(!showVideo)}
                            >
                              <i className="ri-youtube-line"></i>
                            </button>
                          </label>
                          <p className="fw-bold font-size-12 text-center m-0">
                            Video
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {showVideo && (
                    <div className="col-lg-12">
                      <div className="iq-card">
                        <div className="card-body border border-primary p-3">
                          <div className="row w-100">
                            <div className="col-12 mb-3 col-md-5">
                              <input
                                type="text"
                                className="form-control"
                                id="video_name"
                                value={newVideo.video_name}
                                name="video_name"
                                placeholder="Nama Video"
                                onChange={handleChangeVideo}
                              />
                            </div>
                            <div className="col-12 mb-3 col-md-6 d-flex align-items-center">
                              <div className="w-100">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="video_file"
                                  value={newVideo.video_file}
                                  name="video_file"
                                  placeholder="Link Video"
                                  onChange={handleChangeVideo}
                                />
                                <p className="font-12 text-muted mb-0 mt-2">
                                  {t("youtube_link_format")}:
                                  <span className="fw-bold">
                                    {" "}
                                    https://www.youtube.com/watch?v=xxxxxx
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="col-12 mb-3 col-md-1">
                              <button
                                onClick={handleSaveVideo}
                                type="button"
                                className="btn btn-primary d-flex align-items-center justify-content-center"
                              >
                                <i className="ri-add-line m-0"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <hr />
                  <div className="form-group row">
                    {params?.video_diskusi?.map((item, i) => (
                      <div className="col-lg-12" key={i}>
                        <ItemFileVideo
                          name={item.video_name}
                          type={item.video_type}
                          url={item.video_file}
                          item={item}
                          handleDeleteFile={() => handleDeleteVideo(i)}
                        />
                      </div>
                    ))}
                    {params?.files?.map((item, i) => (
                      <div className="col-lg-12" key={i}>
                        <ItemFile
                          name={item.filename}
                          type={item.type}
                          url={item.path}
                          handleDeleteFile={() => handleDeleteFile(i)}
                        />
                      </div>
                    ))}
                    {params?.h5p_diskusi?.map((item, i) => (
                      <div className="col-lg-12" key={i}>
                        <ItemFile
                          name={item.h5p_filename}
                          type="html"
                          url={item.h5p_file}
                          handleDeleteFile={() => handleDeleteFile(i)}
                          handleH5P={() => setFileH5P(item.h5p_file)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 offset-md-1">
              <div className="iq-card h-100">
                <div className="iq-card-body h-100">
                  <div className="col-lg-12 mb-3">
                    <label htmlFor="topik_id">
                      {t("topik")} <span className="text-danger">*</span> :
                    </label>
                    <select
                      className="form-control form-select"
                      name="topik_id"
                      id="topik_id"
                      value={params.topik_id}
                      onChange={handleChange}
                      required
                    >
                      <option value="">
                        {t("pilih_params", { params: t("topik") })}
                      </option>
                      {dataTopik?.topik?.map((item) => (
                        <option value={item.topik_id} key={item.topik_id}>
                          {item.topic}
                        </option>
                      ))}
                    </select>
                    {error?.topik_id && (
                      <div className="d-block invalid-feedback">
                        {error?.topik_id}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-12 mb-3">
                    <SelectMultiple
                      dataSiswa={dataSiswa}
                      selectedSiswa={params?.list_siswa}
                      selectedAll={params?.allSiswa}
                      handleChange={handleChangeSiswa}
                    />
                    {error?.list_siswa && (
                      <div className="d-block invalid-feedback">
                        {error?.list_siswa}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-12 mb-3">
                    <div className="row">
                      <label htmlFor="start_date" className="col-12">
                        {t("tgl_mulai")} :
                      </label>
                      <div className="col-10">
                        <input
                          type="datetime-local"
                          className="form-control"
                          id="start_date"
                          value={params.start_date}
                          onChange={handleChange}
                          name="start_date"
                          disabled={!params.enable_start_date}
                        />
                        {error?.start_date && (
                          <div className="d-block invalid-feedback">
                            {error?.start_date}
                          </div>
                        )}
                      </div>
                      <div className="col-2 align-self-center">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="enable_start_date"
                            value={params.enable_start_date}
                            checked={params.enable_start_date}
                            onChange={handleChangeChecked}
                            name="enable_start_date"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="enable_start_date"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <div className="row">
                      <label htmlFor="email" className="col-12">
                        {t("tgl_selesai")} :
                      </label>
                      <div className="col-10">
                        <input
                          type="datetime-local"
                          className="form-control"
                          id="end_date"
                          name="end_date"
                          value={params.end_date}
                          onChange={handleChange}
                          disabled={!params.enable_end_date}
                        />
                        {error?.end_date && (
                          <div className="d-block invalid-feedback">
                            {error?.end_date}
                          </div>
                        )}
                      </div>
                      <div className="col-2 align-self-center">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="enable_end_date"
                            value={params.enable_end_date}
                            checked={params.enable_end_date}
                            onChange={handleChangeChecked}
                            name="enable_end_date"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="enable_end_date"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ModalDrive
        show={showDrive}
        handleClose={() => {
          setShowDrive(false);
        }}
        handleSave={handleSelectDrive}
      />
      {fileH5P && (
        <ModalMateriH5P
          url={fileH5P}
          handleClose={() => setFileH5P(null)}
          show={true}
        />
      )}
    </div>
  );
}

export default ModalDiskusi;
