import { combineReducers } from "redux";

import anakReducer from "./anakReducer";
import appReducer from "./appReducer";
import bankSoalReducer from "./bankSoalReducer";
import courseReducer from "./courseReducer";
import driveReducer from "./driveReducer";
import libraryReducer from "./libraryReducer";
import nilaiReducer from "./nilaiReducer";
import notifReducer from "./notifReducer";
import pelajaranReducer from "./pelajaranReducer";
import topikReducer from "./topikReducer";
import userReducer from "./userReducer";
import ujianReducer from "./ujianReducer";

export default combineReducers({
  user: userReducer,
  app: appReducer,
  pelajaran: pelajaranReducer,
  topik: topikReducer,
  anak: anakReducer,
  library: libraryReducer,
  nilai: nilaiReducer,
  bankSoal: bankSoalReducer,
  drive: driveReducer,
  notif: notifReducer,
  course: courseReducer,
  ujian: ujianReducer,
});
