/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Wrapper } from '../../components';

function Faq() {
  return (
    <Wrapper>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="iq-accordion career-style faq-style">
              <div className="iq-card iq-accordion-block accordion-active">
                <div className="active-faq clearfix">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-12">
                        <a className="accordion-title">
                          <span>
                            It is a long established fact that a reader will be?
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-details">
                  <p className="mb-0">
                    Many desktop publishing packages and web page editors now
                    use Lorem Ipsum as their default model text, and a search
                    for 'lorem ipsum' will uncover many web sites still in their
                    infancy.
                  </p>
                </div>
              </div>
              <div className="iq-card iq-accordion-block">
                <div className="active-faq clearfix">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-12">
                        <a className="accordion-title">
                          <span>
                            Distracted by the readable content of a page whent?
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-details" style={{ display: "none" }}>
                  <p className="mb-0">
                    It has survived not only five centuries, but also the leap
                    into electronic typesetting. Neque porro quisquam est, qui
                    dolorem ipsum quia dolor sit amet, consectetur.
                  </p>
                </div>
              </div>
              <div className="iq-card iq-accordion-block">
                <div className="active-faq clearfix">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-12">
                        <a className="accordion-title">
                          <span>What is user interface kit? </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-details" style={{ display: "none" }}>
                  <p className="mb-0">
                    It has survived not only five centuries, but also the leap
                    into electronic typesetting. Neque porro quisquam est, qui
                    dolorem ipsum quia dolor sit amet, consectetur.
                  </p>
                </div>
              </div>
              <div className="iq-card iq-accordion-block">
                <div className="active-faq clearfix">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-12">
                        <a className="accordion-title">
                          <span>
                            The readable content of a page when looking at its
                            layout?
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-details" style={{ display: "none" }}>
                  <p className="mb-0">
                    It has survived not only five centuries, but also the leap
                    into electronic typesetting. Neque porro quisquam est, qui
                    dolorem ipsum quia dolor sit amet, consectetur.
                  </p>
                </div>
              </div>
              <div className="iq-card iq-accordion-block">
                <div className="active-faq clearfix">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-12">
                        <a className="accordion-title">
                          <span>
                            The readable content of a page when looking at its
                            layout?
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-details" style={{ display: "none" }}>
                  <p className="mb-0">
                    It has survived not only five centuries, but also the leap
                    into electronic typesetting. Neque porro quisquam est, qui
                    dolorem ipsum quia dolor sit amet, consectetur.
                  </p>
                </div>
              </div>
              <div className="iq-card iq-accordion-block">
                <div className="active-faq clearfix">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-12">
                        <a className="accordion-title">
                          <span> What is user interface kit? </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-details" style={{ display: "none" }}>
                  <p className="mb-0">
                    It has survived not only five centuries, but also the leap
                    into electronic typesetting. Neque porro quisquam est, qui
                    dolorem ipsum quia dolor sit amet, consectetur.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 text-center">
            <img
              src="images/page-img/page-load-loader.gif"
              alt="loader"
              style={{ height: 100 }}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Faq;
