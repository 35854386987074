import moment from "moment";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";

import { ModalFile } from "../modal";
moment.locale("id");
const ItemPesanDiskusi = ({
  img,
  nama,
  pesan,
  imgPesan,
  date,
  parentChatId,
  handleBalas,
  subThread,
  handleDelete,
  code,
  userId,
  userOrtu,
  userGuru,
  handleEdit,
}) => {
  const { user } = useSelector(({ user }) => user);
  const ownThread = user?.user_id === userId;
  const [showImage, setShowImage] = useState(false);
  return (
    <Fragment>
      <li className={`mb-2 ${subThread ? "ms-5" : "ms-0"}`}>
        <div className="d-flex">
          <div className="user-img flex-shrink-0">
            <img
              src={img}
              alt={nama}
              className="avatar-35 rounded-circle img-fluid bg-grey"
            />
          </div>
          <div className="comment-data-block ms-3">
            <h6 className="mr-2 fw-bold mb-2">{nama}</h6>
            {imgPesan ? (
              <img
                onClick={() => setShowImage(true)}
                src={imgPesan}
                alt="pesan"
                className="rounded image-diskusi cursor-pointer"
              />
            ) : null}
            <p className="mb-0">{pesan}</p>
            <div className="d-flex flex-wrap align-items-center comment-activity font-size-12 gap-2">
              <button
                onClick={() => handleBalas(parentChatId)}
                className={`bg-transparent border-0 text-primary p-0 ${
                  userOrtu ? "d-none" : ""
                }`}
              >
                Balas
              </button>
              <button
                onClick={handleEdit}
                className={`bg-transparent border-0 text-primary p-0 ${
                  (!ownThread || userOrtu) && !userGuru ? "d-none" : ""
                }`}
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(code)}
                className={`bg-transparent border-0 text-danger p-0 ${
                  (!ownThread || userOrtu) && !userGuru ? "d-none" : ""
                }`}
              >
                Hapus
              </button>
              <span>{moment(date).fromNow()}</span>
            </div>
          </div>
        </div>
      </li>
      {imgPesan && showImage ? (
        <ModalFile
          handleClose={() => setShowImage(false)}
          show={showImage}
          type="image/png"
          url={imgPesan}
        />
      ) : null}
    </Fragment>
  );
};

export default ItemPesanDiskusi;
