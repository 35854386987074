import { GET_PROFILE, LOGIN, LOGIN_GOOGLE, LOGOUT } from "../constant";

const initState = {};

const userReducer = (state = initState, action) => {
  switch (action?.type) {
    case LOGIN + "_PENDING":
      return {};
    case LOGIN + "_FULFILLED":
      return action?.payload;
    case LOGIN + "_REJECTED":
      return {};
    case LOGIN_GOOGLE + "_PENDING":
      return {};
    case LOGIN_GOOGLE + "_FULFILLED":
      return action?.payload;
    case LOGIN_GOOGLE + "_REJECTED":
      return {};
    case GET_PROFILE + "_PENDING":
      return state;
    case GET_PROFILE + "_FULFILLED":
      return {
        ...state,
        user: action.payload,
      };
    case GET_PROFILE + "_REJECTED":
      return {};
    case LOGOUT:
      return {};
    default: {
      return state;
    }
  }
};

export default userReducer;
