import { Link } from "react-router-dom";
import { formatRupiah } from "../../utils/formatRupiah";

const CardCourseSlider = ({
  id,
  thumbnail,
  title,
  author,
  lesson,
  discount,
  price,
  type,
  level,
}) => {
  return (
    <div className="card m-0 product-box">
      <Link to={`/course/buy/${id}`} className="card-body product-content">
        <div className="row">
          <div className="col-md-4 col-4">
            <img
              src={thumbnail}
              className="card-img-fit thumbnail-course"
              alt="#"
            />
          </div>
          <div className="col-md-8 col-8">
            <h5 className="card-title elipsis-2-line">{title}</h5>
            <div className="row">
              <div className="col-8">
                <p className="text-muted font-size-13">
                  <i className="ri-user-2-fill"></i> {author}
                </p>
              </div>
              <div className="col-4">
                <p className="text-muted font-size-13 text-right">
                  <i className="ri-book-fill"></i> {lesson}
                </p>
              </div>
            </div>
            <hr className="mb-0 mt-0" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="align-self-center">
              <div className="row">
                <div className="col-8">
                  <div className="mt-2">
                    <p className="text-muted mb-2">Available</p>
                    <div className="d-flex align-items-center gap-2">
                      {discount ? (
                        <h5 className="font-size-14 fw-bold mb-0 text-danger text-decoration-line-through">
                          {formatRupiah(price)}
                        </h5>
                      ) : null}
                      <h5 className="font-size-16 fw-bold mb-0">
                        {type === "premium"
                          ? discount
                            ? formatRupiah(price - (discount / 100) * price)
                            : formatRupiah(price)
                          : "Free"}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-4 text-right">
                  <div className="mt-2">
                    <p className="text-muted mb-1">Level</p>
                    <span className="badge badge-danger">{level}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
export default CardCourseSlider;
