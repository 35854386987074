import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageLoader } from "../../assets/images";
import {
  CardUjian,
  FilterUjianGuru,
  FilterUjianSiswa,
  ModalUjian,
  Wrapper,
} from "../../components";
import {
  delUjian,
  getDataUjian,
  getUjianInfo,
} from "../../redux/actions/ujianAction";
import { checkIsGuru } from "../../utils/roleUser";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const GuruSection = ({ setShowModal }) => {
  const { t } = useTranslation();
  const [ujianInfo, setUjianInfo] = useState(null);
  useEffect(() => {
    getUjianInfo().then((res) => setUjianInfo(res?.data?.data));
  }, []);

  return (
    <div className="col-12 d-flex align-items-md-center justify-content-between mb-3 flex-column flex-md-row gap-2">
      <div className="group-member d-flex align-items-center gap-2">
        <div className="iq-media-group d-flex align-items-center">
          <figure className="iq-media mb-0">
            <img
              className="img-fluid avatar-60 rounded-circle"
              src={ujianInfo?.user_avatar}
              alt=""
            />
          </figure>
        </div>
        <div className="info">
          <h4>{ujianInfo?.user_nama}</h4>
          <p className="mb-0">
            <i className="ri-lock-fill pr-2"></i>
            {t("tahun_pelajaran")} {ujianInfo?.tahunpelajaran_nama}
          </p>
        </div>
      </div>
      <div className="d-flex gap-2 align-self-end">
        <Link
          to="/"
          className="btn iq-bg-light iq-bg-primary-hover text-dark border"
        >
          <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
        </Link>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setShowModal(true)}
        >
          <i className="ri-add-line"></i>Tambah
        </button>
      </div>
    </div>
  );
};
const Ujian = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dataUjian, loading } = useSelector(({ ujian }) => ujian);
  const { selectedAnak } = useSelector(({ anak }) => anak);
  const { user } = useSelector(({ user }) => user);
  const [showModal, setShowModal] = useState(false);
  const [editUjian, setEditUjian] = useState(null);
  const [filter, setFilter] = useState(null);

  useEffect(() => {
    if (filter) {
      dispatch(getDataUjian(filter));
    }
  }, [filter, dispatch]);

  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };

  const handleChangeFilter = (data) => setFilter(data);

  const handleEdit = (idUjian, idKelas) => {
    setShowModal(true);
    setEditUjian({ id_ujian: idUjian, id_kelas: idKelas });
  };

  const handleCloseModal = (status) => {
    setShowModal(false);
    setEditUjian(null);
    if (status) dispatch(getDataUjian(filter));
  };

  const handleDelete = (idUjian, kelasId, mapelId) => {
    const params = {
      kelasdetail_id: kelasId,
      kelasmapel_id: mapelId,
    };
    Swal.fire({
      title: t("konfirmasi_hapus"),
      text: t("are_u_sure_delete", { params: t("ujian") }),
      icon: "question",
      confirmButtonText: "Yakin",
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: "Batal",
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendDeleteUjian(idUjian, params);
    });
  };

  const sendDeleteUjian = (idUjian, params) => {
    delUjian(idUjian, params)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Berhasil dihapus",
          text: t("data_berhasil_dihapus", { data: t("ujian") }),
          showConfirmButton: false,
          timer: 1500,
        }).then(() => dispatch(getDataUjian(filter)));
      })
      .catch(() => {
        showAlert(t("gagal_hapus", { data: t("ujian") }), false);
      });
  };

  const isGuru = checkIsGuru(user?.user_roleid);
  return (
    <Wrapper title={t("ujian")}>
      <div className="container">
        <div className="row">
          {isGuru ? (
            <>
              <FilterUjianGuru onFilterChange={handleChangeFilter} />
              <GuruSection setShowModal={setShowModal} />
            </>
          ) : (
            <FilterUjianSiswa onFilterChange={handleChangeFilter} />
          )}
          {loading ? (
            <div className="col-12 text-center">
              <img src={PageLoader} alt="loader" style={{ height: "100px" }} />
            </div>
          ) : dataUjian?.length <= 0 ? (
            <p className="py-2 text-center">
              {t("data_params_kosong", { params: t("ujian") })}
            </p>
          ) : (
            dataUjian?.map((item) => (
              <CardUjian
                key={item.ujian_id}
                thumbnail={item.kelas_mapel?.kelasmapel_image}
                cover={item?.kelas_mapel?.kelasmapel_avatar}
                mapel={item?.kelas_mapel?.mapel?.mapel_nama}
                status={item?.ujian_status}
                statusPengerjaan={item?.status_pengerjaan}
                startDate={item?.ujian_mulai}
                endDate={item?.ujian_selesai}
                desc={item?.ujian_deskripsi}
                totalKolaborasi={item?.jumlah_kolaborasi}
                totalSiswa={item?.jumlah_siswa}
                totalSoal={item?.jumlah_soal}
                idUjian={item.ujian_id}
                handleEdit={handleEdit}
                kelas={selectedAnak?.kelasdetail_nama}
                namaAnak={selectedAnak?.user_nama}
                semester={item?.semester?.semester_nama}
                kolaborasi={item?.kolaborasi}
                handleDelete={handleDelete}
                jenisUjian={item.ujian_jenis}
                kelasSiswa={item?.user?.detailKelas?.kelasdetail_nama}
                kelasId={item?.kelas_mapel?.kelasmapel_kelasdetailid}
                mapelId={item?.kelas_mapel?.kelasmapel_id}
              />
            ))
          )}
        </div>
      </div>
      {isGuru && showModal && (
        <ModalUjian
          show={showModal}
          handleClose={handleCloseModal}
          tahunPelajaran={filter?.tahunpelajaran_id}
          semesterId={filter?.semester_id}
          editUjian={editUjian?.id_ujian}
          kelasId={editUjian?.id_kelas}
        />
      )}
    </Wrapper>
  );
};

export default Ujian;
