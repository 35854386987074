import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import { Wrapper } from "../../components";
import { getDetailTugasNilai } from "../../redux/actions/nilaiAction";

function PenilaianTugas() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [detail, setDetail] = useState([]);

  const getDetail = useCallback((id) => {
    getDetailTugasNilai(id).then((res) => setDetail(res?.data?.data));
  }, []);

  useEffect(() => {
    getDetail(id);
  }, [id, getDetail]);

  return (
    <Wrapper title={t("penilaian")}>
      <div className="container">
        <div className="row row-eq-height">
          <div className="col-lg-12">
            <div className="iq-card">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title d-flex">
                  <h4 className="card-title">
                    {t("penilaian")} ({detail?.title})
                  </h4>
                </div>
              </div>

              <div className="col-lg-12 p-0">
                <div className="iq-card">
                  <div className="iq-card-body">
                    <div className="btn-group float-right mb-2" role="group">
                      {detail && (
                        <Link
                          to={`/pelajaran/${detail?.topik?.kelasmapel_id}?th=${detail?.topik?.tahunpelajaran_id}&smt=${detail?.topik?.semester_id}`}
                          className="btn iq-bg-light iq-bg-primary-hover text-dark border"
                        >
                          <i className="ri-arrow-left-line m-0"></i>{" "}
                          {t("kembali")}
                        </Link>
                      )}
                      <Link
                        to={`/koreksi/${id}`}
                        className="btn btn-primary text-center"
                      >
                        <i className="ri-file-edit-line"></i>{" "}
                        {t("penilaian_essay")}
                      </Link>
                    </div>
                    <div className="table-responsive table-hover w-100">
                      <table
                        id="datatable"
                        className="table table-striped table-bordered"
                      >
                        <thead className="table-primary text-white">
                          <tr>
                            <th
                              width="5%"
                              className="bg-transparent text-center"
                            >
                              No
                            </th>
                            <th width="40%" className="bg-transparent">
                              NIS
                            </th>
                            <th width="45%" className="bg-transparent">
                              {t("nama_siswa")}
                            </th>
                            <th
                              width="10%"
                              className="bg-transparent text-center"
                            >
                              {t("nilai")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {detail?.siswas?.length > 0 ? (
                            detail?.siswas?.map((item, idx) => (
                              <tr key={item.user_id}>
                                <td className="text-center">{idx + 1}</td>
                                <td>{item?.user?.user_no}</td>
                                <td>{item?.user?.user_nama}</td>
                                <td className="text-center">
                                  <b style={{ color: "green" }}>
                                    {item?.user?.pengerjaan_tugas
                                      ?.pengerjaan_nilai || 0}
                                  </b>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={4} className="text-center">
                                {t("data_tidak_ada")}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default PenilaianTugas;
