import React from "react";
import {
  ItemSoalEssay,
  ItemSoalMenjodohkan,
  ItemSoalMultiple,
  ItemSoalPernyataan,
  ItemSoalPilgan,
  ItemSoalTrueFalse,
} from "../item";

/**
 * Renders different types of input components based on the value of the `soalJenis` parameter.
 * @param {Object} props - The input component props.
 * @param {string} props.soalJenis - The type of input component to render.
 * @param {Function} props.handleChangeJWBPilgan - The function to handle the change event for the pilgan input component.
 * @param {Object} props.soalPilgan - The data for the pilgan input component.
 * @param {Function} props.handleChangeFilePG - The function to handle the file change event for the pilgan input component.
 * @param {Function} props.handleDeleteFilePG - The function to handle the file deletion event for the pilgan input component.
 * @param {boolean} props.uploadFileJwb - Indicates whether the file is being uploaded for the input component.
 * @param {Function} props.handleChangeJWBPooling - The function to handle the change event for the pooling input component.
 * @param {Object} props.soalPooling - The data for the pooling input component.
 * @param {Function} props.handleChangeFilePooling - The function to handle the file change event for the pooling input component.
 * @param {Function} props.handleDeleteFilePooling - The function to handle the file deletion event for the pooling input component.
 * @param {Function} props.handleChangeJWBEssay - The function to handle the change event for the essay input component.
 * @param {Object} props.soalEssay - The data for the essay input component.
 * @param {Function} props.handleChangeFileEssay - The function to handle the file change event for the essay input component.
 * @param {Function} props.handleDelFileEssay - The function to handle the file deletion event for the essay input component.
 * @param {Function} props.handleChangeJWBTF - The function to handle the change event for the true/false input component.
 * @param {Object} props.soalTrueFalse - The data for the true/false input component.
 * @param {Function} props.handleChangeFileTrueFalse - The function to handle the file change event for the true/false input component.
 * @param {Function} props.handleDeleteFileTrueFalse - The function to handle the file deletion event for the true/false input component.
 * @param {Object} props.soalMenjodohkan - The data for the menjodohkan input component.
 * @param {Function} props.handleAddPernyataanJodoh - The function to handle adding pernyataan in the menjodohkan input component.
 * @param {Function} props.handleDelPernyataanJodoh - The function to handle deleting pernyataan in the menjodohkan input component.
 * @param {Function} props.changePernyataanJodoh - The function to handle changing pernyataan in the menjodohkan input component.
 * @param {Function} props.changeJawabanJodoh - The function to handle changing jawaban in the menjodohkan input component.
 * @param {Function} props.handleAddPilihanJodoh - The function to handle adding pilihan in the menjodohkan input component.
 * @param {Function} props.handleDelPilihanJodoh - The function to handle deleting pilihan in the menjodohkan input component.
 * @param {Function} props.changePilihanIsiJodoh - The function to handle changing pilihan isi in the menjodohkan input component.
 * @param {Function} props.handleChangeNamaMenjodohkan - The function to handle changing nama in the menjodohkan input component.
 * @param {Function} props.handleChangeJWBPernyataan - The function to handle the change event for the pernyataan input component.
 * @param {Object} props.soalPernyataan - The data for the pernyataan input component.
 * @param {Function} props.handleAddSoalPernyataan - The function to handle adding pilihan in the pernyataan input component.
 * @param {Function} props.handleDelSoalPernyataan - The function to handle deleting pilihan in the pernyataan input component.
 * @param {Function} props.changeSoalPernyataan - The function to handle changing soal pernyataan in the pernyataan input component.
 * @param {Function} props.changeJawabanSoalPernyataan - The function to handle changing jawaban in the pernyataan input component.
 * @returns {JSX.Element} - The rendered input component based on the value of `soalJenis`.
 */
const RenderSoalInput = (props) => {
  const {
    soalJenis,
    handleChangeJWBPilgan,
    soalPilgan,
    handleChangeFilePG,
    handleDeleteFilePG,
    uploadFileJwb,
    handleChangeJWBPooling,
    soalPooling,
    handleChangeFilePooling,
    handleDeleteFilePooling,
    handleChangeJWBEssay,
    soalEssay,
    handleChangeFileEssay,
    handleDelFileEssay,
    handleChangeJWBTF,
    soalTrueFalse,
    handleChangeFileTrueFalse,
    handleDeleteFileTrueFalse,
    soalMenjodohkan,
    handleAddPernyataanJodoh,
    handleDelPernyataanJodoh,
    changePernyataanJodoh,
    changeJawabanJodoh,
    handleAddPilihanJodoh,
    handleDelPilihanJodoh,
    changePilihanIsiJodoh,
    handleChangeNamaMenjodohkan,
    handleChangeJWBPernyataan,
    soalPernyataan,
    handleAddSoalPernyataan,
    handleDelSoalPernyataan,
    changeSoalPernyataan,
    changeJawabanSoalPernyataan,
  } = props;

  switch (soalJenis) {
    case "pg":
      return (
        <ItemSoalPilgan
          handleChange={handleChangeJWBPilgan}
          pilgan={soalPilgan}
          handleChangeFile={handleChangeFilePG}
          handleDeleteFile={handleDeleteFilePG}
          loadingUpload={uploadFileJwb}
        />
      );
    case "polling":
      return (
        <ItemSoalMultiple
          handleChange={handleChangeJWBPooling}
          pooling={soalPooling}
          handleChangeFile={handleChangeFilePooling}
          handleDeleteFile={handleDeleteFilePooling}
          loadingUpload={uploadFileJwb}
        />
      );
    case "essay":
      return (
        <ItemSoalEssay
          handleChange={handleChangeJWBEssay}
          essay={soalEssay}
          handleChangeFile={handleChangeFileEssay}
          loadingUpload={uploadFileJwb}
          handleDelFile={handleDelFileEssay}
        />
      );
    case "true_false":
      return (
        <ItemSoalTrueFalse
          data={soalTrueFalse}
          handleChangeFile={handleChangeFileTrueFalse}
          handleDeleteFile={handleDeleteFileTrueFalse}
          loadingUpload={uploadFileJwb}
          handleChange={handleChangeJWBTF}
        />
      );
    case "menjodohkan":
      return (
        <ItemSoalMenjodohkan
          data={soalMenjodohkan}
          addPernyataan={handleAddPernyataanJodoh}
          delPernyataan={handleDelPernyataanJodoh}
          changePernyataan={changePernyataanJodoh}
          changeJawaban={changeJawabanJodoh}
          addPilihan={handleAddPilihanJodoh}
          delPilihan={handleDelPilihanJodoh}
          changePilihanIsi={changePilihanIsiJodoh}
          changeNama={handleChangeNamaMenjodohkan}
        />
      );
    case "pernyataan":
      return (
        <ItemSoalPernyataan
          handleChange={handleChangeJWBPernyataan}
          soalPernyataan={soalPernyataan}
          addPilihan={handleAddSoalPernyataan}
          delPilihan={handleDelSoalPernyataan}
          changePernyataan={changeSoalPernyataan}
          changeJawaban={changeJawabanSoalPernyataan}
        />
      );
    default:
      return null;
  }
};

export default RenderSoalInput;
