import React, { Fragment, useEffect } from "react";

import Footer from "./Footer";
import Header from "./Header";

function Wrapper({ children, title }) {
  useEffect(() => {
    document.title = `${title} | Smartclass Bakti Mulya 400`;
  }, [title]);

  useEffect(() => {
    let loading = document.getElementById("loading");
    let timeout = setTimeout(() => {
      loading.classList.add("d-none");
    }, 1000);
    window?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Fragment>
      <div id="loading">
        <div id="loading-center"></div>
      </div>
      <div className="wrapper">
        <Header />
        <div id="content-page" className="content-page">
          {children}
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Wrapper;
