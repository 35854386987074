import { GET_ANAK, LOGOUT, SET_SELECTED_ANAK } from "../constant";

const initState = {
  dataAnak: [],
  selectedAnak: null,
};

const anakReducer = (state = initState, action) => {
  switch (action?.type) {
    case GET_ANAK + "_PENDING":
      return state;
    case GET_ANAK + "_FULFILLED":
      return {
        ...state,
        dataAnak: action?.payload,
      };
    case GET_ANAK + "_REJECTED":
      return state;
    case SET_SELECTED_ANAK:
      console.log("set anak", action?.payload);
      return {
        ...state,
        selectedAnak: action.payload,
      };
    case LOGOUT:
      return {
        dataAnak: [],
        selectedAnak: null,
      };
    default: {
      return state;
    }
  }
};

export default anakReducer;
