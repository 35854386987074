import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function KelasCard({
  thumbnail,
  img,
  kelas,
  guru,
  siswa,
  pertemuan,
  tugas,
  member,
  link,
  namaKelas,
  semester,
}) {
  const { t } = useTranslation();
  return (
    <div className="col-md-6 col-lg-4">
      <div className="iq-card">
        <div className="top-bg-image">
          <img
            src={thumbnail}
            className="img-fluid w-100"
            alt={kelas}
            onError={(e) => {
              e.target.src = require("../../assets/images/default_thumbnail.webp");
              e.target.onError = null;
            }}
          />
        </div>
        <div className="iq-card-body text-center">
          <div className="group-icon">
            <img
              src={img}
              alt="profile-img"
              className="rounded-circle img-fluid avatar-120"
            />
          </div>
          <div className="group-info pt-3 pb-3">
            <h4>
              <Link to={link}>{kelas}</Link>
            </h4>
            <div className="d-flex gap-1 justify-content-center">
              <p className="text-center m-0">{namaKelas}</p>
              {semester ? (
                <p className="text-center m-0">(Semester {semester})</p>
              ) : null}
            </div>
            {guru?.map((item, i) => (
              <p className="m-0" key={i}>
                {item}
              </p>
            ))}
          </div>
          <div className="group-details d-inline-block pb-3">
            <ul className="d-flex align-items-center justify-content-between list-inline m-0 p-0">
              <li className="ps-3 pe-3">
                <p className="mb-0">{t("siswa")}</p>
                <h6>{siswa}</h6>
              </li>
              <li className="ps-3 pe-3">
                <p className="mb-0">{t("topik")}</p>
                <h6>{pertemuan}</h6>
              </li>
              <li className="ps-3 pe-3">
                <p className="mb-0">{t("tugas")}</p>
                <h6>{tugas}</h6>
              </li>
            </ul>
          </div>
          <div className="group-member mb-3">
            <div className="iq-media-group">
              {member?.map((item, i) => (
                <Link to="/" className="iq-media" key={i}>
                  <img
                    className="img-fluid avatar-40 rounded-circle"
                    src={item.avatar}
                    alt="siswa"
                  />
                </Link>
              ))}
            </div>
          </div>
          <Link to={link} className="btn btn-primary d-block">
            {t("enter")}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default KelasCard;
