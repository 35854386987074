import { useTranslation } from 'react-i18next';

import { AUDIO_EXT, PNG_IMG_EXT, SVG_IMG_EXT, VIDEO_EXT } from '../../config/fileType';

const MejodohkanImportSoal = ({data, index, checked, handleChange}) => {
    const {t} = useTranslation();
    return (
        <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title d-flex align-items-center">
                    <div className="checkbox d-inline-block me-3">
                        <input
                        className="rabsen"
                        style={{ width: 25 }}
                        type="checkbox"
                        name="cerita"
                        id={data.soal_id}
                        checked={checked}
                        onChange={({target:{checked}}) => handleChange(data.soal_id, checked)}
                        />
                    </div>
                    <h4 className="card-title">{t('soal')} {index + 1}</h4>&nbsp;
                    <span className="badge badge-pill badge-danger h-100 align-self-center">
                        <i className="ri-checkbox-line"></i> {t("menjodohkan")}
                    </span>
                </div>
                <div className="d-flex border rounded">
                    <i className="ri-flag-2-fill ms-1 text-dark font-size-18 "></i>
                    <select
                        className="form-control form-control-sm bg-transparent border-0 font-size-14 fw-bold me-0"
                        value={data?.soal_points}
                        disabled
                    >
                        <option className="bg-white fw-bold" value="0">0 {t('poin')}</option>
                        <option className="bg-white fw-bold" value="1">1 {t('poin')}</option>
                        <option className="bg-white fw-bold" value="2">2 {t('poin')}</option>
                        <option className="bg-white fw-bold" value="3">3 {t('poin')}</option>
                        <option className="bg-white fw-bold" value="4">4 {t('poin')}</option>
                        <option className="bg-white fw-bold" value="5">5 {t('poin')}</option>
                        <option className="bg-white fw-bold" value="6">6 {t('poin')}</option>
                        <option className="bg-white fw-bold" value="7">7 {t('poin')}</option>
                        <option className="bg-white fw-bold" value="8">8 {t('poin')}</option>
                        <option className="bg-white fw-bold" value="9">9 {t('poin')}</option>
                        <option className="bg-white fw-bold" value="10">10 {t('poin')}</option>
                    </select>
                </div>
            </div>
            <div className="iq-card-body">
                <div className="d-flex">
                    {data?.soal_file && <div className="me-3">
                    {SVG_IMG_EXT?.includes(data.soal_file_type) || PNG_IMG_EXT?.includes(data.soal_file_type) ? (
                        <img
                        src={data.soal_file}
                        className="img-fluid rounded iq-bg-dark" width="120" height="120"
                        alt="foto soal"
                        />
                    ) : VIDEO_EXT?.includes(data.soal_file_type) ? (
                        <video
                        src={data.soal_file}
                        controls
                        className="w-100 h-auto"
                        ></video>
                    ) : AUDIO_EXT?.includes(data.soal_file_type) ? (
                        <audio src={data.soal_file} controls></audio>
                    ) : (
                        <p className="m-0 text-muted text-italic">
                        {t('format_tdk_diketahui')}
                        </p>
                    )}
                    </div>}
                    <div className="w-100" dangerouslySetInnerHTML={{__html: data?.soal_pertanyaan}} />
                </div>
                <hr />
                <p className="mb-1">{t('jawaban')} :</p>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="table-responsive">
                            <table id="datatable" className="table table-bordered table-hover">
                                <thead className="table-primary text-white">
                                    <tr>
                                        <th width="10%" className="text-center bg-transparent">
                                            No
                                        </th>
                                        <th width="75%" className='bg-transparent'>{t('pernyataan')}</th>
                                        <th width="15%" className="text-center bg-transparent">
                                            {t('jawaban')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.soal_menjodohkan?.pernyataan?.map((item, idx) => (
                                            <tr key={item.soal_menjodohkan_pernyataan_id}>
                                                <td className="text-center">{idx + 1}</td>
                                                <td>{item.soal_menjodohkan_pernyataan}</td>
                                                <td className="text-center">{item.soal_menjodohkan_jawaban}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="table-responsive">
                            <table id="datatable" className="table table-bordered table-hover">
                                <thead className="table-primary text-white">
                                    <tr>
                                        <th className="text-center bg-transparent" width="10%">
                                            Key
                                        </th>
                                        <th className='bg-transparent'>Benda</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.soal_menjodohkan?.pilihan?.map((item) => (
                                            <tr key={item.soal_menjodohkan_pilihan_id}>
                                                <td className="text-center">{item.soal_menjodohkan_pilihan_key}</td>
                                                <td>{item.soal_menjodohkan_pilihan_isi}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MejodohkanImportSoal;