import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import { PageLoader } from "../../assets/images";
import { getBobotSoal, updBobotSoal } from "../../redux/actions/tugasAction";

function BobotSoal({ show, handleClose, tugasId }) {
  const { t } = useTranslation();
  const [submit, setSubmit] = useState(false);
  const [bobot, setBobot] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    setSubmit(true);
    updBobotSoal({ ...bobot, tugas_id: tugasId })
      .then(() => {
        showAlert(t("berhasil_simpan", { params: t("bobot_soal") }), true);
        setSubmit(false);
      })
      .catch(() => {
        showAlert(t("data_gagal_simpan", { params: t("bobot_soal") }), false);
        setSubmit(false);
      });
  };
  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };
  useEffect(() => {
    setLoading(true);
    getBobotSoal(tugasId)
      .then((res) => {
        setBobot(res?.data?.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [tugasId]);

  const handleChange = ({ target: { name, value } }) => {
    setBobot((pref) => ({
      ...pref,
      [name]: value,
    }));
  };

  return (
    <div className="col-lg-12">
      <Modal
        show={show}
        onHide={() => {
          if (!submit) handleClose();
        }}
        id="BobotSoal"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title">{t("setting_bobot_soal")}</h5>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="text-center">
              <img src={PageLoader} alt="loader" width={50} height={66} />
            </div>
          ) : (
            <div className="row">
              {bobot?.bobot_pg !== undefined && (
                <div className="col-lg-12">
                  <div className="row">
                    <label htmlFor="bobot_pg" className="col-12">
                      {t("pilihan_ganda")} :
                    </label>
                    <div className="col-12">
                      <input
                        type="number"
                        className="form-control"
                        id="bobot_pg"
                        placeholder="0%"
                        name="bobot_pg"
                        value={bobot?.bobot_pg}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              )}
              {bobot?.bobot_essay !== undefined && (
                <div className="col-lg-12">
                  <div className="row">
                    <label htmlFor="bobot_essay" className="col-12">
                      {t("isian_uraian")} :
                    </label>
                    <div className="col-12">
                      <input
                        type="number"
                        className="form-control"
                        id="bobot_essay"
                        placeholder="0%"
                        name="bobot_essay"
                        value={bobot?.bobot_essay}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              )}
              {bobot?.bobot_polling !== undefined && (
                <div className="col-lg-12">
                  <div className="row">
                    <label htmlFor="bobot_polling" className="col-12">
                      {t("pemilihan")} :
                    </label>
                    <div className="col-12">
                      <input
                        type="number"
                        className="form-control"
                        id="bobot_polling"
                        placeholder="0%"
                        name="bobot_polling"
                        value={bobot?.bobot_polling}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              )}
              {bobot?.bobot_true_false !== undefined && (
                <div className="col-lg-12">
                  <div className="row">
                    <label htmlFor="bobot_true_false" className="col-12">
                      {t("benar_salah")} :
                    </label>
                    <div className="col-12">
                      <input
                        type="number"
                        className="form-control"
                        id="bobot_true_false"
                        placeholder="0%"
                        name="bobot_true_false"
                        value={bobot?.bobot_true_false}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              )}
              {bobot?.bobot_pernyataan !== undefined && (
                <div className="col-lg-12">
                  <div className="row">
                    <label htmlFor="bobot_pernyataan" className="col-12">
                      {t("pernyataan")} :
                    </label>
                    <div className="col-12">
                      <input
                        type="number"
                        className="form-control"
                        id="bobot_pernyataan"
                        placeholder="0%"
                        name="bobot_pernyataan"
                        value={bobot?.bobot_pernyataan}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              )}
              {bobot?.bobot_menjodohkan !== undefined && (
                <div className="col-lg-12">
                  <div className="row">
                    <label htmlFor="bobot_menjodohkan" className="col-12">
                      {t("menjodohkan")} :
                    </label>
                    <div className="col-12">
                      <input
                        type="number"
                        className="form-control"
                        id="bobot_menjodohkan"
                        placeholder="0%"
                        name="bobot_menjodohkan"
                        value={bobot?.bobot_menjodohkan}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleClose}
          >
            {t("tutup")}
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={submit}
          >
            {submit ? (
              <img src={PageLoader} alt="loader" style={{ width: 15 }} />
            ) : (
              t("save_changes")
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BobotSoal;
