import React from "react";
import { Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import {
  AUDIO_EXT,
  EXCEL_EXT,
  MS_EXT,
  PPT_EXT,
  VIDEO_EXT,
} from "../../config/fileType";

function ModalPreviewDrive({ type, url, nama, show, handleClose }) {
  return (
    <div className="col-lg-12">
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        id="ModalPreviewDrive"
        fullscreen
      >
        <Modal.Header className="border-0 bg-black">
          <h4 className="modal-title text-white m-0">{nama}</h4>
          <button
            onClick={handleClose}
            className="bg-transparent border-0 text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-x"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Container className="h-100">
            {type === "application/pdf" ? (
              <iframe
                src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${url}`}
                title="preview"
                height="100%"
                width="100%"
              />
            ) : EXCEL_EXT.includes(type) ||
              MS_EXT.includes(type) ||
              PPT_EXT.includes(type) ? (
              <div className="embed-responsive embed-responsive-ppt">
                <iframe
                  className="embed-responsive-item ppt"
                  src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
                  allowFullScreen
                  title="materi video"
                ></iframe>
              </div>
            ) : VIDEO_EXT.includes(type) ? (
              <video src={url} controls className="w-100 h-auto"></video>
            ) : AUDIO_EXT.includes(type) ? (
              <div className="mx-auto w-fit">
                <audio src={url} controls />
              </div>
            ) : (
              <img
                src={url}
                alt="preview file"
                className="w-100 h-100 contain-image"
              />
            )}
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalPreviewDrive;
