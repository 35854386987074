import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { Logo1 } from "../../assets/images";
import {
  BobotSoal,
  Cerita,
  ListSoal,
  PreviewSoal,
  SettingSiswa,
  TambahTugas,
  Wrapper,
} from "../../components";
import FilterSoal from "../../components/modal/FilterSoal";
import { DEFAULT_FILTER_SOAL } from "../../config/defaultFilterSoal";
import { getListSiswa } from "../../redux/actions/pelajaranAction";
import { getTopik } from "../../redux/actions/topikAction";
import {
  addSoal,
  addSoalCerita,
  delCerita,
  delSoal,
  delTugas,
  exportSoal,
  getDetailTugas,
  getListSoal,
  getSoalCerita,
  publishTugas,
  unpublishTugas,
  updHidePembahasan,
} from "../../redux/actions/tugasAction";

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
function Tugas() {
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const topik = useSelector(({ topik }) => topik);
  const [showSelectSiswa, setShowSelectSiswa] = useState(false);
  const [detail, setDetail] = useState({});
  const [dataSoal, setDataSoal] = useState([]);
  const [dataSoalCerita, setDataSoalCerita] = useState([]);
  const [listSiswa, setListSiswa] = useState([]);
  const [showModalTugas, setShowModalTugas] = useState(false);
  const [loadingPembahasan, setLoadingPembahasan] = useState(false);
  // const [loadingSoalUrut, setLoadingSoalUrut] = useState(false);
  const [previewSoal, setPreviewSoal] = useState(false);
  const [showBobotSoal, setShowBobotSoal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [filter, setFilter] = useState(DEFAULT_FILTER_SOAL);
  const [search, setSearch] = useState("");

  const getDetail = useCallback(async (id) => {
    const response = await getDetailTugas(id);

    if (response) {
      setDetail(response?.data?.data);
    }
    getListSoal(id).then((res) => setDataSoal(res?.data?.data));
    getSoalCerita(id).then((res) => setDataSoalCerita(res?.data?.data));
  }, []);

  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };

  const handleDeleteTugas = () => {
    Swal.fire({
      title: t("konfirmasi_hapus"),
      text: t("are_u_sure_delete", { params: t("tugas") }),
      icon: "question",
      confirmButtonText: "Yakin",
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: "Batal",
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendDeleteTugas();
    });
  };

  const sendDeleteTugas = () => {
    delTugas(id)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Berhasil dihapus",
          showConfirmButton: false,
          timer: 1500,
        }).then(() =>
          navigate(
            `/pelajaran/${detail?.topik?.kelasmapel_id}?th=${detail?.topik?.tahunpelajaran_id}&smt=${detail?.topik?.semester_id}`
          )
        );
      })
      .catch(() => {
        showAlert(t("gagal_hapus", { data: t("tugas") }), false);
      });
  };

  const handleDeleteSoal = (code) => {
    Swal.fire({
      title: t("konfirmasi_hapus"),
      text: t("are_u_sure_delete", { params: t("soal") }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: "Batal",
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendDeleteSoal(code);
    });
  };

  const sendDeleteSoal = (code) => {
    delSoal(code)
      .then(() => {
        getDetail(id);
      })
      .catch(() => {
        showAlert(t("gagal_hapus", { data: t("soal") }), false);
      });
  };

  const handleCopySoal = (data) => {
    Swal.fire({
      title: t("konfirmasi"),
      text: t("apakah_anda_yakin_menyalin", { data: t("soal") }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendCopySoal(data);
    });
  };

  const sendCopySoal = (data) => {
    addSoal(data)
      .then(() => {
        getDetail(id);
      })
      .catch(() => {
        showAlert(t("gagal_menyalin", { data: t("soal") }), false);
      });
  };

  const handleDeleteCerita = (code) => {
    Swal.fire({
      title: t("konfirmasi_hapus"),
      text: t("are_u_sure_delete", { params: t("soal_cerita") }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendDeleteCerita(code);
    });
  };

  const sendDeleteCerita = (code) => {
    delCerita(code)
      .then(() => {
        getDetail(id);
      })
      .catch(() => {
        showAlert(t("gagal_hapus", { data: t("soal_cerita") }), false);
      });
  };

  const handleHidePembahasan = () => {
    const params = {
      tugas_id: id,
      hide_pembahasan: detail?.hide_pembahasan ? 0 : 1,
    };
    setLoadingPembahasan(true);
    updHidePembahasan(params)
      .then(() => {
        setLoadingPembahasan(false);
        getDetail(id);
      })
      .catch((err) => {
        setLoadingPembahasan(false);
        showAlert(
          t("pembahasan_gagal", {
            status: detail?.hide_pembahasan ? t("sembunyikan") : t("tampilkan"),
          }),
          false
        );
      });
  };

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, [id]);
  useEffect(() => {
    if (detail?.topik?.kelas_mapel?.kelasmapel_kelasdetailid) {
      let params = new URLSearchParams(location.search);
      let th = params.get("th");
      let smt = params.get("smt");
      let data = {};
      if (th) data["tahunpelajaran_id"] = th;
      if (smt) data["semester_id"] = smt;
      getListSiswa(detail?.topik?.kelas_mapel?.kelasmapel_kelasdetailid, data)
        .then((res) => {
          setListSiswa(res?.data?.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    if (detail?.topik?.kelasmapel_id) {
      let params = new URLSearchParams(location.search);
      let th = params.get("th");
      let smt = params.get("smt");
      const data = {
        tahunpelajaran_id: th,
        semester_id: smt,
      };
      dispatch(getTopik(detail?.topik?.kelasmapel_id, data));
    }
  }, [detail]);

  const handlePublishTugas = () => {
    Swal.fire({
      title: t(
        detail?.status === "publish"
          ? "konfirmasi_unpublish"
          : "konfirmasi_publish"
      ),
      text: t(
        detail?.status === "publish"
          ? "msg_unpublish_tugas"
          : "msg_publish_tugas"
      ),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed && detail?.status !== "publish") {
        sendPublishTugas();
      } else {
        sendUnpublishTugas();
      }
    });
  };

  const sendPublishTugas = () => {
    publishTugas({ tugas_id: id })
      .then(() => {
        getDetail(id);
      })
      .catch(() => {
        showAlert(t("gagal_publish"), false);
      });
  };

  const sendUnpublishTugas = () => {
    const params = {
      tugas_id: id?.toString(),
    };
    unpublishTugas(params)
      .then(() => {
        getDetail(id);
      })
      .catch((err) => {
        showAlert(t("failed_edit_data", { data: t("tugas") }), false);
      });
  };

  const handleCopyCerita = (data) => {
    Swal.fire({
      title: t("konfirmasi"),
      text: t("apakah_anda_yakin_menyalin", { data: t("soal_cerita") }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendCopySoalCerita(data);
    });
  };

  const sendCopySoalCerita = (data) => {
    addSoalCerita({ ...data, detail: [] })
      .then(() => {
        getDetail(id);
      })
      .catch(() => {
        showAlert(t("gagal_menyalin", { data: t("soal_cerita") }), false);
      });
  };

  const handleExport = () => {
    const params = {
      tugas_id: id,
    };
    setLoadingExport(true);
    exportSoal(params)
      .then(() => {
        setLoadingExport(false);
        showAlert(t("soal_berhasil_export"), true);
        getDetail(id);
      })
      .catch((err) => {
        setLoadingExport(false);
        showAlert(t("terjadi_kesalahan"), false);
      });
  };

  const handleChangeFilter = (name) => {
    if (name === "all") {
      setFilter(filter.length === 7 ? [] : DEFAULT_FILTER_SOAL);
      return;
    }
    let cek = filter?.filter((item) => item === name);
    if (cek.length <= 0) {
      setFilter((pref) => [...pref, name]);
    } else {
      setFilter((pref) => [...pref.filter((item) => item !== name)]);
    }
  };

  const checkFilter = (code) => {
    let cek = filter.filter((item) => item === code);
    if (cek.length > 0) return true;
    return false;
  };

  return (
    <Wrapper title={t("tugas")}>
      <div className="container">
        <div className="row row-eq-height">
          <div className="col-lg-12">
            <div className="iq-card">
              <div className="iq-card-body">
                <div className="d-flex">
                  <div className="me-3">
                    <img
                      src={Logo1}
                      className="img-fluid rounded p-2 iq-bg-dark"
                      width="120"
                      height="120"
                      alt="logo"
                    />
                  </div>
                  <div className="w-100">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center gap-8">
                        <span>{t("tugas")}</span>
                        {detail?.status === "publish" && (
                          <span className="badge badge-pill badge-primary">
                            {t("soal_dipublish")}
                          </span>
                        )}
                      </div>

                      <div className="btn-group" role="group">
                        {detail?.topik && (
                          <Link
                            to={`/pelajaran/${detail?.topik?.kelasmapel_id}?th=${detail?.topik?.tahunpelajaran_id}&smt=${detail?.topik?.semester_id}`}
                            className="btn btn-sm iq-bg-light iq-bg-primary-hover text-dark align-items-center gap-8 border py-2 d-none d-md-flex"
                          >
                            <i className="ri-arrow-left-line m-0"></i>
                            <span className="d-none d-md-block">
                              {t("kembali")}
                            </span>
                          </Link>
                        )}
                        <button
                          onClick={handlePublishTugas}
                          className="btn btn-sm iq-bg-light iq-bg-primary-hover text-dark d-flex align-items-center gap-8 border py-2"
                        >
                          <i
                            className={
                              detail?.status === "publish"
                                ? "ri-download-line"
                                : "ri-upload-line"
                            }
                          ></i>
                          <span className="d-none d-md-block">
                            {t(
                              detail?.status === "publish"
                                ? "unpublish_tugas"
                                : "publish_tugas"
                            )}
                          </span>
                        </button>
                      </div>
                    </div>
                    <h4 className="fw-bold">{detail?.title ?? "-"}</h4>
                    <div className="d-flex me-4 align-items-center gap-8">
                      <div>
                        <i className="ri-user-line "></i>{" "}
                        {detail?.list_siswa?.length || 0} {t("siswa")}
                      </div>
                      <div>
                        <i className="ri-flag-2-line "></i> {detail?.points}{" "}
                        point
                      </div>
                    </div>
                    <div className="d-flex me-4 align-items-center gap-8">
                      {detail?.end_date && (
                        <div>
                          <i className="ri-timer-line "></i> {t("due")}{" "}
                          {detail?.end_date
                            ? moment(detail?.end_date).format("DD MMM (HH:mm)")
                            : ""}
                        </div>
                      )}
                      <div>
                        <i className="ri-list-check "></i>{" "}
                        {dataSoal?.length ?? 0} {t("soal")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4 d-flex justify-content-between flex-column flex-md-row">
                  <div className="group-member d-flex align-items-center">
                    <div className="iq-media-group">
                      <img
                        className="img-fluid avatar-40 rounded-circle"
                        src={detail?.user?.user_avatar}
                        alt=""
                      />
                    </div>
                    &nbsp;
                    <div className="info">
                      <h6>{detail?.user?.user_nama} </h6>
                      <span className="mb-0 font-size-12">
                        {detail?.createdAt
                          ? moment(detail?.createdAt).format("DD MMMM YYYY")
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="btn-group mt-3" role="group">
                      <button
                        onClick={() => setShowBobotSoal(true)}
                        className="btn btn-sm iq-bg-secondary iq-bg-primary-hover text-dark align-self-end fw-bolder"
                      >
                        <i className="ri-percent-line"></i> {t("bobot")}
                      </button>
                      <button
                        onClick={() => setPreviewSoal(true)}
                        className="btn btn-sm iq-bg-secondary iq-bg-primary-hover text-dark align-self-end fw-bolder"
                      >
                        <i className="ri-computer-line m-0"></i> {t("preview")}
                      </button>
                      {detail?.status !== "publish" && (
                        <button
                          onClick={() => setShowModalTugas(true)}
                          className="btn btn-sm iq-bg-secondary iq-bg-primary-hover text-dark align-self-end fw-bolder bg-transparent"
                        >
                          <i className="ri-edit-line m-0"></i> Edit
                        </button>
                      )}
                      {/* &nbsp;
                      <button
                        onClick={() => setShowSelectSiswa(true)}
                        className="btn btn-sm iq-bg-secondary iq-bg-primary-hover text-dark align-self-end fw-bolder bg-transparent"
                      >
                        <i className="ri-group-line m-0"></i> Siswa
                      </button> */}
                      &nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {detail?.status !== "publish" && (
              <div className="d-flex mb-3 gap-8">
                <Link
                  to={`/tambah-soal/${id}`}
                  className="btn btn-primary d-block flex-1 border-primary font-size-18 fw-bold"
                  style={{
                    borderRadius: "0.75rem",
                    boxShadow: "0 3px 0 0 #64a963",
                  }}
                >
                  <i className="ri-add-line"></i> {t("tambah")} {t("soal")}
                </Link>
                <button
                  className="btn btn-danger d-block flex-1 border-danger font-size-18 fw-bold"
                  style={{
                    borderRadius: "0.75rem",
                    boxShadow: "0 3px 0 0 #db7564",
                  }}
                  onClick={handleDeleteTugas}
                >
                  <i className="ri-delete-bin-line"></i> {t("hapus")}{" "}
                  {t("tugas")}
                </button>
              </div>
            )}
            <div className="d-flex justify-content-between align-items-md-center flex-column flex-md-row mb-3 gap-8">
              <div className="font-size-16 d-flex gap-8">
                <button
                  onClick={handleHidePembahasan}
                  className="btn btn-sm iq-bg-light bg-white iq-bg-primary-hover text-dark border w-fit h-fit"
                  disabled={loadingPembahasan}
                >
                  <i
                    className={`${
                      detail?.hide_pembahasan
                        ? "ri-eye-line"
                        : "ri-eye-off-line"
                    } m-0`}
                  ></i>{" "}
                  {loadingPembahasan
                    ? "Loading..."
                    : detail?.hide_pembahasan
                    ? "Tampilkan Pembahasan"
                    : "Sembunyikan Pembahasan"}
                </button>
                {detail?.status !== "publish" && (
                  <Dropdown align="end">
                    <Dropdown.Toggle
                      variant="success"
                      className="btn btn-sm iq-bg-light bg-white iq-bg-primary-hover text-dark border"
                      id="dropdown-basic"
                    >
                      <i className="ri-folder-3-line m-0"></i>{" "}
                      {loadingExport ? "Loading..." : t("bank_soal")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          navigate(
                            `/import-bank-soal/${detail?.topik?.kelasmapel_id}/${id}`
                          );
                        }}
                      >
                        {t("import_soal")}
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={loadingExport}
                        onClick={handleExport}
                      >
                        {t("export_soal")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
              <div className="d-flex gap-8">
                <div className="d-flex flex-1 gap-8">
                  <input
                    type="search"
                    className="form-control form-control-md bg-white "
                    placeholder="Search"
                    value={search}
                    onChange={({ target: { value } }) => setSearch(value)}
                  />
                  <button
                    onClick={() => setShowFilter(true)}
                    className="btn bg-warning text-dark fw-bolder h-100 d-flex align-items-center border"
                  >
                    <i className="ri-equalizer-line m-0"></i>&nbsp;Filter
                  </button>
                </div>
              </div>
            </div>
            {checkFilter("cerita") &&
              dataSoalCerita
                ?.filter((item) => {
                  if (search.length <= 0) return true;
                  return item.cerita_deskripsi
                    .toLowerCase()
                    .includes(search.toLowerCase());
                })
                ?.map((item, index) => (
                  <Cerita
                    key={crypto.randomUUID()}
                    data={item}
                    index={index}
                    handleCopy={() => handleCopyCerita(item)}
                    delCerita={handleDeleteCerita}
                    dataSoal={dataSoal}
                    publishTugas={detail?.status === "publish"}
                  />
                ))}
            {dataSoal?.length <= 0 && dataSoalCerita.length <= 0 ? (
              <p className="text-center">
                {t("data_params_kosong", { params: t("soal") })}
              </p>
            ) : (
              dataSoal
                .filter((item) => filter.includes(item.soal_jenis))
                ?.filter((item) => {
                  if (search.length <= 0) return true;
                  return item.soal_pertanyaan
                    .toLowerCase()
                    .includes(search.toLowerCase());
                })
                ?.map((item, index) => (
                  <ListSoal
                    handleCopy={handleCopySoal}
                    handleDelete={handleDeleteSoal}
                    index={index}
                    item={item}
                    soalJenis={item?.soal_jenis}
                    status={detail?.status}
                    key={item.soal_id}
                    pathEdit="/edit-soal"
                    disabledPoint
                    disabledWaktu
                  />
                ))
            )}
          </div>
          {showSelectSiswa && (
            <SettingSiswa
              show={showSelectSiswa}
              listSiswa={listSiswa}
              selectedSiswa={detail?.list_siswa}
              handleClose={() => setShowSelectSiswa(false)}
              selectAll={detail?.allSiswa}
            />
          )}
          {showModalTugas && (
            <TambahTugas
              show={showModalTugas}
              dataTopik={topik?.topik}
              dataSiswa={listSiswa}
              handleClose={() => {
                setShowModalTugas(false);
                getDetail(id);
              }}
              editTugas={id}
            />
          )}

          {previewSoal && (
            <PreviewSoal
              dataSoal={dataSoal}
              show={previewSoal}
              handleClose={() => setPreviewSoal(false)}
              dataSoalCerita={dataSoalCerita}
            />
          )}
          {showBobotSoal && (
            <BobotSoal
              show={showBobotSoal}
              handleClose={() => setShowBobotSoal(false)}
              tugasId={id}
            />
          )}
          {showFilter && (
            <FilterSoal
              show={showFilter}
              handleClose={() => setShowFilter(false)}
              params={filter}
              handleChange={handleChangeFilter}
            />
          )}
        </div>
      </div>
    </Wrapper>
  );
}

export default Tugas;
