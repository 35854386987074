/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  return (
    <Fragment>
      <footer className="bg-white iq-footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  <a
                    href="https://panduan.bukakalabs.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("panduan")}
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">{t("hubungi_kami")}</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 text-footer">
              {new Date().getFullYear()} ©️{" "}
              <span className="text-primary"> Bakti Mulya 400</span> All Right
              Reserved
            </div>
          </div>
        </div>
      </footer>
      {/* <nav className="iq-float-menu">
        <input
          type="checkbox"
          className="iq-float-menu-open"
          name="menu-open"
          id="menu-open"
        />
        <label className="iq-float-menu-open-button" htmlFor="menu-open">
          <span className="lines line-1"></span>
          <span className="lines line-2"></span>
          <span className="lines line-3"></span>
        </label>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="button-tooltip-2">{t("panduan")}</Tooltip>}
        >
          <Link className="iq-float-menu-item bg-info" to="/panduan">
            <i className="ri-text-direction-r text-white"></i>
          </Link>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="button-tooltip-2">{t("sistem_pendukung")}</Tooltip>
          }
        >
          <a
            className="iq-float-menu-item bg-danger"
            href="suport"
            id="dark-mode"
            data-active="true"
          >
            <i className="ri-sun-line text-white"></i>
          </a>
        </OverlayTrigger>
        <Link
          className="iq-float-menu-item bg-warning"
          to="/faq"
          data-toggle="tooltip"
          data-placement="top"
          title="FAQ"
        >
          <i className="ri-palette-line text-white"></i>
        </Link>
      </nav> */}
    </Fragment>
  );
}

export default Footer;
