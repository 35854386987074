import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { ItemSelectSiswa } from '../item';

function FilterSoal({ show, handleClose, params, handleChange }) {
  return (
    <div className="col-lg-12">
      <Modal size="md" show={show} id="FilterSoal" onHide={handleClose}>
        <Modal.Header closeButton>
          <h5 className="modal-title">Filter</h5>
        </Modal.Header>
        <Modal.Body>
          <ul className="todo-task-lists m-0 p-0 overflow-auto">
            <ItemSelectSiswa
              padding="py-2"
              nama="Select All"
              selected={params.length === 7}
              code="all"
              handleChange={(name, code) => handleChange(code)}
            />
            <ItemSelectSiswa
              padding="py-2"
              nama="Cerita"
              selected={params.filter((item) => item === "cerita").length > 0}
              code="cerita"
              handleChange={(name, code) => handleChange(code)}
            />
            <ItemSelectSiswa
              padding="py-2"
              nama="Pilihan Ganda"
              selected={params.filter((item) => item === "pg").length > 0}
              code="pg"
              handleChange={(name, code) => handleChange(code)}
            />
            <ItemSelectSiswa
              padding="py-2"
              nama="Pooling"
              selected={params.filter((item) => item === "polling").length > 0}
              code="polling"
              handleChange={(name, code) => handleChange(code)}
            />
            <ItemSelectSiswa
              padding="py-2"
              nama="Isian atau Uraian"
              selected={params.filter((item) => item === "essay").length > 0}
              code="essay"
              handleChange={(name, code) => handleChange(code)}
            />
            <ItemSelectSiswa
              padding="py-2"
              nama="True & False"
              selected={
                params.filter((item) => item === "true_false").length > 0
              }
              code="true_false"
              handleChange={(name, code) => handleChange(code)}
            />
            <ItemSelectSiswa
              padding="py-2"
              nama="Menjodohkan"
              selected={
                params.filter((item) => item === "menjodohkan").length > 0
              }
              code="menjodohkan"
              handleChange={(name, code) => handleChange(code)}
            />
            <ItemSelectSiswa
              padding="py-2"
              nama="Pernyataan"
              selected={
                params.filter((item) => item === "pernyataan").length > 0
              }
              code="pernyataan"
              handleChange={(name, code) => handleChange(code)}
            />
          </ul>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default FilterSoal;
