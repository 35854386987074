import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

/**
 * Renders a set of buttons based on the provided props.
 * Each button is wrapped in an OverlayTrigger component from the react-bootstrap library,
 * which displays a tooltip when the button is hovered over.
 * The function also uses the useTranslation hook from the react-i18next library to translate the tooltip text.
 *
 * @param {Object} props - The props object.
 * @param {boolean} props.showBtnEdit - Determines whether to show the "Edit" button.
 * @param {boolean} props.showBtnStatus - Determines whether to show the status button.
 * @param {boolean} props.statusShow - Determines the current status of the item.
 * @param {boolean} props.showBtnDelete - Determines whether to show the "Delete" button.
 * @param {boolean} props.showBtnShare - Determines whether to show the "Share" button.
 * @param {function} props.handleEdit - Event handler for the "Edit" button click.
 * @param {function} props.handleShare - Event handler for the "Share" button click.
 * @param {function} props.handleStatus - Event handler for the status button click.
 * @param {function} props.handleDelete - Event handler for the "Delete" button click.
 * @returns {JSX.Element} - The rendered set of buttons based on the provided props.
 */
const ActionData = ({
  showBtnEdit,
  showBtnStatus,
  statusShow,
  showBtnDelete,
  showBtnShare,
  handleEdit,
  handleShare,
  handleStatus,
  handleDelete,
}) => {
  const { t } = useTranslation();

  const renderButton = (tooltipText, onClickHandler, iconClassName) => (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id="button-tooltip-2">{tooltipText}</Tooltip>}
    >
      <button
        className="bg-transparent border-0 text-primary"
        onClick={onClickHandler}
      >
        <i className={iconClassName}></i>
      </button>
    </OverlayTrigger>
  );

  return (
    <div className="d-flex align-items-center">
      {showBtnEdit && renderButton("Edit", handleEdit, "las la-pen")}
      {showBtnShare &&
        renderButton(t("bagikan"), handleShare, "ri-share-forward-line")}
      {showBtnStatus &&
        renderButton(
          t(statusShow ? "sembunyikan" : "tampilkan"),
          handleStatus,
          statusShow ? "las la-eye" : "las la-eye-slash"
        )}
      {showBtnDelete &&
        renderButton(t("hapus"), handleDelete, "las la-trash-alt")}
    </div>
  );
};

export default ActionData;
