import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ModalFilter } from "../modal";
import { Link } from "react-router-dom";

/**
 * Renders a filter modal for a course search feature.
 *
 * @param {Object} params - The current filter parameters.
 * @param {Function} handleChange - The event handler for filter option changes.
 * @param {Function} handleSearch - The event handler for search.
 * @param {string} search - The current search keyword.
 * @param {Function} handleChangeSearch - The event handler for search input changes.
 * @param {boolean} submit - Indicates whether the search form is being submitted.
 * @param {Array} dataCategory - The available course categories.
 * @param {boolean} showAdvanceFilter - Indicates whether to show advanced filter options.
 * @param {Array} filterData - The filter options.
 * @param {Function} filterMobile - The event handler for mobile filter.
 * @param {String} title - The title of the content.
 * @returns {JSX.Element} The rendered filter modal component.
 */
function FilterCourse({
  params,
  handleChange,
  handleSearch,
  search,
  handleChangeSearch,
  submit,
  dataCategory,
  showAdvanceFilter,
  filterData,
  filterMobile,
  title,
}) {
  const { t } = useTranslation();
  const [showCategory, setShowCategory] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleChangeCategory = (val) => {
    setShowCategory(false);
    handleChange({
      target: {
        name: "category",
        value: val,
      },
    });
  };
  const handleResize = () => {
    const newIsMobile = window.innerWidth <= 768; // Update breakpoint as needed

    if (isMobile && !newIsMobile && openFilter) {
      setOpenFilter(false);
    }
    setIsMobile(newIsMobile);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, openFilter]);

  return (
    <>
      <div className="iq-card iq-card-block iq-card-stretch iq-card-height d-none d-lg-block">
        {title && (
          <div className="iq-card-header d-flex justify-content-between align-items-center col-12">
            <h4 className="card-title">{title}</h4>
            <Link
              to="/"
              className="btn btn-sm iq-bg-light iq-bg-primary-hover text-dark border bg-transparent"
            >
              <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
            </Link>
          </div>
        )}
        <div className="iq-card-body">
          <div className="row">
            {showAdvanceFilter ? (
              <div className="col-12">
                <div className="d-flex justify-content-between align-items-center">
                  <Dropdown
                    show={showCategory}
                    onToggle={(show) => setShowCategory(show)}
                    className="dropdown-category-course"
                  >
                    <Dropdown.Toggle className="btn-dropdown-category border-0 bg-transparent text-black">
                      {t("kategori")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="py-0">
                      <div className="d-flex overflow-hidden rounded-3 gap-4">
                        <div className="sidecontent py-5">
                          <p className="text-primary fw-semibold mb-3">
                            {t("judul1_kategori_course")}
                          </p>
                          <h5 className="text-white fw-semibold mb-1">
                            {t("judul_kategory_course")}
                          </h5>
                          <p className="text-white-50 font-size-14">
                            {t("subjudul_kategory_course")}
                          </p>
                          <button
                            onClick={() => handleChangeCategory("")}
                            className="btn btn-primary rounded-pill"
                          >
                            {t("semua_course")}
                          </button>
                        </div>
                        <div className="pe-4 py-5 min-max-content menucontent">
                          {dataCategory?.map((item) => (
                            <button
                              key={item.course_category_id}
                              onClick={() =>
                                handleChangeCategory(item.course_category_id)
                              }
                              className="border-0 bg-transparent text-primary font-size-14 fw-semibold"
                            >
                              {item.course_category_name}
                            </button>
                          ))}
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    <div className="iq-search-bar p-0">
                      <form
                        onSubmit={handleSearch}
                        className="searchbox align-items-center d-flex"
                      >
                        <input
                          value={search}
                          onChange={handleChangeSearch}
                          type="text"
                          className="text search-input"
                          placeholder={t("placeholder_search")}
                        />
                        <button
                          disabled={submit}
                          type="submit"
                          className="border-0 bg-transparent position-absolute end-0 me-2"
                        >
                          <i className="ri-search-line"></i>
                        </button>
                      </form>
                    </div>

                    <Link
                      to="/"
                      className="btn btn-sm iq-bg-light iq-bg-primary-hover text-dark border bg-transparent"
                    >
                      <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
                    </Link>
                  </div>
                </div>
                <hr />
              </div>
            ) : null}
            {filterData?.map((item) => {
              return (
                <div className="col-4" key={item.label}>
                  <div className="form-group">
                    <label className="fw-bold font-size-16 ps-3">
                      {t(item.label)}:
                    </label>
                    {item.data?.map((option) => (
                      <div
                        className="custom-control custom-checkbox mt-1"
                        key={option.value}
                      >
                        <label className="font-size-16">
                          <input
                            name={item.name}
                            type="checkbox"
                            className="checkbox-2x me-2"
                            value={option.value}
                            checked={
                              Array.isArray(params[item.name])
                                ? params[item.name].includes(option.value)
                                : params[item.name] === option.value
                            }
                            onChange={handleChange}
                          />{" "}
                          {t(option.label)}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <nav className="iq-float-menu d-block d-md-none">
        <button
          className="iq-float-menu-open-button iq-bg-primary"
          onClick={() => setOpenFilter(true)}
        >
          <i className="fa fa-filter text-primary align-middle"></i>
        </button>
      </nav>
      {openFilter && (
        <ModalFilter
          show={openFilter}
          handleClose={() => setOpenFilter(false)}
          filterData={filterData}
          showAdvanceFilter={showAdvanceFilter}
          handleChange={handleChange}
          params={params}
          filterMobile={filterMobile}
        />
      )}
    </>
  );
}

export default FilterCourse;
