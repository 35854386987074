import "react-medium-image-zoom/dist/styles.css";

import React, { useRef } from "react";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Zoom from "react-medium-image-zoom";
import { useDispatch, useSelector } from "react-redux";
import { ReactSortable } from "react-sortablejs";

import {
  delTopik,
  setDetailTopik,
  sortDetailTopik,
  updStatusTopik,
  updTopik,
} from "../../redux/actions/topikAction";
import { checkIsGuru } from "../../utils/roleUser";
import { ItemDiskusi, ItemFile, ItemTugas } from "../item";
import ItemMateri from "../item/ItemMateri";
import Swal from "sweetalert2";

function TopikCard({
  topik,
  desc,
  files,
  code,
  status,
  dataDetail,
  sortingData,
  checked,
  dataTopik,
  handleEditTopik,
  handleEditMateri,
  handleEditTugas,
  handleEditDiskusi,
  handleOpenMateriH5P,
  handleChecked,
  getDataTopik,
  showAction,
  sekolahId,
  idMapel,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sortingRef = useRef(false);
  const { user } = useSelector(({ user }) => user);

  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };

  const handleDelete = (code) => {
    Swal.fire({
      title: t("konfirmasi_hapus"),
      text: t("are_u_sure_delete", {
        params: t("data_ini", { data: t("topik") }),
      }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendDeleteTopik(code);
    });
  };

  const sendDeleteTopik = (code) => {
    delTopik(code)
      .then(() => {
        getDataTopik();
      })
      .catch(() => {
        showAlert(t("failed_delete_data", { data: t("topik") }), false);
      });
  };

  const handleChangeStatus = (code, status) => {
    Swal.fire({
      title: t("konfirmasi_edit"),
      text: t("are_u_sure_edit", {
        data: t("topik"),
        status: t(status ? "sembunyikan" : "tampilkan"),
      }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendStatusTopik(code, status);
    });
  };

  const sendStatusTopik = (topik_id, status) => {
    const params = {
      topik_id: topik_id?.toString(),
      status,
    };
    updStatusTopik(params)
      .then(() => {
        getDataTopik();
      })
      .catch(() => {
        showAlert(t("failed_edit_data", { data: t("topik") }), false);
      });
  };

  const handleSortDetail = (newData) => {
    if (!sortingRef.current) return;
    const params = {
      id: code,
      data: newData,
    };
    dispatch(setDetailTopik(params));
    sortDetailTopik({
      topik_id: code,
      detail_list: newData?.map((item, idx) => ({
        materi_id: item?.materi_id,
        tugas_id: item?.tugas_id,
        diskusi_id: item?.diskusi_id,
        index: idx,
      })),
    });
  };

  const handleDeleteFileTopik = (topik_id, filetopik_id) => {
    let params = dataTopik;
    params.files = params?.files?.filter(
      (item) => item.filetopik_id !== filetopik_id
    );
    updTopik(topik_id, params)
      .then(() => {
        getDataTopik();
      })
      .catch((err) =>
        showAlert(t("gagal_hapus", { data: t("file_topik") }), false)
      );
  };

  return (
    <div className="iq-card iq-accordion-block item-tugas">
      <div className="active-faq clearfix">
        <Accordion.Item
          eventKey={`#topik-${code}`}
          className="container border-0 bg-transparent px-2"
        >
          <div className="d-flex align-items-center">
            {showAction && checkIsGuru(user?.user_roleid) ? (
              <input
                className="rabsen"
                type="checkbox"
                name="topik"
                style={{ width: 16 }}
                onChange={() => handleChecked(code, !checked)}
                checked={checked}
              />
            ) : null}
            <Accordion.Header
              className="header-topik-card flex-1"
              id="head-topik-card"
            >
              <h5
                className={`fw-bold ${
                  status === "" ? "text-black" : "text-danger"
                }`}
              >
                {topik}
              </h5>
            </Accordion.Header>
          </div>
          <Accordion.Body className="px-2 py-0">
            <div className="card-body pb-3">
              <div>
                <Zoom>
                  <div
                    dangerouslySetInnerHTML={{ __html: desc }}
                    className="preview-html"
                  ></div>
                </Zoom>
                {checkIsGuru(user?.user_roleid) && (
                  <div className="d-flex">
                    <button
                      onClick={handleEditTopik}
                      className="bg-transparent border-0 text-primary"
                    >
                      <i className="las la-pen"></i> Edit {t("topik")}
                    </button>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          {t(!status ? "sembunyikan" : "tampilkan")}
                        </Tooltip>
                      }
                    >
                      <button
                        className="bg-transparent border-0 text-primary"
                        onClick={() =>
                          handleChangeStatus(
                            code,
                            status === "" ? "Hide" : null
                          )
                        }
                      >
                        <i
                          className={
                            status === "" ? "las la-eye-slash" : "las la-eye"
                          }
                        ></i>
                      </button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip-2">{t("hapus")}</Tooltip>
                      }
                    >
                      <button
                        className="bg-transparent border-0 text-primary"
                        onClick={() => handleDelete(code)}
                      >
                        <i className="las la-trash-alt"></i>
                      </button>
                    </OverlayTrigger>
                  </div>
                )}
              </div>
              <div className="row">
                {files?.map((item) => (
                  <div className="col-lg-6 col-12 mb-1" key={item.filetopik_id}>
                    <ItemFile
                      key={item.filemateri_id}
                      name={item?.filename}
                      type={item.type}
                      url={item.path}
                      handleDeleteFile={() =>
                        handleDeleteFileTopik(code, item.filetopik_id)
                      }
                      handleH5P={handleOpenMateriH5P}
                    />
                  </div>
                ))}
              </div>
              <div className="iq-card-body p-0">
                <ul className="iq-timeline">
                  <ReactSortable
                    onUpdate={() => (sortingRef.current = true)}
                    list={dataDetail}
                    setList={handleSortDetail}
                    disabled={!checkIsGuru(user?.user_roleid) || !sortingData}
                    handle="#topik-item-detail"
                  >
                    {dataDetail?.map((item) => {
                      if (item?.tugas_id) {
                        return (
                          <ItemTugas
                            key={item.tugas_id}
                            code={item.tugas_id}
                            desc={item.deskripsi}
                            judul={item.title}
                            nama_tugas={item.nama_tugas}
                            updated_at={item.updatedAt}
                            end_date={item.end_date}
                            nilaiRaport={item.nilai_raport}
                            files={item.files}
                            materiVideo={item.video_tugas}
                            h5p={item.h5p_tugas}
                            status={item.status}
                            statusHide={item.status_hide}
                            statusPengerjaan={item.status_pengerjaan}
                            handleEditTugas={() =>
                              handleEditTugas(item.tugas_id)
                            }
                            getDataTopik={getDataTopik}
                            sekolahId={sekolahId}
                            idTopik={code}
                          />
                        );
                      } else if (item?.materi_id) {
                        return (
                          <ItemMateri
                            key={item.materi_id}
                            code={item.materi_id}
                            desc={item.deskripsi}
                            judul={item.title}
                            updated_at={item.updatedAt}
                            files={item.files}
                            status={item.status}
                            materiVideo={item.video_materi}
                            h5p={item.h5p_materi}
                            dataTugas={item}
                            handleEditMateri={() =>
                              handleEditMateri(item.materi_id)
                            }
                            getDataTopik={getDataTopik}
                            sekolahId={sekolahId}
                            idMapel={idMapel}
                            idTopik={code}
                          />
                        );
                      } else if (item?.diskusi_id) {
                        return (
                          <ItemDiskusi
                            key={item.diskusi_id}
                            code={item.diskusi_id}
                            judul={item.title}
                            desc={item.deskripsi}
                            files={item.files}
                            dataVideo={item.video_diskusi}
                            h5p={item.h5p_diskusi}
                            status={item.status}
                            updatedAt={item.updatedAt}
                            threads={item.diskusi_thread}
                            dataDiskusi={item}
                            handleEdit={handleEditDiskusi}
                            getDataTopik={getDataTopik}
                            sekolahId={sekolahId}
                            idMapel={idMapel}
                            idTopik={code}
                          />
                        );
                      }
                      return null;
                    })}
                  </ReactSortable>
                </ul>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </div>
    </div>
  );
}

export default TopikCard;
