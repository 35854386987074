import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import {
  FooterBuyCourseM,
  HeaderBuyCourse,
  MateriVideo,
  PreviewBuyCourse,
  SliderCourseLain,
  UploadBuktiCourse,
  Wrapper,
} from "../../components";
import PlaylistBuyCourse from "../../components/section/PlaylistBuyCourse";
import {
  buyCourse,
  getCourseDetail,
  getCourseMateri,
  getCourseTopik,
  markedCourse,
} from "../../redux/actions/courseAction";
import { formatRupiah } from "../../utils/formatRupiah";
import { useSelector } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
const courseStatus = ["ongoing", "finish"];
const BuyCourse = () => {
  const playlistRef = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useSelector(({ user }) => user);
  const [detail, setDetail] = useState(null);
  const [materi, setMateri] = useState([]);
  const [topik, setTopik] = useState([]);
  const [loading, setLoading] = useState(false);
  const [video, setVideo] = useState("");
  const [loadingMateri, setLoadingMateri] = useState(false);
  const [loadingTopik, setLoadingTopik] = useState(false);
  const [nextTopik, setNextTopik] = useState(false);
  const [pageTopik, setPageTopik] = useState(1);
  const [submitSubs, setSubmitSubs] = useState(false);
  const [showUploadBukti, setShowUploadBukti] = useState(false);
  const price = formatRupiah(
    detail?.course_price - (detail?.course_diskon / 100) * detail?.course_price
  );

  const getTopik = () => {
    const data = {
      page: pageTopik,
      limit: 5,
    };
    setLoadingTopik(true);
    getCourseTopik(id, data)
      .then((res) => {
        if (res?.data) {
          const dataTopik =
            pageTopik === 1 ? res?.data : [...topik, ...res.data];
          setTopik(dataTopik);
          setNextTopik(pageTopik < res?.last_page);
        }
        setLoadingTopik(false);
      })
      .catch(() => setLoadingTopik(false));
  };

  const getDetail = () => {
    setLoading(true);
    getCourseDetail(id, { user_id: user?.user_id })
      .then((res) => {
        setDetail(res);
        setVideo({
          video_type: res?.course_video_type,
          video: res?.course_video,
        });
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    setLoadingMateri(true);
    getDetail();
    getCourseMateri(id)
      .then((res) => {
        setMateri(res);
        setLoadingMateri(false);
      })
      .catch(() => setLoadingMateri(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, user]);
  useEffect(() => {
    getTopik();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTopik]);

  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    }).then((res) => {
      if (status) getDetail();
    });
  };

  const handleSubscribe = () => {
    Swal.fire({
      titleText: t("konfirm_berlangganan"),
      text: t("desc_konfirm_berlangganan"),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#3f9b4b",
      cancelButtonText: t("batal"),
      cancelButtonColor: "#777d74",
      reverseButtons: true,
    }).then((res) => {
      if (res.isConfirmed) {
        sendSubscribe();
      }
    });
  };

  const sendSubscribe = () => {
    setSubmitSubs(true);
    const params = {
      user_id: user?.user_id,
      course_id: id,
    };
    buyCourse(params)
      .then((res) => {
        setSubmitSubs(false);
        showAlert(t("success_buy_course"), true);
      })
      .catch(() => {
        showAlert(t("failed_buy_course"), false);
        setSubmitSubs(false);
      });
  };

  const handleMoreVideo = () => {
    Swal.fire({
      title: detail?.joined ? "Info" : "Oopss!",
      text: t(
        courseStatus.includes(detail?.joined?.join_status)
          ? "lanjutkan_belajar"
          : detail?.joined?.join_status === "pending"
          ? "bukti_pending"
          : "info_langganan"
      ),
      icon: "info",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: t(
        courseStatus.includes(detail?.joined?.join_status)
          ? "lanjutkan"
          : detail?.joined?.join_status === "pending"
          ? "oke"
          : "beli_course"
      ),
      confirmButtonColor: "#3f9b4b",
      cancelButtonText: t("batal"),
      cancelButtonColor: "#777d74",
    }).then((res) => {
      if (res.isConfirmed) {
        if (!detail?.joined) {
          sendSubscribe();
        } else if (detail?.joined?.join_status !== "pending") {
          navigate(`/course/${id}`);
        }
      }
    });
  };

  const handleMarked = () => {
    markedCourse({
      user_id: user?.user_id,
      course_id: id,
    })
      .then((res) => {
        setDetail((pref) => ({
          ...pref,
          marked: res,
        }));
      })
      .catch(() => {
        showAlert(t("gagal_menandai_course"), false);
      });
  };

  const handleScrollToPlaylist = () => {
    playlistRef?.current?.scrollIntoView({
      behavior: "smooth",
    });
    const playlist = playlistRef?.current;
    let headerOffset = 75;
    let elementPosition = playlist.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.scrollY - headerOffset;

    window.scrollBy({
      top: offsetPosition - window.scrollY,
      behavior: "smooth",
    });
  };

  return (
    <Wrapper title={`${t("kursus")} ${detail?.course_title || ""}`}>
      <div className="container-course" id="page-kursus">
        {loading && !detail ? (
          <div className="my-5 text-center">
            <Spinner
              animation="border"
              role="status"
              variant="secondary"
              className="mx-auto"
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div className="container-lg-fluid container-xxl">
            <div className="row mt-sm-4">
              <HeaderBuyCourse
                author={detail?.courseMentor?.mentor_name}
                heading={detail?.course_headline}
                rating={detail?.total_rating}
                release_date={detail?.createdAt}
                review={detail?.review_count}
                sertifikat={detail?.course_certificate}
                subscribe={detail?.joins_count}
                title={detail?.course_title}
                marked={detail?.marked}
                handleMarked={handleMarked}
              />
              <div className="col-lg-8">
                <PreviewBuyCourse
                  heading={detail?.course_headline}
                  title={detail?.course_title}
                  desc={detail?.course_deskripsi}
                  author_img={detail?.courseMentor?.mentor_foto}
                  total_lesson={detail?.materi_count}
                  author_name={detail?.courseMentor?.mentor_name}
                  total_rating={detail?.total_rating}
                  total_subs={detail?.joins_count}
                  total_time={detail?.total_materi_time}
                  topik={topik}
                  video_type={video?.video_type}
                  video={video?.video}
                  marked={detail?.marked}
                  handleMarked={handleMarked}
                  sertifikat={detail?.course_certificate}
                  handleNextTopik={() => setPageTopik((pref) => pref + 1)}
                  loadingTopik={loadingTopik}
                  nextTopik={nextTopik}
                  handleScrollToPlaylist={handleScrollToPlaylist}
                  handlePlay={(data) => setVideo(data)}
                  handleMoreVideo={handleMoreVideo}
                />
                <div className="d-none d-lg-block">
                  <h3
                    ref={playlistRef}
                    className="font-size-22 fw-bold mb-3 mt-4"
                  >
                    {t("materi_kursus")}
                  </h3>
                  <PlaylistBuyCourse
                    topik={topik}
                    handleNext={() => setPageTopik((pref) => pref + 1)}
                    loadingTopik={loadingTopik}
                    nextTopik={nextTopik}
                    handlePlay={(data) => setVideo(data)}
                    handleMoreVideo={handleMoreVideo}
                  />
                </div>
                <h3 className="font-size-22 fw-bold mb-3 mt-4">
                  {t("kursus_lainnya")}
                </h3>
                <div className="mb-4">
                  <SliderCourseLain category={detail?.course_category_id} />
                </div>
              </div>
              <div className="col-lg-4 d-none d-lg-block">
                <div className="iq-card overflow-hidden">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">
                        {detail?.materi_count} {t("lessons")}{" "}
                        {detail?.total_materi_time < 60
                          ? detail?.total_materi_time
                          : detail?.total_materi_time / 60}{" "}
                        (
                        {detail?.total_materi_time < 60 ? t("menit") : t("jam")}
                        )
                      </h4>
                    </div>
                  </div>
                  <MateriVideo
                    detail={detail}
                    handlePlay={(data) => setVideo(data)}
                    materi={materi}
                    loading={loadingMateri}
                    handleMoreVideo={handleMoreVideo}
                    handleScrollToPlaylist={handleScrollToPlaylist}
                  />
                  {courseStatus.includes(detail?.joined?.join_status) ? (
                    <Link
                      className="btn w-100 rounded-0 btn-primary text-center py-3 border-0 text-white fw-bold font-size-20"
                      to={`/course/${detail?.course_id}`}
                    >
                      {t("lanjutkan_belajar")}
                    </Link>
                  ) : detail?.joined?.join_status === "pending" ? (
                    <button
                      disabled={
                        detail?.joined?.invoice?.invoice_status === "waiting" &&
                        detail?.joined?.invoice_doc !== null
                      }
                      onClick={() => setShowUploadBukti(true)}
                      type="button"
                      className={`btn w-100 rounded-0 text-center py-3 border-0 text-white fw-bold font-size-20
                    ${
                      detail?.joined?.invoice?.invoice_status === "reject"
                        ? "btn-danger"
                        : "btn-primary"
                    }`}
                    >
                      {detail?.joined?.invoice_doc === null ? (
                        t("upload_bukti")
                      ) : detail?.joined?.invoice?.invoice_status ===
                        "waiting" ? (
                        t("bukti_pending")
                      ) : detail?.joined?.invoice?.invoice_status ===
                        "reject" ? (
                        <>
                          <span>{t("bukti_ditolak")}</span>
                          <p className="font-size-14 fw-normal fst-italic p-0 m-0">
                            ({t("lihat_keterangan")})
                          </p>
                        </>
                      ) : null}
                    </button>
                  ) : (
                    <button
                      disabled={submitSubs || loading}
                      onClick={handleSubscribe}
                      type="button"
                      className="btn w-100 rounded-0 btn-primary text-center py-3 border-0 text-white fw-bold font-size-20"
                    >
                      {submitSubs ? (
                        <Spinner variant="light" animation="border" size="sm" />
                      ) : (
                        price
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <FooterBuyCourseM
        handleSubscribe={handleSubscribe}
        joined={detail?.joined}
        loading={loading}
        price={price}
        submitSubs={submitSubs}
        courseId={id}
        handleUploadBukti={() => setShowUploadBukti(true)}
      />
      <UploadBuktiCourse
        handleClose={(success) => {
          setShowUploadBukti(false);
          if (success) {
            showAlert(t("data_berhasil_dikirim", { data: "file" }), true);
          }
        }}
        show={showUploadBukti}
        invoiceId={detail?.joined?.invoice?.invoice_id}
        files={detail?.joined?.invoice?.documents}
      />
      <Modal
        show={loading && detail}
        centered
        contentClassName="bg-transparent border-0 d-flex align-items-center justify-content-center"
      >
        <Spinner animation="border" variant="dark" />
      </Modal>
    </Wrapper>
  );
};

export default BuyCourse;
