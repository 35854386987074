import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { LoadingGreen, PageLoader } from "../../assets/images";
import {
  addTopik,
  getDetailTopik,
  getTopik,
  updTopik,
  uploadFile,
} from "../../redux/actions/topikAction";
import { ItemFile } from "../item";
import ModalDrive from "./ModalDrive";
import { InputEditor, SelectMultiple } from "../input";

function TambahTopik({
  detailKelas,
  dataSiswa,
  show,
  handleClose,
  editTopik,
  dataTopik,
}) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const fileInput = useRef();
  const editorRef = useRef(null);
  const [submit, setSubmit] = useState(false);
  // const [tugas, setTugas] = useState([]);
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const [showDrive, setShowDrive] = useState(false);
  const [params, setParams] = useState({
    kelasmapel_id: "",
    start_date: "",
    end_date: "",
    topic: "",
    jumlah: "1",
    deskripsi: "",
    files: [],
    enable_start_date: false,
    enable_end_date: false,
    list_siswa: [],
    allSiswa: false,
    selectedSiswa: [],
    topik_id_target: "",
    nilai: "",
    tugas_id: "",
    kelasmapel_kelasdetailid: "",
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    setParams({
      kelasmapel_id: detailKelas?.kelasmapel_id,
      start_date: "",
      end_date: "",
      topic: "",
      jumlah: "1",
      deskripsi: "",
      files: [],
      enable_start_date: false,
      enable_end_date: false,
      list_siswa: [],
      allSiswa: false,
      selectedSiswa: [],
    });
    handleChangeSiswa("all", true);
    setSubmit(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };
  const handleChange = ({ target: { value, name } }) => {
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };
  const handleChangeChecked = ({ target: { checked, name } }) => {
    setParams((pref) => ({
      ...pref,
      [name]: checked,
      [name.slice(7, name.length)]: "",
    }));
  };
  const handleChangeFile = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (files.length > 0) {
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`files`, files[i]);
      }
      setUploadFileLoading(true);
      uploadFile(formData)
        .then((res) => {
          setUploadFileLoading(false);
          setParams((pref) => ({
            ...pref,
            files: [...pref.files, ...res?.data?.data],
          }));
        })
        .catch((err) => {
          setUploadFileLoading(false);
          console.log("err", err);
        });
    }
  };
  const handleSubmit = (status) => {
    setError(null);
    setSubmit(true);
    let url = new URLSearchParams(location.search);
    let tahunpelajaran_id = url.get("th");
    let semester_id = url.get("smt");
    let data = {
      kelasmapel_id: params?.kelasmapel_id,
      topic: params?.topic,
      jumlah: params?.jumlah,
      deskripsi: editorRef.current.getContent(),
      files: params?.files,
      list_siswa: params?.allSiswa ? [] : params.list_siswa,
      allSiswa: params?.allSiswa,
      topik_id_target: params?.topik_id_target,
      nilai: params?.nilai,
      tugas_id: params?.tugas_id,
      status: status,
      tahunpelajaran_id,
      semester_id,
    };
    if (params?.start_date?.length > 0) data.start_date = params.start_date;
    if (params?.end_date?.length > 0) data.end_date = params.end_date;
    if (editTopik) {
      updTopik(editTopik, data)
        .then(() => {
          showAlert(t("berhasil_simpan", { params: t("topik") }), true);
          handleClose();
          setSubmit(false);
          const data = {
            tahunpelajaran_id,
            semester_id,
          };
          dispatch(getTopik(id, data));
        })
        .catch((err) => {
          setError(err?.response?.data?.error);
          setSubmit(false);
        });
    } else {
      addTopik(data)
        .then(() => {
          showAlert(t("berhasil_simpan", { params: t("topik") }), true);
          handleClose();
          setSubmit(false);
          const data = {
            tahunpelajaran_id,
            semester_id,
          };
          dispatch(getTopik(id, data));
        })
        .catch((err) => {
          setError(err?.response?.data?.error);
          setSubmit(false);
        });
    }
  };
  const handleChangeSiswa = (name, value) => {
    if (name === "all" && value) {
      setParams((pref) => ({
        ...pref,
        list_siswa: dataSiswa?.map((item) => item?.user?.user_id?.toString()),
        allSiswa: true,
      }));
      return;
    } else if (name === "all") {
      setParams((pref) => ({ ...pref, list_siswa: [], allSiswa: false }));
      return;
    }
    let cek = params?.list_siswa?.filter(
      (item) => item.toString() === value.toString()
    );
    if (cek?.length > 0) {
      setParams((pref) => ({
        ...pref,
        list_siswa: params?.list_siswa?.filter(
          (item) => item.toString() !== value.toString()
        ),
        allSiswa: false,
      }));
    } else {
      setParams((pref) => ({
        ...pref,
        list_siswa: [...pref.list_siswa, value?.toString()],
        allSiswa: false,
      }));
    }
  };

  const handleDeleteFile = (idx) => {
    setParams((pref) => ({
      ...pref,
      files: pref?.files?.filter((item, i) => i !== idx),
    }));
  };

  const formatDate = (value) => {
    let result = value;
    if (value) {
      result = result?.substr(0, 19)?.replace("T", " ");
    }
    return result;
  };

  useEffect(() => {
    if (editTopik) {
      getDetailTopik(editTopik).then((res) => {
        let data = res?.data?.data;
        setParams((pref) => ({
          ...pref,
          kelasmapel_id: data?.kelasmapel_id,
          start_date: formatDate(data?.start_date),
          end_date: formatDate(data?.end_date),
          topic: data?.topic,
          deskripsi: data?.deskripsi,
          files: data?.files,
          enable_start_date: data?.start_date?.length > 0,
          enable_end_date: data?.end_date?.length > 0,
          list_siswa: data?.list_siswa,
          allSiswa: data?.allSiswa,
          topik_id_target: data?.topik_id_target,
          nilai: data?.nilai,
          tugas_id: data?.tugas_id,
        }));
      });
      // getTugasByTopik(editTopik).then((res) => setTugas(res?.data?.data));
    }
  }, [editTopik]);

  const handleSelectDrive = (file) => {
    if (file) {
      setParams((pref) => ({
        ...pref,
        files: [
          ...pref.files,
          {
            filename: file?.drive_file_name,
            path: file?.drive_file_url,
            type: file?.drive_file_type,
          },
        ],
      }));
    }
    setShowDrive(false);
  };

  return (
    <div className="col-lg-12">
      <Modal
        enforceFocus={false}
        fullscreen={true}
        show={show}
        onHide={handleClose}
        id="tambahtopik"
        backdrop="static"
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <button
              className="border-0 bg-white btn-close m-0 p-2 shadow-none"
              onClick={handleClose}
            ></button>
            <h4 className="modal-title">
              {editTopik ? "Edit" : t("tambah")} {t("topik")}
            </h4>
          </div>
          <Dropdown align="end">
            <Dropdown.Toggle
              variant="success"
              className="btn-primary border-0"
              id="dropdown-basic"
              disabled={submit}
            >
              {submit ? (
                <img src={PageLoader} alt="loader" style={{ width: 15 }} />
              ) : (
                t("simpan")
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item disabled={submit} onClick={() => handleSubmit("")}>
                {t("simpan")}
              </Dropdown.Item>
              <Dropdown.Item
                disabled={submit}
                onClick={() => handleSubmit("Draft")}
              >
                {t("simpan")} Draft
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Modal.Header>
        <Modal.Body className="p-0 overflow-x-hidden">
          <div className="row">
            <div className="col-lg-7 offset-md-1 pt-4">
              <div className="iq-card w-modal mb-4">
                <div className="iq-card-body">
                  <div className="w-100 mb-3">
                    <div className="row">
                      <label htmlFor="topic" className="col-12">
                        {t("topik")} <span className="text-danger">*</span> :
                      </label>
                      <div className="w-100">
                        <input
                          type="text"
                          className="form-control"
                          id="topic"
                          value={params.topic}
                          name="topic"
                          placeholder={t("topik")}
                          onChange={handleChange}
                          required
                        />
                        {error?.topic && (
                          <div className="d-block invalid-feedback">
                            {error?.topic}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-100">
                    <label htmlFor="email">{t("desc")} :</label>
                    <InputEditor
                      editorRef={editorRef}
                      value={params.deskripsi}
                    />
                    {error?.deskripsi && (
                      <div className="d-block invalid-feedback">
                        {error?.deskripsi}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="iq-card w-modal">
                <div className="iq-card-body">
                  <div className="form-group row align-items-center">
                    <div className="d-flex flex-grow-1 align-items-center">
                      <div className="send-panel d-flex">
                        <div className="me-2 d-flex align-items-center justify-content-between flex-column">
                          <label
                            className="mb-2 rounded-circle border border-primary iq-bg-primary-hover text-primary square-48"
                            htmlFor="files"
                          >
                            <input
                              type="file"
                              name="files"
                              id="files"
                              style={{ display: "none" }}
                              accept="
                                  image/jpeg, 
                                  image/png, 
                                  image/jpg, 
                                  .pptx,
                                  .xlsx,
                                  .xls,
                                  .doc,
                                  .docx,
                                  .ppt,
                                  .pdf,
                                  audio/*,
                                  .html,
                                  video/*
                                  "
                              onChange={handleChangeFile}
                              multiple
                              ref={fileInput}
                              disabled={uploadFileLoading}
                            />
                            <button
                              type="button"
                              disabled={uploadFileLoading}
                              onClick={() => fileInput?.current?.click()}
                              className="m-2 border-0 bg-transparent d-flex align-items-center"
                            >
                              {uploadFileLoading ? (
                                <img
                                  src={LoadingGreen}
                                  alt="loading"
                                  width={16}
                                  height={16}
                                />
                              ) : (
                                <i className="ri-upload-2-line m-0 text-primary"></i>
                              )}
                            </button>
                          </label>
                          <p className="fw-bold font-size-12 text-center m-0">
                            {t("unggah")}
                          </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between flex-column">
                          <label className="mb-0 rounded-circle border border-primary iq-bg-primary-hover text-primary  square-48">
                            <button
                              onClick={() => setShowDrive(true)}
                              className="m-2 bg-transparent border-0 text-primary"
                            >
                              <i className="ri-drive-line"></i>
                            </button>
                          </label>
                          <p className="fw-bold font-size-12 text-center m-0">
                            Drive
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="form-group row">
                    {params?.files?.map((item, i) => (
                      <div className="col-lg-12" key={i}>
                        <ItemFile
                          name={item.filename}
                          type={item.type}
                          url={item.path}
                          handleDeleteFile={() => handleDeleteFile(i)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 offset-md-1">
              <div className="iq-card h-100">
                <div className="iq-card-body h-100">
                  <div className="w-100 mb-3">
                    <SelectMultiple
                      dataSiswa={dataSiswa}
                      selectedSiswa={params?.list_siswa}
                      selectedAll={params?.allSiswa}
                      handleChange={handleChangeSiswa}
                    />
                  </div>
                  <div className="w-100 mb-3">
                    <div className="row">
                      <label htmlFor="start_date" className="col-12">
                        {t("tgl_mulai")} :
                      </label>
                      <div className="col-10">
                        <input
                          type="datetime-local"
                          className="form-control"
                          id="start_date"
                          value={params.start_date}
                          onChange={handleChange}
                          name="start_date"
                          disabled={!params.enable_start_date}
                        />
                        {error?.start_date && (
                          <div className="d-block invalid-feedback">
                            {error?.start_date}
                          </div>
                        )}
                      </div>
                      <div className="col-2 align-self-center">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="enable_start_date"
                            value={params.enable_start_date}
                            checked={params.enable_start_date}
                            onChange={handleChangeChecked}
                            name="enable_start_date"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="enable_start_date"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 mb-3">
                    <div className="row">
                      <label htmlFor="email" className="col-12">
                        {t("tgl_selesai")} :
                      </label>
                      <div className="col-10">
                        <input
                          type="datetime-local"
                          className="form-control"
                          id="end_date"
                          name="end_date"
                          value={params.end_date}
                          onChange={handleChange}
                          disabled={!params.enable_end_date}
                        />
                        {error?.end_date && (
                          <div className="d-block invalid-feedback">
                            {error?.end_date}
                          </div>
                        )}
                      </div>
                      <div className="col-2 align-self-center">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="enable_end_date"
                            value={params.enable_end_date}
                            checked={params.enable_end_date}
                            onChange={handleChangeChecked}
                            name="enable_end_date"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="enable_end_date"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* {editTopik && (
                    <>
                      <div className="w-100 mb-3 row">
                        <label htmlFor="tugas_id" className="col-12">
                          {t("tugas")}
                        </label>
                        <div className="col-9">
                          <select
                            className="form-control form-select"
                            id="tugas_id"
                            name="tugas_id"
                            value={params.tugas_id}
                            onChange={handleChange}
                          >
                            <option value="" disabled="">
                              {t("pilih_params", { params: t("tugas") })}
                            </option>
                            {tugas?.map((item) => (
                              <option key={item.tugas_id} value={item.tugas_id}>
                                {item.title}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-3">
                          <input
                            type="number"
                            name="nilai"
                            value={params.nilai}
                            onChange={handleChange}
                            className="form-control"
                            placeholder={t("nilai")}
                          />
                        </div>
                      </div>
                      <div className="w-100">
                        <label htmlFor="topik_id_target">{t("topik")}</label>
                        <select
                          className="form-control form-select"
                          id="topik_id_target"
                          name="topik_id_target"
                          value={params.topik_id_target}
                          onChange={handleChange}
                        >
                          <option value="" disabled>
                            {t("pilih_params", { params: t("topik") })}
                          </option>
                          {dataTopik
                            ?.filter(
                              (item) =>
                                item.topik_id?.toString() !==
                                editTopik.toString()
                            )
                            ?.map((item) => (
                              <option key={item.topik_id} value={item.topik_id}>
                                {item.topic}
                              </option>
                            ))}
                        </select>
                      </div>
                    </>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ModalDrive
        show={showDrive}
        handleClose={() => {
          setShowDrive(false);
        }}
        handleSave={handleSelectDrive}
      />
    </div>
  );
}

export default TambahTopik;
