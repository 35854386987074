import { API, setAuthToken } from "../../config/api";

export const addTugas = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("tugas", params);
};

export const updTugas = (code, params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("tugas/" + code, params);
};

export const delTugas = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.delete("tugas/" + code);
};

export const getDetailTugas = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`tugas/${code}`);
};

export const getListSoal = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`soal?tugas_id=${code}`);
};

export const getTugasByTopik = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`topik/${code}/tugas`);
};

export const addSoal = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`soal`, params);
};

export const uploadFileSoal = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("soal/upload-file", params);
};

export const delSoal = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.delete("soal?soal_id=" + code);
};

export const getDetailSoal = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`soal/detail?soal_id=${code}`);
};

export const updSoal = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("soal/update", params);
};

export const getListSoalCerita = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`soal/cerita/list-soal?tugas_id=${code}`);
};

export const addSoalCerita = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`soal/cerita`, params);
};

export const updSoalCerita = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`soal/cerita/update`, params);
};

export const getSoalCerita = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`soal/cerita?tugas_id=${code}`);
};

export const getDetailSoalCerita = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`soal/cerita/detail?cerita_id=${code}`);
};

export const delCerita = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.delete("soal/cerita?cerita_id=" + code);
};

export const updHidePembahasan = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("tugas/hide-pembahasan", params);
};

export const updSoalUrut = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("tugas/soal-urut", params);
};

export const getTargetTugas = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`tugas/target?${new URLSearchParams(params).toString()}`);
};

export const submitTargetTugas = (edit, params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`tugas/target${edit ? "/update" : ""}`, params);
};

export const delTarget = (id) => {
  const token = localStorage?.getItem("token");
  setAuthToken(token);
  return API.delete(`tugas/target?id=${id}`);
};

export const kerjakanTugas = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`pengerjaan-soal/mulai`, params);
};

export const submitTugas = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`pengerjaan-soal/finish`, params);
};

export const submitCatatanTugas = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`nilai/catatan`, params);
};

export const submitJawabanTugas = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`pengerjaan-soal`, params);
};

export const publishTugas = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("tugas/publish", params);
};

export const unpublishTugas = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("tugas/update-status-hide", params);
};

export const getBobotSoal = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get("tugas/bobot?tugas_id=" + code);
};

export const updBobotSoal = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("tugas/bobot", params);
};

export const exportSoal = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("soal/eksport", params);
};

export const getOptionBankSoal = (id) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`bank-soal/list?kelasmapel_id=${id}`);
};

export const sendImportBankSoal = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("soal/import", params);
};

export const shareTugas = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("tugas/share", params);
};

export const uploadFileTugas = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("tugas/upload-file", params);
};

export const delFileTugas = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.delete(`tugas/file?${new URLSearchParams(params).toString()}`);
};

export const uploadFilePengerjaan = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("pengerjaan-soal/upload-file", params);
};
