import React from "react";

import { ItemPreviewRating } from "../item";

function CardReviewCourse({ img, rating, name, review }) {
  return (
    <div className="product-description mt-3 ps-2">
      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-center pb-3 gap-3">
            <img
              className="img-fluid rounded-circle avatar-45"
              src={img}
              alt="review"
            />
            <div className="media-body ml-2 align-self-center">
              <span className="fw-bold">{name}</span>
              <p className="mb-0">
                <ItemPreviewRating rating={rating} />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p>{review}</p>
        </div>
      </div>
    </div>
  );
}

export default CardReviewCourse;
