import React from "react";
import { useTranslation } from "react-i18next";

function ItemSoalMenjodohkan({
  data,
  addPernyataan,
  delPernyataan,
  changePernyataan,
  changeJawaban,
  addPilihan,
  delPilihan,
  changePilihanIsi,
  changeNama,
  preview,
  kerjakan,
  handleChange,
  dipilih,
}) {
  const { t } = useTranslation();
  return (
    <div className={`row ${preview && kerjakan ? "py-4" : "p-3"}`}>
      <div className="col-lg-6">
        <div className="table-responsive">
          <table
            id="datatable"
            className="table table-bordered table-hover mb-2"
          >
            <thead className="table-primary text-white">
              <tr>
                <th
                  width="10%"
                  className={`text-center bg-transparent va-middle ${
                    kerjakan || preview ? "font-size-20" : ""
                  }`}
                >
                  No
                </th>
                <th
                  width="65%"
                  className={`bg-transparent va-middle ${
                    kerjakan || preview ? "font-size-20" : ""
                  }`}
                >
                  {t("pernyataan")}
                </th>
                <th
                  width="15%"
                  className={`bg-transparent va-middle ${
                    kerjakan || preview ? "font-size-20" : ""
                  }`}
                >
                  {t("jawaban")}
                </th>
                {!preview && !kerjakan && (
                  <th
                    width="10%"
                    className={`bg-transparent va-middle ${
                      kerjakan ? "font-size-20" : ""
                    }`}
                  >
                    {t("aksi")}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {data?.pernyataan?.map((item, idx) => {
                const cek = dipilih?.filter(
                  (jwb) =>
                    jwb.soal_menjodohkan_pernyataan_id.toString() ===
                    item.soal_menjodohkan_pernyataan_id.toString()
                );
                return (
                  <tr key={idx}>
                    <td
                      className={`text-center ${
                        kerjakan || preview ? "font-size-20" : ""
                      }`}
                    >
                      {idx + 1}
                    </td>
                    <td>
                      {kerjakan || preview ? (
                        <p className="m-0 font-size-20">
                          {item.soal_menjodohkan_pernyataan}
                        </p>
                      ) : (
                        <textarea
                          className="form-control"
                          rows="1"
                          name={idx}
                          value={item.soal_menjodohkan_pernyataan}
                          onChange={changePernyataan}
                          placeholder={t("soal")}
                        ></textarea>
                      )}
                    </td>
                    <td>
                      {kerjakan ? (
                        <select
                          name={item.soal_menjodohkan_pernyataan_id}
                          id={idx}
                          onChange={handleChange}
                          className="form-control form-select"
                          value={cek?.length > 0 ? cek[0].jawaban : ""}
                        >
                          <option value="">Jawab</option>
                          {data?.pilihan?.map((data) => (
                            <option
                              value={data?.soal_menjodohkan_pilihan_key}
                              key={data?.soal_menjodohkan_pilihan_key}
                            >
                              {data?.soal_menjodohkan_pilihan_key}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="topik"
                          name={idx}
                          value={item.soal_menjodohkan_jawaban}
                          onChange={changeJawaban}
                          placeholder={t("jawaban")}
                          disabled={preview}
                        />
                      )}
                    </td>
                    {!preview && !kerjakan && (
                      <td>
                        <button
                          className="btn btn-sm btn-danger mb-1"
                          onClick={() => delPernyataan(idx)}
                        >
                          <i className="ri-delete-bin-7-line m-0"></i>
                        </button>
                        {idx === data?.pernyataan?.length - 1 && (
                          <button
                            onClick={addPernyataan}
                            className="btn btn-sm btn-primary mb-1"
                          >
                            <i className="ri-add-circle-fill m-0"></i>
                          </button>
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="table-responsive">
          <table id="datatable" className="table table-bordered table-hover">
            <thead className="table-primary text-white">
              <tr>
                <th
                  className={`text-center bg-transparent va-middle ${
                    kerjakan || preview ? "font-size-20" : ""
                  }`}
                  width="10%"
                >
                  Key
                </th>
                <th width="80%" className="bg-transparent va-middle">
                  {kerjakan || preview ? (
                    <p className="m-0 font-size-20">
                      {data?.soal_menjodohkan_nama_pilihan}
                    </p>
                  ) : (
                    <input
                      type="text"
                      className="form-control bg-transparent form-control-sm placeholder-white text-white"
                      id="soal_menjodohkan_nama_pilihan"
                      name="soal_menjodohkan_nama_pilihan"
                      value={data?.soal_menjodohkan_nama_pilihan}
                      onChange={changeNama}
                      placeholder={t("pernyataan")}
                    />
                  )}
                </th>
                {!preview && !kerjakan && (
                  <th width="10%" className="bg-transparent va-middle">
                    {t("aksi")}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {data?.pilihan?.map((item, idx) => (
                <tr key={item.soal_menjodohkan_pilihan_key}>
                  <td
                    className={`text-center ${kerjakan ? "font-size-20" : ""}`}
                  >
                    {item.soal_menjodohkan_pilihan_key}
                  </td>
                  <td>
                    {kerjakan || preview ? (
                      <p className="m-0 font-size-20">
                        {item.soal_menjodohkan_pilihan_isi}
                      </p>
                    ) : (
                      <textarea
                        className="form-control"
                        rows="1"
                        name={idx}
                        value={item.soal_menjodohkan_pilihan_isi}
                        onChange={changePilihanIsi}
                        placeholder={t("soal")}
                      ></textarea>
                    )}
                  </td>
                  {!preview && !kerjakan && (
                    <td>
                      <button
                        onClick={() => delPilihan(idx)}
                        className="btn btn-sm btn-danger mb-1"
                      >
                        <i className="ri-delete-bin-7-line m-0"></i>
                      </button>
                      {idx === data.pilihan.length - 1 && (
                        <button
                          onClick={addPilihan}
                          className="btn btn-sm btn-primary mb-1"
                        >
                          <i className="ri-add-circle-fill m-0"></i>
                        </button>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ItemSoalMenjodohkan;
