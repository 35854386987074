export const SVG_IMG_EXT = ["image/jpg", "image/jpeg", "image/svg"];
export const PNG_IMG_EXT = ["image/png"];
export const EXCEL_EXT = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
];
export const MS_EXT = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
];
export const PPT_EXT = ["application/vnd.ms-powerpoint"];
export const AUDIO_EXT = [
  "audio/aac",
  "audio/mpeg",
  "audio/ogg",
  "audio/wma",
  "audio/flac",
  "audio/pcm",
  "audio/wave",
];
export const VIDEO_EXT = [
  "video/mp4",
  "video/avi",
  "video/mkv",
  "video/3gp",
  "video/dat",
  "video/gifv",
  "video/wmv",
  "video/flv",
  "video/webm",
];
