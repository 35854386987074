export const checkIsGuru = (role) => {
  return role !== 7 && role !== 6;
};

export const checkIsWalas = (role) => {
  return role === 11;
};

export const checkIsOrtu = (role) => {
  return role === 7;
};
export const checkIsSiswa = (role) => {
  return role === 6;
};

export const ALL_CAN_ACCESS = "ALL";
export const GURU_CAN_ACCESS = "GURU";
export const SISWA_CAN_ACCESS = "SISWA";
export const ORTU_CAN_ACCESS = "ORANG TUA";
export const GURU_SISWA_CAN_ACCESS = "GURU_SISWA";
export const ORTU_SISWA_CAN_ACCESS = "ORANG_TUA_SISWA";

export const checkCanAccess = (role, accessType) => {
  switch (accessType) {
    case ALL_CAN_ACCESS:
      return true;
    case GURU_CAN_ACCESS:
      return role !== 7 && role !== 6;
    case SISWA_CAN_ACCESS:
      return role === 6;
    case ORTU_CAN_ACCESS:
      return role === 7;
    case GURU_SISWA_CAN_ACCESS:
      return role === 6 || (role !== 7 && role !== 6);
    case ORTU_SISWA_CAN_ACCESS:
      return role === 6 || role === 7;
    default:
      return false;
  }
};
