import { API, setAuthToken } from "../../config/api";
import { GET_PROFILE } from "../constant";

export const getProfile = () => {
  return {
    type: GET_PROFILE,
    payload: async () => {
      const token = localStorage.getItem("token");
      setAuthToken(token);
      let res = await API.get("/auth/me").catch((err) => {
        localStorage.removeItem("token");
        window.location.href = "/login";
        return Promise.reject(err);
      });
      localStorage.setItem("auth_user", JSON.stringify(res?.data?.data));
      return res?.data?.data;
    },
  };
};

export const getDetailProfile = async () => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await API.get("/profile").catch((err) => {
    localStorage.removeItem("token");
    window.location.href = "/login";
    return Promise.reject(err);
  });
  return res?.data?.data;
};

export const changePassword = async (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("/auth/change_password", params);
};
