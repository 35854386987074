import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  AUDIO_EXT,
  PNG_IMG_EXT,
  SVG_IMG_EXT,
  VIDEO_EXT,
} from "../../../config/fileType";
import ItemFile from "../ItemFile";
import { InputPoint, InputWaktuSoal } from "../../input";

const UraianCard = ({
  data,
  index,
  delSoal,
  handleCopySoal,
  penilaian,
  handleChangePoint,
  pembahasan,
  pathEdit,
  publishTugas,
  files,
  creatorName,
  handleChangeWaktu,
  disabledPoint,
  disabledWaktu,
  hideWaktu,
  pathEnding,
}) => {
  const { t } = useTranslation();
  return (
    <div className="iq-card">
      <div className="iq-card-header d-flex justify-content-between">
        <div className="iq-header-title d-flex">
          <h4 className="card-title">Soal {data?.soal_no || index + 1}</h4>
          &nbsp;
          {!penilaian && !pembahasan && (
            <span className="badge badge-pill badge-warning h-100 align-self-center">
              <i className="ri-pencil-line"></i> {t("isian_uraian")}
            </span>
          )}
        </div>
        {!penilaian && !pembahasan ? (
          publishTugas ? null : (
            <div className="btn-group" role="group">
              <Link
                to={`${pathEdit || "/edit-soal"}/${data?.soal_id}${pathEnding}`}
                className="btn btn-sm iq-bg-light iq-bg-primary-hover text-dark border d-flex"
              >
                <i className="ri-edit-line m-0"></i>
                <span className="d-none d-md-block">{t("ubah")}</span>
              </Link>
              &nbsp;
              <button
                onClick={handleCopySoal}
                className="btn btn-sm iq-bg-light iq-bg-success-hover text-dark border"
              >
                <i className="ri-file-copy-line m-0"></i>
              </button>
              &nbsp;
              <button
                onClick={() => delSoal(data?.soal_id)}
                className="btn btn-sm iq-bg-light iq-bg-danger-hover text-dark border"
              >
                <i className="ri-delete-bin-line m-0"></i>
              </button>
            </div>
          )
        ) : (
          <>
            <InputPoint
              handleChange={handleChangePoint}
              value={data?.pengerjaan_soal?.pengerjaan_points}
              bordered
              maxPoint={data?.soal_points}
            />
          </>
        )}
      </div>
      <div className="iq-card-body">
        <p className="mb-1">{t("soal")} :</p>
        <div className="d-flex">
          {data?.soal_file && (
            <div className="me-3">
              {SVG_IMG_EXT?.includes(data.soal_file_type) ||
              PNG_IMG_EXT?.includes(data.soal_file_type) ? (
                <img
                  src={data.soal_file}
                  className="img-fluid rounded iq-bg-dark"
                  width="120"
                  height="120"
                  alt="foto soal"
                />
              ) : VIDEO_EXT?.includes(data.soal_file_type) ? (
                <video
                  src={data.soal_file}
                  controls
                  className="w-100 h-auto"
                ></video>
              ) : AUDIO_EXT?.includes(data.soal_file_type) ? (
                <audio src={data.soal_file} controls></audio>
              ) : (
                <p className="m-0 text-muted text-italic">
                  {t("format_tdk_diketahui")}
                </p>
              )}
            </div>
          )}
          <div
            className="w-100"
            dangerouslySetInnerHTML={{ __html: data?.soal_pertanyaan }}
          />
        </div>
        <hr />
        <p className="mb-1">{t("jawaban")} :</p>
        <div className="row font-size-16">
          <div className="col-12 d-flex">
            <span
              className="ri-checkbox-blank-circle-fill "
              style={{ color: "#28a745" }}
            ></span>
            &nbsp;
            <div className="w-100">
              {!penilaian && !pembahasan && data?.soal_essay?.soal_file && (
                <img
                  src={data?.soal_essay?.soal_file}
                  className="img-fluid rounded iq-bg-dark mb-2"
                  width="120"
                  height="120"
                  alt="foto jawaban"
                />
              )}
              {penilaian || pembahasan ? (
                <div
                  className="w-100"
                  dangerouslySetInnerHTML={{
                    __html:
                      data?.pengerjaan_soal?.pengerjaan_soal_essay
                        ?.pengerjaan_jawaban,
                  }}
                />
              ) : (
                <p className="mb-1">{data?.soal_essay?.soal_jawaban ?? "-"}</p>
              )}
            </div>
          </div>
        </div>
        <div className="w-100">
          {files?.map((item) => (
            <div className="col-12 mb-1" key={item.pengerjaan_soal_file_id}>
              <ItemFile
                name={item?.filename}
                type={item.type}
                url={item.path}
              />
            </div>
          ))}
        </div>
        {!penilaian && !pembahasan && (
          <>
            <hr />
            <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between">
              <div className="d-flex gap-2 mb-1">
                <InputPoint
                  value={data?.soal_points}
                  disabled={disabledPoint}
                  handleChange={handleChangePoint}
                  bordered
                />
                {!hideWaktu && (
                  <InputWaktuSoal
                    value={data?.soal_waktu}
                    disabled={disabledWaktu}
                    handleChange={handleChangeWaktu}
                    bordered
                  />
                )}
              </div>
              {creatorName && (
                <p className="fst-italic mb-1">
                  {t("dibuat")}: {creatorName}
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UraianCard;
