import {
  GET_OPTION_TOPIK,
  GET_PROFILE,
  GET_TOPIK,
  LOGOUT,
  SET_DETAIL_TOPIK,
  SET_TOPIK,
} from "../constant";

const initState = {
  topik: [],
  loading: false,
  optionTopik: [],
};

const topikReducer = (state = initState, action) => {
  switch (action?.type) {
    case GET_TOPIK + "_PENDING":
      return {
        ...state,
        loading: true,
      };
    case GET_TOPIK + "_FULFILLED":
      return {
        ...state,
        topik: action.payload,
        loading: false,
      };
    case GET_TOPIK + "_REJECTED":
      return {
        ...state,
        loading: false,
      };
    case GET_OPTION_TOPIK + "_FULFILLED":
      return {
        ...state,
        optionTopik: action.payload,
      };
    case GET_PROFILE + "_REJECTED":
      return {
        ...state,
        topik: [],
        loading: false,
      };
    case SET_TOPIK:
      return {
        ...state,
        topik: action.payload,
      };
    case SET_DETAIL_TOPIK:
      let semuaTopik = state.topik;
      let idx = semuaTopik?.findIndex(
        (item) => item.topik_id === action?.payload?.id
      );
      semuaTopik[idx].data_detail = action?.payload?.data;
      return {
        ...state,
        topik: semuaTopik,
      };
    case LOGOUT:
      return {
        topik: [],
        loading: false,
      };
    default: {
      return state;
    }
  }
};

export default topikReducer;
