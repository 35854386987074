import "moment/locale/id";

import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Swal from "sweetalert2";
import {
  delMateri,
  updMateri,
  updStatusMateri,
} from "../../redux/actions/materiAction";
import { checkIsGuru } from "../../utils/roleUser";
import { ActionData } from "../section";
import ItemFile from "./ItemFile";
import ItemFileVideo from "./ItemFileVideo";
import ShareMateri from "../modal/ShareMateri";

moment.locale("id");

function ItemMateri({
  judul,
  desc,
  files,
  updated_at,
  code,
  status,
  materiVideo,
  h5p,
  dataTugas,
  handleEditMateri,
  getDataTopik,
  sekolahId,
  idMapel,
  idTopik,
}) {
  const { t } = useTranslation();
  const { user } = useSelector(({ user }) => user);
  const isGuru = checkIsGuru(user?.user_roleid);
  const [shareMateri, setShareMateri] = useState(false);

  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };

  const handleDelete = () => {
    Swal.fire({
      title: t("konfirmasi_hapus"),
      text: t("are_u_sure_delete", {
        params: t("data_ini", { data: t("materi") }),
      }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendDeleteMateri();
    });
  };

  const sendDeleteMateri = () => {
    delMateri(code)
      .then(() => {
        getDataTopik();
      })
      .catch(() => {
        showAlert(t("failed_delete_data", { data: t("materi") }), false);
      });
  };

  const handleChangeStatus = () => {
    let newStatus = status === "" ? "Hide" : null;
    Swal.fire({
      title: t("konfirmasi_edit"),
      text: t("are_u_sure_edit", {
        data: t("materi"),
        status: t(newStatus ? "sembunyikan" : "tampilkan"),
      }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendStatusMateri(newStatus);
    });
  };

  const sendStatusMateri = (status) => {
    const params = {
      materi_id: code?.toString(),
      status,
    };
    updStatusMateri(params)
      .then(() => {
        getDataTopik();
      })
      .catch(() => {
        showAlert(t("failed_edit_data", { data: t("materi") }), false);
      });
  };

  const handleDeleteFile = (code, type) => {
    let params = dataTugas;
    if (type === "file") {
      params.files = params?.files?.filter(
        (item) => item.filemateri_id !== code
      );
    } else if (type === "h5p") {
      params.h5p_materi = params?.h5p_materi?.filter(
        (item) => item.h5p_materi_id !== code
      );
    } else if (type === "video") {
      params.video_materi = params?.video_materi?.filter(
        (item) => item.video_materi_id !== code
      );
    }
    updMateri(dataTugas?.materi_id, params)
      .then(() => {
        getDataTopik();
      })
      .catch(() =>
        showAlert(t("gagal_hapus", { data: t("file_materi") }), false)
      );
  };

  const handleShare = () => setShareMateri(code);

  return (
    <li>
      <div id="topik-item-detail" className="timeline-dots"></div>
      <div className="d-flex align-items-md-center justify-content-between mb-1 flex-column flex-md-row">
        <div className="d-flex align-items-md-center gap-2 flex-column flex-md-row">
          <h6 className="m-0">{judul}</h6>
          {isGuru && (
            <ActionData
              handleDelete={handleDelete}
              handleEdit={handleEditMateri}
              handleShare={handleShare}
              handleStatus={handleChangeStatus}
              showBtnDelete
              showBtnEdit
              showBtnShare
              showBtnStatus
              statusShow={status === ""}
            />
          )}
        </div>
        <p className="font-size-12 m-0">
          {updated_at ? moment(updated_at).format("DD MMMM YYYY") : ""}{" "}
        </p>
      </div>
      {desc && (
        <div className="d-inline-block w-100">
          <div
            dangerouslySetInnerHTML={{ __html: desc }}
            className="preview-html"
          ></div>
        </div>
      )}
      {materiVideo?.map((item) => (
        <ItemFileVideo
          key={item.video_materi_id}
          name={item.video_name}
          type={item.video_type}
          url={item.video_file}
          handleDeleteFile={() =>
            handleDeleteFile(item.video_materi_id, "video")
          }
          item={item}
        />
      ))}
      {files?.map((item) => (
        <ItemFile
          key={item.filemateri_id}
          name={item.filename}
          type={item.type}
          url={item.path}
          handleDeleteFile={() => handleDeleteFile(item.filemateri_id, "file")}
        />
      ))}
      {h5p?.map((item) => (
        <ItemFile
          key={item.h5p_materi_id}
          name={item.h5p_filename}
          type="html"
          url={item.h5p_file}
          handleDeleteFile={() => handleDeleteFile(item.h5p_materi_id, "h5p")}
        />
      ))}
      {shareMateri && (
        <ShareMateri
          show={shareMateri !== null}
          materiId={shareMateri}
          sekolahId={sekolahId}
          handleClose={() => {
            setShareMateri(null);
          }}
          idMapel={idMapel}
          idTopik={idTopik}
        />
      )}
    </li>
  );
}

export default ItemMateri;
