import React from "react";
import { useTranslation } from "react-i18next";
import { Cerita } from "..";
import { PageLoader } from "../../assets/images";
import ListPembahasan from "./ListPembahasan";

const SoalJawaban = ({
  loading,
  hidePembahasan,
  soalCerita,
  soal,
  disabledPoint,
}) => {
  const { t } = useTranslation();
  return (
    <div className="col-lg-12">
      <div className="iq-card">
        <div className="iq-card-header d-flex align-items-center">
          <h4 className="card-title m-0">{t("soal_jawaban")}</h4>
        </div>
        {loading ? (
          <div className="text-center">
            <img src={PageLoader} alt="loader" width={50} />
          </div>
        ) : hidePembahasan ? (
          <div className="text-center">
            <p className="py-3">{t("pembahasan_tdk_bisa_ditampilkan")}</p>
          </div>
        ) : (
          <div className="col-lg-12 p-0">
            <div className="p-3">
              {soalCerita?.map((item, index) => (
                <Cerita
                  key={crypto.randomUUID()}
                  data={item}
                  index={index}
                  penilaian={true}
                  dataSoal={soal}
                />
              ))}
              {soal?.length > 0 ? (
                soal?.map((item, idx) => (
                  <ListPembahasan
                    idx={idx}
                    item={item}
                    soalJenis={item?.soal_jenis}
                    key={crypto.randomUUID()}
                    disabledPoint={disabledPoint}
                  />
                ))
              ) : (
                <p className="text-center m-0 text-muted pb-4">
                  {t("siswa_blm_mengerjakan")}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SoalJawaban;
