import React from "react";
import { useTranslation } from "react-i18next";

import { SlideHome, Wrapper } from "../../components";
import Header from "./section/header";

function Home() {
  const { t } = useTranslation();

  return (
    <Wrapper title={`${t("beranda")}`}>
      <div className="container">
        <div className="row">
          <SlideHome />
          <Header />
        </div>
      </div>
    </Wrapper>
  );
}

export default Home;
