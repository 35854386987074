import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";

import { PageLoader } from "../../assets/images";
import { InputEditor, InputJenisSoal, Wrapper } from "../../components";
import {
  addSoalCeritaUjian,
  getListSoalCeritaUjian,
  uploadFileSoalUjian,
} from "../../redux/actions/ujianAction";

function AddSoalCeritaUjian() {
  const { t } = useTranslation();
  const { id, idKelas } = useParams();
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const [submit, setSubmit] = useState(false);
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const [soal, setSoal] = useState([]);
  const [selectedSoal, setSelectedSoal] = useState([]);
  const [params, setParams] = useState({
    ujian_id: "",
    cerita_deskripsi: "",
    cerita_file: "",
    detail: [],
  });
  const handleChangeSelect = (data) => {
    setSelectedSoal(data);
    setParams((pref) => ({
      ...pref,
      detail: data?.map((item) => ({ soal_id: item.value })),
    }));
  };
  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    }).then(() => {
      if (status) {
        navigate(`/ujian/soal/${id}/${idKelas}`);
      }
    });
  };
  const handleSubmit = () => {
    setSubmit(true);
    const data = {
      ...params,
      cerita_deskripsi: editorRef?.current?.getContent(),
    };
    addSoalCeritaUjian(data)
      .then(() => {
        showAlert(t("berhasil_simpan", { params: t("soal") }), true);
        setSubmit(false);
      })
      .catch(() => {
        showAlert(t("data_gagal_simpan", { params: t("soal") }), false);
        setSubmit(false);
      });
  };
  const handleChangeFile = ({ target: { files } }) => {
    if (files.length > 0) {
      var formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`file`, files[i]);
      }
      setUploadFileLoading(true);
      uploadFileSoalUjian(formData)
        .then((res) => {
          setUploadFileLoading(false);
          setParams((pref) => ({
            ...pref,
            cerita_file: res?.data?.data[0]?.url,
          }));
        })
        .catch((err) => {
          setUploadFileLoading(false);
        });
    }
  };
  const stripHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };
  useEffect(() => {
    getListSoalCeritaUjian(id).then(({ data }) =>
      setSoal(
        data?.data?.map((item) => {
          let soal = stripHtml(item.soal_pertanyaan);
          return {
            value: item.soal_id,
            label: soal.length > 200 ? soal.substring(0, 200) + "..." : soal,
          };
        })
      )
    );
    setParams((pref) => ({
      ...pref,
      ujian_id: id,
    }));
  }, [id]);

  return (
    <Wrapper title={t("tambah_soal_cerita")}>
      <div className="container">
        <div className="row row-eq-height">
          <div className="col-lg-12">
            <div className="row pl-0 pr-0 justify-content-end">
              <div className="col-lg-6 col-sm-4 col-12 d-flex justify-content-lg-end">
                <InputJenisSoal
                  value="cerita"
                  handleChange={({ target: { value } }) =>
                    navigate(`/tambah-soal/${id}/${value}`)
                  }
                />
              </div>
            </div>
            <div className="iq-card">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title d-flex">
                  <h4 className="card-title fw-bold">{t("soal")}</h4>
                </div>
              </div>
              <div className="col-lg-12 p-0">
                <div className="iq-card ">
                  <div className="iq-card-body">
                    <div className="row">
                      {params?.cerita_file ? (
                        <div className="col-12 col-lg-3 pr-3 mb-2">
                          <div className="user-images position-relative overflow-hidden">
                            {params.cerita_file ? (
                              <img
                                src={params.cerita_file}
                                className="img-fluid rounded w-100"
                                alt="soal"
                              />
                            ) : null}
                            <button
                              className="border-0 rounded position-absolute image-edit-btn iq-bg-primary font-size-18 right-10 top-10"
                              onClick={() =>
                                setParams((pref) => ({
                                  ...pref,
                                  cerita_file: "",
                                }))
                              }
                            >
                              <i className="ri-delete-bin-fill"></i>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="col-12 col-lg-2 pr-3 mb-2">
                          <div className="send-panel row">
                            <div className="col-lg-12 col-6">
                              <label
                                className="p-3 iq-bg-primary rounded w-100 "
                                htmlFor="image_file"
                              >
                                <input
                                  type="file"
                                  onChange={handleChangeFile}
                                  id="image_file"
                                  className="d-none"
                                  name="soal_file"
                                  accept="image/png, image/jpg, image/jpeg"
                                  disabled={uploadFileLoading}
                                />
                                <div className="w-100 text-primary h-auto d-flex flex-column justify-content-center align-items-center btn p-0">
                                  <i className="ri-image-fill font-size-40"></i>{" "}
                                  {uploadFileLoading
                                    ? `${t("loading")}...`
                                    : "Image"}
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        className={`col-12 px-2 ${
                          params?.cerita_file ? "col-lg-9" : "col-lg-10"
                        } pb-3`}
                      >
                        <Select
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            colors: {
                              ...theme.colors,
                              primary: "#3f9b4b",
                            },
                          })}
                          value={selectedSoal}
                          onChange={handleChangeSelect}
                          isMulti
                          options={soal}
                          className="mb-2 z-index-2"
                        />
                        <InputEditor editorRef={editorRef} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-end mb-5">
              <Link
                to={`/ujian/soal/${id}/${idKelas}`}
                className="btn btn-dark text-center fw-bold mx-1 float-right"
              >
                {t("batal")}
              </Link>
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary text-center fw-bold  float-right"
              >
                <i className="ri-save-2-fill m-0"></i>
                {submit ? (
                  <img src={PageLoader} alt="loader" style={{ width: 15 }} />
                ) : (
                  t("simpan")
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default AddSoalCeritaUjian;
