import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

import { ItemSelectSiswa } from '../item';

function SettingSiswa({
  listSiswa,
  show,
  handleClose,
  selectedSiswa,
  selectAll,
}) {
  const { t } = useTranslation();
  const [selectSiswa, setSelectSiswa] = useState([]);
  const [allSiswa, setAllSiswa] = useState(false);

  useEffect(() => {
    setSelectSiswa(
      selectAll
        ? listSiswa?.map((item) => item.userkelas_userid)
        : selectedSiswa
    );
    setAllSiswa(selectAll);
  }, [selectedSiswa, selectAll, listSiswa]);

  const cekSelected = (id) => {
    let cek = selectSiswa?.filter(
      (item) => item?.toString() === id?.toString()
    );
    return cek?.length > 0;
  };

  const handleChange = () => {};

  return (
    <div className="col-lg-12">
      <Modal size="md" show={show} onHide={handleClose} id="SettingSiswa">
        <Modal.Header closeButton>
          <h5 className="modal-title">{t("atur_siswa")}</h5>
        </Modal.Header>
        <Modal.Body>
          <ul className="todo-task-lists m-0 p-0">
            <li className="d-flex align-items-center iq-bg-primary px-1 py-3">
              <div className="media-support-info">
                <h6 className="d-inline-block fw-bold">{t("pilih_semua")}</h6>
              </div>
              <div className="iq-card-header-toolbar d-flex align-items-center">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    name="allSiswa"
                    className="custom-control-input"
                    id="allSiswa"
                    checked={allSiswa}
                    onChange={({ target: { checked } }) => {
                      setAllSiswa(checked);
                      if (checked) {
                        setSelectSiswa(
                          listSiswa?.map((item) => item.userkelas_userid)
                        );
                      } else {
                        setSelectSiswa([]);
                      }
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="allSiswa"
                  ></label>
                </div>
              </div>
            </li>
            {listSiswa?.map((item) => (
              <ItemSelectSiswa
                key={item?.user?.user_id}
                img={item?.user?.user_avatar}
                nama={item?.user?.user_nama}
                selected={cekSelected(item?.user?.user_id?.toString())}
                handleChange={handleChange}
                code={item?.user?.user_id}
              />
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            {t("tutup")}
          </button>
          <button type="button" className="btn btn-primary">
            {t("save_changes")}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SettingSiswa;
