import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { IcGoogle, Logo1 } from "../../assets/images";
import { login, loginGoogle } from "../../redux/actions/authAction";
import { useGoogleLogin } from "@react-oauth/google";

function Login({ handleLogin }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [submit, setSubmit] = useState(false);
  const [params, setParams] = useState({
    username: "",
    password: "",
  });
  useEffect(() => {
    document.title = `${t("masuk")} | Smartclass Bakti Mulya 400`;
  }, [t]);

  const handleChange = ({ target: { value, name } }) => {
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    handleLogin(params)
      .then(() => {
        setSubmit(false);
        navigate("/");
      })
      .catch((err) => {
        let res = err?.response;
        showAlert(res?.data?.error || t("coba_lagi"));
        setSubmit(false);
      });
  };
  const showAlert = (text) => {
    Swal.fire({
      title: "Login Gagal!",
      text: text,
      icon: "error",
      imageWidth: 92,
      imageHeight: 92,
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };
  const handleLoginGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      const params = {
        accessToken: codeResponse?.access_token,
      };
      setSubmit(true);
      dispatch(loginGoogle(params))
        .then(() => {
          setSubmit(false);
          navigate("/");
        })
        .catch((err) => {
          let res = err?.response;
          showAlert(res?.data?.error || t("coba_lagi"));
          setSubmit(false);
        });
    },
  });
  return (
    <Fragment>
      {/* Sign in Start */}
      <section className="sign-in-page">
        <div className="row no-gutters h-100">
          <div className="col-12 col-lg-5 h-100">
            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
              <div className="sign-in-from w-100">
                <div className="sign-in-logo mb-2">
                  <img src={Logo1} className="img-fluid" alt="logo" />
                </div>
                <h2 className="mb-1 fw-bold">{t("masuk")}</h2>
                <p className="m-0">{t("masuk_desc")}</p>
                <div
                  className="card mt-2 border"
                  style={{
                    backgroundColor: "rgb(255, 255, 255)",
                    borderRadius: 16,
                  }}
                >
                  <div className="card-body" style={{ padding: 25 }}>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input
                          type="text"
                          className="form-control mb-0"
                          id="username"
                          placeholder={t("masukkan", { params: "username" })}
                          onChange={handleChange}
                          value={params.username}
                          name="username"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="pass">{t("password")}</label>
                        <input
                          type="password"
                          className="form-control mb-0"
                          id="pass"
                          placeholder={t("password")}
                          onChange={handleChange}
                          value={params.password}
                          name="password"
                        />
                      </div>
                      {/* <div className="d-flex justify-content-end mt-2">
                        <Link to="/lupa_password">Lupa Password</Link>
                      </div> */}
                      <button
                        disabled={submit}
                        type="submit"
                        className={`btn btn-primary btn-lg rounded-pill w-100  mt-3 ${
                          submit ? "btn-disabled" : ""
                        }`}
                      >
                        {submit ? (
                          <p className="m-0">{t("loading")} ...</p>
                        ) : (
                          t("masuk")
                        )}
                      </button>
                      <span className="d-flex justify-content-center font-size-12 m-2">
                        {t("atau")}
                      </span>
                      <button
                        disabled={submit}
                        onClick={handleLoginGoogle}
                        type="button"
                        className="btn btn-outline-primary w-100 rounded-pill"
                      >
                        <img
                          src={IcGoogle}
                          alt="icon google"
                          width={24}
                          height={24}
                        />
                        <span className="ms-2">Google</span>
                      </button>
                      {/* <div className="sign-info">
                        <ul className="iq-social-media">
                          <li>
                            <a href="https://www.facebook.com/sekolahbm400">
                              <i className="ri-facebook-box-fill"></i>
                            </a>
                          </li>
                          <li>
                            <a href="https://twitter.com/baktimulya400">
                              <i className="ri-twitter-fill"></i>
                            </a>
                          </li>
                          <li>
                            <a href="https://www.instagram.com/bm400school/">
                              <i className="ri-instagram-fill"></i>
                            </a>
                          </li>
                        </ul>
                      </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 bg-sign-in d-none d-lg-block"></div>
        </div>
      </section>
    </Fragment>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleLogin: (params) => dispatch(login(params)),
  };
};
export default connect(null, mapDispatchToProps)(Login);
