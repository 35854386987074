import { API, setAuthToken } from "../../config/api";
import { GET_NILAI } from "../constant";

export const getNilai = (params) => {
  return {
    type: GET_NILAI,
    payload: async () => {
      const token = localStorage.getItem("token");
      setAuthToken(token);
      let res = await API.get(
        `nilai/tugas?${new URLSearchParams(params).toString()}`
      ).catch((err) => {
        return Promise.reject(err);
      });
      return res?.data?.data;
    },
  };
};

export const getDetailUjianNilai = (code, kelasId) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let params = `ujian_id=${code}`;
  if (kelasId) params += "&kelasdetail_id=" + kelasId;
  return API.get(`ujian/nilai/detail?${params}`);
};

export const getPengerjaanUjianSiswa = (tugas_id, user_id) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`ujian/nilai/soal?ujian_id=${tugas_id}&user_id=${user_id}`);
};

export const updPointsUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("ujian/nilai/update-point", params);
};

export const kirimNilaiRaportUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("ujian/nilai/raport", params);
};

export const getPembahasanUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  const data = new URLSearchParams(params).toString();
  return API.get(`ujian/nilai/pembahasan?${data}`);
};
