import axios from "axios";

const ACCESS_TOKEN = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_BACKEND_API_URL_2;
const headers = {
  headers: {
    Authorization: ACCESS_TOKEN,
  },
};

export const getRapor = async (params) => {
  let res = await axios
    .get(`${API_URL}/rapor?${new URLSearchParams(params).toString()}`, headers)
    .catch((err) => {
      return Promise.reject(err);
    });
  return res?.data;
};

export const getSemesterUser = async (params) => {
  let res = await axios
    .get(
      `${API_URL}/semester?${new URLSearchParams(params).toString()}`,
      headers
    )
    .catch((err) => {
      return Promise.reject(err);
    });
  return res?.data;
};
