import { GET_DRIVE, GET_DRIVE_DETAIL, LOGOUT } from '../constant';

const initState = {
  dataDrive: {
    folder: [],
    file: [],
  },
  detailDrive: null,
};

const driveReducer = (state = initState, action) => {
  switch (action?.type) {
    case GET_DRIVE + "_PENDING":
      return state;
    case GET_DRIVE + "_FULFILLED":
      return {
        dataDrive: action?.payload,
      };
    case GET_DRIVE + "_REJECTED":
      return state;
    case GET_DRIVE_DETAIL + "_PENDING":
      return state;
    case GET_DRIVE_DETAIL + "_FULFILLED":
      return {
        detailDrive: action?.payload,
      };
    case GET_DRIVE_DETAIL + "_REJECTED":
      return state;
    case LOGOUT:
      return {
        dataDrive: [],
      };
    default: {
      return state;
    }
  }
};

export default driveReducer;
