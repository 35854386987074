import moment from "moment";
import React, { useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ModalDescUjian, ModalLihatNilai } from "..";
import { checkIsGuru, checkIsOrtu, checkIsSiswa } from "../../utils/roleUser";
import Swal from "sweetalert2";
moment.locale("id");
/**
 * Renders a card for displaying information about an exam.
 * @param {Object} props - The component props.
 * @param {string} props.thumbnail - The URL of the thumbnail image for the exam.
 * @param {string} props.cover - The URL of the cover image for the exam.
 * @param {string} props.status - The status of the exam, either "draft" or null.
 * @param {string} props.statusPengerjaan - The status of the exam execution status, either "lanjut-mengerjakan", "kerjakan-ujian", "waktu-habis", "belum-mulai", "lihat-nilai", "hide-nilai".
 * @param {string} props.mapel - The subject of the exam.
 * @param {string} props.startDate - The start date and time of the exam.
 * @param {string} props.endDate - The end date and time of the exam.
 * @param {number} props.totalSiswa - The total number of students.
 * @param {number} props.totalKolaborasi - The total number of collaborations.
 * @param {number} props.totalSoal - The total number of questions.
 * @param {string} props.desc - The description of the exam.
 * @param {string} props.idUjian - The ID of the exam.
 * @param {string} props.jenisUjian - The time type of the exam.
 * @param {string} props.kelasSiswa - The Student Class.
 * @param {string} props.kelasId - The ID of The Class.
 * @param {string} props.mapelId - The ID of The Mapel.
 * @param {Function} props.handleEdit - A function to handle the edit action for the exam.
 * @returns {JSX.Element} The rendered CardUjian component.
 */

const dataStatus = ["waktu-habis", "lihat-nilai", "hide-nilai"];
function RenderButtonGuru({ idUjian, idKelas }) {
  const { t } = useTranslation();
  return (
    <>
      <Link
        to={`/ujian/soal/${idUjian}/${idKelas}`}
        className="btn btn-primary d-block mb-2"
      >
        {t("soal_ujian")}
      </Link>
      <Link
        to={`/ujian/nilai/${idUjian}/${idKelas}`}
        className="btn btn-warning text-white d-block"
      >
        {t("lihat_nilai")}
      </Link>
    </>
  );
}

function RenderButtonSiswa({
  idUjian,
  statusUjian,
  mapel,
  startDate,
  handleLihatNilai,
  statusPengerjaan,
}) {
  const { t } = useTranslation();

  const showAlert = (text, icon) => {
    Swal.fire({
      title: "Oops!",
      text: text,
      icon: icon,
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };

  const handleCooming = () => {
    showAlert(
      t("ujian_blm_dimulai_msg", {
        title: t("nama_ujian", { mapel }),
        time: moment(startDate).fromNow(),
      }),
      "info"
    );
  };

  const handleWaktuHabis = () => {
    showAlert(t("ujian_waktu_habis_msg"), "info");
  };

  const handleHideNilai = () => {
    showAlert(t("ujian_hide_nilai_msg"), "info");
  };

  if (statusUjian === "started" && !dataStatus.includes(statusPengerjaan)) {
    return (
      <Link
        to={`/ujian/kerjakan/${idUjian}`}
        className="btn btn-primary d-block mb-2 text-white"
      >
        {t("kerjakan-ujian")}
      </Link>
    );
  } else if (
    statusUjian === "started" &&
    dataStatus.includes(statusPengerjaan)
  ) {
    return (
      <button
        onClick={handleWaktuHabis}
        className="w-100 btn btn-secondary text-white d-block"
      >
        {t("waktu-habis")}
      </button>
    );
  }
  if (statusUjian === "cooming") {
    return (
      <button
        onClick={handleCooming}
        className={`w-100 btn ${
          statusUjian === "cooming" ? "btn-warning" : "btn-secondary"
        } d-block mb-2 text-white`}
      >
        {t("kerjakan-ujian")}
      </button>
    );
  }
  if (statusUjian === "expired" && statusPengerjaan === "lihat-nilai") {
    return (
      <button
        onClick={handleLihatNilai}
        className="w-100 btn btn-success text-white d-block"
      >
        {t("lihat_nilai")}
      </button>
    );
  } else {
    return (
      <button
        onClick={handleHideNilai}
        className="w-100 btn btn-secondary text-white d-block"
      >
        {t("lihat_nilai")}
      </button>
    );
  }
}

function RenderButtonOrtu({ statusUjian, handleLihatNilai, statusPengerjaan }) {
  const { t } = useTranslation();

  const showAlert = (text, icon) => {
    Swal.fire({
      title: "Oops!",
      text: text,
      icon: icon,
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };

  const handleHideNilai = () => {
    showAlert(t("ujian_hide_nilai_msg"), "info");
  };

  if (statusUjian === "expired" && statusPengerjaan === "lihat-nilai") {
    return (
      <button
        onClick={handleLihatNilai}
        className="w-100 btn btn-success text-white d-block"
      >
        {t("lihat_nilai")}
      </button>
    );
  } else {
    return (
      <button
        onClick={handleHideNilai}
        className="w-100 btn btn-secondary text-white d-block"
      >
        {t("lihat_nilai")}
      </button>
    );
  }
}

function CardUjian({
  thumbnail,
  cover,
  status,
  statusPengerjaan,
  mapel,
  startDate,
  endDate,
  totalSiswa,
  totalKolaborasi,
  totalSoal,
  desc,
  idUjian,
  handleEdit,
  namaAnak,
  kelas,
  semester,
  kolaborasi,
  handleDelete,
  jenisUjian,
  kelasSiswa,
  kelasId,
  mapelId,
}) {
  const { t } = useTranslation();
  const { user } = useSelector(({ user }) => user);
  const [openDesc, setOpenDesc] = useState(false);
  const [openLihatNilai, setOpenLihatNilai] = useState(false);
  const isGuru = checkIsGuru(user?.user_roleid);
  const isSiswa = checkIsSiswa(user?.user_roleid);
  const isOrtu = checkIsOrtu(user?.user_roleid);
  const today = new Date();
  let statusUjian = "";

  const handleOpenDesc = () => {
    setOpenDesc(true);
  };

  const handleCloseDesc = () => {
    setOpenDesc(false);
  };

  const handleToogleLihatNilai = () => setOpenLihatNilai(!openLihatNilai);

  const actionUjian = () => {
    return (
      <div className="position-absolute start-0 end-0 top-0 d-flex justify-content-between w-100">
        {status === "draft" && (
          <span className="badge badge-danger fst-italic m-3">
            {t("belum_publish")}
          </span>
        )}
        <Dropdown align="end" className="ms-auto">
          <Dropdown.Toggle className="bg-transparent border-0 bg-image-none text-black-50 after-none">
            <i className="ri-more-fill fa-2x"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu className="min-max-content">
            <Dropdown.Item
              onClick={() => handleEdit(idUjian, kelasId)}
              as="button"
            >
              <i className="ri-edit-2-fill"></i> {t("ubah")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleDelete(idUjian, kelasId, mapelId)}
              as="button"
            >
              <i className="ri-delete-bin-2-fill"></i> {t("hapus")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  if (startDate && endDate) {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    if (today < startDateObj) {
      statusUjian = "cooming";
    } else if (today >= startDateObj && today <= endDateObj) {
      statusUjian = "started";
    } else {
      statusUjian = "expired";
    }
  } else if (startDate) {
    const startDateObj = new Date(startDate);
    if (today < startDateObj) {
      statusUjian = "cooming";
    }
  } else if (endDate) {
    const endDateObj = new Date(endDate);
    if (today > endDateObj) {
      statusUjian = "expired";
    }
  }

  return (
    <div className="col-md-6 col-lg-4">
      <div className="iq-card">
        <div className="top-bg-image position-relative">
          <img src={thumbnail} className="img-fluid w-100" alt="group-bg" />
          {isGuru && actionUjian()}
        </div>
        <div className="iq-card-body text-center position-relative">
          <div className="group-icon">
            <img
              src={cover}
              alt="profile-img"
              className="rounded-circle img-fluid avatar-120"
            />
          </div>
          <div className="group-info pt-3 pb-3">
            <div className="d-flex gap-2 justify-content-center align-items-center">
              <h4
                className={`fw-bold ${
                  isSiswa
                    ? "text-black"
                    : statusUjian === "started"
                    ? "text-primary"
                    : statusUjian === "cooming"
                    ? "text-warning"
                    : "text-secondary"
                }`}
              >
                {mapel}
              </h4>
              {!isOrtu && (
                <OverlayTrigger
                  overlay={
                    <Tooltip id="button-tooltip-2">{t("informasi")}</Tooltip>
                  }
                >
                  <button
                    onClick={handleOpenDesc}
                    className="btn btn-info rounded-pill btn-sm text-white"
                  >
                    <i className="ri-information-line m-0"></i>
                  </button>
                </OverlayTrigger>
              )}
            </div>
            {startDate && endDate && (
              <p className="text-center m-0">
                {moment(startDate).format("DD MMM YYYY (HH:mm)")} -{" "}
                {moment(endDate).format("DD MMM YYYY (HH:mm)")}
              </p>
            )}
          </div>
          {isGuru && (
            <div className="d-flex align-items-center justify-content-center gap-2 mb-3">
              {kolaborasi?.map((item) => (
                <OverlayTrigger
                  key={crypto.randomUUID()}
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      {item.user.user_nama}
                    </Tooltip>
                  }
                >
                  <span className="badge badge-pill pointer iq-bg-primary mr-2">
                    {item.kelas.kelasdetail_nama}
                  </span>
                </OverlayTrigger>
              ))}
              ({jenisUjian.toUpperCase()} - Semester {semester})
            </div>
          )}
          {isSiswa && (
            <div className="d-flex align-items-center justify-content-center gap-2 mb-3">
              <OverlayTrigger
                key={crypto.randomUUID()}
                overlay={<Tooltip id="button-tooltip-2">{kelasSiswa}</Tooltip>}
              >
                <span className="badge badge-pill pointer iq-bg-primary mr-2">
                  {kelasSiswa}
                </span>
              </OverlayTrigger>
              ({jenisUjian.toUpperCase()} - Semester {semester})
            </div>
          )}
          {isOrtu && (
            <p className="mb-2">
              {namaAnak} ({kelas})
            </p>
          )}
          <div className="group-details d-inline-block mb-3">
            <ul className="d-flex align-items-center justify-content-between list-inline m-0 p-0">
              {isGuru && (
                <li className="ps-3 pe-3">
                  <p className="mb-0">{t("siswa")}</p>
                  <h6>{totalSiswa}</h6>
                </li>
              )}
              {isGuru && (
                <li className="ps-3 pe-3">
                  <p className="mb-0">{t("kolaborasi")}</p>
                  <h6>{totalKolaborasi}</h6>
                </li>
              )}
              {!isOrtu && (
                <li className="ps-3 pe-3">
                  <p className="mb-0">{t("soal")}</p>
                  <h6>{totalSoal}</h6>
                </li>
              )}
              {isOrtu && (
                <li className="ps-3 pe-3">
                  <p className="mb-0">{t("semester")}</p>
                  <h6>
                    Semester {semester} ({jenisUjian.toUpperCase()})
                  </h6>
                </li>
              )}
            </ul>
          </div>
          {isGuru && <RenderButtonGuru idUjian={idUjian} idKelas={kelasId} />}
          {isSiswa && (
            <RenderButtonSiswa
              idUjian={idUjian}
              statusUjian={statusUjian}
              statusPengerjaan={statusPengerjaan}
              mapel={mapel}
              startDate={startDate}
              handleLihatNilai={handleToogleLihatNilai}
            />
          )}
          {isOrtu && (
            <RenderButtonOrtu
              handleLihatNilai={handleToogleLihatNilai}
              idUjian={idUjian}
              statusUjian={statusUjian}
              statusPengerjaan={statusPengerjaan}
            />
          )}
        </div>
      </div>
      <ModalDescUjian
        show={openDesc}
        handleClose={handleCloseDesc}
        desc={desc}
      />
      {!isGuru && openLihatNilai && (
        <ModalLihatNilai
          show={openLihatNilai}
          handleClose={handleToogleLihatNilai}
          idUjian={idUjian}
          semester={semester}
        />
      )}
    </div>
  );
}

export default CardUjian;
