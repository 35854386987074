import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import { PageLoader } from '../../assets/images';
import { updFileDrive } from '../../redux/actions/driveAction';

function EditFileDrive({ show, handleClose, data }) {
  const { t } = useTranslation();
  const [submit, setSubmit] = useState(false);
  const [nama, setNama] = useState("");
  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };
  const handleSubmit = () => {
    setSubmit(true);
    let params = {
      drive_file_id: data?.drive_file_id,
      drive_file_name: nama,
    };
    updFileDrive(params)
      .then(() => {
        setSubmit(false);
        handleClose();
      })
      .catch((err) => {
        showAlert(t("gagal_menyimpan_perubahan"), false);
        setSubmit(false);
      });
  };

  const handleChange = ({ target: { value } }) => {
    setNama(value);
  };

  useEffect(() => {
    if (data) {
      setNama(data?.drive_file_name);
    } else {
      setNama("");
    }
  }, [data]);

  return (
    <div className="col-lg-12">
      <Modal
        size="sm"
        show={show}
        onHide={() => {
          if (!submit) handleClose();
        }}
        id="EditFileDrive"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title">Edit File</h5>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="form-control"
            id="nama"
            value={nama}
            name="nama"
            placeholder={t("nama_file")}
            onChange={handleChange}
            required
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleClose}
          >
            {t("tutup")}
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={submit}
          >
            {submit ? (
              <img src={PageLoader} alt="loader" style={{ width: 15 }} />
            ) : (
              t("save_changes")
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditFileDrive;
