import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Wrapper } from "../../components";
import Kursus from "./Kursus";
import KursusSaya from "./KursusSaya";
import KursusSimpan from "./KursusSimpan";

function Course() {
  const { t } = useTranslation();

  return (
    <Wrapper title={t("kursus")}>
      <div className="container-course" id="page-kursus">
        <Tabs
          defaultActiveKey="kursus"
          className="iq-card p-3"
          fill
          variant="pills"
        >
          <Tab eventKey="kursus" title={t("kursus")}>
            <Kursus />
          </Tab>
          <Tab eventKey="kursus_saya" title={t("kursus_saya")}>
            <KursusSaya />
          </Tab>
          <Tab eventKey="simpan" title={t("marked")}>
            <KursusSimpan />
          </Tab>
        </Tabs>
      </div>
    </Wrapper>
  );
}

export default Course;
