import i18next from "i18next";
import React from "react";

function LanguageCard({ img, name, code }) {
  return (
    <div className="iq-friend-request iq-bg-dark-hover">
      <button
        onClick={() => i18next.changeLanguage(code)}
        className="btn-reset w-100"
      >
        <div className="iq-sub-card iq-sub-card-big d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div>
              <img
                className="avatar-40 rounded"
                src={img}
                alt="icon language"
              />
            </div>
            <div className="media-body ms-3">
              <h6 className="mb-0">{name}</h6>
            </div>
          </div>
        </div>
      </button>
    </div>
  );
}

export default LanguageCard;
