import React from "react";

import ItemPlaylist from "./ItemPlaylist";

function ItemPlaylistCourse({
  id,
  title,
  materi,
  idx,
  handlePlay,
  topikJoin,
  tugas,
  buyCourse,
}) {
  return (
    <div id="accordion" className="iq-accordion">
      <div className="iq-card">
        <div
          className="row py-4 px-3 collapsed"
          data-toggle="collapse"
          data-target={`#playlist-${id}`}
          aria-expanded="false"
        >
          <button
            className="bg-transparent border-0 d-flex justify-content-between"
            id={"heading" + id}
          >
            <div className="d-flex align-items-center gap-3 w-90">
              <div
                className={`btn  square-48 rounded-circle d-flex align-items-center justify-content-center
              ${topikJoin?.topik_finish ? "btn-primary" : "btn-secondary"}`}
              >
                {idx}
              </div>
              <h5 className="fw-bold text-start elipsis-1-line flex-1">
                {title}
              </h5>
            </div>
            <div>
              <i className="ri-arrow-down-s-line fa-2x"></i>
            </div>
          </button>
        </div>
        <div
          id={"playlist-" + id}
          className="collapse"
          aria-labelledby={"heading" + id}
          data-parent="#accordion"
        >
          <div className="card-body px-4 py-2">
            {materi?.map((item) => (
              <ItemPlaylist
                time={item.materi_waktu}
                title={item.materi_title}
                key={item.materi_id}
                status={item.materi_join?.materi_finish}
                handlePlay={() => {
                  if (buyCourse) {
                    handlePlay({
                      type: "materi",
                      video: item.materi_video,
                      video_type: item.materi_video_type,
                      lock: item.materi_lock,
                    });
                  } else if (!buyCourse) {
                    handlePlay({ type: "materi", id: item.materi_id });
                  }
                }}
                type="materi"
                lock={item.materi_lock}
                buyCourse={buyCourse}
              />
            ))}
            {tugas?.map((item) => (
              <ItemPlaylist
                title={item.tugas_title}
                key={item.tugas_id}
                status={item.tugas_join?.tugas_finish}
                handlePlay={() =>
                  handlePlay({ type: "tugas", id: item.tugas_id })
                }
                type="tugas"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemPlaylistCourse;
