import "moment/locale/id";

import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { CatatanTugas, SoalJawaban, Wrapper } from "../../components";
import { getPembahasanTugas } from "../../redux/actions/nilaiAction";

moment.locale("id");

function Pembahasan() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [detail, setDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showCatatan, setShowCatatan] = useState(false);

  const getDetail = useCallback((id) => {
    setLoading(true);
    getPembahasanTugas(id)
      .then((res) => {
        setLoading(false);
        setDetail(res?.data?.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getDetail(id);
  }, [id, getDetail]);

  return (
    <Wrapper title={t("pembahasan")}>
      <div className="container">
        <div className="row row-eq-height">
          <div className="col-lg-12">
            <div className="iq-card">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title d-flex">
                  <h4 className="card-title">{t("pembahasan")}</h4>
                </div>
                <button
                  onClick={() => navigate(-1)}
                  className="btn iq-bg-light iq-bg-primary-hover text-dark border"
                >
                  <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
                </button>
              </div>
              <div className="col-lg-12 p-0">
                <div className="iq-card">
                  <div className="iq-card-body">
                    <div className="table-responsive table-hover">
                      <table id="datatable" className="table table-bordered">
                        <tbody>
                          <tr>
                            <td>
                              <b>{t("tugas")}</b>
                            </td>
                            <td>{detail?.tugas?.title}</td>
                          </tr>
                          <tr>
                            <td width="30%">
                              <b>{t("mulai_selesai")}</b>
                            </td>
                            <td width="70%">
                              {detail?.tugas?.start_date &&
                                moment(detail?.tugas?.start_date).format(
                                  "DD MMMM YYYY (HH:mm:00)"
                                )}{" "}
                              -{" "}
                              {detail?.tugas?.end_date &&
                                moment(detail?.tugas?.end_date).format(
                                  "DD MMMM YYYY (HH:mm:00)"
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>{t("nilai")}</b>
                            </td>
                            <td>
                              <b>
                                <span className="badge badge-primary">
                                  {detail?.tugas?.pengerjaan_nilai || 0}
                                </span>
                              </b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SoalJawaban
            hidePembahasan={detail?.tugas?.hide_pembahasan}
            loading={loading}
            soal={detail?.soal}
            soalCerita={detail?.soal_cerita}
            disabledPoint={false}
          />
        </div>
      </div>
      {showCatatan && (
        <CatatanTugas
          catatan={detail?.catatan_nilai}
          handleClose={() => {
            setShowCatatan(false);
            getDetail(id);
          }}
          show={showCatatan}
          tugasId={id}
        />
      )}
    </Wrapper>
  );
}

export default Pembahasan;
