import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import HTMLFlipBook from "react-pageflip";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function Loading() {
  return (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
function Error() {
  return (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
      <div className="text-white">{t("failed_proccess")}</div>
    </div>
  );
}
export default function ModalPreviewBook({ pdfURL, showModal, handleClose }) {
  const [numPages, setNumPages] = useState(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [windowSizeChange, setWindowSizeChange] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      setWindowSizeChange(true);
      // change windowSizeChange after two second
      setTimeout(() => {
        setWindowSizeChange(false);
      }, 2000);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let pageSize = 650;
  let pageHeight = 919;
  let widthDocument = "w-1700";
  // if window size is phone set pageSize 341, if tablet set to 500
  if (windowSize.width < 341) {
    pageSize = 250;
    pageHeight = 353;
    widthDocument = "w-100";
  } else if (windowSize.width < 425) {
    pageSize = 380;
    pageHeight = 537;
    widthDocument = "w-100";
  } else if (windowSize.width < 1024) {
    pageSize = 600;
    pageHeight = 848;
    widthDocument = "w-100";
  } else if (windowSize.width < 1200) {
    pageSize = 500;
    pageHeight = 707;
    widthDocument = "w-1000";
  } else if (windowSize.width < 1300) {
    pageSize = 550;
    pageHeight = 777;
    widthDocument = "w-1100";
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function pagesList() {
    var pages = [];
    for (var i = 1; i <= numPages; i++) {
      pages.push(
        <div key={crypto.randomUUID()}>
          <Page width={pageSize} pageNumber={i} />
        </div>
      );
    }
    return pages;
  }

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      fullscreen
      contentClassName="bg-transparent"
    >
      <Modal.Header className="border-0 bg-black d-flex align-items-center jusify-content-between">
        <h5 className="modal-title text-white">{t("buku")}</h5>
        <button
          onClick={handleClose}
          className="bg-transparent border-0 text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-x"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="mx-auto">
          {windowSizeChange ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <Document
              file={`${process.env.REACT_APP_BACKEND_API_URL_2}/pdf?pdf=${pdfURL}`}
              // file={`http://127.0.0.1:8000/api/pdf?pdf=${pdfURL}`}
              className={`overflow-hidden m-auto ${widthDocument}`}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<Loading />}
              error={<Error />}
            >
              <HTMLFlipBook width={pageSize} height={pageHeight}>
                {pagesList()}
              </HTMLFlipBook>
            </Document>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
