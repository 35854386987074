import React from "react";

import { ItemPlaylistCourse } from "../item";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";

function PlaylistBuyCourse({
  loadingTopik,
  nextTopik,
  handleNext,
  topik,
  handlePlay,
  handleMoreVideo,
}) {
  const { t } = useTranslation();

  const renderLoadingSpinner = () => {
    if (loadingTopik && topik.length <= 0) {
      return (
        <div className="my-5 text-center">
          <Spinner
            animation="border"
            role="status"
            variant="secondary"
            className="mx-auto"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      );
    }
    return null;
  };

  const renderTopikItems = () => {
    return topik.map((item, idx) => (
      <ItemPlaylistCourse
        key={item.topik_id}
        id={item.topik_id}
        materi={item.materi}
        title={item.topik_title}
        idx={idx + 1}
        handlePlay={(data) => {
          if (data.type === "materi" && !data.lock) handlePlay(data);
          if (data.type === "materi" && data.lock) handleMoreVideo();
        }}
        buyCourse={true}
      />
    ));
  };

  const renderLoadMoreButton = () => {
    if (nextTopik) {
      return (
        <button
          disabled={loadingTopik}
          onClick={handleNext}
          className="btn btn-primary text-white fw-semibold rounded-pill p-12 w-100 my-4"
        >
          {loadingTopik ? t("loading") + "..." : t("muat_lainnya")}
        </button>
      );
    }
    return null;
  };

  return (
    <div id="lesson">
      {renderLoadingSpinner()}
      {renderTopikItems()}
      {renderLoadMoreButton()}
    </div>
  );
}

export default PlaylistBuyCourse;
