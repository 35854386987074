import React from "react";
import { Logo } from "../../assets/images";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TugasDikerjakan = () => {
  const { t } = useTranslation();
  return (
    <div
      className="container d-flex justify-content-center align-items-center h-screen flex-column"
      style={{ maxWidth: 2100, height: "100vh", overflow: "hidden" }}
    >
      <img src={Logo} className="img-fluid mb-3 max-w-400" alt="logo" />
      <h4 className="fw-bold">{t("sudah_mensubmit_tugas")}</h4>
      <p>{t("tugas_tdk_dikerjakan")}</p>
      <Link to={-1} className="btn btn-primary">
        {t("kembali")}
      </Link>
    </div>
  );
};

export default TugasDikerjakan;
