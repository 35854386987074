import React from "react";
import {
  EssayImportSoal,
  MejodohkanImportSoal,
  PernyataanImportSoal,
  PilganImportSoal,
  PoolingImportSoal,
  TrueFalseImportSoal,
} from "../card";

/**
 * Renders different types of importable question cards based on the `soalJenis` prop.
 * @param {Object} props - The component props.
 * @param {string} props.soalJenis - Specifies the type of question card to render.
 * @param {Object} props.item - Contains the data for the question card.
 * @param {number} props.index - The index of the question card in the list.
 * @param {Array} props.selectedSoal - An array of selected question IDs.
 * @param {Function} props.handleChangeSoal - A callback function to handle changes in the selected questions.
 * @returns {JSX.Element} - The rendered question card component.
 */
const RenderImportBankSoal = ({
  soalJenis,
  item,
  index,
  selectedSoal,
  handleChangeSoal,
}) => {
  const checked = selectedSoal.includes(item.soal_id);

  switch (soalJenis) {
    case "pg":
      return (
        <PilganImportSoal
          key={`${index}-soal`}
          data={item}
          index={index}
          checked={checked}
          handleChange={handleChangeSoal}
        />
      );
    case "polling":
      return (
        <PoolingImportSoal
          key={`${index}-soal`}
          data={item}
          index={index}
          checked={checked}
          handleChange={handleChangeSoal}
        />
      );
    case "essay":
      return (
        <EssayImportSoal
          key={`${index}-soal`}
          data={item}
          index={index}
          checked={checked}
          handleChange={handleChangeSoal}
        />
      );
    case "true_false":
      return (
        <TrueFalseImportSoal
          key={`${index}-soal`}
          data={item}
          index={index}
          checked={checked}
          handleChange={handleChangeSoal}
        />
      );
    case "menjodohkan":
      return (
        <MejodohkanImportSoal
          key={`${index}-soal`}
          data={item}
          index={index}
          checked={checked}
          handleChange={handleChangeSoal}
        />
      );
    case "pernyataan":
      return (
        <PernyataanImportSoal
          key={`${index}-soal`}
          data={item}
          index={index}
          checked={checked}
          handleChange={handleChangeSoal}
        />
      );
    default:
      return null;
  }
};

export default RenderImportBankSoal;
