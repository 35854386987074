import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { changePassword } from "../../redux/actions/userAction";
import Swal from "sweetalert2";
import { PageLoader } from "../../assets/images";

const FormChagePassword = () => {
  const { t } = useTranslation();
  const [submit, setSubmit] = useState(false);
  const [errors, setErrors] = useState(null);
  const [params, setParams] = useState({
    password_lama: "",
    password: "",
    password_confirmation: "",
  });
  const [showPassword, setShowPassword] = useState({
    password_lama: false,
    password: false,
    password_confirmation: false,
  });
  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    }).then(() => {
      if (status) {
        setParams({
          password_lama: "",
          password: "",
          password_confirmation: "",
        });
        setErrors(null);
      }
    });
  };
  const handleChange = ({ target: { value, name } }) => {
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    changePassword(params)
      .then(() => {
        showAlert(t("berhasil_simpan", { params: t("password") }), true);
        setSubmit(false);
      })
      .catch((err) => {
        const error = err?.response;
        if (error.status === 500) {
          setErrors(error?.data?.error);
        } else {
          showAlert(t("failed_save_data", { data: t("password") }), false);
        }
        setSubmit(false);
      });
  };
  const toogleShowPassword = (name) => {
    setShowPassword((pref) => ({
      ...pref,
      [name]: !pref[name],
    }));
  };
  return (
    <div
      onSubmit={handleSubmit}
      className="tab-pane fade"
      id="ubahpassword"
      role="tabpanel"
    >
      <h4 className="mb-3">{t("ubah_kata_sandi")}</h4>
      <hr className="my-2" />
      <form>
        <div className="form-group">
          <label htmlFor="password_lama">{t("password_lama")}:</label>
          <div className="input-group">
            <input
              type={showPassword.password_lama ? "text" : "password"}
              className="form-control"
              id="password_lama"
              name="password_lama"
              value={params.password_lama}
              onChange={handleChange}
              placeholder={t("masukkan", { params: t("password_lama") })}
            />
            <button
              type="button"
              onClick={() => toogleShowPassword("password_lama")}
              className="input-group-text"
            >
              {showPassword.password_lama ? (
                <i className="ri-eye-off-line"></i>
              ) : (
                <i className="ri-eye-line"></i>
              )}
            </button>
          </div>
          <div
            className={`invalid-feedback ${
              errors?.password_lama ? "d-block" : null
            }`}
          >
            {errors?.password_lama}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="password">{t("password_baru")}:</label>
          <div className="input-group">
            <input
              type={showPassword.password ? "text" : "password"}
              className="form-control"
              id="password"
              name="password"
              value={params.password}
              onChange={handleChange}
              placeholder={t("masukkan", { params: t("password_baru") })}
            />
            <button
              type="button"
              onClick={() => toogleShowPassword("password")}
              className="input-group-text"
            >
              {showPassword.password ? (
                <i className="ri-eye-off-line"></i>
              ) : (
                <i className="ri-eye-line"></i>
              )}
            </button>
          </div>
          <div
            className={`invalid-feedback ${
              errors?.password ? "d-block" : null
            }`}
          >
            {errors?.password}
          </div>
          <ul className="mt-2">
            <li className="font-size-12 note-password">
              {t("password_min_6")}
            </li>
            <li className="font-size-12 note-password">
              {t("kriteria_password")}
            </li>
          </ul>
        </div>
        <div className="form-group">
          <label htmlFor="password_confirmation">
            {t("konfirmasi_password")}:
          </label>
          <div className="input-group">
            <input
              type={showPassword.password_confirmation ? "text" : "password"}
              className="form-control"
              id="password_confirmation"
              name="password_confirmation"
              value={params.password_confirmation}
              onChange={handleChange}
              placeholder={t("masukkan", { params: t("konfirmasi_password") })}
            />
            <button
              type="button"
              onClick={() => toogleShowPassword("password_confirmation")}
              className="input-group-text"
            >
              {showPassword.password_confirmation ? (
                <i className="ri-eye-off-line"></i>
              ) : (
                <i className="ri-eye-line"></i>
              )}
            </button>
          </div>
          <div
            className={`invalid-feedback ${
              errors?.password_confirmation ? "d-block" : null
            }`}
          >
            {errors?.password_confirmation}
          </div>
        </div>
        <button
          disabled={submit}
          type="submit"
          className="btn btn-primary float-right"
        >
          {submit ? (
            <img
              src={PageLoader}
              alt="loader"
              style={{ height: 20 }}
              className="mx-auto"
            />
          ) : (
            <>
              <i className="ri-save-line"></i> {t("ubah")}
            </>
          )}
        </button>
      </form>
    </div>
  );
};

export default FormChagePassword;
