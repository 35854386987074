import React from "react";
import { useTranslation } from "react-i18next";

const WAKTU = [5, 10, 20, 30, 45, 60, 120, 180, 300, 600, 900];
function InputWaktuSoal({ value, handleChange, disabled, bordered }) {
  const { t } = useTranslation();
  if (bordered) {
    return (
      <div className="d-flex border rounded">
        <i className="ri-timer-fill ms-1 text-dark font-size-18 "></i>
        <select
          className={`form-control form-control-sm bg-transparent border-0 font-size-14 fw-bold form-select ${
            disabled ? "text-secondary" : "text-black"
          }`}
          value={value}
          onChange={handleChange}
          disabled={disabled}
        >
          {WAKTU.map((item) => (
            <option
              className="bg-white fw-bold text-black"
              value={item}
              key={item}
            >
              {item} {t("detik")}
            </option>
          ))}
        </select>
      </div>
    );
  }
  return (
    <div
      className="iq-card iq-card-body p-1 d-flex align-items-center"
      style={{ backgroundColor: "rgb(38 38 38)" }}
    >
      <i className="ri-timer-fill ms-2 font-size-18 text-white"></i>
      <select
        className="form-control bg-transparent form-control-sm text-white border-0 font-size-14 fw-bold cursor-pointer"
        id="soal_waktu"
        name="soal_waktu"
        value={value}
        onChange={handleChange}
        disabled={disabled}
      >
        {WAKTU.map((item) => (
          <option
            className="bg-white fw-bold text-black"
            value={item}
            key={item}
          >
            {item} {t("detik")}
          </option>
        ))}
      </select>
      <label htmlFor="time" className="m-0">
        <i className="ri-arrow-down-s-line ms-2 font-size-18 text-white"></i>
      </label>
    </div>
  );
}

export default InputWaktuSoal;
