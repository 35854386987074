import "moment/locale/id";

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";

import { PageLoader } from "../../../assets/images";
import { getAbsensiSiswaByTopik } from "../../../redux/actions/absenAction";
import { checkIsOrtu } from "../../../utils/roleUser";

moment.locale("id");
function AbsensiSiswa({ detailKelas }) {
  const { t } = useTranslation();
  const location = useLocation();
  const { id } = useParams();
  const [absensi, setAbsensi] = useState([]);
  const [loading, setLoading] = useState(false);
  const { selectedAnak } = useSelector(({ anak }) => anak);
  const { user } = useSelector(({ user }) => user);
  useEffect(() => {
    function getData() {
      let dataParams = new URLSearchParams(location.search);
      let th = dataParams.get("th");
      let smt = dataParams.get("smt");

      let params = {
        kelasmapel_id: id,
      };
      if (th) params.tahunpelajaran_id = th;
      if (smt) params.semester_id = smt;
      if (checkIsOrtu(user?.user_roleid))
        params.userkelas_userid = selectedAnak?.user_id;
      getAbsensiSiswaByTopik(params).then((res) => {
        let allSiswa = res?.map((item) => ({
          kehadiran_id: item.kehadiran_id,
          kehadiran_nilai: item?.kehadiran_topik_siswa?.kehadiran_nilai || "",
          kehadiran_catatan:
            item?.kehadiran_topik_siswa?.kehadiran_catatan || "",
          topik: item?.topik?.topic,
          kehadiran_tanggal: item.kehadiran_tanggal,
        }));
        setAbsensi(allSiswa);
        setLoading(false);
      });
    }
    setLoading(true);
    getData();
  }, [detailKelas, id, selectedAnak, user, location.search]);

  return (
    <div className="tab-pane fade" id="absensi" role="tabpanel">
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title d-flex align-items-center justify-content-between w-100">
            <h4 className="card-title">{t("absensi")}</h4>
            <Link
              to="/kelas"
              className="btn iq-bg-light iq-bg-primary-hover text-dark border"
            >
              <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
            </Link>
          </div>
        </div>
        <hr className="m-0" />
        <div className="iq-card-body">
          <div className="table-responsive">
            <table id="datatable" className="table table-hover table-bordered">
              <thead>
                <tr>
                  <th
                    scope="col"
                    width="5%"
                    className="text-center align-middle"
                  >
                    No
                  </th>
                  <th scope="col" width="30%" className="align-middle">
                    {t("topik")}
                  </th>
                  <th
                    scope="col"
                    width="5%"
                    className="text-center align-middle"
                    style={{ background: "#1bbc9b", color: "white" }}
                  >
                    {t("hadir")}
                  </th>
                  <th
                    scope="col"
                    width="5%"
                    className="text-center align-middle"
                    style={{ background: "#578ebe", color: "white" }}
                  >
                    {t("izin")}
                  </th>
                  <th
                    scope="col"
                    width="5%"
                    className="text-center align-middle"
                    style={{ background: "#e26a6a", color: "white" }}
                  >
                    Alpa
                  </th>
                  <th
                    scope="col"
                    width="5%"
                    className="text-center align-middle"
                    style={{ background: "#f4d03f", color: "white" }}
                  >
                    {t("sakit")}
                  </th>
                  <th scope="col" width="40%" className="align-middle">
                    {t("catatan_guru")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="7" className="text-center">
                      <img
                        src={PageLoader}
                        alt="loader"
                        style={{ height: "34px" }}
                        className="mx-auto"
                      />
                    </td>
                  </tr>
                ) : absensi.length > 0 ? (
                  absensi?.map((item, i) => (
                    <tr key={item.kehadiran_id}>
                      <td className="text-center align-middle">{i + 1}</td>
                      <td className="align-middle">
                        <b>{item?.topik}</b>
                        <p className="m-0">
                          {moment(
                            item?.kehadiran_tanggal?.substr(0, 10)
                          ).format("DD MMMM YYYY")}
                        </p>
                      </td>
                      <td className="text-center align-middle">
                        <input
                          className="rabsen"
                          type="radio"
                          name={`absensi-${i}`}
                          id={`radio-${i}`}
                          checked={item?.kehadiran_nilai === "H"}
                          disabled
                        />
                      </td>
                      <td className="text-center align-middle">
                        <input
                          className="rabsen"
                          type="radio"
                          name={`absensi-${i}`}
                          id={`radio-${i}`}
                          checked={item?.kehadiran_nilai === "I"}
                          disabled
                        />
                      </td>
                      <td className="text-center align-middle">
                        <input
                          className="rabsen"
                          type="radio"
                          name={`absensi-${i}`}
                          id={`radio-${i}`}
                          checked={item?.kehadiran_nilai === "A"}
                          disabled
                        />
                      </td>
                      <td className="text-center align-middle">
                        <input
                          className="rabsen"
                          type="radio"
                          name={`absensi-${i}`}
                          id={`radio-${i}`}
                          checked={item?.kehadiran_nilai === "S"}
                          disabled
                        />
                      </td>
                      <td className="align-middle">{item.kehadiran_catatan}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="py-2" colSpan={7}>
                      <p className="text-center m-0">
                        {t("data_params_kosong", { params: t("absensi") })}
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AbsensiSiswa;
