import { API, setAuthToken } from '../../config/api';

export const addMateri = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("materi", params);
};

export const updMateri = (code, params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("materi/" + code, params);
};

export const updStatusMateri = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("materi/update-status", params);
};

export const uploadFileMateri = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("materi/upload-file", params);
};

export const delMateri = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.delete("materi/" + code);
};

export const getDetailMateri = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`materi/${code}`);
};

export const getMateriByTopik = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`topik/${code}/materi`);
};

export const sendShareKelas = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`kelas/share`, params);
};

export const sendShareMateri = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`materi/share`, params);
};
