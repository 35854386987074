import moment from "moment/moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import {
  Countdown,
  FilePengerjaanUjian,
  FooterPengerjaan,
  HeaderPengerjaan,
  InputEditor,
  ItemSoalMenjodohkan,
  ItemSoalMultiple,
  ItemSoalPernyataan,
  ItemSoalPilgan,
  ItemSoalTrueFalse,
  LoadingPengerjaan,
  ModalNoSoal,
  ModalSoalCerita,
  Soal,
  UjianDikerjakan,
} from "../../components";
import {
  kerjakanUjian,
  submitJawabanUjian,
  submitUjian,
} from "../../redux/actions/ujianAction";

/* eslint-disable react-hooks/exhaustive-deps */
function KerjakanUjian() {
  const { t } = useTranslation();
  const { id } = useParams();
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const [detail, setDetail] = useState(null);
  const [idxSoal, setIdxSoal] = useState(0);
  const [submitJawaban, setSubmitJawaban] = useState(false);
  const [jawabanEssay, setJawabanEssay] = useState("");
  const [showCerita, setShowCerita] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  const [ujianSelesai, setUjianSelesai] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [endPengerjaan, setEndPengerjaan] = useState(false);
  const [filePengerjaan, setFilePengerjaan] = useState([]);
  const [showNoSoal, setShowNoSoal] = useState(false);

  useEffect(() => {
    document.title = `${
      detail?.mapel_nama
        ? t("nama_ujian", { mapel: detail?.mapel_nama })
        : t("ujian")
    } | Smartclass Bakti Mulya 400`;
  }, [detail]);

  const showAlert = useCallback(
    (type, title, text, notAllow = false) => {
      Swal.fire({
        title: title,
        text: text,
        icon: type,
        imageWidth: 92,
        imageHeight: 92,
        confirmButtonText: "Ok",
        confirmButtonColor: "#6fbd44",
      }).then(() => {
        if (notAllow) {
          setTimeout(() => {
            navigate(-1);
          }, 1000);
        }
      });
    },
    [navigate]
  );

  const getDetail = useCallback(
    async (id) => {
      setLoading(true);
      kerjakanUjian({ ujian_id: id })
        .then((res) => {
          let data = res?.data?.data;
          setLoading(false);
          if (data?.pengerjaan_status === "selesai") {
            setUjianSelesai(true);
          } else {
            setDetail({ ...data });
            const ujian = data?.ujian;
            const waktuPengerjaan = ujian?.ujian_waktu_mengerjakan;
            if (waktuPengerjaan === "akhir-ujian") {
              const start =
                data?.pengerjaan_ujian_created ||
                moment().format("YYYY-MM-DDTHH:mm:ss");
              setStartTime(start);
              const startDate = new Date(start);
              const endDate = new Date(ujian?.ujian_selesai);
              const dif = endDate - startDate;
              const examTime = Math.round(dif / 1000);
              setEndTime(examTime);
            } else if (
              waktuPengerjaan === "mulai-siswa" &&
              data?.jenis_waktu_pengerjaan === "perujian"
            ) {
              setStartTime(
                data?.pengerjaan_ujian_created ||
                  moment().format("YYYY-MM-DDTHH:mm:ss")
              );
              setEndTime(data?.waktu_pengerjaan * 60);
            } else if (
              waktuPengerjaan === "mulai-ujian" &&
              data?.jenis_waktu_pengerjaan === "perujian"
            ) {
              setStartTime(ujian?.ujian_mulai);
              setEndTime(data?.waktu_pengerjaan * 60);
            } else if (
              waktuPengerjaan === "mulai-siswa" &&
              startTime === null
            ) {
              setStartTime(
                data?.pengerjaan_ujian_created ||
                  moment().format("YYYY-MM-DDTHH:mm:ss")
              );
              setEndTime(
                data?.pengerjaan?.length > 0
                  ? data?.pengerjaan[idxSoal]?.soal?.soal_waktu
                  : 0
              );
            } else {
              setStartTime(ujian?.ujian_mulai);
              setEndTime(
                data?.pengerjaan?.length > 0
                  ? data?.pengerjaan[idxSoal]?.soal?.soal_waktu
                  : 0
              );
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          showAlert("error", "Error", err?.response?.data?.error, true);
        });
    },
    [showAlert]
  );

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, [id, getDetail]);

  useEffect(() => {
    if (detail?.pengerjaan[idxSoal]?.soal?.soal_jenis === "essay") {
      setJawabanEssay(detail?.pengerjaan[idxSoal]?.pengerjaan_soal_essay || "");
      setFilePengerjaan(
        detail?.pengerjaan[idxSoal]?.pengerjaan_soal_file || []
      );
    } else {
      setJawabanEssay("");
      setFilePengerjaan([]);
    }
  }, [idxSoal, detail]);

  useEffect(() => {
    if (detail?.pengerjaan) {
      handleCalculateProgress();
    }
  }, [detail]);

  useEffect(() => {
    if (detail?.jenis_waktu_pengerjaan === "persoal") {
      setStartTime(moment().format("YYYY-MM-DDTHH:mm:ss"));
      setEndTime(
        detail?.pengerjaan?.length > 0
          ? detail?.pengerjaan[idxSoal]?.soal?.soal_waktu
          : 0
      );
    }
  }, [idxSoal]);

  const handleCalculateProgress = useCallback(() => {
    let calculate = detail?.pengerjaan?.filter(
      (item) => item.pengerjaan_waktu !== null
    );
    setProgress(calculate?.length);
  }, [detail]);

  const handleSelesaiAuto = () => {
    if (detail.jenis_waktu_pengerjaan === "perujian") {
      sendUjian();
    } else if (!endPengerjaan) {
      handleNextPersoal();
    }
  };

  const handleGetDetik = (date) => {
    const now = new Date();
    const start = new Date(date);
    return Math.round((now - start) / 1000);
  };

  const handlePilihPilgan = (jawaban) => {
    setSubmitJawaban(true);
    const params = {
      pengerjaan_soal_id: detail?.pengerjaan[idxSoal]?.pengerjaan_soal_id,
      pengerjaan_jawaban: jawaban,
      pengerjaan_waktu: handleGetDetik(startTime),
      pengerjaan_soal_file: [],
    };
    submitJawabanUjian(params)
      .then(() => {
        let dataPengerjaan = detail?.pengerjaan;
        dataPengerjaan[idxSoal].pengerjaan_soal_pg = jawaban;
        dataPengerjaan[idxSoal].pengerjaan_waktu = handleGetDetik(startTime);
        setDetail((pref) => ({
          ...pref,
          pengerjaan: dataPengerjaan,
        }));
        setSubmitJawaban(false);
      })
      .catch((err) => {
        setSubmitJawaban(false);
      });
  };

  const handlePilihPooling = (jawaban) => {
    setSubmitJawaban(true);
    let detailPengerjaan = detail?.pengerjaan[idxSoal];
    let allJawaban = detailPengerjaan?.pengerjaan_soal_polling;
    let cekJawaban = detailPengerjaan?.pengerjaan_soal_polling?.filter(
      (item) => item === jawaban
    );
    const dataJawaban =
      cekJawaban?.length <= 0
        ? [...allJawaban, jawaban]
        : allJawaban?.filter((item) => item !== jawaban);
    const params = {
      pengerjaan_soal_id: detailPengerjaan?.pengerjaan_soal_id,
      pengerjaan_jawaban: dataJawaban,
      pengerjaan_waktu: handleGetDetik(startTime),
      pengerjaan_soal_file: [],
    };
    submitJawabanUjian(params)
      .then(() => {
        let dataPengerjaan = detail?.pengerjaan;
        dataPengerjaan[idxSoal].pengerjaan_soal_polling = dataJawaban;
        dataPengerjaan[idxSoal].pengerjaan_waktu = handleGetDetik(startTime);
        setDetail((pref) => ({
          ...pref,
          pengerjaan: dataPengerjaan,
        }));
        setSubmitJawaban(false);
      })
      .catch((err) => {
        setSubmitJawaban(false);
      });
  };

  const handlePilihTrueFalse = (jawaban) => {
    setSubmitJawaban(true);
    const params = {
      pengerjaan_soal_id: detail?.pengerjaan[idxSoal]?.pengerjaan_soal_id,
      pengerjaan_jawaban: jawaban,
      pengerjaan_waktu: handleGetDetik(startTime),
      pengerjaan_soal_file: [],
    };
    submitJawabanUjian(params)
      .then(() => {
        let dataPengerjaan = detail?.pengerjaan;
        dataPengerjaan[idxSoal].pengerjaan_soal_true_false = jawaban;
        dataPengerjaan[idxSoal].pengerjaan_waktu = handleGetDetik(startTime);
        setDetail((pref) => ({
          ...pref,
          pengerjaan: dataPengerjaan,
        }));
        setSubmitJawaban(false);
      })
      .catch((err) => {
        setSubmitJawaban(false);
      });
  };

  const handleSubmitEssay = (type) => {
    setSubmitJawaban(true);
    const params = {
      pengerjaan_soal_id: detail?.pengerjaan[idxSoal]?.pengerjaan_soal_id,
      pengerjaan_jawaban: editorRef.current.getContent(),
      pengerjaan_waktu: handleGetDetik(startTime),
      pengerjaan_soal_file: filePengerjaan,
    };
    submitJawabanUjian(params)
      .then(() => {
        let dataPengerjaan = detail?.pengerjaan;
        dataPengerjaan[idxSoal].pengerjaan_soal_essay =
          editorRef.current.getContent();
        dataPengerjaan[idxSoal].pengerjaan_soal_file = filePengerjaan;
        dataPengerjaan[idxSoal].pengerjaan_waktu = handleGetDetik(startTime);
        setDetail((pref) => ({
          ...pref,
          pengerjaan: dataPengerjaan,
        }));
        setJawabanEssay("");
        setSubmitJawaban(false);
        if (
          detail.jenis_waktu_pengerjaan === "persoal" &&
          idxSoal + 1 === detail?.pengerjaan?.length
        )
          return sendUjian();
        if (type === "next") {
          setIdxSoal(
            idxSoal + 1 < detail?.pengerjaan?.length ? idxSoal + 1 : idxSoal
          );
        } else {
          setIdxSoal(idxSoal - 1 <= 0 ? 0 : idxSoal - 1);
        }
      })
      .catch((err) => {
        setSubmitJawaban(false);
      });
  };

  const handleNext = () => {
    if (detail?.pengerjaan?.[idxSoal]?.soal?.soal_jenis === "essay") {
      handleSubmitEssay("next");
    } else {
      setIdxSoal(
        idxSoal + 1 < detail?.pengerjaan?.length
          ? idxSoal + 1
          : detail?.pengerjaan?.length - 1
      );
    }
  };

  const handlePrev = () => {
    if (detail?.pengerjaan?.[idxSoal]?.soal?.soal_jenis === "essay") {
      handleSubmitEssay("prev");
    } else {
      setIdxSoal(idxSoal - 1 <= 0 ? 0 : idxSoal - 1);
    }
  };

  const handleNextPersoal = () => {
    if (detail?.pengerjaan?.[idxSoal]?.soal?.soal_jenis === "essay") {
      handleSubmitEssay("next");
    } else if (parseInt(idxSoal + 1) === parseInt(detail.pengerjaan.length)) {
      if (!endPengerjaan) sendUjian();
      setEndPengerjaan(true);
    } else {
      setIdxSoal(idxSoal + 1);
    }
  };

  const handleJwbanMenjodohkan = ({ target: { value, name } }) => {
    setSubmitJawaban(true);
    let detailPengerjaan = detail?.pengerjaan[idxSoal];
    let jawaban = detailPengerjaan?.pengerjaan_soal_menjodohkan?.filter(
      (item) =>
        item.soal_menjodohkan_pernyataan_id.toString() !== name.toString()
    );
    jawaban.push({
      soal_menjodohkan_pernyataan_id: name,
      jawaban: value,
    });

    const params = {
      pengerjaan_soal_id: detailPengerjaan?.pengerjaan_soal_id,
      pengerjaan_jawaban: jawaban,
      pengerjaan_waktu: handleGetDetik(startTime),
      pengerjaan_soal_file: [],
    };
    submitJawabanUjian(params)
      .then(() => {
        let dataPengerjaan = detail?.pengerjaan;
        dataPengerjaan[idxSoal].pengerjaan_soal_menjodohkan = jawaban;
        dataPengerjaan[idxSoal].pengerjaan_waktu = handleGetDetik(startTime);
        setDetail((pref) => ({
          ...pref,
          pengerjaan: dataPengerjaan,
        }));
        setSubmitJawaban(false);
      })
      .catch((err) => {
        setSubmitJawaban(false);
      });
  };

  const handlePilihPernyataan = (name, value) => {
    setSubmitJawaban(true);
    let detailPengerjaan = detail?.pengerjaan[idxSoal];
    let jawaban = detailPengerjaan?.pengerjaan_soal_pernyataan?.filter(
      (item) => item.soal_pernyataan_pilihan_id.toString() !== name.toString()
    );
    jawaban.push({
      soal_pernyataan_pilihan_id: name,
      jawaban: value,
    });

    const params = {
      pengerjaan_soal_id: detailPengerjaan?.pengerjaan_soal_id,
      pengerjaan_jawaban: jawaban,
      pengerjaan_waktu: handleGetDetik(startTime),
      pengerjaan_soal_file: [],
    };
    submitJawabanUjian(params)
      .then(() => {
        let dataPengerjaan = detail?.pengerjaan;
        dataPengerjaan[idxSoal].pengerjaan_soal_pernyataan = jawaban;
        dataPengerjaan[idxSoal].pengerjaan_waktu = handleGetDetik(startTime);
        setDetail((pref) => ({
          ...pref,
          pengerjaan: dataPengerjaan,
        }));
        setSubmitJawaban(false);
      })
      .catch((err) => {
        setSubmitJawaban(false);
      });
  };

  const handleSelesai = () => {
    if (detail?.pengerjaan?.[idxSoal]?.soal?.soal_jenis === "essay") {
      handleSubmitEssay("next");
    }
    Swal.fire({
      title: t("konfirmasi"),
      text: t("apakah_anda_yakin_mensubmit", { data: t("ujian") }),
      icon: "question",
      imageWidth: 92,
      imageHeight: 92,
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      showCancelButton: true,
      reverseButtons: true,
    }).then((res) => {
      if (res.isConfirmed) {
        sendUjian();
      }
    });
  };

  const sendUjian = () => {
    setSubmitJawaban(true);
    submitUjian({ pengerjaan_ujian_id: detail?.pengerjaan_ujian_id })
      .then(() => {
        showAlert(
          "success",
          t("berhasil"),
          t("berhasil_dikirim", { data: t("ujian") }),
          true
        );
      })
      .catch(() => {
        showAlert(
          "error",
          t("gagal"),
          t("gagal_dikirim", { data: t("ujian") }),
          false
        );
        setSubmitJawaban(false);
      });
  };

  const handleSoalCerita = () => setShowCerita(!showCerita);
  const handleNoSoal = () => setShowNoSoal(true);

  if (loading && detail === null) {
    return <LoadingPengerjaan data="ujian" />;
  }
  if (ujianSelesai) {
    return <UjianDikerjakan />;
  }

  return (
    <div id="content-page" className="content-page p-3">
      <div className="container" style={{ maxWidth: 2100 }}>
        <div className="row">
          {/* Progress */}
          <div className="col-6">
            <ProgressBar
              animated
              now={(progress / detail?.pengerjaan?.length) * 100}
              className="bg-dark rounded-pill"
              style={{ height: 40, maxWidth: 300 }}
              label={`Progress: ${Math.round(
                (progress / detail?.pengerjaan?.length) * 100
              )}%`}
            />
          </div>
          {/* Timer */}
          <div className="col-6">
            <Countdown
              startTime={startTime}
              maxTime={endTime}
              // handleSelesaiAuto={() => {}}
              handleSelesaiAuto={handleSelesaiAuto}
              detail={detail}
            />
          </div>
          {/* Soal */}
          <div className="col-12 mt-4">
            <div className="iq-card">
              {/* Header Soal */}
              <HeaderPengerjaan
                handleCerita={handleSoalCerita}
                handleNoSoal={handleNoSoal}
                idxSoal={idxSoal}
                jmlSoal={detail?.pengerjaan?.length}
                soalCerita={
                  detail?.pengerjaan[idxSoal]?.soal?.soal_cerita_detail
                }
                title={detail?.mapel_nama}
              />
              {/* Pertanyaan */}
              <div className="col-lg-12 p-0">
                <div className="iq-card">
                  <div className="iq-card-body">
                    <div className="row">
                      <Soal
                        soal_file={detail?.pengerjaan[idxSoal]?.soal?.soal_file}
                        soal_file_type={
                          detail?.pengerjaan[idxSoal]?.soal?.soal_file_type
                        }
                        soal_pertanyaan={
                          detail?.pengerjaan[idxSoal]?.soal?.soal_pertanyaan
                        }
                      />
                      <div className="col-lg-12">
                        {detail?.pengerjaan?.[idxSoal]?.soal?.soal_jenis ===
                        "pg" ? (
                          <ItemSoalPilgan
                            pilgan={
                              detail?.pengerjaan?.[idxSoal]?.soal?.soal_pg
                            }
                            kerjakan={true}
                            dipilih={
                              detail?.pengerjaan?.[idxSoal]?.pengerjaan_soal_pg
                            }
                            handlePilih={handlePilihPilgan}
                          />
                        ) : detail?.pengerjaan?.[idxSoal]?.soal?.soal_jenis ===
                          "polling" ? (
                          <ItemSoalMultiple
                            pooling={
                              detail?.pengerjaan?.[idxSoal]?.soal?.soal_polling
                            }
                            kerjakan={true}
                            dipilih={
                              detail?.pengerjaan[idxSoal]
                                ?.pengerjaan_soal_polling
                            }
                            handlePilih={handlePilihPooling}
                          />
                        ) : detail?.pengerjaan?.[idxSoal]?.soal?.soal_jenis ===
                          "essay" ? (
                          <InputEditor
                            editorRef={editorRef}
                            value={jawabanEssay}
                            idxSoal={idxSoal}
                          />
                        ) : detail?.pengerjaan?.[idxSoal]?.soal?.soal_jenis ===
                          "true_false" ? (
                          <ItemSoalTrueFalse
                            data={
                              detail?.pengerjaan?.[idxSoal]?.soal
                                ?.soal_true_false
                            }
                            kerjakan={true}
                            dipilih={
                              detail?.pengerjaan[idxSoal]
                                ?.pengerjaan_soal_true_false
                            }
                            handlePilih={handlePilihTrueFalse}
                          />
                        ) : detail?.pengerjaan?.[idxSoal]?.soal?.soal_jenis ===
                          "menjodohkan" ? (
                          <ItemSoalMenjodohkan
                            data={
                              detail?.pengerjaan?.[idxSoal]?.soal
                                ?.soal_menjodohkan
                            }
                            kerjakan={true}
                            dipilih={
                              detail?.pengerjaan?.[idxSoal]
                                ?.pengerjaan_soal_menjodohkan
                            }
                            handleChange={handleJwbanMenjodohkan}
                          />
                        ) : detail?.pengerjaan?.[idxSoal]?.soal?.soal_jenis ===
                          "pernyataan" ? (
                          <ItemSoalPernyataan
                            soalPernyataan={
                              detail?.pengerjaan?.[idxSoal]?.soal
                                ?.soal_pernyataan
                            }
                            kerjakan={true}
                            dipilih={
                              detail?.pengerjaan[idxSoal]
                                ?.pengerjaan_soal_pernyataan
                            }
                            handlePilih={handlePilihPernyataan}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {detail?.pengerjaan?.[idxSoal]?.soal?.soal_jenis === "essay" ? (
            <div className="col-12 mt-4">
              <FilePengerjaanUjian
                dataFile={filePengerjaan}
                handleUpd={(files) => setFilePengerjaan(files)}
              />
            </div>
          ) : null}
          <FooterPengerjaan
            handleNext={handleNext}
            handlePrev={handlePrev}
            handleSelesai={handleSelesai}
            idxSoal={idxSoal}
            jenisWaktu={detail?.jenis_waktu_pengerjaan}
            jmlSoal={detail?.pengerjaan?.length}
            submitJawaban={submitJawaban}
          />
        </div>
      </div>
      {showCerita && (
        <ModalSoalCerita
          handleClose={handleSoalCerita}
          show={showCerita}
          soalCerita={
            detail?.pengerjaan[idxSoal]?.soal?.soal_cerita_detail?.soal_cerita
          }
        />
      )}
      <ModalNoSoal
        show={showNoSoal}
        handleClose={() => setShowNoSoal(false)}
        dataSoal={detail?.pengerjaan}
        handleClick={(val) => {
          setIdxSoal(val);
          setShowNoSoal(false);
        }}
        typeTugas={detail?.jenis_waktu_pengerjaan}
      />
    </div>
  );
}

export default KerjakanUjian;
