import { GET_DETAIL_NOTIF, GET_DETAIL_NOTIF_GROUP, GET_NOTIF_GROUP, LOGOUT } from '../constant';

const initState = {
  notif: [],
  unread: 0,
  notifGroup: [],
};

const notifReducer = (state = initState, action) => {
  switch (action?.type) {
    case GET_NOTIF_GROUP + "_PENDING":
      return {
        ...state,
        notif: [],
        unread: 0,
      };
    case GET_NOTIF_GROUP + "_FULFILLED":
      return {
        ...state,
        notif: action.payload.group,
        unread: action.payload.all_notif,
      };
    case GET_NOTIF_GROUP + "_REJECTED":
      return {
        ...state,
        notif: [],
        unread: 0,
      };
    case GET_DETAIL_NOTIF_GROUP + "_PENDING":
      return {
        ...state,
        notifGroup: [],
      };
    case GET_DETAIL_NOTIF_GROUP + "_FULFILLED":
      return {
        ...state,
        notifGroup: action.payload,
      };
    case GET_DETAIL_NOTIF_GROUP + "_REJECTED":
      return {
        ...state,
        notifGroup: [],
      };
    case GET_DETAIL_NOTIF + "_FULFILLED":
      let idxNotif = state.notifGroup.findIndex(
        (item) => item.notif_id === action.payload?.notif_id
      );
      let idxNotifGroup = state.notif.findIndex(
        (item) => item.group_notif_id === action.payload?.group_id
      );
      let notifGroup = state.notifGroup;
      let notif = state.notif;
      notifGroup[idxNotif].notif_read = true;
      let unread = notif[idxNotifGroup].not_read;
      notif[idxNotifGroup].not_read = unread <= 0 ? 0 : unread - 1;
      return {
        ...state,
        notifGroup: notifGroup,
        notif: notif,
        unread: state.unread <= 0 ? 0 : parseInt(state.unread) - 1,
      };
    case LOGOUT:
      return {
        notif: [],
        unread: 0,
        notifGroup: [],
      };
    default: {
      return state;
    }
  }
};

export default notifReducer;
