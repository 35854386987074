import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  Absensi,
  AddSoal,
  AddSoalBankSoal,
  AddSoalCerita,
  AddSoalCeritaBankSoal,
  AddSoalCeritaUjian,
  AddSoalUjian,
  BankSoal,
  Chat,
  DetailBankSoal,
  DetailBlog,
  DetailKelas,
  Drive,
  EditSoal,
  EditSoalBankSoal,
  EditSoalCerita,
  EditSoalCeritaBankSoal,
  EditSoalCeritaUjian,
  EditSoalUjian,
  Faq,
  ForgotPass,
  Home,
  ImportBankSoal,
  ImportBankSoalUjian,
  Kalender,
  Kelas,
  KerjakanUjian,
  Koreksi,
  LembarTugas,
  Library,
  Login,
  NotFound,
  Notifikasi,
  Panduan,
  Pembahasan,
  Pembayaran,
  PenilaianTugas,
  PenilaianUjian,
  Profile,
  Rapor,
  SoalUjian,
  Tugas,
  Ujian,
  Loading,
  CannotAccess,
  Course,
  DetailCourse,
  BuyCourse,
  TugasCourse,
} from "../pages";
import {
  ALL_CAN_ACCESS,
  GURU_CAN_ACCESS,
  GURU_SISWA_CAN_ACCESS,
  ORTU_CAN_ACCESS,
  ORTU_SISWA_CAN_ACCESS,
  SISWA_CAN_ACCESS,
  checkCanAccess,
  checkIsSiswa,
} from "../utils/roleUser";
import { checkDeviceUser } from "../redux/actions/ujianAction";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function Router() {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <AuthNotAllow>
            <Login />
          </AuthNotAllow>
        }
      />
      <Route
        path="/lupa_password"
        element={
          <AuthNotAllow>
            <ForgotPass />
          </AuthNotAllow>
        }
      />
      <Route
        path="/"
        element={
          <RequireAuth allowedUserTypes={ALL_CAN_ACCESS}>
            <Home />
          </RequireAuth>
        }
      />
      <Route
        path="/pelajaran/:id"
        element={
          <RequireAuth allowedUserTypes={ALL_CAN_ACCESS}>
            <DetailKelas />
          </RequireAuth>
        }
      />
      <Route
        path="/kelas"
        element={
          <RequireAuth allowedUserTypes={ALL_CAN_ACCESS}>
            <Kelas />
          </RequireAuth>
        }
      />
      <Route
        path="/nilai/:id"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <PenilaianTugas />
          </RequireAuth>
        }
      />
      <Route
        path="/koreksi/:id"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <Koreksi />
          </RequireAuth>
        }
      />
      <Route
        path="/pembahasan/:id"
        element={
          <RequireAuth allowedUserTypes={ALL_CAN_ACCESS}>
            <Pembahasan />
          </RequireAuth>
        }
      />
      <Route
        path="/kalender"
        element={
          <RequireAuth allowedUserTypes={ALL_CAN_ACCESS}>
            <Kalender />
          </RequireAuth>
        }
      />
      <Route
        path="/library"
        element={
          <RequireAuth allowedUserTypes={ALL_CAN_ACCESS}>
            <Library />
          </RequireAuth>
        }
      />
      <Route
        path="/drive"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <Drive />
          </RequireAuth>
        }
      />
      <Route
        path="/banksoal"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <BankSoal />
          </RequireAuth>
        }
      />
      <Route
        path="/banksoal/:id"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <DetailBankSoal />
          </RequireAuth>
        }
      />
      <Route
        path="/banksoal/tambah-soal/:id"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <AddSoalBankSoal />
          </RequireAuth>
        }
      />
      <Route
        path="/banksoal/tambah-soal-cerita/:id"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <AddSoalCeritaBankSoal />
          </RequireAuth>
        }
      />
      <Route
        path="/banksoal/edit-soal/:id"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <EditSoalBankSoal />
          </RequireAuth>
        }
      />
      <Route
        path="/banksoal/edit-soal-cerita/:id"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <EditSoalCeritaBankSoal />
          </RequireAuth>
        }
      />
      <Route
        path="/faq"
        element={
          <RequireAuth allowedUserTypes={ALL_CAN_ACCESS}>
            <Faq />
          </RequireAuth>
        }
      />
      <Route
        path="/panduan"
        element={
          <RequireAuth allowedUserTypes={ALL_CAN_ACCESS}>
            <Panduan />
          </RequireAuth>
        }
      />
      <Route
        path="/pembayaran"
        element={
          <RequireAuth allowedUserTypes={ORTU_CAN_ACCESS}>
            <Pembayaran />
          </RequireAuth>
        }
      />
      <Route
        path="/notifikasi"
        element={
          <RequireAuth allowedUserTypes={ALL_CAN_ACCESS}>
            <Notifikasi />
          </RequireAuth>
        }
      />
      {/* <Route
        path="/edit-tugas/:id"
        element={
          <RequireAuth>
            <EditTugas />
          </RequireAuth>
        }
      /> */}
      <Route
        path="/tugas/:id"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <Tugas />
          </RequireAuth>
        }
      />
      <Route
        path="/tugas/kerjakan/:id"
        element={
          <RequireAuth allowedUserTypes={SISWA_CAN_ACCESS}>
            <LembarTugas />
          </RequireAuth>
        }
      />
      <Route
        path="/tambah-soal/:id"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <AddSoal />
          </RequireAuth>
        }
      />
      <Route
        path="/tambah-soal/:id/:jenis_soal"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <AddSoal />
          </RequireAuth>
        }
      />
      <Route
        path="/tambah-soal-cerita/:id"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <AddSoalCerita />
          </RequireAuth>
        }
      />
      <Route
        path="/edit-soal/:id"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <EditSoal />
          </RequireAuth>
        }
      />
      <Route
        path="/edit-soal-cerita/:id"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <EditSoalCerita />
          </RequireAuth>
        }
      />
      <Route
        path="/chat"
        element={
          <RequireAuth allowedUserTypes={GURU_SISWA_CAN_ACCESS}>
            <Chat />
          </RequireAuth>
        }
      />
      <Route
        path="/blog/:id"
        element={
          <RequireAuth allowedUserTypes={ALL_CAN_ACCESS}>
            <DetailBlog />
          </RequireAuth>
        }
      />
      <Route
        path="/profile"
        element={
          <RequireAuth allowedUserTypes={ALL_CAN_ACCESS}>
            <Profile />
          </RequireAuth>
        }
      />
      <Route
        path="/absensi"
        element={
          <RequireAuth allowedUserTypes={ALL_CAN_ACCESS}>
            <Absensi />
          </RequireAuth>
        }
      />
      <Route
        path="/import-bank-soal/:kelas_id/:tugas_id"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <ImportBankSoal />
          </RequireAuth>
        }
      />
      <Route
        path="/course"
        element={
          <RequireAuth allowedUserTypes={GURU_SISWA_CAN_ACCESS}>
            <Course />
          </RequireAuth>
        }
      />
      <Route
        path="/course/:id"
        element={
          <RequireAuth allowedUserTypes={GURU_SISWA_CAN_ACCESS}>
            <DetailCourse />
          </RequireAuth>
        }
      />
      <Route
        path="/course/buy/:id"
        element={
          <RequireAuth allowedUserTypes={GURU_SISWA_CAN_ACCESS}>
            <BuyCourse />
          </RequireAuth>
        }
      />
      <Route
        path="/course/tugas/:id"
        element={
          <RequireAuth allowedUserTypes={GURU_SISWA_CAN_ACCESS}>
            <TugasCourse />
          </RequireAuth>
        }
      />
      <Route
        path="/rapor"
        element={
          <RequireAuth allowedUserTypes={ORTU_SISWA_CAN_ACCESS}>
            <Rapor />
          </RequireAuth>
        }
      />
      <Route
        path="/ujian"
        element={
          <RequireAuth allowedUserTypes={ALL_CAN_ACCESS}>
            <CheckUserDevice>
              <Ujian />
            </CheckUserDevice>
          </RequireAuth>
        }
      />
      <Route
        path="/ujian/soal/:id/:idKelas"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <SoalUjian />
          </RequireAuth>
        }
      />
      <Route
        path="/ujian/tambah-soal/:id/:idKelas"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <AddSoalUjian />
          </RequireAuth>
        }
      />
      <Route
        path="/ujian/edit-soal/:id/:idKelas"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <EditSoalUjian />
          </RequireAuth>
        }
      />
      <Route
        path="/ujian/tambah-soal-cerita/:id/:idKelas"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <AddSoalCeritaUjian />
          </RequireAuth>
        }
      />
      <Route
        path="/ujian/edit-soal-cerita/:id/:idKelas"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <EditSoalCeritaUjian />
          </RequireAuth>
        }
      />
      <Route
        path="/ujian/import-bank-soal/:kelas_id/:ujian_id"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <ImportBankSoalUjian />
          </RequireAuth>
        }
      />
      <Route
        path="/ujian/nilai/:id/:idKelas"
        element={
          <RequireAuth allowedUserTypes={GURU_CAN_ACCESS}>
            <PenilaianUjian />
          </RequireAuth>
        }
      />
      <Route
        path="/ujian/kerjakan/:id"
        element={
          <RequireAuth allowedUserTypes={SISWA_CAN_ACCESS}>
            <CheckUserDevice>
              <KerjakanUjian />
            </CheckUserDevice>
          </RequireAuth>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

const RequireAuth = ({ children, allowedUserTypes }) => {
  const isLogin = !!localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("auth_user"));
  let location = useLocation();
  if (!isLogin) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (checkCanAccess(user?.user_roleid, allowedUserTypes)) {
    return children;
  }
  return <Navigate to="/" replace />;
};

const AuthNotAllow = ({ children }) => {
  const isLogin = !!localStorage.getItem("token");

  if (isLogin) {
    return <Navigate to="/" replace />;
  }
  return children;
};

const CheckUserDevice = ({ children }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const userUID = localStorage.getItem("userUID");
  const [loading, setLoading] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const { user } = useSelector(({ user }) => user);
  const checkSiswa = checkIsSiswa(user?.user_roleid);

  useEffect(() => {
    if (checkSiswa) {
      setLoading(true);
      let newUserUID = userUID;
      if (!newUserUID) {
        newUserUID = uuidv4();
        localStorage.setItem("userUID", newUserUID);
      }
      checkDeviceUser({
        user_imei: newUserUID,
      })
        .then((res) => {
          setDeviceInfo(res);
          setLoading(false);
        })
        .catch((err) => {
          const error = err?.response;
          if (error?.status === 422) {
            setDeviceInfo(error?.data);
          } else {
            setDeviceInfo({
              status: false,
              message:
                "Terjadi kesalahan saat mengecek device, coba beberapa saat lagi",
            });
          }
          setLoading(false);
        });
    } else {
      setDeviceInfo({
        status: true,
        data: "Berhasil",
      });
    }
  }, [location.pathname, userUID, checkSiswa]);
  if (loading) {
    return <Loading message={`${t("cek_device")}...`} />;
  } else if (deviceInfo?.success === false) {
    return <CannotAccess message={deviceInfo?.error} />;
  } else {
    return children;
  }
};

export default Router;
