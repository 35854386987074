import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import { PageLoader } from '../../assets/images';
import { submitCatatanTugas } from '../../redux/actions/tugasAction';

function CatatanTugas({ show, handleClose, catatan, tugasId }) {
  const { t } = useTranslation();
  const [submit, setSubmit] = useState(false);
  const [catatanTugas, setCatatanTugas] = useState("");

  useEffect(() => {
    setCatatanTugas(catatan);
  }, [catatan]);

  const showAlert = (type, title, text) => {
    Swal.fire({
      title: title,
      text: text,
      icon: type,
      imageWidth: 92,
      imageHeight: 92,
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };

  const handleSubmit = () => {
    setSubmit(true);
    const params = {
      tugas_id: tugasId,
      catatan_nilai: catatanTugas,
    };
    submitCatatanTugas(params)
      .then(() => handleClose())
      .catch(() =>
        showAlert(
          "error",
          t("gagal"),
          t("data_gagal_simpan", { data: t("catatan") })
        )
      );
  };

  return (
    <div className="col-lg-12">
      <Modal
        size="md"
        show={show}
        onHide={() => {
          if (!submit) handleClose();
        }}
        id="CatatanTugas"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title">
            {t("tambah")} {t("catatan")}
          </h5>
        </Modal.Header>
        <Modal.Body>
          <textarea
            className="form-control"
            rows="3"
            placeholder="Catatan"
            value={catatanTugas}
            onChange={({ target: { value } }) => setCatatanTugas(value)}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleClose}
          >
            {t("tutup")}
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={submit}
          >
            {submit ? (
              <img src={PageLoader} alt="loader" style={{ width: 15 }} />
            ) : (
              t("save_changes")
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CatatanTugas;
