import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
  IcFileAudio,
  IcFileDefault,
  IcFileDoc,
  IcFileExcel,
  IcFileJPG,
  IcFilePDF,
  IcFilePNG,
  IcFilePPT,
  IcFileVideo,
} from "../../assets/images/file";
import { SmallFile } from "../../assets/images/small";
import {
  AUDIO_EXT,
  EXCEL_EXT,
  MS_EXT,
  PNG_IMG_EXT,
  PPT_EXT,
  SVG_IMG_EXT,
  VIDEO_EXT,
} from "../../config/fileType";

function CardFile({
  link,
  nama,
  handleEdit,
  handleHapus,
  handleView,
  typeFile,
  hideAction,
  selected,
  handleSelect,
}) {
  const { t } = useTranslation();
  const [iconFile, setIconFile] = useState(SmallFile);
  useEffect(() => {
    if (PNG_IMG_EXT.includes(typeFile)) {
      setIconFile(IcFilePNG);
    } else if (SVG_IMG_EXT.includes(typeFile)) {
      setIconFile(IcFileJPG);
    } else if (VIDEO_EXT.includes(typeFile)) {
      setIconFile(IcFileVideo);
    } else if (typeFile === "application/pdf") {
      setIconFile(IcFilePDF);
    } else if (MS_EXT.includes(typeFile)) {
      setIconFile(IcFileDoc);
    } else if (AUDIO_EXT.includes(typeFile)) {
      setIconFile(IcFileAudio);
    } else if (PPT_EXT.includes(typeFile)) {
      setIconFile(IcFilePPT);
    } else if (EXCEL_EXT.includes(typeFile)) {
      setIconFile(IcFileExcel);
    } else {
      setIconFile(IcFileDefault);
    }
  }, [typeFile]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      ref={ref}
      className="bg-transparent border-0"
    >
      {children}
    </button>
  ));
  return (
    <li
      className={`col-md-2 col-6 py-2 iq-bg-dark-hover card-drive-file ${
        selected && "selected"
      }`}
    >
      <button
        onClick={handleSelect}
        onDoubleClick={handleView}
        className="bg-transparent border-0 w-100"
      >
        <img src={iconFile} alt="icon file" className="img-fluid" />
        <div className="d-flex align-items-center justify-content-between mt-1">
          <h6 className="mt-0 elipsis-1-line text-center flex-1" title={nama}>
            {nama}
          </h6>
          {!hideAction && (
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle}>
                <i className="fw-bold ri-more-2-fill"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                <Dropdown.Item onClick={handleView}>
                  <i className="ri-eye-fill me-2"></i>
                  {t("lihat")}
                </Dropdown.Item>
                <Dropdown.Item href={link} download>
                  <i className="ri-file-download-fill me-2"></i>
                  {t("unduh")}
                </Dropdown.Item>
                <Dropdown.Item onClick={handleEdit}>
                  <i className="ri-pencil-fill me-2"></i>
                  {t("ganti_nama")}
                </Dropdown.Item>
                <Dropdown.Item onClick={handleHapus}>
                  <i className="ri-delete-bin-6-fill me-2"></i>
                  {t("hapus")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </button>
    </li>
  );
}

export default CardFile;
