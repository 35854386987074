import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { reviewCourse } from "../../redux/actions/courseAction";
import { useParams } from "react-router-dom";
import { PageLoader } from "../../assets/images";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

function ModalReviewCourse({ show, handleClose }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useSelector(({ user }) => user);
  const [submit, setSubmit] = useState(false);
  const [errors, setErrors] = useState(null);
  const [params, setParams] = useState({
    rating: 0,
    review: "",
  });

  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };
  const handleChange = ({ target: { value, name } }) => {
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };
  const handleSubmit = () => {
    setSubmit(true);
    const data = {
      course_id: id,
      user_id: user?.user_id,
      rating: parseInt(params.rating),
      review: params.review,
    };
    setErrors(null);
    reviewCourse(data)
      .then((res) => {
        setSubmit(false);
        window.open(res?.url, "_blank");
      })
      .catch((err) => {
        const error = err?.response;
        if (error.status === 400) {
          setErrors(error?.data?.error);
        } else {
          showAlert(t("data_gagal_simpan", { data: t("ulasan") }), false);
        }
        setSubmit(false);
      });
  };
  return (
    <Modal
      size="md"
      show={show}
      onHide={handleClose}
      id="ModalReviewCourse"
      centered
    >
      <Modal.Body className="py-4">
        <h3 className="fw-semibold text-center mb-3">
          {t("berikan_penilaian")}
        </h3>
        <div className="star-widget">
          <div className="d-flex justify-content-center flex-row-reverse">
            <input
              type="radio"
              name="rating"
              id="rate-5"
              value="5"
              checked={params.rating === "5"}
              onChange={handleChange}
            />
            <label htmlFor="rate-5" className="fa fa-star m-0"></label>
            <input
              type="radio"
              name="rating"
              id="rate-4"
              value="4"
              checked={params.rating === "4"}
              onChange={handleChange}
            />
            <label htmlFor="rate-4" className="fa fa-star m-0"></label>
            <input
              type="radio"
              name="rating"
              id="rate-3"
              value="3"
              checked={params.rating === "3"}
              onChange={handleChange}
            />
            <label htmlFor="rate-3" className="fa fa-star m-0"></label>
            <input
              type="radio"
              name="rating"
              id="rate-2"
              value="2"
              checked={params.rating === "2"}
              onChange={handleChange}
            />
            <label htmlFor="rate-2" className="fa fa-star m-0"></label>
            <input
              type="radio"
              name="rating"
              id="rate-1"
              value="1"
              checked={params.rating === "1"}
              onChange={handleChange}
            />
            <label htmlFor="rate-1" className="fa fa-star m-0"></label>
          </div>
          <div
            className={`rating-${params.rating}-desc font-size-20 text-center mb-4`}
          ></div>
        </div>
        <div
          className={`invalid-feedback ${errors?.rating ? "d-block" : null}`}
        >
          {errors?.rating}
        </div>
        <textarea
          className="form-control desc"
          cols="30"
          placeholder={t("placeholder_review")}
          onChange={handleChange}
          value={params.review}
          name="review"
        ></textarea>
        <div
          className={`invalid-feedback ${errors?.review ? "d-block" : null}`}
        >
          {errors?.review}
        </div>
        <div className="text-center">
          <button
            onClick={handleSubmit}
            disabled={submit}
            className="btn btn-primary px-4 py-2 rounded-pill mt-3"
          >
            {submit ? (
              <img
                src={PageLoader}
                alt="loader"
                style={{ height: 24 }}
                className="mx-auto"
              />
            ) : (
              t("kirim_download")
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalReviewCourse;
