import axios from "axios";
import { API, setAuthToken } from "../../config/api";
import { GET_KELAS } from "../constant";

const ACCESS_TOKEN = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_BACKEND_API_URL_2;
const headers = {
  headers: {
    Authorization: ACCESS_TOKEN,
  },
};
export const getSemester = async (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await API.get(
    `/kelas/list-semester?${new URLSearchParams(params).toString()}`
  );
  return res?.data?.data;
};

export const getKelas = (params) => {
  return {
    type: GET_KELAS,
    payload: async () => {
      const token = localStorage.getItem("token");
      setAuthToken(token);
      let res = await API.get(
        `/kelas/list-kelas?${new URLSearchParams(params).toString()}`
      ).catch((err) => {
        return Promise.reject(err);
      });
      return res?.data?.data;
    },
  };
};

export const getMapel = (code, semester) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(
    `kelas/mata-pelajaran?kelasdetail_id=${code}&semester=${semester}`
  );
};

export const getSekolah = async () => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await API.get("kelas/list-sekolah");
  return res?.data?.data;
};

export const getTahunPelajaran = async (sekolah) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let url = "kelas/list-tahun-pelajaran";
  if (sekolah) url += `?sekolah_id=${sekolah}`;
  let res = await API.get(url);
  return res?.data?.data;
};

export const getSlide = async () => {
  let res = await axios.get(`${API_URL}/slide`, headers).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data;
};
