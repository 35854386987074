import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InfoHasilUjian, SoalJawaban } from "../section";
import { getPembahasanUjian } from "../../redux/actions/nilaiUjianAction";
import { PageLoader } from "../../assets/images";
import { useSelector } from "react-redux";
import { checkIsOrtu } from "../../utils/roleUser";

const ModalLihatNilai = ({ show, handleClose, idUjian, semester }) => {
  const { t } = useTranslation();
  const { selectedAnak } = useSelector(({ anak }) => anak);
  const { user } = useSelector(({ user }) => user);
  const [detail, setDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const isOrtu = checkIsOrtu(user?.user_roleid);
  useEffect(() => {
    setLoading(true);
    let params = {
      ujian_id: idUjian,
    };
    if (isOrtu) params.user_id = selectedAnak?.user_id;
    getPembahasanUjian(params)
      .then((res) => {
        setDetail(res?.data?.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [idUjian, isOrtu, selectedAnak]);

  return (
    <div className="col-lg-12">
      <Modal
        fullscreen={true}
        enforceFocus={false}
        show={show}
        onHide={handleClose}
        id="ModalLihatNilai"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title">{t("hasil_ujian")}</h5>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="w-100 my-2 text-center">
              <img src={PageLoader} alt="loader" width={50} />
            </div>
          ) : (
            <>
              <InfoHasilUjian
                mapel={detail?.ujian?.mapel_nama}
                nilai={detail?.ujian?.pengerjaan_nilai}
                startDate={detail?.ujian?.ujian_mulai}
                endDate={detail?.ujian?.ujian_selesai}
                semester={semester}
                kelas={detail?.ujian?.kelasdetail_nama}
                jenis={detail?.ujian?.ujian_jenis.toUpperCase()}
              />
              <SoalJawaban
                hidePembahasan={false}
                loading={loading}
                soal={detail?.soal}
                soalCerita={detail?.soal_cerita}
                disabledPoint={true}
              />
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalLihatNilai;
