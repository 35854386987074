import React from "react";

function ItemPreviewRating({ rating }) {
  return (
    <>
      <i
        className={
          rating === 0.5
            ? "ri-star-half-fill text-warning ms-1"
            : rating >= 1
            ? "ri-star-fill text-warning ms-1"
            : "ri-star-fill ms-1"
        }
      ></i>
      <i
        className={
          rating === 1.5
            ? "ri-star-half-fill text-warning"
            : rating >= 2
            ? "ri-star-fill text-warning"
            : "ri-star-fill"
        }
      ></i>
      <i
        className={
          rating === 2.5
            ? "ri-star-half-fill text-warning"
            : rating >= 3
            ? "ri-star-fill text-warning"
            : "ri-star-fill"
        }
      ></i>
      <i
        className={
          rating === 3.5
            ? "ri-star-half-fill text-warning"
            : rating >= 4
            ? "ri-star-fill text-warning"
            : "ri-star-fill"
        }
      ></i>
      <i
        className={
          rating === 4.5
            ? "ri-star-half-fill text-warning"
            : rating === 5
            ? "ri-star-fill text-warning"
            : "ri-star-fill"
        }
      ></i>
    </>
  );
}

export default ItemPreviewRating;
