import "moment/locale/id";

import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import io from "socket.io-client";

import { IcIndo, IcNotif, IcUK, Logo, User04 } from "../../assets/images";
import { logout } from "../../redux/actions/authAction";
import { getNotifGroup } from "../../redux/actions/notifAction";
import { getProfile } from "../../redux/actions/userAction";
import { LanguageCard } from "../card";

const socket = io(
  process.env.REACT_APP_BACKEND_API_URL + "/socket-connect-notif"
);
moment.locale("id");
const languageOption = [
  {
    code: "id",
    name: "Indonesia",
    icon: IcIndo,
  },
  {
    code: "en",
    name: "United Kingdom",
    icon: IcUK,
  },
];
function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useSelector(({ user }) => user);
  const { unread, notif } = useSelector(({ notif }) => notif);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getNotifGroup());
  }, [dispatch]);

  useEffect(() => {
    socket.on("connect", () => {
      socket.emit("room", user?.user_id);
    });
    socket.on("socket-get-notif", (data) => {
      dispatch(getNotifGroup());
      //ketika ada notif baru bisa langsung hit api get group
    });

    return () => {
      socket.off("connect");
      socket.off("socker-get-notif");
    };
  }, [user?.user_id, dispatch]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(logout());
    navigate("/login");
  };

  return (
    <div className="iq-top-navbar">
      <div className="iq-navbar-custom">
        <nav className="navbar navbar-expand-lg navbar-light p-0">
          <div className="iq-navbar-logo d-flex justify-content-between">
            <a href="/">
              <img src={Logo} className="img-fluid" alt="" />
            </a>
          </div>
          {/* <div className="iq-search-bar">
            <form action="#" className="searchbox">
              <input
                type="text"
                className="text search-input"
                placeholder="Type here to search..."
              />
              <button className="search-link btn-reset">
                <i className="ri-search-line"></i>
              </button>
            </form>
          </div> */}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-label="Toggle navigation"
          >
            <i className="ri-menu-3-line"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto navbar-list">
              <li>
                <Link to="/profile" className="d-flex align-items-center">
                  <img
                    src={user?.user_avatar || User04}
                    className="img-fluid rounded-circle me-3"
                    alt="user"
                  />
                  <div className="caption">
                    <h6 className="mb-0 line-height">{user?.user_nama}</h6>
                  </div>
                </Link>
              </li>
              {/* {location?.pathname === "/notifikasi" ? (
                <li className="nav-item">
                  <button className="search-toggle btn-reset">
                    <i className="ri-group-line"></i>
                  </button>
                  <div className="iq-sub-dropdown iq-sub-dropdown-large">
                    <div className="iq-card shadow-none m-0">
                      <div className="iq-card-body p-0 ">
                        <div className="bg-primary p-3">
                          <h5 className="mb-0 text-white">
                            Friend Request
                            <small className="badge  badge-light float-right pt-1">
                              4
                            </small>
                          </h5>
                        </div>
                        <FriendRequest
                          count={40}
                          img={User01}
                          name="Jaques Amole"
                        />
                        <FriendRequest
                          count={12}
                          img={User02}
                          name="Lucy Tania"
                        />
                        <FriendRequest
                          count={3}
                          img={User03}
                          name="Manny Petty"
                        />
                        <FriendRequest
                          count={15}
                          img={User04}
                          name="Marsha Mello"
                        />
                        <div className="text-center">
                          <Link to="/" className="me-3 btn text-primary">
                            View More Request
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
              )} */}
              <li className="nav-item">
                <button className="search-toggle btn-reset">
                  <i className="ri-global-line"></i>
                </button>
                <div className="iq-sub-dropdown ">
                  <div className="iq-card shadow-none m-0">
                    <div className="iq-card-body p-0">
                      <div className="bg-primary p-3">
                        <h5 className="mb-0 text-white">{t("bahasa")}</h5>
                      </div>
                      {languageOption?.map((item) => (
                        <LanguageCard
                          key={item.code}
                          code={item.code}
                          img={item.icon}
                          name={item.name}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <button className="search-toggle bg-transparent border-0 d-flex">
                  <div id="lottie-beil">
                    <img src={IcNotif} alt="notification" width={24} />
                  </div>
                  {unread > 0 ? <span className="bg-danger dots"></span> : null}
                </button>
                <div className="iq-sub-dropdown">
                  <div className="iq-card shadow-none m-0">
                    <div className="iq-card-body p-0">
                      <div className="bg-primary p-3 d-flex align-items-center justify-content-between">
                        <h5 className="mb-0 text-white">{t("all_notif")}</h5>
                        <small className="badge badge-light float-right pt-1">
                          {unread}
                        </small>
                      </div>
                      {notif?.map((item) => (
                        <Link
                          to={"/notifikasi?tab=" + item.group_notif_id}
                          className="iq-sub-card"
                          key={item.group_notif_id}
                        >
                          <div className="media align-items-center">
                            {item.group_notif_id === "PENGERJAAN-TUGAS" ? (
                              <div className="rounded iq-card-icon iq-bg-success">
                                <i className="ri-book-mark-line"></i>
                              </div>
                            ) : item.group_notif_id === "MATERI" ? (
                              <div className="rounded iq-card-icon iq-bg-primary">
                                <i className="ri-book-line"></i>
                              </div>
                            ) : item.group_notif_id === "ABSENSI" ? (
                              <div className="rounded iq-card-icon iq-bg-info">
                                <i className="ri-group-line"></i>
                              </div>
                            ) : item.group_notif_id === "TUGAS" ? (
                              <div className="rounded iq-card-icon iq-bg-success">
                                <i className="ri-book-mark-line"></i>
                              </div>
                            ) : (
                              <div className="rounded iq-card-icon iq-bg-success">
                                <i className="ri-book-mark-line"></i>
                              </div>
                            )}
                            <div className="flex-1 ms-3">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="flex-1">
                                  <h6 className="mb-0">
                                    {item.group_notif_name}
                                  </h6>
                                  <small className="font-size-12">
                                    {item.time &&
                                      moment(item.time).format(
                                        "DD MMM YYYY HH:mm"
                                      )}
                                  </small>
                                </div>
                                <small className="badge bg-danger rounded-circle float-right pt-1">
                                  {item.not_read}
                                </small>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}
                      <div className="text-center">
                        <Link to="/notifikasi" className="text-primary">
                          {t("view_more")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              {/* <li className="nav-item dropdown">
                <a href="#" className="search-toggle">
                  <div id="lottie-mail">
                    <img src={IcMail} alt="notification" width={24} />
                  </div>
                  <span className="bg-primary count-mail"></span>
                </a>
                <div className="iq-sub-dropdown">
                  <div className="iq-card shadow-none m-0 overflow-hidden">
                    <div className="iq-card-body p-0">
                      <div className="bg-primary p-3 d-flex align-items-center justify-content-between">
                        <h5 className="mb-0 text-white">All Messages</h5>
                        <small className="badge badge-light float-right pt-1">
                          5
                        </small>
                      </div>
                      <a href="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div className="">
                            <img
                              className="avatar-40 rounded"
                              src={User01}
                              alt=""
                            />
                          </div>
                          <div className="flex-1 ms-3">
                            <h6 className="mb-0">Bni Emma Watson</h6>
                            <small className="float-left font-size-12">
                              13 Jun
                            </small>
                          </div>
                        </div>
                      </a>
                      <a href="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div className="">
                            <img
                              className="avatar-40 rounded"
                              src={User02}
                              alt=""
                            />
                          </div>
                          <div className="flex-1 ms-3">
                            <h6 className="mb-0">Lorem Ipsum Watson</h6>
                            <small className="float-left font-size-12">
                              20 Apr
                            </small>
                          </div>
                        </div>
                      </a>
                      <a href="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div className="">
                            <img
                              className="avatar-40 rounded"
                              src={User03}
                              alt=""
                            />
                          </div>
                          <div className="flex-1 ms-3">
                            <h6 className="mb-0">Why do we use it?</h6>
                            <small className="float-left font-size-12">
                              30 Jun
                            </small>
                          </div>
                        </div>
                      </a>
                      <a href="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div className="">
                            <img
                              className="avatar-40 rounded"
                              src={User04}
                              alt=""
                            />
                          </div>
                          <div className="flex-1 ms-3">
                            <h6 className="mb-0">Variations Passages</h6>
                            <small className="float-left font-size-12">
                              12 Sep
                            </small>
                          </div>
                        </div>
                      </a>
                      <a href="#" className="iq-sub-card">
                        <div className="media align-items-center">
                          <div className="">
                            <img
                              className="avatar-40 rounded"
                              src={User05}
                              alt=""
                            />
                          </div>
                          <div className="flex-1 ms-3">
                            <h6 className="mb-0">Lorem Ipsum generators</h6>
                            <small className="float-left font-size-12">
                              5 Dec
                            </small>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </li> */}
            </ul>
            <ul className="navbar-list">
              <li>
                <button className="search-toggle d-flex align-items-center border-0 bg-transparent">
                  <i className="ri-arrow-down-s-fill"></i>
                </button>
                <div className="iq-sub-dropdown iq-user-dropdown">
                  <div className="iq-card shadow-none m-0">
                    <div className="iq-card-body p-0">
                      <div className="bg-primary p-3 line-height">
                        <h5 className="mb-0 text-white line-height">
                          {t("hello", { name: user?.user_nama })}
                        </h5>
                        <span className="text-white font-size-12">
                          {t("tersedia")}
                        </span>
                      </div>
                      <Link
                        to="/profile"
                        className="iq-sub-card iq-bg-primary-hover"
                      >
                        <div className="media align-items-center">
                          <div className="rounded iq-card-icon iq-bg-primary">
                            <i className="ri-file-user-line"></i>
                          </div>
                          <div className="flex-1 ms-3">
                            <h6 className="mb-0">{t("profil_saya")}</h6>
                            <p className="mb-0 font-size-12">
                              {t("profil_saya_desc")}
                            </p>
                          </div>
                        </div>
                      </Link>
                      {/* <a href="profile-edit.html" className="iq-sub-card iq-bg-warning-hover">
												<div className="media align-items-center">
													<div className="rounded iq-card-icon iq-bg-warning">
														<i className="ri-profile-line"></i>
													</div>
													<div className="flex-1 ms-3">
														<h6 className="mb-0">Edit Profile</h6>
														<p className="mb-0 font-size-12">Modify your personal details.</p>
													</div>
												</div>
											</a>
											<a href="account-setting.html" className="iq-sub-card iq-bg-info-hover">
												<div className="media align-items-center">
													<div className="rounded iq-card-icon iq-bg-info">
														<i className="ri-account-box-line"></i>
													</div>
													<div className="flex-1 ms-3">
														<h6 className="mb-0">Account settings</h6>
														<p className="mb-0 font-size-12">Manage your account parameters.</p>
													</div>
												</div>
											</a>
											<a href="privacy-setting.html" className="iq-sub-card iq-bg-danger-hover">
												<div className="media align-items-center">
													<div className="rounded iq-card-icon iq-bg-danger">
														<i className="ri-lock-line"></i>
													</div>
													<div className="flex-1 ms-3">
														<h6 className="mb-0">Privacy Settings</h6>
														<p className="mb-0 font-size-12">Control your privacy parameters.</p>
													</div>
												</div>
											</a> */}
                      <div className="d-inline-block w-100 text-center">
                        <button
                          className="bg-primary iq-sign-btn text-white cursor-pointer"
                          onClick={handleLogout}
                        >
                          {t("keluar")}
                          <i className="ri-login-box-line ms-2"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
