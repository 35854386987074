import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

import { Soal } from "../section";
import {
  ItemSoalEssay,
  ItemSoalMenjodohkan,
  ItemSoalMultiple,
  ItemSoalPernyataan,
  ItemSoalPilgan,
  ItemSoalTrueFalse,
} from "..";

function PreviewSoal({ show, dataSoal, handleClose, dataSoalCerita }) {
  const { t } = useTranslation();
  const [soalCerita, setSoalCerita] = useState(null);
  const [activeSoal, setActiveSoal] = useState(0);
  const [showCerita, setShowCerita] = useState(false);
  useEffect(() => {
    let soal = dataSoal[activeSoal];
    let cerita = dataSoalCerita?.filter((item) => {
      let cek = item.detail?.filter((data) => data.soal_id === soal?.soal_id);
      if (cek?.length > 0) return true;
      return false;
    });
    setSoalCerita(cerita[0] || null);
  }, [activeSoal, dataSoal, dataSoalCerita]);

  return (
    <div className="col-lg-12">
      <Modal
        fullscreen={true}
        enforceFocus={false}
        show={show}
        id="PreviewSoal"
        backdrop="static"
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <div className="btn btn-dark text-white font-size-12">
            <span className="font-size-20 fw-bold">
              {t("soal")} {activeSoal + 1}
            </span>{" "}
            / {dataSoal?.length}
          </div>
          {soalCerita && (
            <button
              onClick={() => setShowCerita(true)}
              className="btn btn-primary text-white ms-2 h-100"
            >
              {t("baca_cerita")}
            </button>
          )}
        </Modal.Header>
        <Modal.Body className="p-0 overflow-x-hidden">
          <div className="p-4">
            <div className="row">
              <Soal
                soal_file={dataSoal[activeSoal]?.soal_file}
                soal_file_type={dataSoal[activeSoal].soal_file_type}
                soal_pertanyaan={dataSoal[activeSoal]?.soal_pertanyaan}
              />
              <div className="col-span-12">
                {dataSoal[activeSoal]?.soal_jenis === "pg" ? (
                  <ItemSoalPilgan
                    pilgan={dataSoal[activeSoal]?.soal_pg}
                    preview={true}
                  />
                ) : dataSoal[activeSoal]?.soal_jenis === "polling" ? (
                  <ItemSoalMultiple
                    pooling={dataSoal[activeSoal]?.soal_polling}
                    preview={true}
                  />
                ) : dataSoal[activeSoal]?.soal_jenis === "essay" ? (
                  <ItemSoalEssay
                    essay={dataSoal[activeSoal]?.soal_essay}
                    preview={true}
                  />
                ) : dataSoal[activeSoal]?.soal_jenis === "true_false" ? (
                  <ItemSoalTrueFalse
                    data={dataSoal[activeSoal]?.soal_true_false}
                    preview={true}
                  />
                ) : dataSoal[activeSoal]?.soal_jenis === "menjodohkan" ? (
                  <ItemSoalMenjodohkan
                    data={dataSoal[activeSoal]?.soal_menjodohkan}
                    preview={true}
                  />
                ) : dataSoal[activeSoal]?.soal_jenis === "pernyataan" ? (
                  <ItemSoalPernyataan
                    soalPernyataan={dataSoal[activeSoal]?.soal_pernyataan}
                    preview={true}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex align-items-center gap-8">
            <button
              type="button"
              name="previous"
              className="btn btn-dark previous action-button-previous"
              value="Previous"
              onClick={() =>
                setActiveSoal(activeSoal <= 0 ? 0 : activeSoal - 1)
              }
            >
              <i className="ri-arrow-left-s-line font-size-20 p-0 fw-bold m-0"></i>
            </button>
            <button
              type="button"
              name="next"
              className="btn btn-primary next action-button"
              value="Next"
              onClick={() =>
                setActiveSoal(
                  activeSoal < dataSoal?.length - 1
                    ? activeSoal + 1
                    : dataSoal?.length - 1
                )
              }
            >
              <i className="ri-arrow-right-s-line font-size-20 p-0 fw-bold m-0"></i>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {showCerita && (
        <Modal
          size="lg"
          show={showCerita}
          onHide={() => {
            setShowCerita(false);
          }}
          id="showcerita"
          fullscreen
        >
          <Modal.Header className="border-0 bg-black">
            <h4 className="modal-title text-white m-0">{t("soal_cerita")}</h4>
            <button
              onClick={() => setShowCerita(false)}
              className="bg-transparent border-0 text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-x"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center">
              <Col xs="12" lg="6">
                <Container>
                  <img
                    src={soalCerita?.cerita_file}
                    className="img-fluid rounded w-100 mb-3"
                    alt="gambar"
                  />
                  <div
                    className="text-white"
                    dangerouslySetInnerHTML={{
                      __html: soalCerita?.cerita_deskripsi,
                    }}
                  ></div>
                </Container>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

export default PreviewSoal;
