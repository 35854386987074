import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getKelas,
  getSekolah,
  getSemester,
  getTahunPelajaran,
} from "../../redux/actions/appAction";
import { useDispatch, useSelector } from "react-redux";

const FilterUjianGuru = ({ onFilterChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dataSekolah, setDataSekolah] = useState([]);
  const [dataTahun, setDataTahun] = useState([]);
  const [dataSemester, setDataSemester] = useState([]);
  const { kelas } = useSelector(({ app }) => app);
  const [filter, setFilter] = useState({
    kelasdetail_id: "",
    semester_id: "",
    sekolah_id: "",
    tahunpelajaran_id: "",
  });

  useEffect(() => {
    getSekolah().then((res) => setDataSekolah(res));
  }, []);

  useEffect(() => {
    if (filter?.sekolah_id) {
      getTahunPelajaran(filter?.sekolah_id).then((res) => setDataTahun(res));
      dispatch(getKelas({ sekolah_id: filter?.sekolah_id }));
    }
  }, [filter?.sekolah_id, dispatch]);

  useEffect(() => {
    if (filter?.sekolah_id && filter?.kelasdetail_id) {
      const params = {
        kelasdetail_id: filter.kelasdetail_id,
        sekolah_id: filter.sekolah_id,
      };
      getSemester(params).then((res) => setDataSemester(res));
    }
  }, [filter?.sekolah_id, filter?.kelasdetail_id, dispatch]);

  useEffect(() => {
    onFilterChange(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleChange = ({ target: { value, name } }) => {
    setFilter((pref) => ({
      ...pref,
      [name]: value,
    }));
  };

  return (
    <div className="col-lg-12">
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title d-flex align-items-center justify-content-between w-100">
            <h4 className="card-title">{t("ujian_saya")}</h4>
          </div>
        </div>
        <div className="iq-card-body">
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="sekolah" className="control-label">
                {t("sekolah")} :
              </label>
              <select
                onChange={handleChange}
                id="sekolah"
                className="form-control form-select"
                value={filter.sekolah_id}
                name="sekolah_id"
              >
                <option value="">
                  --
                  {t("pilih_params", {
                    params: t("sekolah"),
                  })}
                  --
                </option>
                {dataSekolah?.map((item) => (
                  <option key={item.sekolah_id} value={item.sekolah_id}>
                    {item.sekolah_nama}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-lg-3">
              <label htmlFor="tahun_pelajaran" className="control-label">
                {t("tahun_pelajaran")} :
              </label>
              <select
                onChange={handleChange}
                id="tahun_pelajaran"
                className="form-control form-select"
                value={filter.tahunpelajaran_id}
                name="tahunpelajaran_id"
              >
                <option value="">
                  --
                  {t("pilih_params", {
                    params: t("tahun_pelajaran"),
                  })}
                  --
                </option>
                {dataTahun?.map((item) => (
                  <option
                    key={item.tahunpelajaran_id}
                    value={item.tahunpelajaran_id}
                  >
                    {item.tahunpelajaran_nama}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-lg-3">
              <label htmlFor="kelas" className="control-label">
                {t("kelas")} :
              </label>
              <select
                onChange={handleChange}
                id="kelas"
                className="form-control form-select"
                value={filter.kelasdetail_id}
                name="kelasdetail_id"
              >
                <option value="">
                  --
                  {t("pilih_params", {
                    params: t("kelas"),
                  })}
                  --
                </option>
                {kelas?.map((item) => (
                  <option value={item.kelasdetail_id} key={item.kelasdetail_id}>
                    {item.kelasdetail_nama}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-lg-3">
              <label htmlFor="semester_id" className="control-label">
                {t("semester")} :
              </label>
              <select
                onChange={handleChange}
                id="semester_id"
                className="form-control form-select"
                value={filter.semester_id}
                name="semester_id"
              >
                <option value="">
                  --
                  {t("pilih_params", {
                    params: t("semester"),
                  })}
                  --
                </option>
                {dataSemester?.map((item) => (
                  <option value={item.semester_id} key={item.semester_id}>
                    Semester {item.semester_nama}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterUjianGuru;
