import { API, setAuthToken } from "../../config/api";
import { GET_UJIAN } from "../constant";

export const addUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("ujian", params);
};

export const updUjian = (code, params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("ujian/" + code, params);
};

export const updStatusUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("ujian/update-status", params);
};

export const delUjian = (code, params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  const queryString = new URLSearchParams(params).toString();
  return API.delete(`ujian/${code}${queryString ? "?" + queryString : ""}`);
};

export const getDataUjian = (params) => {
  return {
    type: GET_UJIAN,
    payload: async () => {
      const token = localStorage.getItem("token");
      setAuthToken(token);
      const data = new URLSearchParams(params).toString();
      const res = await API.get(`ujian?${data}`).catch((err) => {
        return Promise.reject(err);
      });
      return res?.data?.data;
    },
  };
};

export const getDetailUjian = (code, kelasId, editUjian) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let params = "ujian_id=" + code;
  if (kelasId) params += "&kelasdetail_id=" + kelasId;
  if (editUjian) params += "&edit=1";
  return API.get(`ujian/detail?${params}`);
};

export const getListSoal = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`ujian/soal?ujian_id=${code}`);
};

export const getKolaborasi = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  const paramsString = new URLSearchParams(params).toString();
  return API.get(`ujian/kolaborasi?${paramsString}`);
};

export const addSoalUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("ujian/soal", params);
};

export const uploadFileSoalUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("ujian/soal/upload-file", params);
};

export const delSoalUjian = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.delete("ujian/soal?soal_id=" + code);
};

export const getDetailSoalUjian = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`ujian/soal/detail?soal_id=${code}`);
};

export const updSoalUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("ujian/soal/update", params);
};

export const getListSoalCeritaUjian = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`ujian/soal/cerita/list-soal?ujian_id=${code}`);
};

export const addSoalCeritaUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`ujian/soal/cerita`, params);
};

export const updSoalCeritaUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`ujian/soal/cerita/update`, params);
};

export const getSoalCeritaUjian = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`ujian/soal/cerita?ujian_id=${code}`);
};

export const getDetailSoalCeritaUjian = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`ujian/soal/cerita/detail?cerita_id=${code}`);
};

export const delCeritaUjian = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.delete("ujian/soal/cerita?cerita_id=" + code);
};

export const updHidePembahasanUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("ujian/hide_pembahasan", params);
};

export const updSoalUrutUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("ujian/soal-urut", params);
};

export const getTargetUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`ujian/target?${new URLSearchParams(params).toString()}`);
};

export const submitTargetUjian = (edit, params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`ujian/target${edit ? "/update" : ""}`, params);
};

export const delTarget = (id) => {
  const token = localStorage?.getItem("token");
  setAuthToken(token);
  return API.delete(`ujian/target?id=${id}`);
};

export const kerjakanUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("ujian/pengerjaan-soal/mulai", params);
};

export const submitUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("ujian/pengerjaan-soal/finish", params);
};

export const submitCatatanUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`nilai/catatan`, params);
};

export const submitJawabanUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("ujian/pengerjaan-soal", params);
};

export const publishUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("ujian/publish", params);
};

export const getBobotSoal = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get("ujian/bobot?ujian_id=" + code);
};

export const updBobotSoal = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("ujian/bobot", params);
};

export const exportSoalUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("ujian/soal/eksport", params);
};

export const getOptionBankSoal = (id) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`bank-soal/list?kelasmapel_id=${id}`);
};

export const sendImportBankSoalUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("ujian/soal/import", params);
};

export const updPointSoal = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("ujian/soal/update_points", params);
};

export const updWaktuSoal = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("ujian/soal/update_waktu", params);
};

export const uploadFileUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("ujian/upload-file", params);
};

export const delFileUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.delete(`ujian/file?${new URLSearchParams(params).toString()}`);
};

export const uploadFilePengerjaanUjian = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("ujian/pengerjaan-soal/upload-file", params);
};

export const getUjianInfo = () => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`ujian/info`);
};

export const checkDeviceUser = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`ujian/validation_device`, params);
};
