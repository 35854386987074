import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { PageProfileBg2 } from "../../assets/images";
import { Wrapper } from "../../components/layout";
import { getDetailProfile } from "../../redux/actions/userAction";
import { checkIsGuru, checkIsSiswa } from "../../utils/roleUser";
import { InfoProfile } from "../../components";
import FormChagePassword from "../../components/section/FormChagePassword";
import { Link } from "react-router-dom";

function Profile() {
  const { t } = useTranslation();
  const dataUser = useSelector(({ user }) => user?.user);
  const [user, setUser] = useState(null);
  const [isGuru, setIsGuru] = useState(false);
  const [isSiswa, setIsSiswa] = useState(false);
  useEffect(() => {
    getDetailProfile().then((res) => setUser(res));
  }, []);

  useEffect(() => {
    if (!dataUser) {
      setIsGuru(false);
      setIsSiswa(false);
    } else {
      setIsGuru(checkIsGuru(dataUser?.user_roleid));
      setIsSiswa(checkIsSiswa(dataUser?.user_roleid));
    }
  }, [dataUser]);

  return (
    <Wrapper title={t("profil")}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="iq-card">
              <div className="iq-card-body profile-page p-0">
                <div className="profile-header-image">
                  <div className="cover-container">
                    <img
                      src={PageProfileBg2}
                      alt="profile-bg"
                      className="rounded img-fluid w-100"
                    />
                  </div>
                  <div className="profile-info p-4">
                    <div className="user-detail">
                      <div className="d-flex flex-wrap justify-content-between align-items-start">
                        <div className="profile-detail d-flex">
                          <div className="profile-img pr-4">
                            <img
                              src={user?.user_avatar}
                              alt="profile-img"
                              className="avatar-130 img-fluid"
                            />
                          </div>
                          <div className="user-data-block px-2">
                            <h4>
                              <b>{user?.user_nama}</b>
                            </h4>
                            <p>
                              {isGuru ? "NIP" : isSiswa ? "NIS" : "ID"} :{" "}
                              {user?.user_no}
                            </p>
                          </div>
                        </div>
                        <Link
                          to={`/`}
                          className="btn iq-bg-light iq-bg-primary-hover text-dark border"
                        >
                          <i className="ri-arrow-left-line m-0"></i>{" "}
                          {t("kembali")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="iq-card">
              <div className="iq-card-body">
                <div className="row">
                  <div className="col-md-3">
                    <ul className="nav nav-pills basic-info-items list-inline d-block p-0 m-0">
                      <li>
                        <a
                          className="nav-link text-primary active"
                          data-toggle="pill"
                          href="#profile"
                        >
                          {t("profil")}
                        </a>
                      </li>
                      <li>
                        <a
                          className="nav-link text-primary"
                          data-toggle="pill"
                          href="#ubahpassword"
                        >
                          {t("ubah_kata_sandi")}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-9 pl-4">
                    <div className="tab-content">
                      <InfoProfile
                        isGuru={isGuru}
                        isSiswa={isSiswa}
                        user={user}
                      />
                      <FormChagePassword />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Profile;
