export const EMPTY = "EMPTY";
export const EMPTY_BETWEEN = "EMPTY_BETWEEN";
export const NO_CORRET_ANSWER = "NO_CORRET_ANSWER";
export const ACCEPT = "ACCEPT";
export const NOT_ENOUGH_ANSWER = "NOT_ENOUGH_ANSWER";
export const NOT_FILLED = "NOT_FILLED";
export const EMPTY_ANSWER_NAME = "EMPTY_ANSWER_NAME";

export const checkSoalPilgan = (data) => {
  let prevFilledIndex = -1;
  let hasCorrectAnswer = false;
  let filledAnswer = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i].soal_pilihan?.length > 0 || data[i].soal_file?.length > 0) {
      filledAnswer += 1;
      if (i - prevFilledIndex > 1) {
        return EMPTY_BETWEEN;
      }
      if (data[i].soal_jawaban) hasCorrectAnswer = true;
      prevFilledIndex = i;
    }
  }
  if (prevFilledIndex === -1) {
    return EMPTY;
  } else if (filledAnswer < 2) {
    return NOT_ENOUGH_ANSWER;
  } else if (!hasCorrectAnswer) {
    return NO_CORRET_ANSWER;
  } else {
    return ACCEPT;
  }
};

export const checkSoalPooling = (data) => {
  let prevFilledIndex = -1;
  let hasCorrectAnswer = false;
  let filledAnswer = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i].soal_pilihan?.length > 0 || data[i].soal_file?.length > 0) {
      filledAnswer += 1;
      if (i - prevFilledIndex > 1) {
        return EMPTY_BETWEEN;
      }
      if (data[i].soal_jawaban) hasCorrectAnswer = true;
      prevFilledIndex = i;
    }
  }
  if (prevFilledIndex === -1) {
    return EMPTY;
  } else if (filledAnswer < 2) {
    return NOT_ENOUGH_ANSWER;
  } else if (!hasCorrectAnswer) {
    return NO_CORRET_ANSWER;
  } else {
    return ACCEPT;
  }
};

export const checkSoalTrueFalse = (data) => {
  if (
    data.soal_pilihan_false?.length <= 0 ||
    data.soal_pilihan_true?.length <= 0
  ) {
    return NOT_FILLED;
  } else if (data.soal_jawaban === "") {
    return NO_CORRET_ANSWER;
  } else {
    return ACCEPT;
  }
};

export const checkSoalMenjodohkan = (data) => {
  if (data?.soal_menjodohkan_nama_pilihan?.length <= 0) {
    return EMPTY_ANSWER_NAME;
  } else if (data?.pernyataan?.length !== data?.pilihan?.length) {
    return NOT_ENOUGH_ANSWER;
  }

  for (let i = 0; i < data?.pilihan.length; i++) {
    let pilihan = data?.pilihan[i];
    let pernyataan = data?.pernyataan[i];
    if (pilihan?.soal_menjodohkan_pilihan_isi?.length <= 0) return NOT_FILLED;
    if (
      pernyataan?.soal_menjodohkan_jawaban?.length <= 0 ||
      pernyataan?.soal_menjodohkan_pernyataan?.length <= 0
    )
      return NOT_FILLED;
  }
  return ACCEPT;
};

export function checkSoalPernyataan(data) {
  if (
    data?.soal_pernyataan_false?.length <= 0 ||
    data?.soal_pernyataan_nama?.length <= 0 ||
    data?.soal_pernyataan_true?.length <= 0
  ) {
    return EMPTY_ANSWER_NAME;
  }

  for (let i = 0; i < data?.pilihan.length; i++) {
    let pilihan = data?.pilihan[i];
    if (pilihan?.soal_pernyataan_pertanyaan?.length <= 0) return NOT_FILLED;
  }
  return ACCEPT;
}

export function checkParamsSoal(
  soal_jenis,
  soalPilgan,
  soalPooling,
  soalTrueFalse,
  soalMenjodohkan,
  soalPernyataan
) {
  if (soal_jenis === "pg") {
    let check = checkSoalPilgan(soalPilgan);
    if (check === ACCEPT) return true;
    if (check === EMPTY) {
      return "isi_jwb_dlu";
    }
    if (check === EMPTY_BETWEEN) {
      return "msg_empty_between";
    }
    if (check === NO_CORRET_ANSWER) {
      return "msg_no_correct_answ";
    }
    if (check === NOT_ENOUGH_ANSWER) {
      return "msg_not_enought_answ";
    }
  } else if (soal_jenis === "polling") {
    let check = checkSoalPooling(soalPooling);
    if (check === ACCEPT) return true;
    if (check === EMPTY) {
      return "isi_jwb_dlu";
    } else if (check === EMPTY_BETWEEN) {
      return "msg_empty_between";
    } else if (check === NO_CORRET_ANSWER) {
      return "msg_no_correct_answ";
    } else if (check === NOT_ENOUGH_ANSWER) {
      return "msg_not_enought_answ";
    }
  } else if (soal_jenis === "true_false") {
    let check = checkSoalTrueFalse(soalTrueFalse);
    if (check === ACCEPT) return true;
    if (check === NOT_FILLED) {
      return "msg_not_filled";
    }
    if (check === NO_CORRET_ANSWER) {
      return "msg_no_correct_answ";
    }
  } else if (soal_jenis === "menjodohkan") {
    let check = checkSoalMenjodohkan(soalMenjodohkan);
    if (check === ACCEPT) return true;
    if (check === EMPTY_ANSWER_NAME) {
      return "msg_empty_answer_name";
    } else if (check === NOT_ENOUGH_ANSWER) {
      return "msg_not_enought_answ_menjodohkan";
    } else if (check === NOT_FILLED) {
      return "msg_not_filled";
    }
  } else if (soal_jenis === "pernyataan") {
    let check = checkSoalPernyataan(soalPernyataan);
    if (check === ACCEPT) return true;
    if (check === EMPTY_ANSWER_NAME) {
      return "msg_empty_answer_name";
    } else if (check === NOT_FILLED) {
      return "msg_not_filled";
    }
  }
  return true;
}
