import { API, setAuthToken } from '../../config/api';
import { GET_DETAIL_NOTIF, GET_DETAIL_NOTIF_GROUP, GET_NOTIF_GROUP } from '../constant';

export const getNotifGroup = () => {
  return {
    type: GET_NOTIF_GROUP,
    payload: async () => {
      const token = localStorage.getItem("token");
      setAuthToken(token);
      let res = await API.get("/notification/group").catch((err) => {
        return Promise.reject(err);
      });
      return res?.data?.data;
    },
  };
};

export const getDetailNotifGroup = (id, search) => {
  return {
    type: GET_DETAIL_NOTIF_GROUP,
    payload: async () => {
      const token = localStorage.getItem("token");
      setAuthToken(token);
      let res = await API.get(
        `/notification/group/detail?group_notif_id=${id}&search=${search}`
      ).catch((err) => {
        return Promise.reject(err);
      });
      return res?.data?.data;
    },
  };
};

export const getDetailNotif = (notif_id, group_id) => {
  return {
    type: GET_DETAIL_NOTIF,
    payload: async () => {
      const token = localStorage.getItem("token");
      setAuthToken(token);
      await API.get(`/notification/detail?notif_id=${notif_id}`).catch(
        (err) => {
          return Promise.reject(err);
        }
      );
      return {
        notif_id,
        group_id,
      };
    },
  };
};
