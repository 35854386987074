import React from "react";

const ExamColaboration = ({ data }) => {
  return (
    <div className="d-flex gap-2 align-items-center">
      {data?.map((item) => (
        <span className="badge badge-info" key={item.ujian_kolaborasi_id}>
          {item?.kelas?.kelasdetail_nama}
        </span>
      ))}
    </div>
  );
};

export default ExamColaboration;
