import React from "react";
import { useTranslation } from "react-i18next";

function ItemPlaylist({
  title,
  time,
  handlePlay,
  status,
  type,
  buyCourse,
  lock,
}) {
  const { t } = useTranslation();
  return (
    <button
      onClick={handlePlay}
      className="bg-transparent border-0 d-flex align-items-center p-0 ml-0 gap-3 w-100 justify-content-between align-items-center"
    >
      {buyCourse && (
        <i
          className={`font-size-32 
          ${!lock ? "text-primary" : "text-secondary"}
          ${!lock ? "ri-play-circle-fill" : "ri-lock-fill"}`}
        ></i>
      )}
      {!buyCourse && (
        <i
          className={`font-size-32 
          ${status ? "text-primary" : "text-secondary"}
          ${type === "materi" ? "ri-play-circle-fill" : "ri-mac-fill"}`}
        ></i>
      )}
      <h6 className="font-size-14 text-truncate fw-bold flex-1 text-start">
        {title}
      </h6>
      {time ? (
        <h6 className="font-size-14 fw-bold flex-shrink-0">
          {time} {t("menit")}
        </h6>
      ) : null}
    </button>
  );
}

export default ItemPlaylist;
