import React from "react";
import { useTranslation } from "react-i18next";

function ItemSoalPernyataan({
  handleChange,
  soalPernyataan,
  addPilihan,
  delPilihan,
  changePernyataan,
  changeJawaban,
  preview,
  kerjakan,
  handlePilih,
  dipilih,
}) {
  const { t } = useTranslation();
  return (
    <div className={`row ${preview || kerjakan ? "py-4" : "p-3"}`}>
      <div className="col-lg-12">
        <div className="table-responsive">
          <table id="datatable" className="table table-bordered table-hover">
            <thead className="table-primary text-white">
              <tr>
                <th width="65%" className="bg-transparent va-middle">
                  {preview || kerjakan ? (
                    <p className="m-0">
                      {soalPernyataan?.soal_pernyataan_nama}
                    </p>
                  ) : (
                    <input
                      type="text"
                      className="form-control bg-transparent form-control-sm placeholder-white text-white"
                      value={soalPernyataan?.soal_pernyataan_nama}
                      id="soal_pernyataan_nama"
                      name="soal_pernyataan_nama"
                      onChange={handleChange}
                      placeholder={t("pernyataan")}
                    />
                  )}
                </th>
                <th width="15%" className="bg-transparent va-middle">
                  {preview || kerjakan ? (
                    <p className="m-0">
                      {soalPernyataan?.soal_pernyataan_true}
                    </p>
                  ) : (
                    <input
                      type="text"
                      className="form-control bg-transparent form-control-sm placeholder-white text-white"
                      value={soalPernyataan?.soal_pernyataan_true}
                      id="soal_pernyataan_true"
                      name="soal_pernyataan_true"
                      onChange={handleChange}
                      placeholder={t("pernyataan")}
                    />
                  )}
                </th>
                <th width="15%" className="bg-transparent va-middle">
                  {kerjakan || preview ? (
                    <p className="m-0">
                      {soalPernyataan?.soal_pernyataan_false}
                    </p>
                  ) : (
                    <input
                      type="text"
                      className="form-control bg-transparent form-control-sm placeholder-white text-white"
                      value={soalPernyataan?.soal_pernyataan_false}
                      id="soal_pernyataan_false"
                      name="soal_pernyataan_false"
                      onChange={handleChange}
                      placeholder={t("pernyataan")}
                    />
                  )}
                </th>
                {!preview && !kerjakan && (
                  <th width="5%" className="bg-transparent">
                    {t("aksi")}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {soalPernyataan?.pilihan?.map((item, idx) => {
                const cek = dipilih?.filter(
                  (jwb) =>
                    jwb.soal_pernyataan_pilihan_id.toString() ===
                    item.soal_pernyataan_pilihan_id.toString()
                );
                return (
                  <tr key={idx}>
                    <td>
                      {preview || kerjakan ? (
                        <p className="m-0">{item.soal_pernyataan_pertanyaan}</p>
                      ) : (
                        <textarea
                          className="form-control"
                          rows="1"
                          name={idx}
                          value={item.soal_pernyataan_pertanyaan}
                          onChange={changePernyataan}
                          placeholder={t("soal")}
                        ></textarea>
                      )}
                    </td>
                    <td className="text-center">
                      <input
                        className="rabsen"
                        style={{ width: 25 }}
                        type="radio"
                        name={idx}
                        checked={
                          kerjakan
                            ? cek?.length > 0
                              ? cek[0].jawaban === true
                              : null
                            : item.soal_pernyataan_jawaban === "1" ||
                              item.soal_pernyataan_jawaban === true
                        }
                        onChange={() =>
                          kerjakan
                            ? handlePilih(item.soal_pernyataan_pilihan_id, true)
                            : changeJawaban({
                                target: { name: idx, value: "1" },
                              })
                        }
                        id={idx}
                        disabled={preview}
                      />
                    </td>
                    <td className="text-center">
                      <input
                        className="rabsen"
                        style={{ width: 25 }}
                        type="radio"
                        name={idx}
                        checked={
                          kerjakan
                            ? cek?.length > 0
                              ? cek[0].jawaban === false
                              : null
                            : item.soal_pernyataan_jawaban === "0" ||
                              item.soal_pernyataan_jawaban === false
                        }
                        onChange={() =>
                          kerjakan
                            ? handlePilih(
                                item.soal_pernyataan_pilihan_id,
                                false
                              )
                            : changeJawaban({
                                target: { name: idx, value: "0" },
                              })
                        }
                        id={idx}
                        disabled={preview}
                      />
                    </td>
                    {!preview && !kerjakan && (
                      <td>
                        <button
                          onClick={() => delPilihan(idx)}
                          className="btn btn-sm btn-danger mb-1"
                        >
                          <i className="ri-delete-bin-7-line m-0"></i>
                        </button>
                        {idx === soalPernyataan?.pilihan?.length - 1 && (
                          <button
                            onClick={addPilihan}
                            className="btn btn-sm btn-primary mb-1"
                          >
                            <i className="ri-add-circle-fill m-0"></i>
                          </button>
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ItemSoalPernyataan;
