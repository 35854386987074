import React from "react";
import {
  MenjodohkanCard,
  PernyataanCard,
  PilihanGandaCard,
  PoolingCard,
  TrueFalseCard,
  UraianCard,
} from "..";

const ListPembahasan = ({ soalJenis, idx, item, disabledPoint }) => {
  const props = {
    data: item,
    index: idx,
    pembahasan: true,
    files: item?.pengerjaan_soal?.pengerjaan_soal_file,
    disabledPoint: disabledPoint,
  };
  switch (soalJenis) {
    case "pg":
      return <PilihanGandaCard {...props} />;
    case "polling":
      return <PoolingCard {...props} />;
    case "essay":
      return <UraianCard {...props} />;
    case "true_false":
      return <TrueFalseCard {...props} />;
    case "menjodohkan":
      return <MenjodohkanCard {...props} />;
    case "pernyataan":
      return <PernyataanCard {...props} />;
    default:
      return <></>;
  }
};

export default ListPembahasan;
