import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';

import { PageLoader } from '../../assets/images';
import { addFolderDrive, updFolderDrive } from '../../redux/actions/driveAction';

function TambahFolderDrive({ show, handleClose, data }) {
  const [submit, setSubmit] = useState(false);
  const [nama, setNama] = useState("");
  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? "Berhasil" : "Gagal!",
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };
  const handleSubmit = () => {
    setSubmit(true);
    let params = {
      drive_folder_name: nama,
    };
    if (data) {
      params.drive_folder_id = data?.drive_folder_id;
      updFolderDrive(params)
        .then(() => {
          setSubmit(false);
          handleClose();
        })
        .catch((err) => {
          showAlert(
            "Gagal menyimpan perubahan, coba beberapa saat lagi",
            false
          );
          setSubmit(false);
        });
      return;
    }
    addFolderDrive(params)
      .then(() => {
        setSubmit(false);
        handleClose();
      })
      .catch((err) => {
        setSubmit(false);
        showAlert("Gagal membuat folder, coba beberapa saat lagi", false);
      });
  };

  const handleChange = ({ target: { value } }) => {
    setNama(value);
  };

  useEffect(() => {
    if (data) {
      setNama(data?.drive_folder_name);
    } else {
      setNama("");
    }
  }, [data]);

  return (
    <div className="col-lg-12">
      <Modal
        size="sm"
        show={show}
        onHide={() => {
          if (!submit) handleClose();
        }}
        id="TambahFolderDrive"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title">{data ? "Edit" : "Tambah"} Folder</h5>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="form-control"
            id="nama"
            value={nama}
            name="nama"
            placeholder="Nama folder"
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={submit}
          >
            {submit ? (
              <img src={PageLoader} alt="loader" style={{ width: 15 }} />
            ) : (
              "Save changes"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default TambahFolderDrive;
