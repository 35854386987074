import React from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import { PageLoader } from "../../assets/images";

function InputPilgan({
  shadow,
  bg,
  border,
  answer,
  name,
  handleChange,
  id,
  correct,
  handleChangeFile,
  soalFile,
  handleDeleteFile,
  loadingUpload,
  preview,
  kerjakan,
  dipilih,
}) {
  const { t } = useTranslation();
  const fileRef = useRef(null);
  return (
    <div
      className={`iq-card ${bg} border ${border}`}
      style={{ borderRadius: "0.75rem", boxShadow: `0 8px 0 0 #${shadow}` }}
    >
      <div className="iq-card-body d-flex justify-content-between">
        {!preview && !kerjakan ? (
          <div className="send-panel">
            {soalFile && (
              <button
                onClick={() => handleDeleteFile(id)}
                className="btn iq-bg-primary border-0 p-0 text-primary"
              >
                <i className="ri-delete-bin-fill m-0"></i>
              </button>
            )}
            <label
              className="iq-bg-primary rounded ms-1"
              htmlFor={"soalfile-" + id}
            >
              <input
                type="file"
                id={"soalfile-" + id}
                className="d-none"
                accept="image/png, image/jpg, image/jpeg"
                onChange={(e) => handleChangeFile(id, e)}
                ref={fileRef}
                disabled={loadingUpload === id || loadingUpload}
              />
              <button
                onClick={() => fileRef.current?.click()}
                className="btn border-0 p-0 text-primary"
                disabled={loadingUpload === id || loadingUpload}
              >
                {loadingUpload === id ? (
                  <img src={PageLoader} alt="loader" height={20} width={20} />
                ) : (
                  <i className="ri-image-fill m-0"></i>
                )}
              </button>
            </label>
          </div>
        ) : (
          <div className="flex-1"></div>
        )}
        <div className="radio d-inline-block">
          <input
            className={`rabsen ${preview ? "cursor-notallow" : ""}`}
            style={{ width: 25 }}
            type="radio"
            name="soal_jawaban"
            id={id}
            value={id}
            checked={kerjakan ? dipilih : correct}
            onChange={handleChange}
            readOnly={preview}
          />
        </div>
      </div>
      <div className="iq-card-body pt-0 text-center">
        {soalFile && (
          <img
            src={soalFile}
            alt="Soal File"
            className="img-fluid rounded mb-2"
          />
        )}
        {kerjakan || preview ? (
          <p className="font-size-20 text-center">{answer}</p>
        ) : (!kerjakan && !preview) || answer ? (
          <textarea
            className="placeholder-white bg-transparent text-white form-control text-center font-size-18 line-height pt-5 input-soal"
            style={{ border: "2px solid rgba(255, 0, 0, 0)", height: "auto" }}
            rows="6"
            placeholder={t("isi_jawab_disini")}
            value={answer}
            name={name}
            onChange={handleChange}
            disabled={kerjakan || preview}
          ></textarea>
        ) : null}
      </div>
    </div>
  );
}

export default InputPilgan;
