import React from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import { PageLoader } from "../../assets/images";

function InputEssay({
  handleChange,
  handleChangeFile,
  handleDelFile,
  loadingUpload,
  preview,
  kerjakan,
  jawaban,
  soalFile,
  soalJawaban,
}) {
  const { t } = useTranslation();
  const fileRef = useRef(null);
  return (
    <div className="col-12">
      <div
        className="iq-card bg-primary border border-primary"
        style={{ borderRadius: "0.75rem", boxShadow: `0 8px 0 0 #1e6b28` }}
      >
        {!preview && !kerjakan && (
          <div className="iq-card-body">
            <div className="send-panel">
              {soalFile && (
                <button
                  onClick={handleDelFile}
                  className="btn iq-bg-primary border-0 p-0 text-primary"
                >
                  <i className="ri-delete-bin-fill m-0"></i>
                </button>
              )}
              <label
                className="iq-bg-primary rounded ms-1"
                htmlFor="file-essay"
              >
                <input
                  type="file"
                  id="file-essay"
                  className="d-none"
                  ref={fileRef}
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={handleChangeFile}
                  disabled={loadingUpload}
                />
                <button
                  onClick={() => fileRef.current?.click()}
                  className="btn border-0 p-0 text-primary"
                  disabled={loadingUpload}
                >
                  {loadingUpload ? (
                    <img src={PageLoader} alt="loader" height={20} width={20} />
                  ) : (
                    <i className="ri-image-fill m-0"></i>
                  )}
                </button>
              </label>
            </div>
          </div>
        )}
        <div className={`iq-card-body text-center`}>
          {soalFile && (
            <img
              src={soalFile}
              alt="Soal File"
              className="img-fluid rounded mb-2 w-100 max-w-400"
            />
          )}
          {preview && soalJawaban.length <= 0 ? null : (
            <textarea
              className="placeholder-white bg-transparent text-white form-control text-center font-size-18 line-height pt-5 input-soal"
              style={{ border: "2px solid rgba(255, 0, 0, 0)", height: "auto" }}
              rows="6"
              name="soal_jawaban"
              value={kerjakan ? jawaban : soalJawaban}
              onChange={handleChange}
              placeholder={t("isi_jawab_disini")}
              disabled={preview}
            ></textarea>
          )}
        </div>
      </div>
    </div>
  );
}

export default InputEssay;
