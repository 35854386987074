import React from "react";
import { useTranslation } from "react-i18next";

import { CardCourse } from "../card";
import { PageLoader } from "../../assets/images";

function ListCourse({ loading, data, title, onClick, showBtn, next, page }) {
  const { t } = useTranslation();
  return (
    <>
      {title && (
        <div className="page-separator mb-0 mt-3">
          <div className="page-separator__text card-title">{title}</div>
        </div>
      )}
      <div className="row mt-3 mb-5">
        {loading && page === 1 ? (
          <div className="col-12 text-center py-4">
            <img
              src={PageLoader}
              alt="loader"
              style={{ height: "70px" }}
              className="mx-auto"
            />
          </div>
        ) : data?.length <= 0 ? (
          <p className="text-center py-4">
            {t("data_params_kosong", { params: t("kursus") })}
          </p>
        ) : (
          data?.map((item, idx) => (
            <CardCourse
              key={item.course_id}
              author={item.course_mentor?.mentor?.mentor_name}
              id={item.course_id}
              thumbnail={item.course_thumbnail}
              title={item.course_title}
              total_lesson={item.topik_count}
              level={item.course_level}
              price={item.course_price}
              discount={item.course_diskon}
              type={item.course_type}
              desc={item.course_deskripsi}
              headline={item.course_headline}
              total_time={item.total_materi_time}
              rating={item.total_rating}
              review={item.review_count}
              idx={idx + 1}
              joinStatus={item?.joined?.join_status}
            />
          ))
        )}
        {showBtn && next ? (
          <div className="col-12">
            <button
              disabled={loading}
              onClick={onClick}
              className="btn btn-primary text-white fw-semibold rounded-pill p-12 w-100 my-4"
            >
              {loading ? t("loading") + "..." : t("muat_lainnya")}
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default ListCourse;
