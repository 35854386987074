// import './assets/css/bootstrap.min.css';
import "./assets/css/custom-input.css";
import "./assets/css/flatpickr.min.css";
import "./assets/css/style.css";
import "./assets/css/the_responsive.css";
import "./assets/css/typography.css";
import "react-multi-carousel/lib/styles.css";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import { persist, store } from "./redux";
import Router from "./routes";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persist}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
