import React from "react";
import { Logo, PageLoader } from "../../assets/images";
import { useTranslation } from "react-i18next";

const LoadingPengerjaan = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div
      className="container d-flex justify-content-center align-items-center h-screen flex-column"
      style={{ maxWidth: 2100, height: "100vh", overflow: "hidden" }}
    >
      <img src={Logo} className="img-fluid mb-3 max-w-400" alt="logo" />
      <h4 className="fw-bold">{t("mengambil_data", { data: t(data) })}</h4>
      <img src={PageLoader} alt="loader" width={50} />
    </div>
  );
};

export default LoadingPengerjaan;
