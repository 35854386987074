import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Wrapper } from "../../components";
import { checkIsGuru } from "../../utils/roleUser";
import AbsensiMapel from "./section/absensi-mapel";
import AbsensiSiswa from "./section/absensi-siswa";
import AbsensiWalas from "./section/absensi-walas";
import Weekly from "./section/weekly";
import WeeklySiswa from "./section/weekly-siswa";

function Absensi() {
  const { t } = useTranslation();
  const { user } = useSelector(({ user }) => user);
  const [tabActive, setTabActive] = useState("ABSENSI");
  const [title, setTitle] = useState("");

  useEffect(() => {
    let guru = checkIsGuru(user.user_roleid);
    if (tabActive === "ABSENSI" && guru) {
      setTitle(`${t("absensi")} Walas`);
    } else if (tabActive === "ABSENSI-MAPEL") {
      setTitle(`${t("absensi")} Mapel`);
    } else if (tabActive === "WEEKLY" || tabActive === "RAPOR-MINGGUAN") {
      setTitle(t("mingguan"));
    } else {
      setTitle(t("absensi"));
    }
  }, [tabActive, t, user.user_roleid]);

  return (
    <Wrapper title={title}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 p-0">
            <div className="iq-card">
              <div className="iq-card-body p-0">
                <div className="user-tabing p-3">
                  <div className="d-flex align-items-center justify-content-between row">
                    <div className="col-lg-12">
                      {checkIsGuru(user?.user_roleid) ? (
                        <ul className="nav nav-pills nav-fill">
                          <li className="nav-item">
                            <button
                              className={`nav-link ${
                                tabActive === "ABSENSI"
                                  ? "active"
                                  : "text-muted"
                              }`}
                              onClick={() => setTabActive("ABSENSI")}
                            >
                              <b>{t("absensi")} Walas</b>
                            </button>
                          </li>
                          <li className="nav-item">
                            <button
                              className={`nav-link ${
                                tabActive === "ABSENSI-MAPEL"
                                  ? "active"
                                  : "text-muted"
                              }`}
                              onClick={() => setTabActive("ABSENSI-MAPEL")}
                            >
                              <b>{t("absensi")} Mapel</b>
                            </button>
                          </li>
                          <li className="nav-item">
                            <button
                              className={`nav-link ${
                                tabActive === "WEEKLY" ? "active" : "text-muted"
                              }`}
                              onClick={() => setTabActive("WEEKLY")}
                            >
                              <b>{t("mingguan")}</b>
                            </button>
                          </li>
                        </ul>
                      ) : (
                        <ul className="nav nav-pills nav-fill">
                          <li className="nav-item">
                            <button
                              className={`nav-link ${
                                tabActive === "ABSENSI"
                                  ? "active"
                                  : "text-muted"
                              }`}
                              onClick={() => setTabActive("ABSENSI")}
                            >
                              <b>{t("absensi")}</b>
                            </button>
                          </li>
                          <li className="nav-item">
                            <button
                              className={`nav-link ${
                                tabActive === "RAPOR-MINGGUAN"
                                  ? "active"
                                  : "text-muted"
                              }`}
                              onClick={() => setTabActive("RAPOR-MINGGUAN")}
                            >
                              <b>{t("mingguan")}</b>
                            </button>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 p-0">
            {checkIsGuru(user?.user_roleid) ? (
              <div className="tab-content">
                {tabActive === "ABSENSI" ? (
                  <AbsensiWalas />
                ) : tabActive === "ABSENSI-MAPEL" ? (
                  <AbsensiMapel />
                ) : (
                  <Weekly />
                )}
              </div>
            ) : (
              <div className="tab-content">
                {tabActive === "ABSENSI" ? <AbsensiSiswa /> : <WeeklySiswa />}
              </div>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Absensi;
