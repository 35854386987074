/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { User05, User06, User07, User08, User09, User1, User10 } from '../../assets/images';
import { ListChatCard, Wrapper } from '../../components';
import DetailChat from './section/detail-chat';

function Chat() {
  return (
    <Wrapper>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="iq-card">
              <div className="iq-card-body chat-page p-0">
                <div className="chat-data-block">
                  <div className="row">
                    <div className="col-lg-3 chat-data-left scroller">
                      <div className="chat-search pt-3 ps-3">
                        <div className="d-flex align-items-center">
                          <div className="chat-profile me-3">
                            <img
                              src={User1}
                              alt="chat-user"
                              className="avatar-60"
                            />
                          </div>
                          <div className="chat-caption">
                            <h5 className="mb-0">Bni Jordan</h5>
                            <p className="m-0">Web Designer</p>
                          </div>
                          <button type="submit" className="close-btn-res p-3">
                            <i className="ri-close-fill"></i>
                          </button>
                        </div>
                        <div id="user-detail-popup" className="scroller">
                          <div className="user-profile">
                            <button type="submit" className="close-popup p-3">
                              <i className="ri-close-fill"></i>
                            </button>
                            <div className="user text-center mb-4">
                              <a className="avatar m-0">
                                <img src={User1} alt="avatar" />
                              </a>
                              <div className="user-name mt-4">
                                <h4>Bni Jordan</h4>
                              </div>
                              <div className="user-desc">
                                <p>Web Designer</p>
                              </div>
                            </div>
                            <hr />
                            <div className="user-detail text-left mt-4 ps-4 pe-4">
                              <h5 className="mt-4 mb-4">About</h5>
                              <p>
                                It is long established fact that a reader will
                                be distracted bt the reddable.
                              </p>
                              <h5 className="mt-3 mb-3">Status</h5>
                              <ul className="user-status p-0">
                                <li className="mb-1">
                                  <i className="ri-checkbox-blank-circle-fill text-success pe-1"></i>
                                  <span>Online</span>
                                </li>
                                <li className="mb-1">
                                  <i className="ri-checkbox-blank-circle-fill text-warning pe-1"></i>
                                  <span>Away</span>
                                </li>
                                <li className="mb-1">
                                  <i className="ri-checkbox-blank-circle-fill text-danger pe-1"></i>
                                  <span>Do Not Disturb</span>
                                </li>
                                <li className="mb-1">
                                  <i className="ri-checkbox-blank-circle-fill text-light pe-1"></i>
                                  <span>Offline</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="chat-searchbar mt-4">
                          <div className="form-group chat-search-data m-0">
                            <input
                              type="text"
                              className="form-control round"
                              id="chat-search"
                              placeholder="Search"
                            />
                            <i className="ri-search-line"></i>
                          </div>
                        </div>
                      </div>
                      <div className="chat-sidebar-channel scroller mt-4 ps-3">
                        <h5 className="">Public Channels</h5>
                        <ul className="iq-chat-ui nav flex-column nav-pills">
                          <ListChatCard
                            img={User05}
                            last_time="05 min"
                            link="#chatbox1"
                            message="Lorem ipsum is"
                            name="Team Discussions"
                            unread={20}
                          />
                          <ListChatCard
                            img={User06}
                            last_time="10 min"
                            link="#chatbox2"
                            message="Lorem ipsum is"
                            name="Announcement"
                            unread={10}
                          />
                        </ul>
                        <h5 className="mt-3">Private Channels</h5>
                        <ul className="iq-chat-ui nav flex-column nav-pills">
                          <ListChatCard
                            img={User07}
                            last_time=""
                            link="#chatbox3"
                            message="There are many"
                            name="Designer"
                            unread={0}
                          />
                          <ListChatCard
                            img={User08}
                            last_time=""
                            link="#chatbox4"
                            message="passages of Lorem"
                            name="Developer"
                            unread={0}
                          />
                          <ListChatCard
                            img={User09}
                            last_time=""
                            link="#chatbox5"
                            message="Lorem Ipsum used"
                            name="Testing Team"
                            unread={0}
                          />
                        </ul>
                        <h5 className="mt-3">Direct Message</h5>
                        <ul className="iq-chat-ui nav flex-column nav-pills">
                          <ListChatCard
                            img={User10}
                            last_time=""
                            link="#chatbox6"
                            message="translation by"
                            name="Paul Molive"
                            unread={0}
                          />
                          <ListChatCard
                            img={User05}
                            last_time=""
                            link="#chatbox7"
                            message="Lorem Ipsum which"
                            name="Paige Turner"
                            unread={0}
                          />
                          <ListChatCard
                            img={User06}
                            last_time=""
                            link="#chatbox8"
                            message="simply random text"
                            name="Barb Ackue"
                            unread={0}
                          />
                          <ListChatCard
                            img={User07}
                            last_time=""
                            link="#chatbox9"
                            message="but also the leap"
                            name="Maya Didas"
                            unread={0}
                          />
                          <ListChatCard
                            img={User08}
                            last_time=""
                            link="#chatbox10"
                            message="Contrary to popular"
                            name="Monty Carlo"
                            unread={0}
                          />
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-9 chat-data p-0 chat-data-right">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade active show"
                          id="default-block"
                          role="tabpanel"
                        >
                          <div className="chat-start">
                            <span className="iq-start-icon text-primary">
                              <i className="ri-message-3-line"></i>
                            </span>
                            <button
                              id="chat-start"
                              className="btn bg-white mt-3"
                            >
                              Start Conversation!
                            </button>
                          </div>
                        </div>
                        <DetailChat id="chatbox1" />
                        <DetailChat id="chatbox2" />
                        <DetailChat id="chatbox3" />
                        <DetailChat id="chatbox4" />
                        <DetailChat id="chatbox5" />
                        <DetailChat id="chatbox6" />
                        <DetailChat id="chatbox7" />
                        <DetailChat id="chatbox8" />
                        <DetailChat id="chatbox9" />
                        <DetailChat id="chatbox10" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Chat;
