import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  getAbsensiKelas,
  getAbsensiSemester,
  getSiswaAbsensi,
  getTopikAbsensi,
} from "../../../redux/actions/absenAction";
import { getPelajaranByKelas } from "../../../redux/actions/pelajaranAction";
import { Link } from "react-router-dom";

function AbsensiMapel() {
  const { t } = useTranslation();
  const [kelas, setKelas] = useState([]);
  const [semester, setSemester] = useState([]);
  const [mapel, setMapel] = useState([]);
  const [topik, setTopik] = useState([]);
  const [detailTopik, setDetailTopik] = useState(null);
  const [siswa, setSiswa] = useState([]);
  const [selectedKelas, setSelectedKelas] = useState(null);
  const [params, setParams] = useState({
    semester_id: "",
    tahunpelajaran_id: "",
    kelasdetail_id: "",
    mapel_id: "",
    kehadiran_id: "",
    kehadiran_type: "absensi_mapel",
  });
  function checkProperties(obj) {
    for (var key in obj) {
      if (obj[key] === null || obj[key] === "") return false;
    }
    return true;
  }
  useEffect(() => {
    function getData() {
      getAbsensiKelas().then((res) => setKelas(res));
    }
    getData();
  }, []);
  useEffect(() => {
    setSemester([]);
    function getData() {
      let check = kelas.filter(
        (item) => parseInt(item.id) === parseInt(selectedKelas)
      );
      let data = {
        kelasdetail_id: check[0]?.kelasdetail_id,
        tahunpelajaran_id: check[0]?.tahunpelajaran_id,
      };
      getAbsensiSemester(data).then((res) => setSemester(res));
    }
    if (selectedKelas) {
      getData();
    }
  }, [selectedKelas, kelas]);
  useEffect(() => {
    setMapel([]);
    function getData() {
      getPelajaranByKelas(params.kelasdetail_id, {
        semester_id: params.semester_id,
      }).then((res) => setMapel(res?.data?.data));
    }
    if (params.kelasdetail_id && params.semester_id) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.kelasdetail_id, params.semester_id]);
  useEffect(() => {
    setTopik([]);
    function getData() {
      getTopikAbsensi(params.mapel_id).then((res) => setTopik(res));
    }
    if (params.mapel_id) {
      getData();
    }
  }, [params.mapel_id]);
  useEffect(() => {
    function getData() {
      getSiswaAbsensi("walikelas", params).then((res) => {
        let allSiswa = res?.map((item) => ({
          kehadiran_nilai: item?.kehadiran_nilai || "",
          kehadiran_keterangan: item?.kehadiran_catatan || "",
          user: item?.user,
        }));
        setSiswa(allSiswa);
      });
    }
    let check = checkProperties(params);
    if (check) {
      getData();
    }
  }, [params]);

  const handleChange = ({ target: { value, name } }) => {
    if (name === "kehadiran_id" && value) {
      const cek = topik?.filter(
        (item) => item.kehadiran_id?.toString() === value?.toString()
      );
      setDetailTopik(null);
      if (value.length > 0) {
        setDetailTopik(cek[0]);
      }
    }
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };

  const handleChangeKelas = ({ target: { value } }) => {
    let dataKelas = kelas?.filter(
      (item) => parseInt(item.id) === parseInt(value)
    );
    setParams((pref) => ({
      ...pref,
      kelasdetail_id: dataKelas.length > 0 ? dataKelas[0].kelasdetail_id : "",
      tahunpelajaran_id:
        dataKelas.length > 0 ? dataKelas[0].tahunpelajaran_id : "",
    }));
    setSelectedKelas(value);
  };

  return (
    <div>
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title d-flex justify-content-between align-items-center w-100">
            <h4 className="card-title">Filter {t("kelas")}</h4>
            <Link
              to={`/`}
              className="btn iq-bg-light iq-bg-primary-hover text-dark border"
            >
              <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
            </Link>
          </div>
        </div>
        <div className="iq-card-body">
          <div className="row">
            <div className="col-12 col-md-3">
              <label htmlFor="kelasdetail_id" className="control-label">
                {t("kelas")} :
              </label>
              <select
                id="kelasdetail_id"
                className="form-control form-select"
                name="kelasdetail_id"
                onChange={handleChangeKelas}
                value={selectedKelas}
              >
                <option value="">
                  --{t("pilih_params", { params: t("kelas") })}--
                </option>
                {kelas?.map((e) => (
                  <option value={e.id} key={e.id}>
                    {e.kelasdetail_nama} ({e?.tahunpelajaran_nama})
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-md-3">
              <label htmlFor="semester" className="control-label">
                Semester :
              </label>
              <select
                className="form-control form-select"
                name="semester_id"
                id="semester_id"
                onChange={handleChange}
                value={params.semester_id}
              >
                <option value="">
                  --{t("pilih_params", { params: "Semester" })}--
                </option>
                {semester?.map((e) => (
                  <option value={e.semester_id} key={e.semester_id}>
                    Semester {e.semester_nama}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-md-3">
              <label htmlFor="mapel" className="control-label">
                {t("mata_pelajaran")} :
              </label>
              <select
                className="form-control form-select"
                name="mapel_id"
                id="mapel_id"
                onChange={handleChange}
                value={params.mapel_id}
              >
                <option value="">
                  --{t("pilih_params", { params: t("mata_pelajaran") })}--
                </option>
                {mapel?.map((e) => (
                  <option value={e.kelasmapel_id} key={e.kelasmapel_id}>
                    {e?.mapel?.mapel_nama}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-md-3">
              <label htmlFor="topik" className="control-label">
                {t("topik")} :
              </label>
              <select
                className="form-control form-select"
                name="kehadiran_id"
                id="kehadiran_id"
                onChange={handleChange}
                value={params.kehadiran_id}
              >
                <option value="">
                  --{t("pilih_params", { params: "topik" })}--
                </option>
                {topik?.map((e) => (
                  <option value={e.kehadiran_id} key={e.kehadiran_id}>
                    {e.kehadiran_topik} - Tanggal:
                    {moment(e?.kehadiran_tanggal.substr(0, 10)).format(
                      " DD MMMM YYYY"
                    )}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title">
            <h4 className="card-title">
              {t("absensi_guru_mapel")}
              {detailTopik && (
                <span className="badge badge-pill badge-success ms-1">
                  {moment(detailTopik?.kehadiran_tanggal.substr(0, 10)).format(
                    "DD MMMM YYYY"
                  )}
                </span>
              )}
            </h4>
          </div>
        </div>
        <div className="iq-card-body">
          <div className="tab-content" id="myTabContent-5">
            <div className="table-responsive">
              <table
                id="datatable"
                className="table table-hover table-bordered"
              >
                <thead>
                  <tr>
                    <th scope="col" width="5%" className="text-center">
                      No
                    </th>
                    <th scope="col" width="30%">
                      {t("nama_siswa")}
                    </th>
                    <th
                      scope="col"
                      width="5%"
                      className="text-center iq-bg-primary"
                    >
                      {t("hadir")}
                    </th>
                    <th
                      scope="col"
                      width="5%"
                      className="text-center iq-bg-success"
                    >
                      {t("izin")}
                    </th>
                    <th
                      scope="col"
                      width="5%"
                      className="text-center iq-bg-danger"
                    >
                      Alpa
                    </th>
                    <th
                      scope="col"
                      width="5%"
                      className="text-center iq-bg-warning"
                    >
                      {t("sakit")}
                    </th>
                    <th scope="col" width="40%">
                      {t("catatan_guru")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {siswa?.length <= 0 ? (
                    <tr className="text-center">
                      <td colSpan={7}>{t("data_kosong")}</td>
                    </tr>
                  ) : (
                    siswa?.map((item, idx) => (
                      <tr key={item.userkelas_userid}>
                        <td className="text-center">{idx + 1}</td>
                        <td>
                          <b>{item?.user?.user_nama}</b>
                          {item?.kehadiran_nilai === "I" && (
                            <span className="badge badge-pill badge-primary">
                              {t("izin")}
                            </span>
                          )}
                        </td>
                        <td className="text-center">
                          <div className="radio d-inline-block">
                            <input
                              className="rabsen"
                              type="radio"
                              name={`absensi-${idx}`}
                              id={`radio-${idx}`}
                              checked={item?.kehadiran_nilai === "H"}
                              disabled={item?.kehadiran_nilai !== "H"}
                            />
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="radio d-inline-block">
                            <input
                              className="rabsen"
                              type="radio"
                              name={`absensi-${idx}`}
                              id={`radio-${idx}`}
                              checked={item?.kehadiran_nilai === "I"}
                              disabled={item?.kehadiran_nilai !== "I"}
                            />
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="radio d-inline-block">
                            <input
                              className="rabsen"
                              type="radio"
                              name={`absensi-${idx}`}
                              id={`radio-${idx}`}
                              checked={item?.kehadiran_nilai === "A"}
                              disabled={item?.kehadiran_nilai !== "A"}
                            />
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="radio d-inline-block">
                            <input
                              className="rabsen"
                              type="radio"
                              name={`absensi-${idx}`}
                              id={`radio-${idx}`}
                              checked={item?.kehadiran_nilai === "S"}
                              disabled={item?.kehadiran_nilai !== "S"}
                            />
                          </div>
                        </td>
                        <td>{item.kehadiran_keterangan}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AbsensiMapel;
