import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import { ModalDetailWeekly, ModalPekan } from "../../../components/modal";
import {
  getAbsensiKelas,
  getAbsensiSemester,
  getPekanAbsensi,
  getWeeklyAbsensi,
  submitAbsensiWeekly,
  updPublishPekan,
} from "../../../redux/actions/absenAction";
import { Link } from "react-router-dom";

function Weekly() {
  const { t } = useTranslation();
  const [kelas, setKelas] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [openPekan, setOpenPekan] = useState(false);
  const [openDetailPekan, setOpenDetailPekan] = useState(null);
  const [selectedPekan, setSelectedPekan] = useState(null);
  const [semester, setSemester] = useState([]);
  const [pekan, setPekan] = useState([]);
  const [siswa, setSiswa] = useState([]);
  const [selectedKelas, setSelectedKelas] = useState(null);
  const [params, setParams] = useState({
    semester_id: "",
    tahunpelajaran_id: "",
    kelasdetail_id: "",
    pekan_id: "",
    weekly_type: "walas",
  });
  function checkProperties(obj) {
    for (let key in obj) {
      if (obj[key] === null || obj[key] === "") return false;
    }
    return true;
  }
  useEffect(() => {
    const date = new Date();
    const tgl = moment(date).format("YYYY-MM-DD");
    setParams((pref) => ({
      ...pref,
      kehadiran_tanggal: tgl,
    }));
    function getData() {
      getAbsensiKelas().then((res) => setKelas(res));
    }
    getData();
  }, []);
  useEffect(() => {
    setSemester([]);
    function getData() {
      let check = kelas.filter(
        (item) => parseInt(item.id) === parseInt(selectedKelas)
      );
      let data = {
        kelasdetail_id: check[0]?.kelasdetail_id,
        tahunpelajaran_id: check[0]?.tahunpelajaran_id,
      };
      getAbsensiSemester(data).then((res) => setSemester(res));
    }
    if (selectedKelas) {
      getData();
    }
  }, [selectedKelas, kelas]);
  const getPekan = () => {
    const data = {
      kelasdetail_id: params.kelasdetail_id,
      semester_id: params.semester_id,
      tahunpelajaran_id: params.tahunpelajaran_id,
    };
    getPekanAbsensi(data).then((res) => setPekan(res));
  };
  useEffect(() => {
    setPekan([]);
    if (params.kelasdetail_id && params.semester_id) {
      getPekan();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  useEffect(() => {
    function getData() {
      getWeeklyAbsensi(params).then((res) => {
        let allSiswa = res?.map((item) => ({
          userkelas_userid: item.userkelas_userid,
          weekly_catatan_walas: item?.weekly_walas?.weekly_catatan_walas,
          user: item?.user,
          weekly_pekanid: item?.weekly_walas?.weekly_pekanid || crypto.randomUUID(),
        }));
        setSiswa(allSiswa);
      });
    }
    let check = checkProperties(params);
    if (check) {
      getData();
    }
  }, [params]);

  const handleChange = ({ target: { value, name } }) => {
    if (name === "pekan_id" && value) {
      const cek = pekan?.filter(
        (item) => parseInt(item.pekan_id) === parseInt(value)
      );
      setSelectedPekan(cek[0]);
    } else {
      setSelectedPekan(null);
    }
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };
  const handleChangeKelas = ({ target: { value } }) => {
    let dataKelas = kelas?.filter(
      (item) => parseInt(item.id) === parseInt(value)
    );
    setParams((pref) => ({
      ...pref,
      tahunpelajaran_id:
        dataKelas.length > 0 ? dataKelas[0].tahunpelajaran_id : "",
      kelasdetail_id: dataKelas.length > 0 ? dataKelas[0].kelasdetail_id : "",
    }));
    setSelectedKelas(value);
  };
  const handleChangeSiswa = (idx, name, value) => {
    let allSiswa = siswa;
    allSiswa[idx][name] = value;
    setSiswa([...allSiswa]);
  };
  const showAlert = (type, title, text) => {
    Swal.fire({
      title: title,
      text: text,
      icon: type,
      imageWidth: 92,
      imageHeight: 92,
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };
  const handleSubmitAbsensi = () => {
    setSubmit(true);
    let data = {
      ...params,
      siswa,
    };
    submitAbsensiWeekly("walas", data)
      .then((res) => {
        setSubmit(false);
        showAlert(
          "success",
          `${t("absensi")} ${t("berhasil")}`,
          t("berhasil_simpan", { params: t("absensi") })
        );
      })
      .catch((err) => {
        setSubmit(false);
        showAlert(
          "error",
          `${t("absensi")} ${t("gagal")}`,
          t("failed_save_data", { data: t("absensi") })
        );
      });
  };
  const handlePublishAbsensi = () => {
    setSubmit(true);
    const status =
      selectedPekan?.pekan_publish === "publish" ? "unpublish" : "publish";
    let data = {
      pekan_id: selectedPekan?.pekan_id,
      pekan_publish: status,
    };
    updPublishPekan(data)
      .then(() => {
        setSelectedPekan((pref) => ({
          ...pref,
          pekan_publish: status,
        }));
        setSubmit(false);
        showAlert(
          "success",
          t("berhasil"),
          t(
            status === "publish"
              ? "absensi_berhasil_publish"
              : "absensi_berhasil_unpublish"
          )
        );
      })
      .catch((err) => {
        setSubmit(false);
        showAlert(
          "error",
          t("gagal"),
          t("gagal_status_absensi", { status: status })
        );
      });
  };
  return (
    <div>
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title d-flex w-100 align-items-center justify-content-between">
            <h4 className="card-title">Filter {t("kelas")}</h4>
            <Link
              to={`/`}
              className="btn iq-bg-light iq-bg-primary-hover text-dark border"
            >
              <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
            </Link>
          </div>
        </div>
        <div className="iq-card-body">
          <div className="row">
            <div className="col-12 col-md-4">
              <label htmlFor="kelasdetail_id" className="control-label">
                {t("kelas")} :
              </label>
              <select
                id="kelasdetail_id"
                className="form-control form-select"
                name="kelasdetail_id"
                onChange={handleChangeKelas}
                value={selectedKelas}
              >
                <option value="">
                  --{t("pilih_params", { params: t("kelas") })}--
                </option>
                {kelas?.map((e) => (
                  <option value={e.id} key={e.id}>
                    {e.kelasdetail_nama} ({e?.tahunpelajaran_nama})
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-md-4">
              <label htmlFor="semester" className="control-label">
                Semester :
              </label>
              <select
                className="form-control form-select"
                name="semester_id"
                id="semester_id"
                onChange={handleChange}
                value={params.semester_id}
              >
                <option value="">
                  --{t("pilih_params", { params: "Semester" })}--
                </option>
                {semester?.map((e) => (
                  <option value={e.semester_id} key={e.semester_id}>
                    Semester {e.semester_nama}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-md-4">
              <label htmlFor="pekan_id" className="control-label">
                {t("pekan")} :
              </label>
              <div className="d-flex gap-1">
                <select
                  className="form-control form-select"
                  name="pekan_id"
                  id="pekan_id"
                  value={params.pekan_id}
                  onChange={handleChange}
                >
                  <option value="">
                    --{t("pilih_params", { params: t("pekan") })}--
                  </option>
                  {pekan?.map((item) => (
                    <option value={item.pekan_id} key={item.pekan_id}>
                      {item.pekan_nama}
                    </option>
                  ))}
                </select>
                {params.kelasdetail_id && params.semester_id && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setOpenPekan(true)}
                  >
                    <i className="ri-add-line m-0"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title">
            <h4 className="card-title">{t("weekly_walas")}</h4>
          </div>
        </div>
        <div className="iq-card-body">
          <div className="table-responsive">
            <table id="datatable" className="table table-bordered">
              <thead className="table-primary text-white">
                <tr>
                  <th className="bg-transparent" width="5%">
                    No
                  </th>
                  <th className="bg-transparent" width="20%">
                    {t("nama_siswa")}
                  </th>
                  <th className="bg-transparent" width="10%">
                    {t("penilaian")}
                  </th>
                  <th className="bg-transparent">{t("catatan")}</th>
                </tr>
              </thead>
              <tbody>
                {siswa?.length <= 0 ? (
                  <tr>
                    <td colSpan={4} className="text-center">
                      {t("data_kosong")}
                    </td>
                  </tr>
                ) : (
                  siswa?.map((item, idx) => (
                    <tr key={item.userkelas_userid}>
                      <td>{idx + 1}</td>
                      <td>{item?.user?.user_nama}</td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => setOpenDetailPekan(item)}
                        >
                          Detail
                        </button>
                      </td>
                      <td>
                        <textarea
                          key={item?.weekly_pekanid}
                          className="form-control h-auto"
                          rows="3"
                          placeholder="Catatan"
                          value={item?.weekly_catatan_walas}
                          onChange={({ target: { value } }) =>
                            handleChangeSiswa(
                              idx,
                              "weekly_catatan_walas",
                              value
                            )
                          }
                        ></textarea>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <div className="d-flex gap-2 justify-content-end">
              <button
                type="button"
                disabled={submit}
                className="btn btn-primary"
                onClick={handleSubmitAbsensi}
              >
                {t("save_changes")}
              </button>
              {selectedPekan?.pekan_publish && (
                <button
                  type="button"
                  disabled={submit}
                  className="btn btn-primary"
                  onClick={handlePublishAbsensi}
                >
                  {selectedPekan?.pekan_publish === "publish"
                    ? "Unpublish"
                    : "Publish"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalPekan
        show={openPekan}
        handleClose={() => {
          setOpenPekan(false);
        }}
        kelasdetail_id={params.kelasdetail_id}
        semester_id={params.semester_id}
        pekan={pekan}
        getPekan={getPekan}
        tahunpelajaran_id={params.tahunpelajaran_id}
      />
      {openDetailPekan && (
        <ModalDetailWeekly
          handleClose={() => setOpenDetailPekan(null)}
          pekan_id={params.pekan_id}
          show={openDetailPekan}
          userId={openDetailPekan?.userkelas_userid}
        />
      )}
    </div>
  );
}

export default Weekly;
