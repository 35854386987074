import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ItemFile } from "..";
import { LoadingGreen } from "../../assets/images";
import { uploadFilePengerjaanUjian } from "../../redux/actions/ujianAction";

function FilePengerjaanUjian({ dataFile, handleUpd }) {
  const fileInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const handleChangeFile = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (files.length > 0) {
      var formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`files`, files[i]);
      }
      setLoading(true);
      uploadFilePengerjaanUjian(formData)
        .then((res) => {
          setLoading(false);
          let allFile = [...dataFile, ...res?.data?.data];
          handleUpd(allFile);
          fileInput.current.value = "";
        })
        .catch((err) => {
          setLoading(false);
          console.log("err", err);
        });
    }
  };
  const handleDeleteFile = (idx) => {
    let allFile = dataFile?.filter((item, index) => idx !== index);
    handleUpd(allFile);
  };
  return (
    <div className="iq-card">
      <div className="iq-card-body">
        <div className="form-group row align-items-center">
          <div className="d-flex flex-grow-1 align-items-center">
            <div className="send-panel d-flex">
              <div className="me-2 d-flex align-items-center justify-content-between flex-column">
                <label
                  className="mb-2 rounded-circle border border-primary iq-bg-primary-hover text-primary square-48"
                  htmlFor="files"
                >
                  <input
                    type="file"
                    name="files"
                    id="files"
                    style={{ display: "none" }}
                    accept="
                            image/jpeg, 
                            image/png, 
                            image/jpg, 
                            .pptx,
                            .xlsx,
                            .xls,
                            .doc,
                            .docx,
                            .ppt,
                            .pdf,
                            audio/*
                            "
                    onChange={handleChangeFile}
                    multiple
                    ref={fileInput}
                    disabled={loading}
                  />
                  <button
                    type="button"
                    disabled={loading}
                    onClick={() => fileInput?.current?.click()}
                    className="m-2 border-0 bg-transparent d-flex align-items-center"
                  >
                    {loading ? (
                      <img
                        src={LoadingGreen}
                        alt="loading"
                        width={16}
                        height={16}
                      />
                    ) : (
                      <i className="ri-upload-2-line m-0 text-primary"></i>
                    )}
                  </button>
                </label>
                <p className="fw-bold font-size-12 text-center m-0">
                  {t("unggah")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="form-group row">
          {dataFile?.map((item, i) => (
            <div className="col-lg-12" key={i}>
              <ItemFile
                name={item.filename}
                type={item.type}
                url={item.path}
                handleDeleteFile={() => handleDeleteFile(i)}
                canDelete
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FilePengerjaanUjian;
