import axios from 'axios';

// export const URL = "https://buildlms.superipey.id/";
export const URL = process.env.REACT_APP_BACKEND_API_URL;

export const API = axios.create({
	baseURL: URL,
});

export const setAuthToken = (token) => {
	API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	API.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
	API.defaults.headers.common["Content-Type"] = "application/json";
};
