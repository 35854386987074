import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import { CardCourseSlider } from "../card";
import { getMoreCourse } from "../../redux/actions/courseAction";
import { Spinner } from "react-bootstrap";

function SliderCourseLain({ category }) {
  const [dataCourse, setDataCourse] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!category) return;
    const params = {
      category_id: category,
      page: 1,
      limit: 4,
    };
    setLoading(true);
    getMoreCourse(params)
      .then((res) => {
        setDataCourse(res?.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [category]);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      {loading && dataCourse.length <= 0 ? (
        <div className="my-5 text-center">
          <Spinner
            animation="border"
            role="status"
            variant="secondary"
            className="mx-auto"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Slider {...settings} className="slider-course">
          {dataCourse?.map((item) => (
            <div className="p-2" key={item.course_id}>
              <CardCourseSlider
                author={item.course_mentor?.mentor?.mentor_name}
                discount={item.course_diskon}
                id={item.course_id}
                lesson={item.topik_count}
                level={item.course_level}
                price={item.course_price}
                thumbnail={item.course_thumbnail}
                title={item.course_title}
                type={item.course_type}
              />
            </div>
          ))}
        </Slider>
      )}
    </>
  );
}

export default SliderCourseLain;
