import React from "react";
import { PageLoader } from "../../assets/images";
import { CardMyCourse } from "../card";
import { useTranslation } from "react-i18next";

const ListCourseUser = ({ loading, page, data, next, handleNext, title }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="page-separator mb-0 mt-3">
        <div className="page-separator__text card-title">{t(title)}</div>
      </div>
      <div className="row mb-5">
        {loading && page === 1 ? (
          <div className="col-12 text-center py-4">
            <img
              src={PageLoader}
              alt="loader"
              style={{ height: "70px" }}
              className="mx-auto"
            />
          </div>
        ) : data?.length <= 0 ? (
          <p className="text-center py-4">
            {t("data_params_kosong", { params: t("kursus") })}
          </p>
        ) : (
          data?.map((item) => (
            <CardMyCourse
              courseId={item.course_id}
              level={item.course_level}
              progress={item?.joined?.join_status}
              thumbnail={item.course_thumbnail}
              title={item.course_title}
              totalLesson={item.topik_count}
              key={item.course_id}
              joinStatus={item?.joined?.invoice?.invoice_status}
              lastView={item?.joined?.join_last_view}
            />
          ))
        )}
        {next ? (
          <div className="col-12">
            <button
              disabled={loading}
              onClick={handleNext}
              className="btn btn-primary text-white fw-semibold rounded-pill p-12 w-100 my-4"
            >
              {loading ? t("loading") + "..." : t("muat_lainnya")}
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ListCourseUser;
