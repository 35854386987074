import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { useDispatch, useSelector } from "react-redux";
import { PageLoader } from "../../assets/images";
import {
  getListSiswa,
  getPelajaran,
} from "../../redux/actions/pelajaranAction";
import {
  addUjian,
  getDetailUjian,
  getKolaborasi,
  updUjian,
} from "../../redux/actions/ujianAction";
import { InputEditor, SelectKolaborasi } from "../input";
import SelectMultiple from "../input/SelectMultiple";

function ModalUjian({
  show,
  handleClose,
  editUjian,
  tahunPelajaran,
  semesterId,
  kelasId,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const { pelajaran } = useSelector(({ pelajaran }) => pelajaran);
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(null);
  const [dataSiswa, setDataSiswa] = useState([]);
  const [dataKolaborasi, setDataKolaborasi] = useState([]);
  const [params, setParams] = useState({
    kelasmapel_id: "",
    tahunpelajaran_id: "",
    semester_id: "",
    ujian_mulai: "",
    ujian_selesai: "",
    ujian_acak_soal: 0,
    ujian_acak_jawaban: 0,
    ujian_deskripsi: "",
    ujian_jenis_waktu: "perujian",
    ujian_waktu_pengerjaan: "0",
    ujian_waktu_mengerjakan: "mulai-siswa",
    ujian_jenis: "sts",
    ujian_status: "draft",
    ujian_allsiswa: false,
    list_siswa: [],
    list_kolaborasi: [],
  });
  const showAlert = (text, status, lanjutkan, ujian_id, kelas_id) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    }).then((res) => {
      if (res.isConfirmed && lanjutkan) {
        if (editUjian) {
          navigate(`/ujian/soal/${editUjian}/${kelas_id}`);
          return;
        }
        navigate(`/ujian/soal/${ujian_id}/${kelas_id}`);
      }
    });
  };
  const handleChange = ({ target: { value, name } }) => {
    if (name === "ujian_jenis_waktu" && value) {
      if (value === "persoal")
        setParams((pref) => ({
          ...pref,
          ujian_waktu_pengerjaan: "0",
        }));
    }
    if (name === "ujian_waktu_mengerjakan" && value === "akhir-ujian") {
      setParams((pref) => ({
        ...pref,
        ujian_jenis_waktu: "perujian",
        ujian_waktu_pengerjaan: "0",
      }));
    }
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };
  const handleSubmit = (lanjutkan) => {
    setSubmit(true);
    let data = {
      ...params,
      ujian_deskripsi: editorRef.current.getContent(),
      list_siswa: params.ujian_allsiswa ? [] : params.list_siswa,
      ujian_acak_soal: params.ujian_acak_soal ? 1 : 0,
      ujian_acak_jawaban: params.ujian_acak_jawaban ? 1 : 0,
    };

    if (editUjian) {
      updUjian(editUjian, data)
        .then((res) => {
          showAlert(
            t("berhasil_simpan", { params: t("ujian") }),
            true,
            lanjutkan,
            kelasId
          );
          handleClose(true);
          setSubmit(false);
        })
        .catch((err) => {
          setError(err?.response?.data?.error);
          setSubmit(false);
        });
    } else {
      addUjian(data)
        .then((res) => {
          showAlert(
            t("berhasil_simpan", { params: t("ujian") }),
            true,
            lanjutkan,
            res?.data?.data?.ujian_id,
            res?.data?.data?.kelas_mapel?.kelasmapel_kelasdetailid
          );
          handleClose(true);
          setSubmit(false);
        })
        .catch((err) => {
          setError(err?.response?.data?.error);
          setSubmit(false);
        });
    }
  };
  const handleChangeSiswa = (name, value) => {
    const valueString = value?.toString();
    const newList = [...params.list_siswa];
    const index = newList.findIndex((item) => item.toString() === valueString);
    if (name === "all" && value) {
      setParams((pref) => ({
        ...pref,
        list_siswa: dataSiswa?.map((item) => item?.user?.user_id?.toString()),
        ujian_allsiswa: true,
      }));
      return;
    } else if (name === "all") {
      setParams((pref) => ({ ...pref, list_siswa: [], ujian_allsiswa: false }));
      return;
    }

    if (index !== -1) {
      newList.splice(index, 1);
      setParams((pref) => ({
        ...pref,
        list_siswa: newList,
        ujian_allsiswa: false,
      }));
    } else {
      setParams((pref) => ({
        ...pref,
        list_siswa: [...pref.list_siswa, valueString],
        ujian_allsiswa: false,
      }));
    }
  };
  const handleChangeKolaborasi = (value) => {
    setParams((pref) => ({
      ...pref,
      list_kolaborasi: value,
    }));
  };
  useEffect(() => {
    if (params.kelasmapel_id) {
      const data = {
        tahunpelajaran_id: tahunPelajaran,
        semester_id: semesterId,
      };
      const idxData = pelajaran.findIndex(
        (item) =>
          parseInt(item.kelasmapel_id) === parseInt(params.kelasmapel_id)
      );
      if (idxData === -1) {
        setDataSiswa([]);
        setParams((pref) => ({
          ...pref,
          list_siswa: [],
          ujian_allsiswa: true,
        }));
      } else {
        let kelasId = pelajaran[idxData].kelasmapel_kelasdetailid;
        getListSiswa(kelasId, data).then(({ data }) => {
          if (data?.data) {
            setDataSiswa(data?.data);
          }
        });
      }
      let paramsKolaborasi = {
        kelasmapel_id: params.kelasmapel_id,
      };
      if (tahunPelajaran) paramsKolaborasi.tahunpelajaran_id = tahunPelajaran;
      if (semesterId) paramsKolaborasi.semester_id = semesterId;

      getKolaborasi(paramsKolaborasi).then(({ data }) =>
        setDataKolaborasi(
          data?.data?.map((item) => ({
            ...item?.user,
            kelasdetail_id: item?.kelasMapel?.kelasmapel_kelasdetailid,
            kelas_nama: item?.kelasMapel?.detailKelas?.kelasdetail_nama,
          }))
        )
      );
    } else {
      setDataSiswa([]);
      setParams((pref) => ({
        ...pref,
        list_siswa: [],
        ujian_allsiswa: true,
      }));
    }
  }, [params.kelasmapel_id, tahunPelajaran, semesterId, pelajaran]);
  useEffect(() => {
    if (params.list_siswa.length <= 0 && !editUjian) {
      handleChangeSiswa("all", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSiswa]);
  const formatDate = (value) => {
    let result = value;
    if (value) {
      result = result?.substr(0, 19)?.replace("T", " ");
    }
    return result;
  };
  useEffect(() => {
    if (editUjian) {
      getDetailUjian(editUjian, kelasId, true).then((res) => {
        let data = res?.data?.data;
        setParams((pref) => ({
          ...pref,
          kelasmapel_id: data?.kelasmapel_id,
          tahunpelajaran_id: data?.tahunpelajaran_id,
          semester_id: data?.semester_id,
          ujian_mulai: formatDate(data?.ujian_mulai),
          ujian_selesai: formatDate(data?.ujian_selesai),
          ujian_acak_soal: data?.ujian_acak_soal ? 1 : 0,
          ujian_acak_jawaban: data?.ujian_acak_jawaban ? 1 : 0,
          ujian_deskripsi: data?.ujian_deskripsi,
          ujian_jenis_waktu: data?.ujian_jenis_waktu,
          ujian_waktu_pengerjaan: data?.ujian_waktu_pengerjaan,
          ujian_waktu_mengerjakan: data?.ujian_waktu_mengerjakan,
          ujian_jenis: data?.ujian_jenis,
          ujian_status: data?.ujian_status,
          ujian_allsiswa: data?.ujian_allsiswa,
          list_siswa: data?.siswas?.map((item) => item.user_id.toString()),
          list_kolaborasi: data?.kolaborasi?.map((item) => ({
            ...item?.user,
            ...item?.kelas,
          })),
        }));
      });
    }
  }, [editUjian, kelasId]);
  useEffect(() => {
    let paramsKolaborasi = {};
    if (tahunPelajaran) paramsKolaborasi.tahunpelajaran_id = tahunPelajaran;
    if (semesterId) paramsKolaborasi.semester_id = semesterId;
    dispatch(getPelajaran(paramsKolaborasi));
  }, [dispatch, tahunPelajaran, semesterId]);

  return (
    <div className="col-lg-12">
      <Modal
        fullscreen={true}
        enforceFocus={false}
        show={show}
        onHide={() => {
          if (!submit) handleClose();
        }}
        id="modalUjian"
        backdrop="static"
      >
        <Modal.Header>
          <div className="d-flex align-items-center">
            <button
              className="border-0 bg-white btn-close m-0 p-2 shadow-none"
              onClick={handleClose}
            ></button>
            <h4 className="modal-title">
              {editUjian ? "Edit" : t("tambah")} {t("ujian")}
            </h4>
          </div>
          <Dropdown align="end">
            <Dropdown.Toggle
              variant="success"
              className="btn-primary border-0"
              id="dropdown-basic"
              disabled={submit}
            >
              {submit ? (
                <img src={PageLoader} alt="loader" style={{ width: 15 }} />
              ) : (
                t("simpan")
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                disabled={submit}
                onClick={() => handleSubmit(false)}
              >
                {t("simpan")}
              </Dropdown.Item>
              <Dropdown.Item
                disabled={submit}
                onClick={() => handleSubmit(true)}
              >
                {t("simpan_lanjutkan")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Modal.Header>
        <Modal.Body className="p-0 overflow-x-hidden">
          <div className="row h-100">
            <div className="col-lg-7 offset-md-1 pt-4">
              <div className="iq-card w-modal mb-4">
                <div className="iq-card-body">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="ujian_deskripsi">{t("desc")} :</label>
                    <InputEditor
                      editorRef={editorRef}
                      value={params.ujian_deskripsi}
                    />
                    {error?.ujian_deskripsi && (
                      <div className="d-block invalid-feedback">
                        {error?.ujian_deskripsi}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 offset-md-1">
              <div className="iq-card h-100">
                <div className="iq-card-body h-100">
                  <div className="col-lg-12 mb-3">
                    <label htmlFor="kelasmapel_id">
                      {t("kelas")} & {t("mata_pelajaran")}{" "}
                      <span className="text-danger">*</span> :
                    </label>
                    <select
                      className="form-control form-select"
                      name="kelasmapel_id"
                      id="kelasmapel_id"
                      value={params.kelasmapel_id}
                      onChange={handleChange}
                    >
                      <option value="">
                        {t("pilih_params", {
                          params: `${t("kelas")} ${t("dan")} ${t(
                            "mata_pelajaran"
                          )}`,
                        })}
                      </option>
                      {pelajaran?.map((item) => (
                        <option
                          value={item.kelasmapel_id}
                          key={item.kelasmapel_id}
                        >{`${item.detailKelas.kelasdetail_nama} - ${item.mapel.mapel_nama}`}</option>
                      ))}
                    </select>
                    {error?.kelasmapel_id && (
                      <div className="d-block invalid-feedback">
                        {error?.kelasmapel_id}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-12 mb-3">
                    <SelectKolaborasi
                      dataOption={dataKolaborasi}
                      dataSelected={params?.list_kolaborasi}
                      selectedAll={false}
                      handleChange={handleChangeKolaborasi}
                    />
                  </div>
                  <div className="col-lg-12 mb-3">
                    <SelectMultiple
                      dataSiswa={dataSiswa}
                      selectedSiswa={params?.list_siswa}
                      selectedAll={params?.ujian_allsiswa}
                      handleChange={handleChangeSiswa}
                    />
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label htmlFor="ujian_mulai">
                      {t("tgl_mulai")} <span className="text-danger">*</span> :
                    </label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      id="ujian_mulai"
                      value={params.ujian_mulai}
                      onChange={handleChange}
                      name="ujian_mulai"
                    />
                    {error?.ujian_mulai && (
                      <div className="d-block invalid-feedback">
                        {error?.ujian_mulai}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label htmlFor="ujian_selesai">
                      {t("tgl_selesai")} <span className="text-danger">*</span>{" "}
                      :
                    </label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      id="ujian_selesai"
                      name="ujian_selesai"
                      value={params.ujian_selesai}
                      onChange={handleChange}
                    />
                    {error?.ujian_selesai && (
                      <div className="d-block invalid-feedback">
                        {error?.ujian_selesai}
                      </div>
                    )}
                  </div>
                  <hr />
                  <div className="col-lg-12 mb-3">
                    <div className="row">
                      <label htmlFor="ujian_acak_soal" className="col-12">
                        {t("acak")}:
                      </label>
                      <div className="col-12 d-flex">
                        <div className="custom-control custom-checkbox custom-control-inline">
                          <input
                            type="checkbox"
                            id="ujian_acak_soal"
                            name="ujian_acak_soal"
                            className="custom-control-input"
                            checked={params.ujian_acak_soal}
                            onChange={({ target: { checked } }) =>
                              handleChange({
                                target: {
                                  name: "ujian_acak_soal",
                                  value: checked,
                                },
                              })
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="ujian_acak_soal"
                          >
                            {t("soal")}
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox custom-control-inline">
                          <input
                            type="checkbox"
                            id="ujian_acak_jawaban"
                            name="ujian_acak_jawaban"
                            className="custom-control-input"
                            checked={params.ujian_acak_jawaban}
                            onChange={({ target: { checked } }) =>
                              handleChange({
                                target: {
                                  name: "ujian_acak_jawaban",
                                  value: checked,
                                },
                              })
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="ujian_acak_jawaban"
                          >
                            {t("jawaban")}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="col-lg-12 mb-3">
                    <label htmlFor="ujian_waktu_mengerjakan">
                      {t("waktu_mengerjakan")}{" "}
                      <span className="text-danger">*</span> :
                    </label>
                    <select
                      className="form-control form-select"
                      id="ujian_waktu_mengerjakan"
                      name="ujian_waktu_mengerjakan"
                      value={params.ujian_waktu_mengerjakan}
                      onChange={handleChange}
                    >
                      <option value="mulai-siswa">{t("mulai-siswa")}</option>
                      <option value="mulai-ujian">{t("mulai-ujian")}</option>
                      <option value="akhir-ujian">{t("akhir-ujian")}</option>
                    </select>
                  </div>
                  {params.ujian_waktu_mengerjakan !== "akhir-ujian" && (
                    <>
                      <hr />
                      <div className="col-lg-12 mb-3">
                        <div className="row">
                          <label htmlFor="perujian" className="col-12">
                            {t("waktu_pengerjaan")}{" "}
                            <span className="text-danger">*</span> :
                          </label>
                          <div className="col-12 d-flex">
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                required
                                type="radio"
                                id="perujian"
                                name="ujian_jenis_waktu"
                                className="custom-control-input"
                                checked={
                                  params.ujian_jenis_waktu === "perujian"
                                }
                                onChange={({ target: { value } }) =>
                                  handleChange({
                                    target: {
                                      name: "ujian_jenis_waktu",
                                      value: value ? "perujian" : "",
                                    },
                                  })
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="perujian"
                              >
                                {t("perujian")}
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                required
                                type="radio"
                                id="persoal"
                                name="ujian_jenis_waktu"
                                className="custom-control-input"
                                checked={params.ujian_jenis_waktu === "persoal"}
                                onChange={({ target: { value } }) =>
                                  handleChange({
                                    target: {
                                      name: "ujian_jenis_waktu",
                                      value: value ? "persoal" : "",
                                    },
                                  })
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="persoal"
                              >
                                {t("persoal")}
                              </label>
                            </div>
                          </div>
                          {params.ujian_jenis_waktu === "perujian" ? (
                            <div className="col-12 mt-2 d-flex">
                              <input
                                type="number"
                                className="form-control"
                                id="ujian_waktu_pengerjaan"
                                placeholder="0"
                                value={params.ujian_waktu_pengerjaan}
                                onChange={handleChange}
                                name="ujian_waktu_pengerjaan"
                              />
                              <span className="ms-3 me-1 align-self-center">
                                {t("menit")}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </>
                  )}
                  <hr />
                  <div className="col-lg-12">
                    <div className="row">
                      <label htmlFor="sts" className="col-12">
                        {t("ujian")} <span className="text-danger">*</span> :
                      </label>
                      <div className="col-12 d-flex">
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="sts"
                            name="ujian_jenis"
                            className="custom-control-input"
                            checked={params.ujian_jenis === "sts"}
                            onChange={({ target: { value } }) =>
                              handleChange({
                                target: {
                                  name: "ujian_jenis",
                                  value: value ? "sts" : "",
                                },
                              })
                            }
                          />
                          <label className="custom-control-label" htmlFor="sts">
                            STS
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="sas"
                            name="ujian_jenis"
                            className="custom-control-input"
                            checked={params.ujian_jenis === "sas"}
                            onChange={({ target: { value } }) =>
                              handleChange({
                                target: {
                                  name: "ujian_jenis",
                                  value: value ? "sas" : "",
                                },
                              })
                            }
                          />
                          <label className="custom-control-label" htmlFor="sas">
                            SAS
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalUjian;
