import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { checkIsGuru, checkIsOrtu } from "../../../utils/roleUser";
import { getJadwal } from "../../../redux/actions/kalenderAction";
import { useTranslation } from "react-i18next";
import { PageLoader } from "../../../assets/images";
import { Link } from "react-router-dom";

export const JadwalSection = () => {
  const { t } = useTranslation();
  const { user } = useSelector(({ user }) => user);
  const [dataJadwal, setDataJadwal] = useState([]);
  const { selectedAnak } = useSelector(({ anak }) => anak);
  const [loading, setLoading] = useState(false);
  const IsGuru = checkIsGuru(user?.user_roleid);
  useEffect(() => {
    const checkOrtu = checkIsOrtu(user.user_roleid);
    if (checkOrtu && !selectedAnak) return;
    setLoading(true);
    let user_id = checkOrtu ? selectedAnak?.user_id : user?.user_id;
    getJadwal({ user_id: user_id }).then((res) => {
      setLoading(false);
      setDataJadwal(res?.data);
    });
  }, [user, selectedAnak]);

  const renderTabelCell = (data) => {
    if (!data) return <span>-</span>;
    if (!IsGuru) return <span>{data?.mapel_nama}</span>;
    return (
      <>
        <span className="m-0 w-100 d-block">{data?.mapel_nama}</span>
        <span className="m-0">({data?.kelasdetail_nama})</span>
      </>
    );
  };
  return (
    <div className="iq-card">
      <div className="iq-card-header d-flex justify-content-between">
        <div className="iq-header-title w-100 d-flex align-items-center justify-content-between">
          <h4 className="card-title">{t("jadwal_saya")}</h4>
          <Link
            to="/kelas"
            className="btn iq-bg-light iq-bg-primary-hover text-dark border d-flex align-items-center gap-2"
          >
            <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
          </Link>
        </div>
      </div>
      <div className="iq-card-body">
        <Table bordered hover className="text-center" responsive>
          <thead>
            <tr>
              <th className="bg-primary-gradien text-white bg-transparent">
                {t("waktu")}
              </th>
              <th className="bg-primary-gradien text-white bg-transparent">
                {t("senin")}
              </th>
              <th className="bg-primary-gradien text-white bg-transparent">
                {t("selasa")}
              </th>
              <th className="bg-primary-gradien text-white bg-transparent">
                {t("rabu")}
              </th>
              <th className="bg-primary-gradien text-white bg-transparent">
                {t("kamis")}
              </th>
              <th className="bg-primary-gradien text-white bg-transparent">
                {t("jumat")}
              </th>
              <th className="bg-primary-gradien text-white bg-transparent">
                {t("sabtu")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={7}>
                  <img src={PageLoader} alt="loader" style={{ width: 34 }} />
                </td>
              </tr>
            ) : dataJadwal.length <= 0 ? (
              <tr>
                <td colSpan={7} className="text-center">
                  {t("data_params_kosong", { params: t("jadwal") })}
                </td>
              </tr>
            ) : (
              dataJadwal.map((item) => (
                <tr key={item.waktubelajar_id}>
                  {parseInt(item.waktubelajar_istirahat) === 1 ? (
                    <>
                      <th className="bg-primary-gradien text-white bg-transparent align-middle">
                        {`${item.waktubelajar_mulai?.substr(
                          0,
                          5
                        )} - ${item.waktubelajar_sampai?.substr(0, 5)}`}
                      </th>
                      <td colSpan={6} className="table-secondary">
                        {t("istirahat")}
                      </td>
                    </>
                  ) : (
                    <>
                      <th className="bg-primary-gradien text-white bg-transparent align-middle">
                        {`${item.waktubelajar_mulai?.substr(
                          0,
                          5
                        )} - ${item.waktubelajar_sampai?.substr(0, 5)}`}
                      </th>
                      <td className="align-middle">
                        {renderTabelCell(item.senin)}
                      </td>
                      <td className="align-middle">
                        {renderTabelCell(item.selasa)}
                      </td>
                      <td className="align-middle">
                        {renderTabelCell(item.rabu)}
                      </td>
                      <td className="align-middle">
                        {renderTabelCell(item.kamis)}
                      </td>
                      <td className="align-middle">
                        {renderTabelCell(item.jumat)}
                      </td>
                      <td className="align-middle">
                        {renderTabelCell(item.sabtu)}
                      </td>
                    </>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
