import React from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

function ModalMateriH5P({ show, handleClose, url }) {
  const { t } = useTranslation();
  return (
    <div className="col-lg-12">
      <Modal
        size="xl"
        fullscreen
        show={show}
        onHide={handleClose}
        id="ModalMateriH5P"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title">{t("materi")}</h5>
        </Modal.Header>
        <Modal.Body>
          {url && (
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item"
                src={url}
                title="materi video"
              ></iframe>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalMateriH5P;
