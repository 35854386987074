import { GET_NILAI, GET_PROFILE, LOGOUT } from '../constant';

const initState = {
  nilai: [],
  loading: false,
};

const nilaiReducer = (state = initState, action) => {
  switch (action?.type) {
    case GET_NILAI + "_PENDING":
      return {
        ...state,
        loading: true,
      };
    case GET_NILAI + "_FULFILLED":
      return {
        ...state,
        nilai: action.payload,
        loading: false,
      };
    case GET_NILAI + "_REJECTED":
      return {
        ...state,
        loading: false,
      };
    case GET_PROFILE + "_REJECTED":
      return {
        nilai: [],
        loading: false,
      };
    case LOGOUT:
      return {
        nilai: [],
        loading: false,
      };
    default: {
      return state;
    }
  }
};

export default nilaiReducer;
