import { API, setAuthToken } from "../../config/api";
import { GET_ANAK, SET_SELECTED_ANAK } from "../constant";

export const getAnak = () => {
  return {
    type: GET_ANAK,
    payload: async () => {
      const token = localStorage.getItem("token");
      setAuthToken(token);
      let res = await API.get("kelas/list-anak").catch((err) => {
        return Promise.reject(err);
      });
      return res?.data?.data;
    },
  };
};

export const setAnak = (anak) => {
  return {
    type: SET_SELECTED_ANAK,
    payload: anak,
  };
};
