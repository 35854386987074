/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { Logo1 } from "../../assets/images";
import {
  Cerita,
  ListSoal,
  PreviewSoal,
  SettingSiswa,
  TambahTugas,
  Wrapper,
} from "../../components";
import {
  addSoalBS,
  addSoalCeritaBS,
  delBankSoal,
  delCeritaBS,
  delSoalBS,
  getDetailBankSoal,
  getListSoal,
  getSoalCeritaBS,
} from "../../redux/actions/bankSoalAction";
import { getListSiswa } from "../../redux/actions/pelajaranAction";
import { getTopik } from "../../redux/actions/topikAction";

/* eslint-disable jsx-a11y/anchor-is-valid */
function DetailBankSoal() {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const topik = useSelector(({ topik }) => topik);
  const [showSelectSiswa, setShowSelectSiswa] = useState(false);
  const [detail, setDetail] = useState({});
  const [dataSoal, setDataSoal] = useState([]);
  const [dataSoalCerita, setDataSoalCerita] = useState([]);
  const [listSiswa, setListSiswa] = useState([]);
  const [showModalTugas, setShowModalTugas] = useState(false);
  const [previewSoal, setPreviewSoal] = useState(false);

  const getDetail = useCallback(async (id) => {
    const response = await getDetailBankSoal(id);

    if (response) {
      setDetail(response?.data?.data);
    }
    getListSoal(id).then((res) => setDataSoal(res?.data?.data));
    getSoalCeritaBS(id).then((res) => setDataSoalCerita(res?.data?.data));
  }, []);

  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? "Berhasil" : "Gagal!",
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };

  const handleDeleteTugas = () => {
    Swal.fire({
      title: t("konfirmasi_hapus"),
      text: t("are_u_sure_delete", { params: t("bank_soal") }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendDeleteTugas();
    });
  };

  const sendDeleteTugas = () => {
    delBankSoal(id)
      .then(() => {
        navigate(-1);
      })
      .catch(() => {
        showAlert(t("gagal_hapus", { data: t("bank_soal") }), false);
      });
  };

  const handleDeleteSoal = (code) => {
    Swal.fire({
      title: t("konfirmasi_hapus"),
      text: t("are_u_sure_delete", { params: t("soal") }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendDeleteSoal(code);
    });
  };

  const sendDeleteSoal = (code) => {
    delSoalBS(code)
      .then(() => {
        getDetail(id);
      })
      .catch(() => {
        showAlert(t("gagal_hapus", { data: t("soal") }), false);
      });
  };

  const handleCopySoal = (data) => {
    Swal.fire({
      title: t("konfirmasi"),
      text: t("apakah_anda_yakin_menyalin", { data: t("soal") }),
      icon: "question",
      confirmButtonText: "Yakin",
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: "Batal",
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendCopySoal(data);
    });
  };

  const sendCopySoal = (data) => {
    addSoalBS(data)
      .then(() => {
        getDetail(id);
      })
      .catch(() => {
        showAlert(t("gagal_menyalin", { data: t("soal") }), false);
      });
  };

  const handleDeleteCerita = (code) => {
    Swal.fire({
      title: t("konfirmasi_hapus"),
      text: t("are_u_sure_delete", {
        params: t("data_ini", { data: t("soal_cerita") }),
      }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendDeleteCerita(code);
    });
  };

  const sendDeleteCerita = (code) => {
    delCeritaBS(code)
      .then(() => {
        getDetail(id);
      })
      .catch(() => {
        showAlert(t("gagal_hapus", { data: t("cerita") }), false);
      });
  };

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, [id]);
  useEffect(() => {
    if (detail?.topik?.kelas_mapel?.kelasmapel_kelasdetailid) {
      getListSiswa(detail?.topik?.kelas_mapel?.kelasmapel_kelasdetailid)
        .then((res) => {
          setListSiswa(res?.data?.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    if (detail?.topik?.kelasmapel_id) {
      dispatch(getTopik(detail?.topik?.kelasmapel_id));
    }
  }, [detail]);

  const handleCopyCerita = (data) => {
    Swal.fire({
      title: t("konfirmasi"),
      text: t("apakah_anda_yakin_menyalin", { data: t("cerita") }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendCopySoalCerita(data);
    });
  };

  const sendCopySoalCerita = (data) => {
    addSoalCeritaBS({ ...data, detail: [] })
      .then(() => {
        getDetail(id);
      })
      .catch(() => {
        showAlert(t("gagal_menyalin", { data: t("cerita") }), false);
      });
  };

  return (
    <Wrapper title={`${detail?.bk_master_nama}`}>
      <div className="container">
        <div className="row row-eq-height">
          <div className="col-lg-12">
            <div className="iq-card">
              <div className="iq-card-body">
                <div className="d-flex">
                  <div className="me-3">
                    <img
                      src={Logo1}
                      className="img-fluid rounded p-2 iq-bg-dark"
                      width="120"
                      height="120"
                      alt="logo"
                    />
                  </div>
                  <div className="w-100">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center gap-8">
                        <span>{t("bank_soal")}</span>
                        {detail?.status === "publish" && (
                          <span className="badge badge-pill badge-primary">
                            {t("soal_dipublish")}
                          </span>
                        )}
                      </div>
                      <Link
                        to="/banksoal"
                        className="btn btn-sm iq-bg-light iq-bg-primary-hover text-dark border bg-transparent"
                      >
                        <i className="ri-arrow-left-line m-0"></i>{" "}
                        {t("kembali")}
                      </Link>
                    </div>
                    <h4 className="fw-bold">{detail?.bk_master_nama ?? "-"}</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: detail?.bk_master_deskripsi,
                      }}
                    ></div>
                    <div className="d-flex me-4 align-items-center gap-8">
                      <div>
                        <i className="ri-flag-2-line "></i>{" "}
                        {detail?.total_point} {t("poin")}
                      </div>
                      <div>
                        <i className="ri-list-check "></i>{" "}
                        {dataSoal?.length ?? 0} {t("soal")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4 d-flex justify-content-between">
                  <div className="group-member d-flex align-items-center">
                    <div className="iq-media-group">
                      <img
                        className="img-fluid avatar-40 rounded-circle"
                        src={detail?.user?.user_avatar}
                        alt=""
                      />
                    </div>
                    &nbsp;
                    <div className="info">
                      <h6>{detail?.user?.user_nama} </h6>
                      <span className="mb-0 font-size-12">
                        {detail?.createdAt
                          ? moment(detail?.createdAt).format("DD MMMM YYYY")
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="btn-group mt-3" role="group">
                      <button
                        onClick={() => setPreviewSoal(true)}
                        className="btn btn-sm iq-bg-secondary iq-bg-primary-hover text-dark align-self-end fw-bolder"
                      >
                        <i className="ri-computer-line m-0"></i> {t("lihat")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex mb-3 gap-8">
              <Link
                to={`/banksoal/tambah-soal/${id}`}
                className="btn btn-primary d-block w-50 border-primary btn-action-soal fw-bold d-flex align-items-center justify-content-center"
                style={{
                  borderRadius: "0.75rem",
                  boxShadow: "0 3px 0 0 #64a963",
                }}
              >
                <i className="ri-add-line"></i> {t("tambah")} {t("soal")}
              </Link>
              <button
                className="btn btn-danger d-block w-50 border-danger btn-action-soal fw-bold d-flex align-items-center justify-content-center"
                style={{
                  borderRadius: "0.75rem",
                  boxShadow: "0 3px 0 0 #db7564",
                }}
                onClick={handleDeleteTugas}
              >
                <i className="ri-delete-bin-line"></i> {t("hapus")}{" "}
                {t("bank_soal")}
              </button>
            </div>
            {dataSoalCerita?.map((item, index) => (
              <Cerita
                key={`${index}-cerita`}
                data={item}
                index={index}
                delCerita={handleDeleteCerita}
                pathEdit="/banksoal/edit-soal-cerita"
                handleCopy={() => handleCopyCerita(item)}
                dataSoal={dataSoal}
              />
            ))}
            {dataSoal?.length <= 0 && dataSoalCerita.length <= 0 ? (
              <p className="text-center">
                {t("data_params_kosong", { params: t("soal") })}
              </p>
            ) : (
              dataSoal?.map((item, index) => (
                <ListSoal
                  handleCopy={handleCopySoal}
                  handleDelete={handleDeleteSoal}
                  index={index}
                  item={item}
                  soalJenis={item?.soal_jenis}
                  status={detail?.status}
                  key={item.soal_id}
                  pathEdit="/banksoal/edit-soal"
                  disabledPoint
                  disabledWaktu
                />
              ))
            )}
          </div>
          {showSelectSiswa && (
            <SettingSiswa
              show={showSelectSiswa}
              listSiswa={listSiswa}
              selectedSiswa={detail?.list_siswa}
              handleClose={() => setShowSelectSiswa(false)}
              selectAll={detail?.allSiswa}
            />
          )}
          {showModalTugas && (
            <TambahTugas
              show={showModalTugas}
              dataTopik={topik?.topik}
              dataSiswa={listSiswa}
              handleClose={() => {
                setShowModalTugas(false);
                getDetail(id);
              }}
              editTugas={id}
            />
          )}
          {previewSoal && (
            <PreviewSoal
              dataSoal={dataSoal}
              show={previewSoal}
              handleClose={() => setPreviewSoal(false)}
              dataSoalCerita={dataSoalCerita}
            />
          )}
        </div>
      </div>
    </Wrapper>
  );
}

export default DetailBankSoal;
