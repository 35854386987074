import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';

import reducers from './reducers';

const history = createBrowserHistory();
const persistConfit = {
	key: "root",
	storage,
};

const rootReducer = persistReducer(persistConfit, reducers);
const middleware = applyMiddleware(promise, thunk, routerMiddleware(history));

const store = createStore(rootReducer, middleware);
const persist = persistStore(store);
export { store, persist };
