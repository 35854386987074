import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  getBookCategory,
  getBookSubject,
  getBookType,
} from "../../redux/actions/libraryAction";

const SortLibrary = ({
  selectedCategory,
  setSelectedCategory,
  selectedSubject,
  setSelectedSubject,
  selectedType,
  setSelectedType,
  library,
}) => {
  const { t } = useTranslation();
  const [category, setCategory] = useState([]);
  const [subject, setSubject] = useState([]);
  const [type, setType] = useState([]);
  useEffect(() => {
    if (library) {
      getBookCategory(library).then((res) => setCategory(res?.data));
      getBookSubject(library).then((res) => setSubject(res?.data));
      getBookType(library).then((res) => setType(res?.data));
    }
  }, [library]);

  const handleCategoryChange = (e) => {
    const value = parseInt(e.target.value);
    const checked = selectedCategory.filter((item) => item === value);
    if (checked.length <= 0) {
      setSelectedCategory((prev) => [...prev, value]);
    } else {
      setSelectedCategory((prev) => prev.filter((item) => item !== value));
    }
  };

  const handleSubjectChange = (e) => {
    const value = parseInt(e.target.value);
    const checked = selectedSubject.filter((item) => item === value);
    if (checked.length <= 0) {
      setSelectedSubject((prev) => [...prev, value]);
    } else {
      setSelectedSubject((prev) => prev.filter((item) => item !== value));
    }
  };

  const handleTypeChange = (e) => {
    const value = parseInt(e.target.value);
    const checked = selectedType.filter((item) => item === value);
    if (checked.length <= 0) {
      setSelectedType((prev) => [...prev, value]);
    } else {
      setSelectedType((prev) => prev.filter((item) => item !== value));
    }
  };
  return (
    <div className="iq-card">
      <div className="iq-card-header d-flex justify-content-between">
        <div className="iq-header-title">
          <h4 className="card-title">{t("urutkan")}</h4>
        </div>
      </div>
      <div className="iq-card-body">
        <Accordion className="accordion-sort-library">
          <Accordion.Item eventKey="1" className="border-0">
            <Accordion.Header>{t("kategori")}</Accordion.Header>
            <Accordion.Body className="p-0">
              {category?.map((item) => (
                <div
                  className="custom-control custom-checkbox custom-control-inline mb-2"
                  key={item.id}
                >
                  <input
                    name="kategori"
                    type="checkbox"
                    className="custom-control-input"
                    id={"kategori-" + item.id}
                    value={item.id}
                    onChange={handleCategoryChange}
                    checked={selectedCategory.includes(item.id)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={"kategori-" + item.id}
                  >
                    {item.category} ({item.buku_count})
                  </label>
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className="border-0">
            <Accordion.Header>{t("subjek")}</Accordion.Header>
            <Accordion.Body className="p-0">
              {subject?.map((item) => (
                <div
                  className="custom-control custom-checkbox custom-control-inline mb-2"
                  key={item.id}
                >
                  <input
                    name="subject"
                    type="checkbox"
                    className="custom-control-input"
                    id={"subject-" + item.id}
                    value={item.id}
                    onChange={handleSubjectChange}
                    checked={selectedSubject.includes(item.id)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={"subject-" + item.id}
                  >
                    {item.name} ({item.buku_count})
                  </label>
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className="border-0">
            <Accordion.Header>{t("bentuk")}</Accordion.Header>
            <Accordion.Body className="p-0">
              {type?.map((item) => (
                <div
                  className="custom-control custom-checkbox custom-control-inline mb-2"
                  key={item.id}
                >
                  <input
                    name="type"
                    type="checkbox"
                    className="custom-control-input"
                    id={"type-" + item.id}
                    value={item.id}
                    onChange={handleTypeChange}
                    checked={selectedType.includes(item.id)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={"type-" + item.id}
                  >
                    {item.name} ({item.buku_count})
                  </label>
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default SortLibrary;
