import { API, setAuthToken } from "../../config/api";
import { GET_NILAI } from "../constant";

export const getNilai = (params) => {
  return {
    type: GET_NILAI,
    payload: async () => {
      const token = localStorage.getItem("token");
      setAuthToken(token);
      let res = await API.get(
        `nilai/tugas?${new URLSearchParams(params).toString()}`
      ).catch((err) => {
        return Promise.reject(err);
      });
      return res?.data?.data;
    },
  };
};

export const getDetailTugasNilai = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`nilai/tugas/detail?tugas_id=${code}`);
};

export const getPengerjaanSiswa = (tugas_id, user_id) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`nilai/tugas/soal?tugas_id=${tugas_id}&user_id=${user_id}`);
};

export const updPoints = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("nilai/update-point", params);
};

export const kirimNilaiRaport = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("nilai/raport", params);
};

export const getPembahasanTugas = (tugas_id) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`nilai/pembahasan?tugas_id=${tugas_id}`);
};
