import axios from "axios";

const ACCESS_TOKEN = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_BACKEND_API_URL_2;
const headers = {
  headers: {
    Authorization: ACCESS_TOKEN,
  },
};

export const getKalender = async (params) => {
  const data = new URLSearchParams(params).toString();
  let res = await axios
    .get(`${API_URL}/kalender?${data}`, headers)
    .catch((err) => {
      return Promise.reject(err);
    });
  return res?.data;
};

export const getJadwal = async (params) => {
  const data = new URLSearchParams(params).toString();
  let res = await axios
    .get(`${API_URL}/jadwal?${data}`, headers)
    .catch((err) => {
      return Promise.reject(err);
    });
  return res?.data;
};
