import React from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ModalSoalCerita = ({ show, handleClose, soalCerita }) => {
  const { t } = useTranslation();
  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      id="showcerita"
      fullscreen
    >
      <Modal.Header className="border-0 bg-black">
        <h4 className="modal-title text-white m-0">{t("soal_cerita")}</h4>
        <button
          onClick={handleClose}
          className="bg-transparent border-0 text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-x"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center">
          <Col xs="12" lg="6">
            <Container>
              <img
                src={soalCerita?.cerita_file}
                className="img-fluid rounded w-100 mb-3"
                alt="gambar"
              />
              <div
                className="text-white"
                dangerouslySetInnerHTML={{
                  __html: soalCerita?.cerita_deskripsi,
                }}
              ></div>
            </Container>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ModalSoalCerita;
