import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { Wrapper } from "../../components";
import { getDetailPelajaran } from "../../redux/actions/pelajaranAction";
import { checkIsGuru } from "../../utils/roleUser";
import Absensi from "./section/absensi";
import AbsensiSiswa from "./section/absensi-siswa";
import Anggota from "./section/anggota";
import Materi from "./section/materi";
import Nilai from "./section/nilai";
import Weekly from "./section/weekly";

function DetailKelas() {
  const { t } = useTranslation();
  const location = useLocation();
  const [detailKelas, setDetailKelas] = useState(null);
  const { user } = useSelector(({ user }) => user);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    let params = new URLSearchParams(location.search);
    let th = params.get("th");
    let smt = params.get("smt");
    const data = {
      tahunpelajaran_id: th,
      semester_id: smt,
    };
    getDetailPelajaran(id, data)
      .then((res) => {
        setDetailKelas(res?.data?.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [id, location.search]);
  return (
    <Wrapper title={detailKelas?.mapel?.mapel_nama || t("mata_pelajaran")}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="iq-card">
              <div className="iq-card-body p-0">
                <div className="user-tabing p-3">
                  <div className="d-flex align-items-center justify-content-between row">
                    <div className="col-lg-12">
                      <ul
                        className="nav nav-pills nav-fill"
                        id="pills-tab-1"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-toggle="pill"
                            href="#materi"
                          >
                            <b>{t("materi")}</b>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="pill"
                            href="#absensi"
                          >
                            <b>{t("absensi")}</b>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="pill"
                            href="#nilai"
                          >
                            <b>{t("nilai")}</b>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="pill"
                            href="#anggota"
                          >
                            <b>{t("siswa")}</b>
                          </a>
                        </li>
                        {checkIsGuru(user?.user_roleid) && (
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="pill"
                              href="#Weekly"
                            >
                              <b>{t("mingguan")}</b>
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="tab-content">
              <Materi detailKelas={detailKelas} loading={loading} />
              {checkIsGuru(user?.user_roleid) ? (
                <Absensi detailKelas={detailKelas} loading={loading} />
              ) : (
                <AbsensiSiswa detailKelas={detailKelas} loading={loading} />
              )}
              <Nilai detailKelas={detailKelas} loading={loading} />
              <Anggota detailKelas={detailKelas} loading={loading} />
              <Weekly detailKelas={detailKelas} loading={loading} />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default DetailKelas;
