import React from "react";
import { InputPilgan } from "../input";

function ItemSoalTrueFalse({
  handleChange,
  data,
  handleChangeFile,
  handleDeleteFile,
  loadingUpload,
  preview,
  kerjakan,
  handlePilih,
  dipilih,
}) {
  return (
    <div
      className={`row ${
        !preview && !kerjakan ? "px-3 pb-3" : "py-4"
      } justify-content-center`}
    >
      <div className="col-lg-6">
        <InputPilgan
          handleChange={(e) => (kerjakan ? handlePilih(true) : handleChange(e))}
          id="1"
          correct={data.soal_jawaban === "1" || data?.soal_jawaban === true}
          answer={data.soal_pilihan_true}
          name="soal_pilihan_true"
          bg="bg-primary"
          border="border-primary"
          shadow="1e6b28"
          handleChangeFile={(index, e) =>
            handleChangeFile(
              index === "1" ? "soal_file_true" : "soal_file_false",
              e
            )
          }
          handleDeleteFile={(index) =>
            handleDeleteFile(
              index === "1" ? "soal_file_true" : "soal_file_false"
            )
          }
          soalFile={data?.soal_file_true}
          loadingUpload={
            loadingUpload === "soal_file_true"
              ? "1"
              : loadingUpload === "soal_file_false"
              ? "0"
              : null
          }
          preview={preview}
          kerjakan={kerjakan}
          dipilih={dipilih === true}
        />
      </div>
      <div className="col-lg-6">
        <InputPilgan
          handleChange={(e) =>
            kerjakan ? handlePilih(false) : handleChange(e)
          }
          id="0"
          correct={data.soal_jawaban === "0" || data?.soal_jawaban === false}
          answer={data.soal_pilihan_false}
          name="soal_pilihan_false"
          bg="bg-warning"
          border="border-warning"
          shadow="c9870f"
          handleChangeFile={(index, e) =>
            handleChangeFile(
              index === "1" ? "soal_file_true" : "soal_file_false",
              e
            )
          }
          handleDeleteFile={(index) =>
            handleDeleteFile(
              index === "1" ? "soal_file_true" : "soal_file_false"
            )
          }
          soalFile={data?.soal_file_false}
          loadingUpload={
            loadingUpload === "soal_file_true"
              ? "1"
              : loadingUpload === "soal_file_false"
              ? "0"
              : null
          }
          preview={preview}
          kerjakan={kerjakan}
          dipilih={dipilih === false}
        />
      </div>
    </div>
  );
}

export default ItemSoalTrueFalse;
