import React from 'react';

function ListChatCard({ link, img, name, message, unread, last_time }) {
	return (
		<li>
			<a data-toggle="pill" href={link}>
				<div className="d-flex align-items-center">
					<div className="avatar me-2">
						<img src={img} alt="chatuserimage" className="avatar-50" />
						<span className="avatar-status">
							<i className="ri-checkbox-blank-circle-fill text-success"></i>
						</span>
					</div>
					<div className="chat-sidebar-name">
						<h6 className="mb-0">{name}</h6>
						<span>{message}</span>
					</div>
					<div className="chat-meta float-right text-center mt-2 me-1">
						{unread > 0 && <div className="chat-msg-counter bg-primary text-white">{unread}</div>}
						<span className="text-nowrap">{last_time}</span>
					</div>
				</div>
			</a>
		</li>
	);
}

export default ListChatCard;
