import React from "react";
import { useTranslation } from "react-i18next";

const InputFileSoalCerita = ({
  file,
  handleDelFile,
  handleChange,
  loading,
}) => {
  const { t } = useTranslation();
  if (file) {
    return (
      <div className="col-12 col-lg-3 pr-3 mb-2">
        <div className="user-images position-relative overflow-hidden">
          {file ? (
            <img src={file} className="img-fluid rounded w-100" alt="soal" />
          ) : null}
          <button
            className="border-0 rounded position-absolute image-edit-btn iq-bg-primary font-size-18 right-10 top-10"
            onClick={handleDelFile}
          >
            <i className="ri-delete-bin-fill"></i>
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="col-12 col-lg-2 pr-3 mb-2">
      <div className="send-panel row">
        <div className="col-lg-12 col-6">
          <label
            className="p-3 iq-bg-primary rounded w-100 "
            htmlFor="image_file"
          >
            <input
              type="file"
              onChange={handleChange}
              id="image_file"
              className="d-none"
              name="soal_file"
              accept="image/png, image/jpg, image/jpeg"
              disabled={loading}
            />
            <div className="btn d-flex align-items-center justify-content-center text-primary flex-column h-auto w-100 p-0">
              <i className="ri-image-fill font-size-40 mb-2"></i>
              {loading ? `${t("loading")}...` : "Image"}
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default InputFileSoalCerita;
