import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Renders a footer section for a quiz or questionnaire.
 * @param {Object} props - The component props.
 * @param {string} props.jenisWaktu - The type of time for answering the questions.
 * @param {boolean} props.submitJawaban - Indicates whether the answers have been submitted or not.
 * @param {number} props.idxSoal - The index of the current question.
 * @param {number} props.jmlSoal - The total number of questions.
 * @param {function} props.handlePrev - Event handler for the previous button click.
 * @param {function} props.handleSelesai - Event handler for the finish button click.
 * @param {function} props.handleNext - Event handler for the next button click.
 * @returns {JSX.Element} The rendered footer section.
 */
const FooterPengerjaan = ({
  jenisWaktu,
  submitJawaban,
  idxSoal,
  jmlSoal,
  handlePrev,
  handleSelesai,
  handleNext,
}) => {
  const { t } = useTranslation();

  const renderPreviousButton = () => {
    if (jenisWaktu !== "persoal") {
      return (
        <button
          type="button"
          name="previous"
          className="btn btn-dark previous action-button-previous"
          value="Previous"
          onClick={handlePrev}
          disabled={submitJawaban}
        >
          <i className="ri-arrow-left-s-line font-size-20 p-0 fw-bold m-0"></i>
        </button>
      );
    }
    return null;
  };

  const renderNextButton = () => {
    if (idxSoal + 1 === jmlSoal) {
      return (
        <button
          type="button"
          name="next"
          className="btn btn-primary next action-button"
          value="Next"
          onClick={handleSelesai}
          disabled={submitJawaban}
        >
          <span>{t("selesai")}</span>
          <i className="ri-arrow-right-s-line font-size-20 p-0 fw-bold m-0"></i>
        </button>
      );
    }
    return (
      <button
        type="button"
        name="next"
        className="btn btn-primary next action-button"
        value="Next"
        onClick={handleNext}
        disabled={submitJawaban}
      >
        <i className="ri-arrow-right-s-line font-size-20 p-0 fw-bold m-0"></i>
      </button>
    );
  };

  return (
    <div className="col-12 mt-2">
      <div className="d-flex align-items-center justify-content-end gap-8 opacity-1">
        {renderPreviousButton()}
        {renderNextButton()}
      </div>
    </div>
  );
};

export default FooterPengerjaan;
