import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Login4, Login5, Login6, Logo1 } from '../../assets/images';

function ForgotPass() {
	return (
		<Fragment>
			{/* Sign in Start */}
			<section className="sign-in-page">
				<div className="row no-gutters">
					<div className="col-md-6 text-center">
						<div className="h-fit-screen w-100 d-flex align-items-center justify-content-center">
							<div className="w-100 sign-in-detail text-white px-3">
								<div
									className="owl-carousel"
									data-autoplay="true"
									data-loop="true"
									data-nav="false"
									data-dots="true"
									data-items="1"
									data-items-laptop="1"
									data-items-tab="1"
									data-items-mobile="1"
									data-items-mobile-sm="1"
									data-margin="0"
								>
									<div className="item">
										<img src={Login6} className="img-fluid mb-4" alt="logo" />
										<h4 className="mb-1 text-white fw-bold">Find new friends</h4>
										<p>It is a long established fact that a reader will be distracted by the readable content.</p>
									</div>
									<div className="item">
										<img src={Login5} className="img-fluid mb-4" alt="logo" />
										<h4 className="mb-1 text-white fw-bold">Connect with the world</h4>
										<p>It is a long established fact that a reader will be distracted by the readable content.</p>
									</div>
									<div className="item">
										<img src={Login4} className="img-fluid mb-4" alt="logo" />
										<h4 className="mb-1 text-white fw-bold">Create new events</h4>
										<p>It is a long established fact that a reader will be distracted by the readable content.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6 bg-grey">
						<div className="h-100 w-100 d-flex align-items-center justify-content-center">
							<div className="sign-in-from">
								<div className="header">
									<div className="sign-in-logo mb-2">
										<img src={Logo1} className="img-fluid" alt="logo" />
									</div>
									<div className="teks">
										<h2 className="mb-1 fw-bold">Lupa Kata Sandi?</h2>
										<p className="m-0">
											Masukkan email atau No WA yang telah terdaftar dan kami akan mengirimkan instruksi untuk mengganti kata
											sandi Anda.
										</p>
									</div>
								</div>
								<div className="card mt-2" style={{ backgroundColor: "rgb(255, 255, 255)", borderRadius: 16 }}>
									<div className="card-body" style={{ padding: 25 }}>
										<form className="mt-4" action="index-guru.html">
											<div className="form-group">
												<label htmlFor="exampleInputEmail1">Email / No Wa</label>
												<input
													type="email"
													className="form-control mb-0"
													id="exampleInputEmail1"
													placeholder="Enter Email / No Wa"
												/>
											</div>
											<div className="d-inline-block w-100 mt-5 d-flex">
												<Link to="/login" className="btn btn-light btn-lg rounded-pill w-100 fw-bold ">
													<i className="ri-arrow-left-s-line"></i>
													Back Sign in
												</Link>
												&nbsp;
												<button type="submit" className="btn btn-primary btn-lg rounded-pill w-100 m-0">
													Reset Password
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Fragment>
	);
}

export default ForgotPass;
