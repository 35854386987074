import "moment/locale/id";

import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { FiturCard } from "../../../components";
import {
  ALL_CAN_ACCESS,
  GURU_CAN_ACCESS,
  GURU_SISWA_CAN_ACCESS,
  ORTU_CAN_ACCESS,
  ORTU_SISWA_CAN_ACCESS,
  checkCanAccess,
} from "../../../utils/roleUser";
import { Link } from "react-router-dom";

moment.locale("id");
function Header() {
  const { t } = useTranslation();
  const { user } = useSelector(({ user }) => user);
  const MENU = [
    {
      icon: "ri-apps-line",
      link: "/kelas",
      name: t("kelas"),
      allowed: ALL_CAN_ACCESS,
      disabled: false,
    },
    {
      icon: "ri-contacts-book-line",
      link: "/absensi",
      name: t("absensi"),
      allowed: ALL_CAN_ACCESS,
      disabled: false,
    },
    {
      icon: "ri-todo-line",
      link: "/ujian",
      name: t("ujian"),
      allowed: GURU_SISWA_CAN_ACCESS,
      disabled: false,
    },
    {
      icon: "ri-book-mark-line",
      link: "/rapor",
      name: t("rapor"),
      allowed: ORTU_SISWA_CAN_ACCESS,
      disabled: false,
    },
    {
      icon: "ri-government-line",
      link: "/libray",
      name: t("perpustakaan"),
      allowed: GURU_SISWA_CAN_ACCESS,
      disabled: true,
    },
    // {
    //   icon: "ri-drive-line",
    //   link: "/bk",
    //   name: "BK",
    //   allowed: [getRoleUser(6)],
    //   disabled: true,
    // },
    {
      icon: "ri-drive-line",
      link: "/drive",
      name: t("drive"),
      allowed: GURU_CAN_ACCESS,
      disabled: false,
    },
    {
      icon: "ri-database-2-line",
      link: "/banksoal",
      name: t("bank_soal"),
      allowed: GURU_CAN_ACCESS,
      disabled: false,
    },
    {
      icon: "ri-database-2-line",
      link: "/course",
      name: t("kursus"),
      allowed: GURU_SISWA_CAN_ACCESS,
      disabled: false,
    },
    {
      icon: "ri-bank-card-line",
      link: "/pembayaran",
      name: t("pembayaran"),
      allowed: ORTU_CAN_ACCESS,
      disabled: false,
    },
  ];
  return (
    <div className="col-lg-12">
      {user?.password_changed === "1" ? null : (
        <div className="iq-card">
          <div className="iq-card-body bg-white card-warning-home">
            <div className="d-flex justify-content-between align-items-center">
              <div className="todo-date d-flex mr-3 font-size-14">
                <span>
                  <b>{t("hello", { name: user?.user_nama })}</b>
                  <br />
                  {t("change_password_info")}
                  <b>
                    <Link to="/profile" className="ms-1">
                      {t("klik_disini")}!
                    </Link>
                  </b>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="iq-card p-3">
        <div className="row">
          <div className="col-lg-12">
            <div className="iq-card">
              <div className="iq-card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="todo-date d-flex me-3">
                    <i className="ri-calendar-2-line text-success me-2"></i>
                    <span>{moment().format("dddd, Do MMMM, YYYY")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="row justify-content-center">
              {MENU?.filter((item) =>
                checkCanAccess(user.user_roleid, item.allowed)
              )?.map((item) => (
                <div className="col-6 col-md-4 col-lg-2 mb-3" key={item.link}>
                  <FiturCard
                    icon={item.icon}
                    link={item.link}
                    name={item.name}
                    disabled={item.disabled}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
