import "moment/locale/id";

import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import Swal from "sweetalert2";
import { Small22 } from "../../assets/images/small";
import {
  delFileTugas,
  delTugas,
  publishTugas,
  unpublishTugas,
} from "../../redux/actions/tugasAction";
import { checkIsGuru } from "../../utils/roleUser";
import ShareTugas from "../modal/ShareTugas";
import { ActionData, ActionItemTugas } from "../section";
import ItemFile from "./ItemFile";
import ItemFileVideo from "./ItemFileVideo";

moment.locale("id");

function ItemTugas({
  judul,
  desc,
  code,
  updated_at,
  end_date,
  status,
  handleEditTugas,
  nilaiRaport,
  files,
  materiVideo,
  h5p,
  getDataTopik,
  statusHide,
  sekolahId,
  idTopik,
}) {
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useSelector(({ user }) => user);
  const [shareTugas, setShareTugas] = useState(false);
  let bgColor;
  if (status === "draft") {
    bgColor = "iq-bg-danger";
  } else if (status === "belum-mulai") {
    bgColor = "iq-bg-warning";
  } else if (status === "sembunyikan") {
    bgColor = "iq-bg-danger";
  } else if (status === "sedang-berjalan") {
    bgColor = "iq-bg-primary";
  } else {
    bgColor = "iq-bg-secondary";
  }

  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };

  const handleDelete = () => {
    Swal.fire({
      title: t("konfirmasi_hapus"),
      text: t("are_u_sure_delete", {
        params: t("data_ini", { data: t("tugas") }),
      }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendDelete(code);
    });
  };
  const sendDelete = (code) => {
    delTugas(code)
      .then(() => {
        getDataTopik();
      })
      .catch(() => {
        showAlert(t("failed_delete_data", { data: t("tugas") }), false);
      });
  };

  const handleShareTugas = () => {
    setShareTugas(code);
  };

  const handleChangeStatus = () => {
    Swal.fire({
      title: t("konfirmasi_edit"),
      text: t("are_u_sure_edit", {
        data: t("tugas"),
        status: t(!statusHide ? "sembunyikan" : "tampilkan"),
      }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed && !statusHide) {
        sendStatusTugas();
      } else {
        sendPublishTugas();
      }
    });
  };

  const sendStatusTugas = () => {
    const params = {
      tugas_id: code?.toString(),
    };
    unpublishTugas(params)
      .then(() => {
        getDataTopik();
      })
      .catch((err) => {
        showAlert(t("failed_edit_data", { data: t("tugas") }), false);
      });
  };

  const sendPublishTugas = () => {
    publishTugas({ tugas_id: code?.toString() })
      .then(() => {
        getDataTopik();
      })
      .catch(() => {
        showAlert(t("gagal_publish"), false);
      });
  };

  const handleDeleteFile = (id, type) => {
    const params = {
      type,
      id,
    };
    delFileTugas(params)
      .then(() => {
        getDataTopik();
      })
      .catch(() =>
        showAlert(t("gagal_hapus", { data: t("file_tugas") }), false)
      );
  };

  const isGuru = checkIsGuru(user?.user_roleid);

  return (
    <li>
      <div
        id="topik-item-detail"
        className="timeline-dots border-info handle"
      ></div>
      <div className="d-flex align-items-md-center justify-content-between mb-1 flex-column flex-md-row">
        <div className="d-flex align-items-md-center flex-1 flex-column flex-md-row gap-2">
          <h6 className="float-left m-0">{judul} </h6>
          {nilaiRaport && (
            <span className="bg-primary font-size-12 px-2 rounded">
              {t("nilai_rapor")}
            </span>
          )}
          {isGuru && (
            <ActionData
              handleDelete={handleDelete}
              handleEdit={handleEditTugas}
              handleShare={handleShareTugas}
              handleStatus={handleChangeStatus}
              showBtnDelete={status === "draft"}
              showBtnEdit={status === "draft"}
              statusShow={!statusHide}
              showBtnShare
              showBtnStatus
            />
          )}
        </div>
        <p className="font-size-12 m-0">
          {updated_at ? moment(updated_at).format("DD MMMM YYYY") : ""}
        </p>
      </div>
      {desc && (
        <div className="d-inline-block w-100">
          <div
            dangerouslySetInnerHTML={{ __html: desc }}
            className="preview-html"
          ></div>
        </div>
      )}
      {materiVideo?.map((item) => (
        <ItemFileVideo
          key={item.video_tugas_id}
          name={item.video_name}
          type={item.video_type}
          url={item.video_file}
          item={item}
          handleDeleteFile={() =>
            handleDeleteFile(item.video_tugas_id, "video_tugas")
          }
        />
      ))}
      {files?.map((item) => (
        <ItemFile
          key={item.filetugas_id}
          name={item.filename}
          type={item.type}
          url={item.path}
          handleDeleteFile={() => handleDeleteFile(item.filetugas_id, "files")}
        />
      ))}
      {h5p?.map((item) => (
        <ItemFile
          key={item.h5p_tugas_id}
          name={item.h5p_filename}
          type="html"
          url={item.h5p_file}
          handleDeleteFile={() => handleDeleteFile(item.h5p_tugas_id, "h5p")}
          // handleH5P={handleOpenMateriH5P}
        />
      ))}
      <div
        className={`${bgColor} rounded p-2 pointer d-flex align-items-md-center justify-content-between flex-column flex-md-row gap-8`}
      >
        {user?.user_roleid === 6 && status === "sedang-berjalan" ? (
          <Link
            to={`/tugas/kerjakan/${code}`}
            className="flex-1 d-flex align-items-center"
          >
            <img src={Small22} alt="icon" className="img-fluid" />
            <p className="m-0 ms-1">
              {judul}{" "}
              {end_date && (
                <>
                  <i className="ri-time-line" />
                  {t("tenggat")}:{" "}
                  {end_date ? moment(end_date).format("DD MMM HH.mm") : ""}
                </>
              )}
            </p>
          </Link>
        ) : (
          <div className="flex-1 d-flex align-items-center">
            <img src={Small22} alt="icon" className="img-fluid" />
            <p className="m-0 ms-1">
              {judul}{" "}
              {end_date && (
                <>
                  <i className="ri-time-line" />
                  Tenggat:{" "}
                  {end_date ? moment(end_date).format("DD MMM HH.mm") : ""}
                </>
              )}
            </p>
          </div>
        )}
        {isGuru && (
          <ActionItemTugas
            handleDelete={handleDelete}
            showBtnDelete={status === "draft"}
            tugasId={code}
          />
        )}
      </div>

      {shareTugas && (
        <ShareTugas
          show={shareTugas !== null}
          tugasId={shareTugas}
          idMapel={id}
          sekolahId={sekolahId}
          handleClose={() => {
            setShareTugas(null);
          }}
          idTopik={idTopik}
        />
      )}
    </li>
  );
}

export default ItemTugas;
