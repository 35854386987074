import { GET_PROFILE, GET_UJIAN, LOGOUT } from "../constant";

const initState = {
  dataUjian: [],
  loading: false,
};

const ujianReducer = (state = initState, action) => {
  switch (action?.type) {
    case GET_UJIAN + "_PENDING":
      return {
        ...state,
        loading: true,
      };
    case GET_UJIAN + "_FULFILLED":
      return {
        ...state,
        dataUjian: action.payload,
        loading: false,
      };
    case GET_UJIAN + "_REJECTED":
      return {
        ...state,
        loading: false,
      };
    case GET_PROFILE + "_REJECTED":
      return {
        dataUjian: [],
        loading: false,
      };
    case LOGOUT:
      return {
        dataUjian: [],
        loading: false,
      };
    default: {
      return state;
    }
  }
};

export default ujianReducer;
