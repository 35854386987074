import { Editor } from "@tinymce/tinymce-react";
import React from "react";

import { uploadFile } from "../../redux/actions/topikAction";

function InputEditor({ editorRef, value, idxSoal }) {
  return (
    <Editor
      key={idxSoal}
      apiKey={process.env.REACT_APP_BACKEND_API_EDITOR}
      onInit={(evt, editor) => (editorRef.current = editor)}
      initialValue={value}
      init={{
        height: 300,
        menubar: false,
        external_plugins: {
          tiny_mce_wiris:
            "https://cdn.jsdelivr.net/npm/@wiris/mathtype-tinymce5@8.1.1/plugin.min.js",
        },
        plugins: [
          "advlist",
          "autolink",
          "lists",
          "link",
          "image",
          "charmap",
          "preview",
          "anchor",
          "searchreplace",
          "visualblocks",
          "code",
          "fullscreen",
          "insertdatetime",
          "media",
          "table",
          "code",
          "help",
          "wordcount",
        ],
        toolbar:
          "undo redo | blocks | " +
          "bold italic forecolor image | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          "removeformat tiny_mce_wiris_formulaEditor | help link",
        draggable_modal: true,
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        anchor_top: false,
        anchor_bottom: false,
        image_title: true,
        automatic_uploads: true,
        file_picker_types: "image",
        file_picker_callback: (cb, value, meta) => {
          const input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");

          input.addEventListener("change", (e) => {
            const file = e.target.files[0];
            var formData = new FormData();
            formData.append("files", file);
            uploadFile(formData)
              .then((res) => {
                let data = res?.data?.data;
                if (data?.length <= 0) return cb("");
                data = data[0];
                cb(data?.path, { title: data?.filename });
              })
              .catch((err) => {
                console.log("err", err);
                cb("");
              });
          });

          input.click();
        },
      }}
    />
  );
}

export default InputEditor;
