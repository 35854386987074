import React, { useCallback, useEffect, useRef, useState } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import { PageLoader } from "../../assets/images";
import {
  CardFile,
  CardFolder,
  EditFileDrive,
  ModalPreviewDrive,
  TambahFolderDrive,
  Wrapper,
} from "../../components";
import {
  addFileDrive,
  delFileDrive,
  delFolderDrive,
  getDrive,
  getDriveDetail,
} from "../../redux/actions/driveAction";
import { Link } from "react-router-dom";

function Drive() {
  const { t } = useTranslation();
  let fileInput = useRef(null);
  const dispatch = useDispatch();
  const { dataDrive, detailDrive } = useSelector(({ drive }) => drive);
  const [showTambahFolder, setShowTambahFolder] = useState(false);
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const [folderSelect, setFolderSelect] = useState(null);
  const [editFolder, setEditFolder] = useState(null);
  const [editFile, setEditFile] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);
  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };
  const getData = useCallback(
    (folderSelect) => {
      if (folderSelect) {
        dispatch(getDriveDetail(folderSelect?.drive_folder_id));
        return;
      }
      dispatch(getDrive());
    },
    [dispatch]
  );
  useEffect(() => {
    getData(folderSelect);
  }, [getData, folderSelect]);
  const handleChangeFile = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (files.length > 0) {
      var formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`files`, files[i]);
      }
      if (folderSelect?.drive_folder_id)
        formData.append("drive_folder_id", folderSelect?.drive_folder_id);
      console.log("form daa", formData);
      setUploadFileLoading(true);
      addFileDrive(formData)
        .then((res) => {
          setUploadFileLoading(false);
          getData(folderSelect);
          fileInput.current.value = "";
        })
        .catch((err) => {
          setUploadFileLoading(false);
          console.log("err", err);
        });
    }
  };
  const handleDelFolder = (id, nama) => {
    Swal.fire({
      title: t("konfirmasi_hapus"),
      text: t("are_u_sure_delete", { params: "folder " + nama }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendDelFolder(id);
    });
  };
  const sendDelFolder = (id) => {
    delFolderDrive(id)
      .then(() => {
        getData(folderSelect);
      })
      .catch(() => {
        showAlert(t("gagal_hapus", { data: "folder" }), false);
      });
  };
  const handleDelFile = (id, nama) => {
    Swal.fire({
      title: t("konfirmasi_hapus"),
      text: t("are_u_sure_delete", { params: "file " + nama }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendDeleteFile(id);
    });
  };
  const sendDeleteFile = (id) => {
    delFileDrive(id)
      .then(() => {
        getData(folderSelect);
      })
      .catch(() => {
        showAlert(t("gagal_hapus", { data: "file" }), false);
      });
  };
  return (
    <Wrapper
      title={`${folderSelect ? folderSelect?.drive_folder_name : t("drive")}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mail-box-detail">
            <div className="iq-card">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                  <h4 className="card-title">{t("drive")}</h4>
                </div>
                <div className="d-flex gap-2">
                  <Link
                    to="/"
                    className="btn iq-bg-light iq-bg-primary-hover text-dark border"
                  >
                    <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
                  </Link>
                  <Dropdown>
                    <Dropdown.Toggle
                      disabled={uploadFileLoading}
                      variant="primary"
                      id="dropdown-basic"
                    >
                      {uploadFileLoading ? (
                        <img
                          src={PageLoader}
                          alt="loader"
                          style={{ width: 15 }}
                        />
                      ) : (
                        <i className="ri-stack-line"></i>
                      )}
                      {uploadFileLoading ? "Loading..." : t("tambah")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="top-0">
                      <Dropdown.Item
                        onClick={() => {
                          fileInput?.current?.click();
                        }}
                      >
                        {t("tambah")} File
                      </Dropdown.Item>
                      {!folderSelect && (
                        <Dropdown.Item
                          onClick={() => setShowTambahFolder(true)}
                        >
                          {t("tambah")} Folder
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="iq-card-body p-0">
                <Breadcrumb className="px-3 py-2 iq-bg-primary drive-breadcrumb">
                  <Breadcrumb.Item onClick={() => setFolderSelect(null)}>
                    <i className="ri-home-2-line"></i>
                  </Breadcrumb.Item>
                  {folderSelect && (
                    <Breadcrumb.Item active>
                      {folderSelect?.drive_folder_name}
                    </Breadcrumb.Item>
                  )}
                </Breadcrumb>

                <div className="iq-card">
                  {folderSelect ? (
                    <div className="iq-card-body">
                      <ul className="profile-img-gallary d-flex flex-wrap p-0 m-0 text-center">
                        {detailDrive?.length <= 0 ? (
                          <li className="w-100">
                            <p className="py-3 text-muted text-center m-0">
                              {t("folder_kosong")}
                            </p>
                          </li>
                        ) : (
                          detailDrive?.map((item) => (
                            <CardFile
                              typeFile={item.drive_file_type}
                              link={item.drive_file_url}
                              nama={item.drive_file_name}
                              key={item.drive_file_id}
                              handleEdit={() => setEditFile(item)}
                              handleHapus={() =>
                                handleDelFile(
                                  item.drive_file_id,
                                  item.drive_file_name
                                )
                              }
                              handleView={() => setPreviewFile(item)}
                            />
                          ))
                        )}
                      </ul>
                    </div>
                  ) : (
                    <div className="iq-card-body">
                      <h5 className="mt-2 mb-2">Folder</h5>
                      <ul className="profile-img-gallary d-flex flex-wrap p-0 m-0 mb-5 text-center">
                        {dataDrive?.folder?.length <= 0 ? (
                          <li className="w-100">
                            <p className="py-3 text-muted text-center m-0">
                              {t("data_params_kosong", { params: "folder" })}
                            </p>
                          </li>
                        ) : (
                          dataDrive?.folder?.map((item) => (
                            <CardFolder
                              key={item.drive_folder_id}
                              name={item.drive_folder_name}
                              onDoubleClick={() => setFolderSelect(item)}
                              handleEdit={() => {
                                setEditFolder(item);
                                setShowTambahFolder(true);
                              }}
                              handleHapus={() =>
                                handleDelFolder(
                                  item.drive_folder_id,
                                  item.drive_folder_name
                                )
                              }
                            />
                          ))
                        )}
                      </ul>
                      <h5 className="mt-2 mb-2">File</h5>
                      <ul className="profile-img-gallary d-flex flex-wrap p-0 m-0 text-center">
                        {dataDrive?.file?.length <= 0 ? (
                          <li className="w-100">
                            <p className="py-3 text-muted text-center m-0">
                              {t("data_params_kosong", { params: "file" })}
                            </p>
                          </li>
                        ) : (
                          dataDrive?.file?.map((item) => (
                            <CardFile
                              typeFile={item.drive_file_type}
                              link={item.drive_file_url}
                              nama={item.drive_file_name}
                              key={item.drive_file_id}
                              handleEdit={() => setEditFile(item)}
                              handleHapus={() =>
                                handleDelFile(
                                  item.drive_file_id,
                                  item.drive_file_name
                                )
                              }
                              handleView={() => setPreviewFile(item)}
                            />
                          ))
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TambahFolderDrive
        handleClose={() => {
          setEditFolder(null);
          setShowTambahFolder(false);
          getData(folderSelect);
        }}
        show={showTambahFolder}
        data={editFolder}
      />
      {editFile && (
        <EditFileDrive
          handleClose={() => {
            setEditFile(null);
            getData(folderSelect);
          }}
          show={editFile}
          data={editFile}
        />
      )}
      {previewFile && (
        <ModalPreviewDrive
          handleClose={() => setPreviewFile(null)}
          show={true}
          type={previewFile?.drive_file_type}
          url={previewFile?.drive_file_url}
          nama={previewFile?.drive_file_nama}
        />
      )}
      <input
        type="file"
        name="files"
        id="files"
        accept="
			image/jpeg, 
			image/png, 
			image/jpg, 
			.pptx,
			.xlsx,
			.xls,
			.doc,
			.docx,
			.ppt,
			.pdf,
      video/*,
      audio/*
			"
        onChange={handleChangeFile}
        multiple
        ref={fileInput}
        disabled={uploadFileLoading}
        className="d-none"
      />
    </Wrapper>
  );
}

export default Drive;
