import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ItemPreviewRating } from "../item";

function DescBuyCourse({
  desc,
  img,
  name,
  rating,
  subscriber,
  lesson,
  time,
  sertifikat,
}) {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  return (
    <>
      <div
        className={`text-dark overflow-hidden position-relative ${
          !showMore ? "preview-desc" : "h-auto"
        }`}
        dangerouslySetInnerHTML={{ __html: desc }}
      ></div>
      <button
        onClick={() => setShowMore(!showMore)}
        className="bg-transparent border-0 text-primary p-0 d-none d-lg-block"
      >
        {showMore ? t("lebih_sedikit") : t("lihat_lainnya")}{" "}
        <i className="ri-arrow-right-s-line"></i>
      </button>
      <div className="d-lg-none">
        <h6 className="fw-bold mb-3">{t("belajar_dengan")}</h6>
        <div className="d-flex flex-wrap gap-3 align-items-center">
          <img
            className="rounded-circle img-fluid"
            width={50}
            height={50}
            src={img}
            alt={name}
          />
          <div className="media-support-info">
            <h5 className="mb-0 d-inline-block">{name}</h5>
            <p className="mb-0 font-weight-bold font-size-14">
              {rating}
              <ItemPreviewRating rating={rating} />
            </p>
          </div>
        </div>
        <h6 className="fw-bold mt-4">{t("benefit")}</h6>
        <p className="mb-0 mt-2 font-size-14">
          <i className="text-primary fa-1-5x ri-checkbox-circle-fill mr-2"></i>{" "}
          {sertifikat
            ? t("mendapatkan_setifikat")
            : t("tidak_mendapatkan_setifikat")}
        </p>
        <p className="mb-0 mt-2 font-size-14">
          <i className="text-primary fa-1-5x ri-checkbox-circle-fill mr-2"></i>{" "}
          {subscriber} {t("berlangganan")}
        </p>
        <p className="mb-0 mt-2 font-size-14">
          <i className="text-primary fa-1-5x ri-checkbox-circle-fill mr-2"></i>{" "}
          {lesson} {t("lessons")}
        </p>
        <p className="mb-0 mt-2 font-size-14">
          <i className="text-primary fa-1-5x ri-checkbox-circle-fill mr-2"></i>{" "}
          {time < 60 ? time : time / 60} {time < 60 ? t("menit") : t("jam")}
        </p>
      </div>
    </>
  );
}

export default DescBuyCourse;
