import { API, setAuthToken } from '../../config/api';

export const addliveClass = (params) => {
	const token = localStorage.getItem("token");
	setAuthToken(token);
	return API.post("live-class", params);
};

export const updliveClass = (code, params) => {
	const token = localStorage.getItem("token");
	setAuthToken(token);
	return API.post("live-class/" + code, params);
};

export const delliveClass = (code) => {
	const token = localStorage.getItem("token");
	setAuthToken(token);
	return API.delete("live-class/" + code);
};

export const getDetailliveClass = (code) => {
	const token = localStorage.getItem("token");
	setAuthToken(token);
	return API.get(`live-class/${code}`);
};
