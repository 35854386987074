import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { PageLoader } from "../../assets/images";
import { RenderImportBankSoal, Wrapper } from "../../components";
import CeritaImportSoal from "../../components/card/CeritaImportSoal";
import FilterSoal from "../../components/modal/FilterSoal";
import { DEFAULT_FILTER_SOAL } from "../../config/defaultFilterSoal";
import {
  getListSoal,
  getSoalCeritaBS,
} from "../../redux/actions/bankSoalAction";
import { getOptionBankSoal } from "../../redux/actions/tugasAction";
import { sendImportBankSoalUjian } from "../../redux/actions/ujianAction";

function ImportBankSoalUjian() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { kelas_id, ujian_id } = useParams();
  const [optionBankSoal, setOptionBankSoal] = useState([]);
  const [optionSoal, setOptionSoal] = useState([]);
  const [optionCerita, setOptionCerita] = useState([]);
  const [selectedBankSoal, setSelectedBankSoal] = useState("");
  const [filter, setFilter] = useState(DEFAULT_FILTER_SOAL);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedSoal, setSelectedSoal] = useState([]);
  const [selectedCerita, setSelectedCerita] = useState([]);
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    getOptionBankSoal(kelas_id).then((res) => {
      setOptionBankSoal(res?.data?.data);
    });
  }, [kelas_id]);

  useEffect(() => {
    if (selectedBankSoal) {
      getListSoal(selectedBankSoal).then((res) => {
        setOptionSoal(res?.data?.data);
        setSelectedSoal([]);
      });
      getSoalCeritaBS(selectedBankSoal).then((res) => {
        setOptionCerita(res?.data?.data);
        setSelectedCerita([]);
      });
    } else {
      setOptionSoal([]);
      setOptionCerita([]);
    }
  }, [selectedBankSoal]);

  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    }).then((res) => {
      if (status && res.isConfirmed) navigate(-1);
    });
  };

  const handleChangeFilter = (name) => {
    if (name === "all") {
      setFilter(filter.length === 7 ? [] : DEFAULT_FILTER_SOAL);
      return;
    }
    let cek = filter?.filter((item) => item === name);
    if (cek.length <= 0) {
      setFilter((pref) => [...pref, name]);
    } else {
      setFilter((pref) => [...pref.filter((item) => item !== name)]);
    }
  };

  const handleChangeCerita = (id, value) => {
    if (value) {
      setSelectedCerita((pref) => [...pref, id]);
    } else {
      setSelectedCerita((pref) => [...pref.filter((item) => item !== id)]);
    }
  };

  const handleChangeSoal = (id, value) => {
    if (value) {
      setSelectedSoal((pref) => [...pref, id]);
    } else {
      setSelectedSoal((pref) => [...pref.filter((item) => item !== id)]);
    }
  };

  const cekSelectedData = () => {
    let cekCerita = selectedCerita.length === optionCerita.length;
    let cekSoal = selectedSoal.length === optionSoal.length;
    return cekCerita && cekSoal;
  };

  const handleChangeSelected = (checked) => {
    if (!checked) {
      setSelectedCerita([]);
      setSelectedSoal([]);
      return;
    }
    setSelectedCerita(optionCerita?.map((item) => item.cerita_id));
    setSelectedSoal(optionSoal?.map((item) => item.soal_id));
  };

  const handleSubmit = () => {
    setSubmit(true);
    const params = {
      ujian_id: ujian_id,
      bk_soal_id: selectedSoal,
      bk_cerita_id: selectedCerita,
    };
    sendImportBankSoalUjian(params)
      .then(() => {
        setSubmit(false);
        showAlert(t("berhasil_import_bank"), true);
      })
      .catch((err) => {
        const error = err?.response?.data;
        setSubmit(false);
        showAlert(error?.error || t("gagal_import_bank"), false);
      });
  };

  return (
    <Wrapper title={`${t("impor")} ${t("bank_soal")}`}>
      <div className="container">
        <div className="row row-eq-height">
          <div className="col-lg-12">
            <div className="iq-card">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title d-flex">
                  <h4 className="card-title">
                    {t("impor")} {t("bank_soal")}
                  </h4>
                </div>

                <div className="btn-group" role="group">
                  <button
                    onClick={() => navigate(-1)}
                    className="btn btn-secondary text-center d-flex align-items-center gap-8"
                    disabled={submit}
                  >
                    <i className="ri-arrow-left-line m-0"></i>{" "}
                    <span className="d-none d-md-block">{t("kembali")}</span>
                  </button>
                  <button
                    onClick={handleSubmit}
                    disabled={submit}
                    className="btn btn-primary text-center"
                  >
                    {submit ? (
                      <img
                        src={PageLoader}
                        alt="loader"
                        style={{ width: 15 }}
                      />
                    ) : (
                      t("simpan")
                    )}
                  </button>
                </div>
              </div>

              <div className="col-lg-12 p-0">
                <div className="iq-card">
                  <div className="iq-card-body">
                    <div className="col-lg-12 pe-3">
                      <div className="row">
                        <label
                          htmlFor="semester"
                          className="control-label col-12 col-md-2 align-self-center mb-0"
                        >
                          {t("bank_soal")} :
                        </label>
                        <div className="col-12 col-md-10">
                          <select
                            className="form-control form-select"
                            onChange={({ target: { value } }) =>
                              setSelectedBankSoal(value)
                            }
                            value={selectedBankSoal}
                          >
                            <option value="">
                              --{t("pilih_params", { params: t("bank_soal") })}
                              --
                            </option>
                            {optionBankSoal?.map((item) => (
                              <option
                                value={item.bk_master_id}
                                key={item.bk_master_id}
                              >
                                {item.bk_master_nama}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="d-flex flex-column flex-md-row gap-8 justify-content-between mb-3">
              <div className="d-flex border rounded bg-white">
                <div className="checkbox d-inline-block me-3 pt-2 ps-3 pe-3 d-flex">
                  <input
                    className="rabsen me-2"
                    style={{ width: 25 }}
                    type="checkbox"
                    name="all"
                    id="checkbox-all"
                    checked={cekSelectedData()}
                    onChange={({ target: { checked } }) =>
                      handleChangeSelected(checked)
                    }
                  />
                  <label
                    className="align-self-center fw-bold font-size-16"
                    htmlFor="checkbox-all"
                  >
                    {t("pilih_semua")}
                  </label>
                  <div></div>
                </div>
              </div>
              <div className="form-group mb-0 d-flex gap-8 align-items-center">
                <input
                  type="search"
                  className="form-control bg-white "
                  placeholder="Search"
                  value={search}
                  onChange={({ target: { value } }) => setSearch(value)}
                />
                <button
                  onClick={() => setShowFilter(true)}
                  className="btn bg-warning text-dark align-items-center fw-bolder d-flex height-45"
                >
                  <i className="ri-equalizer-line m-0"></i>&nbsp;Filter
                </button>
              </div>
            </div>
            {selectedBankSoal ? (
              <Fragment>
                {filter.includes("cerita") &&
                  optionCerita
                    ?.filter((item) => {
                      if (search.length <= 0) return true;
                      return item.cerita_deskripsi
                        .toLowerCase()
                        .includes(search.toLowerCase());
                    })
                    ?.map((item, index) => (
                      <CeritaImportSoal
                        key={`${index}-cerita`}
                        data={item}
                        index={index}
                        dataSoal={optionSoal}
                        handleChange={handleChangeCerita}
                        checked={
                          selectedCerita.filter(
                            (data) => data === item.cerita_id
                          ).length > 0
                        }
                      />
                    ))}
                {optionSoal
                  .filter((item) => filter.includes(item.soal_jenis))
                  ?.filter((item) => {
                    if (search.length <= 0) return true;
                    return item.soal_pertanyaan
                      .toLowerCase()
                      .includes(search.toLowerCase());
                  })
                  ?.map((item, index) => (
                    <RenderImportBankSoal
                      handleChangeSoal={handleChangeSoal}
                      index={index}
                      item={item}
                      selectedSoal={selectedSoal}
                      soalJenis={item?.soal_jenis}
                    />
                  ))}
              </Fragment>
            ) : (
              <div className="py-2">
                <p className="text-center">{t("pilih_bank_dahulu")}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {showFilter && (
        <FilterSoal
          show={showFilter}
          handleClose={() => setShowFilter(false)}
          params={filter}
          handleChange={handleChangeFilter}
        />
      )}
    </Wrapper>
  );
}

export default ImportBankSoalUjian;
