import React, { useEffect, useState } from "react";

function ItemCourseVideo({ type, video }) {
  const [url, setUrl] = useState("");
  useEffect(() => {
    if (video && type === "text") {
      let url = new URL(video);
      let c = url.searchParams.get("v");
      setUrl(c);
    } else {
      setUrl("");
    }
  }, [video, type]);
  return (
    <div id="iframe-course" className="w-100 ratio ratio-16x9 overflow-hidden">
      {type === "text" ? (
        <iframe
          title="embed youtube"
          className="embed-responsive-item rounded"
          src={`https://www.youtube.com/embed/${url}`}
        ></iframe>
      ) : (
        <video src={video} controls className="w-100 h-auto"></video>
      )}
    </div>
  );
}

export default ItemCourseVideo;
