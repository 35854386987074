import React from "react";
import ListPengerjaan from "./ListPengerjaan";
import { PageLoader } from "../../assets/images";
import { useTranslation } from "react-i18next";
import { Cerita } from "../item";
import moment from "moment";

moment.locale("id");
const ContentPenilaian = ({
  handleChangePoint,
  loading,
  selectedSiswa,
  activeTab,
  handleTab,
  hasilPengerjaan,
  mulai,
  selesai,
}) => {
  const { t } = useTranslation();
  return (
    <div className="col-lg-8">
      <div className="iq-card">
        <div className="iq-card-header d-flex align-items-center">
          <h4 className="card-title m-0">{t("soal_jawaban")}</h4>
        </div>
        {(mulai || selesai) && (
          <div className="iq-card-body">
            <div className="card bg-primary">
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table
                    id="datatable"
                    className="table table-borderless text-white mb-0"
                  >
                    <tbody>
                      <tr>
                        <td className="border-0 fw-bold">
                          {t("mulai_mengerjakan")}
                        </td>
                        <td className="border-0">
                          {moment(mulai).format("DD MMMM YYYY (HH:mm)")}
                        </td>
                      </tr>
                      <tr>
                        <td className="border-0 fw-bold">
                          {t("selesai_mengerjakan")}
                        </td>
                        <td className="border-0">
                          {moment(selesai).format("DD MMMM YYYY (HH:mm)")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
        {loading ? (
          <div className="text-center">
            <img src={PageLoader} alt="loader" width={50} />
          </div>
        ) : selectedSiswa ? (
          <div className="col-lg-12 p-0">
            <div className="iq-card-body">
              {/* Tab */}
              <ul className="nav nav-pills">
                <li className="nav-item">
                  <button
                    className={`nav-link text-primary ${
                      activeTab === "soal_essay" && "active"
                    }`}
                    onClick={() => handleTab("soal_essay")}
                  >
                    {t("isian_uraian")}
                    <span className="badge badge-danger ms-2">
                      {hasilPengerjaan?.soal_essay?.length}
                    </span>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link text-primary ${
                      activeTab === "soal_lain" && "active"
                    }`}
                    onClick={() => handleTab("soal_lain")}
                  >
                    {t("soal_lain")}
                    <span className="badge badge-danger ms-2">
                      {hasilPengerjaan?.soal_lain?.length}
                    </span>
                  </button>
                </li>
              </ul>
            </div>
            <div className="p-3">
              {hasilPengerjaan?.[`${activeTab}_cerita`]?.map((item, index) => (
                <Cerita
                  key={crypto.randomUUID()}
                  data={item}
                  index={index}
                  penilaian={true}
                  dataSoal={hasilPengerjaan?.[activeTab]}
                />
              ))}
              {hasilPengerjaan?.[activeTab]?.length <= 0 ? (
                <p className="text-center m-0 text-muted pb-4 text-capitalize">
                  {t("data_tidak_ada")}
                </p>
              ) : hasilPengerjaan?.[activeTab]?.length > 0 ? (
                hasilPengerjaan?.[activeTab]?.map((item, idx) => (
                  <ListPengerjaan
                    handleChangePoint={handleChangePoint}
                    idx={idx}
                    item={item}
                    soalJenis={item?.soal_jenis}
                    key={crypto.randomUUID()}
                  />
                ))
              ) : (
                <p className="text-center m-0 text-muted pb-4">
                  {t("siswa_blm_mengerjakan")}
                </p>
              )}
            </div>
          </div>
        ) : (
          <p className="text-center m-0 text-muted py-4">
            {t("pilih_siswa_dahulu")}
          </p>
        )}
      </div>
    </div>
  );
};

export default ContentPenilaian;
