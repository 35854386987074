import React from "react";
import { Logo } from "../../assets/images";

const CourseTugasInfo = ({ title, message, button }) => {
  return (
    <div
      className="container d-flex justify-content-center align-items-center h-screen flex-column"
      style={{ maxWidth: 2100, height: "100vh", overflow: "hidden" }}
    >
      <img src={Logo} className="img-fluid mb-3 max-w-400" alt="logo" />
      <h4 className="fw-bold mb-3">{title}</h4>
      <p className="text-muted max-w-600 text-center">{message}</p>
      {button}
    </div>
  );
};

export default CourseTugasInfo;
