import { API, setAuthToken } from "../../config/api";
import { GET_PELAJARAN } from "../constant";

export const getPelajaran = (params) => {
  return {
    type: GET_PELAJARAN,
    payload: async () => {
      const token = localStorage.getItem("token");
      setAuthToken(token);
      let stringParams = params ? new URLSearchParams(params).toString() : "";
      let res = await API.get(
        `kelas/mata-pelajaran${stringParams ? "?" + stringParams : ""}`
      ).catch((err) => {
        return Promise.reject(err);
      });
      return res?.data?.data;
    },
  };
};

export const getDetailPelajaran = (id, params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(
    `kelas/mata-pelajaran/${id}?${new URLSearchParams(params).toString()}`
  );
};

export const getListSiswa = (id, params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(
    `kelas/list-siswa/${id}?${new URLSearchParams(params).toString()}`
  );
};

export const getPelajaranByKelas = (kelas_id, params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(
    `kelas/mata-pelajaran/all/${kelas_id}?${new URLSearchParams(
      params
    ).toString()}`
  );
};
