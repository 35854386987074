import React, { useCallback, useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  CourseTugasInfo,
  ItemSoalPilgan,
  LoadingPengerjaan,
  ModalNoSoalTugasCourse,
  Soal,
} from "../../components";
import {
  getDetailTugasCourse,
  kerjakanUlangCourse,
  mulaiTugasCourse,
  simpanTugasCourse,
  submitJawbanTugasCourse,
} from "../../redux/actions/courseAction";

const TugasCourse = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const { user } = useSelector(({ user }) => user);
  const [detail, setDetail] = useState(null);
  const [pengerjaan, setPengerjaan] = useState(null);
  const [progress, setProgress] = useState(0);
  const [idxSoal, setIdxSoal] = useState(0);
  const [submitJawaban, setSubmitJawaban] = useState(false);
  const [showNoSoal, setShowNoSoal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingUlang, setLoadingUlang] = useState(false);

  const handleMulai = useCallback((start) => {
    const data = { user_id: user?.user_id };
    setSubmitJawaban(true);
    mulaiTugasCourse(id, data)
      .then((res) => {
        if (start) {
          getDetail();
        }
        setPengerjaan(res);
        setSubmitJawaban(false);
        setLoadingUlang(false);
      })
      .catch(() => {
        setSubmitJawaban(false);
        setLoadingUlang(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showAlert = useCallback(
    (type, title, text, notAllow = false, finish = false) => {
      Swal.fire({
        title: title,
        text: text,
        icon: type,
        imageWidth: 92,
        imageHeight: 92,
        confirmButtonText: "Ok",
        confirmButtonColor: "#6fbd44",
      }).then(() => {
        if (notAllow && !finish) {
          window.location.reload();
        } else if (notAllow && finish) {
          setTimeout(() => {
            navigate(-1);
          }, 1000);
        }
      });
    },
    [navigate]
  );

  const getDetail = useCallback(() => {
    const data = { user_id: user?.user_id };
    getDetailTugasCourse(id, data)
      .then((res) => {
        setDetail(res);
        if (res?.tugas_join && !pengerjaan) {
          handleMulai();
        }
      })
      .catch((err) => {
        let error = err?.response?.data;
        setLoading(false);
        showAlert("error", "Error", error?.message, true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.user_id, id, handleMulai, showAlert]);

  useEffect(() => {
    document.title = `${
      detail?.tugas_title || t("tugas")
    } | Smartclass Bakti Mulya 400`;
  }, [detail, t]);

  useEffect(() => {
    if (pengerjaan?.soal) {
      let calculate = pengerjaan?.soal?.filter(
        (item) => item.pengerjaan_tugas !== null
      );
      setProgress(calculate?.length);
    }
  }, [pengerjaan]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getDetail();
    }
  }, [getDetail, id]);

  const handleSubmitJawab = (jawaban) => {
    setSubmitJawaban(true);
    const params = {
      user_id: user?.user_id,
      soal_id: pengerjaan?.soal[idxSoal]?.soal_id,
      tugas_join_id: pengerjaan?.tugas_join_id,
      jawaban: jawaban,
    };
    submitJawbanTugasCourse(id, params)
      .then(() => {
        handleMulai();
        setSubmitJawaban(false);
      })
      .catch((err) => {
        setSubmitJawaban(false);
      });
  };

  const handleNext = () => {
    setIdxSoal(
      idxSoal + 1 < pengerjaan?.soal?.length
        ? idxSoal + 1
        : pengerjaan?.soal?.length - 1
    );
  };

  const handlePrev = () => {
    setIdxSoal(idxSoal - 1 <= 0 ? 0 : idxSoal - 1);
  };

  if (loading && detail === null) {
    return <LoadingPengerjaan data="tugas" />;
  }

  const sendTugas = () => {
    setSubmitJawaban(true);
    const data = {
      user_id: user?.user_id,
      tugas_join_id: pengerjaan?.tugas_join_id,
    };
    simpanTugasCourse(id, data)
      .then((res) => {
        showAlert(
          "success",
          t("berhasil"),
          t("berhasil_dikirim", { data: t("tugas") }),
          true,
          res?.data?.tugas_finish === 1
        );
      })
      .catch(() => {
        showAlert(
          "error",
          t("gagal"),
          t("gagal_dikirim", { data: t("tugas") }),
          false
        );
        setSubmitJawaban(false);
      });
  };

  const handleSelesai = () => {
    Swal.fire({
      title: t("konfirmasi"),
      text: t("apakah_anda_yakin_mensubmit", { data: t("tugas") }),
      icon: "question",
      imageWidth: 92,
      imageHeight: 92,
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      showCancelButton: true,
      reverseButtons: true,
    }).then((res) => {
      if (res.isConfirmed) {
        sendTugas();
      }
    });
  };

  function buttonBack(link) {
    return (
      <Link to={link} className="btn btn-primary">
        {t("kembali")}
      </Link>
    );
  }

  const handleKerjakanUlang = () => {
    setLoadingUlang(true);
    const data = {
      user_id: user?.user_id,
      tugas_id: id,
    };
    kerjakanUlangCourse(data)
      .then(() => {
        getDetail();
      })
      .catch(() => {
        showAlert("error", t("gagal"), t("terjadi_kesalahan"), false);
        setLoadingUlang(false);
      });
  };

  function buttonKKM() {
    return (
      <div className="d-flex gap-2">
        <Link to={`/course/${detail?.course_id}`} className="btn btn-secondary">
          {t("kembali_ke_course")}
        </Link>
        <button
          disabled={loadingUlang}
          onClick={handleKerjakanUlang}
          className="btn btn-primary"
        >
          {t("kerjakan_ulang")}
        </button>
      </div>
    );
  }

  const handleMulaiKerjakan = () => {
    setLoadingUlang(true);
    handleMulai(true);
  };

  function buttonKerjakan() {
    return (
      <div className="d-flex gap-2">
        <Link to={`/course/${detail?.course_id}`} className="btn btn-secondary">
          {t("kembali_ke_course")}
        </Link>
        <button
          disabled={loadingUlang}
          onClick={handleMulaiKerjakan}
          className="btn btn-primary"
        >
          {t("kerjakan_tugas")}
        </button>
      </div>
    );
  }

  if (!detail?.tugas_join) {
    return (
      <CourseTugasInfo
        button={buttonKerjakan()}
        message={t("mulai_kerjakan_tugas")}
        title={detail?.tugas_title}
      />
    );
  }

  const lolosKKM = detail?.tugas_join?.tugas_nilai >= detail?.tugas_kkm;

  if (detail?.tugas_join?.tugas_simpan && lolosKKM) {
    return (
      <CourseTugasInfo
        button={buttonBack(`/course/${detail?.course_id}`)}
        message={t("sudah_mensubmit_tugas")}
        title={t("selesai")}
      />
    );
  } else if (detail?.tugas_join?.tugas_simpan && !lolosKKM) {
    return (
      <CourseTugasInfo
        button={buttonKKM()}
        message={t("tugas_course_kkm_msg", {
          point: detail?.tugas_join?.tugas_nilai,
          soal: pengerjaan?.soal?.length,
          kkm: detail?.tugas_kkm,
        })}
        title={t("gagal")}
      />
    );
  }

  return (
    <div id="content-page" className="content-page p-3">
      <div className="container" style={{ maxWidth: 2100 }}>
        <div className="row">
          {/* Progress */}
          <div className="col-6">
            <ProgressBar
              animated
              now={(progress / pengerjaan?.soal?.length) * 100 || 0}
              className="bg-dark rounded-pill"
              style={{ height: 40, maxWidth: 300 }}
              label={`Progress: ${
                Math.round((progress / pengerjaan?.soal?.length) * 100) || 0
              }%`}
            />
          </div>
          {/* Soal */}
          <div className="col-12 mt-4">
            <div className="iq-card">
              {/* Header Soal */}
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title d-flex gap-8">
                  <h4 className="card-title fw-bold">{pengerjaan?.title}</h4>
                </div>
                <div className="d-flex gap-2">
                  <button
                    onClick={() => setShowNoSoal(true)}
                    type="button"
                    className="btn btn-sm btn-primary font-weight-bold text-center"
                  >
                    <i className="ri-list-unordered m-1"></i>
                  </button>
                  <div className="text-dark font-size-20">
                    <span className="font-size-20 fw-bold">
                      {t("soal")} {idxSoal + 1}
                    </span>{" "}
                    / {pengerjaan?.soal?.length}
                  </div>
                </div>
              </div>
              {/* Pertanyaan */}
              <div className="col-lg-12 p-0">
                <div className="iq-card">
                  <div className="iq-card-body">
                    <div className="row">
                      <Soal
                        soal_file={pengerjaan?.soal[idxSoal]?.soal_file}
                        soal_file_type={
                          pengerjaan?.soal[idxSoal]?.soal_file_type
                        }
                        soal_pertanyaan={
                          pengerjaan?.soal[idxSoal]?.soal_pertanyaan
                        }
                      />
                      <div className="col-span-12">
                        <ItemSoalPilgan
                          pilgan={pengerjaan?.soal?.[idxSoal]?.soal_pg}
                          kerjakan={pengerjaan?.tugas_finish === 0}
                          dipilih={
                            pengerjaan?.soal?.[idxSoal]?.pengerjaan_tugas
                              ?.pengerjaan_pg?.pengerjaan_jawaban
                          }
                          handlePilih={handleSubmitJawab}
                          preview={pengerjaan?.tugas_finish === 1}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-2">
            <div className="d-flex align-items-center justify-content-end gap-8 opacity-1">
              <button
                type="button"
                name="previous"
                className="btn btn-dark previous action-button-previous"
                value="Previous"
                onClick={handlePrev}
                disabled={submitJawaban}
              >
                <i className="ri-arrow-left-s-line font-size-20 p-0 fw-bold m-0"></i>
              </button>

              {idxSoal + 1 === pengerjaan?.soal?.length ? (
                <button
                  type="button"
                  name="next"
                  className="btn btn-primary next action-button d-flex align-items-center"
                  value="Next"
                  onClick={handleSelesai}
                  disabled={submitJawaban}
                >
                  <span>{t("selesai")}</span>
                  <i className="ri-arrow-right-s-line font-size-20 p-0 fw-bold m-0"></i>
                </button>
              ) : (
                <button
                  type="button"
                  name="next"
                  className="btn btn-primary next action-button"
                  value="Next"
                  onClick={handleNext}
                  disabled={submitJawaban}
                >
                  <i className="ri-arrow-right-s-line font-size-20 p-0 fw-bold m-0"></i>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalNoSoalTugasCourse
        show={showNoSoal}
        handleClose={() => setShowNoSoal(false)}
        dataSoal={pengerjaan?.soal}
        handleClick={(val) => {
          setIdxSoal(val);
          setShowNoSoal(false);
        }}
      />
    </div>
  );
};

export default TugasCourse;
