import "moment/locale/id";

import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import { ItemPreviewRating } from "../item";

moment.locale("id");
function HeaderBuyCourse({
  heading,
  title,
  release_date,
  author,
  rating,
  review,
  subscribe,
  sertifikat,
  marked,
  handleMarked,
}) {
  const { t } = useTranslation();
  return (
    <div className="col-lg-12 text-center position-relative d-none d-lg-block">
      <div className="position-absolute end-0 top-0 py-2 pe-4 d-none d-lg-block">
        <button onClick={handleMarked} className="bg-transparent border-0">
          <i
            className={`ri-bookmark-fill ri-2x mr-2 ${
              marked ? "text-warning" : "text-secondary"
            }`}
          ></i>
        </button>
        <button className="bg-transparent border-0">
          <i className="ri-share-fill ri-2x mr-2 text-secondary"></i>
        </button>
      </div>
      <div className="iq-card">
        <div className="iq-card-body">
          <h3 className="mb-2 text-center fw-bold heading-course mx-auto">
            {heading}
          </h3>
          <p className="text-dark">{title}</p>
          <div className="row justify-content-center mt-4">
            <div className="col-6 col-lg-3">
              <div className="d-flex align-items-center gap-2 flex-column flex-md-row justify-content-center">
                <i className="ri-global-line text-success"></i>
                <h6>
                  {t("tanggal_rilis", {
                    data: moment(release_date).format("MMMM YYYY"),
                  })}
                </h6>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="d-flex align-items-center gap-2 flex-column flex-md-row justify-content-center">
                <i className="ri-user-voice-line text-success"></i>
                <h6>{author}</h6>
              </div>
            </div>
          </div>

          <div className="row justify-content-center mt-4">
            <div className="col-6 col-lg-2 text-center mb-4">
              <div className="media-support-info">
                <h6>Rating</h6>
                <p className="mb-0 mt-3 fw-bold font-size-20">
                  {rating} <ItemPreviewRating rating={rating} />
                </p>
              </div>
            </div>
            <div className="col-6 col-lg-2 text-center mb-4">
              <div className="media-support-info">
                <h6>Review</h6>
                <p className="mb-0 fw-bold mt-3 font-size-20">{review}</p>
              </div>
            </div>
            <div className="col-6 col-lg-2 text-center mb-4">
              <div className="media-support-info">
                <h6>Subscribe</h6>
                <p className="mb-0 mt-3 fw-bold font-size-20">{subscribe}</p>
              </div>
            </div>
            <div className="col-6 col-lg-2 text-center mb-4">
              <div className="media-support-info">
                <h6>Sertifikat</h6>
                {sertifikat ? (
                  <i className="text-primary ri-checkbox-circle-fill fa-2x"></i>
                ) : (
                  <i className="text-default ri-close-circle-fill fa-2x"></i>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderBuyCourse;
