import React from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { delMultipleTopik } from "../../redux/actions/topikAction";
import { Link } from "react-router-dom";

const ActionMateri = ({
  jmlSelectedTopik,
  selectedTopik,
  topik,
  handleCheckedAll,
  sortingData,
  handleShare,
  handleSorting,
  handleTopik,
  handleDiskusi,
  handleMateri,
  handleTugas,
  getDataTopik,
  handleAction,
  showAction,
  handleConvert,
  loadingConvert,
}) => {
  const { t } = useTranslation();
  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };
  const handleDeleteTopik = () => {
    Swal.fire({
      title: t("konfirmasi_hapus"),
      text: t("are_u_sure_delete", {
        params: t("topik"),
      }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendDeleteMultiple();
    });
  };
  const sendDeleteMultiple = () => {
    delMultipleTopik({ topik_id: selectedTopik })
      .then(() => {
        getDataTopik();
      })
      .catch(() => {
        showAlert(t("failed_delete_data", { data: t("topik") }), false);
      });
  };
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  return (
    <div className="col-12">
      <div className="row mb-3 justify-content-center justify-content-md-between">
        {!showAction && (
          <div className="col-12 col-lg-4 col-md-4 mb-3">
            <button
              onClick={handleAction}
              className="btn btn-info font-size-12 align-self-center d-flex text-white"
            >
              <i className="ri-checkbox-line"></i> {t("tandai")}
            </button>
          </div>
        )}
        {showAction && (
          <div className="col-12 d-flex align-items-center gap-2 mb-3">
            <div className="d-flex border rounded bg-white px-2 gap-1">
              <input
                className="rabsen"
                type="checkbox"
                name="all_topik"
                id="all-topik"
                style={{ width: 16 }}
                checked={jmlSelectedTopik === topik?.length}
                onChange={handleCheckedAll}
              />
              <label
                className="align-self-center m-0 line-height user-select-none cursor-pointer"
                htmlFor="all-topik"
              >
                {t("pilih_semua")}
              </label>
            </div>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="button-tooltip-2">{t("convert")}</Tooltip>}
            >
              <button
                disabled={jmlSelectedTopik <= 0 || loadingConvert}
                onClick={handleConvert}
                className="btn btn-sm btn-info align-self-center d-flex text-white"
              >
                <i className="ri-file-word-line m-0"></i> {t("convert")}
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="button-tooltip-2">{t("bagikan")}</Tooltip>}
            >
              <button
                disabled={jmlSelectedTopik <= 0 || loadingConvert}
                onClick={handleShare}
                className="btn btn-sm btn-warning align-self-center d-flex text-white"
              >
                <i className="ri-share-forward-line m-0"></i> {t("bagikan")}
              </button>
            </OverlayTrigger>
            <button
              disabled={jmlSelectedTopik <= 0 || loadingConvert}
              onClick={handleDeleteTopik}
              className="btn btn-sm btn-danger align-self-center d-flex"
            >
              <i className="ri-delete-bin-line m-0"></i>
            </button>
            <span className="align-self-center">|</span>
            <button
              disabled={loadingConvert}
              onClick={handleAction}
              className="btn btn-sm btn-secondary font-size-12 align-self-center d-flex"
            >
              <i className="ri-close-line m-0"></i>
            </button>
          </div>
        )}
        <div
          className={`col-12 ${
            showAction ? "col-md-12" : "col-md-8"
          } d-flex align-items-center justify-content-end mb-3`}
        >
          <div
            className={`custom-control custom-switch custom-switch-color custom-control-inline ${
              isMobile ? "" : "d-none"
            }`}
          >
            <input
              type="checkbox"
              className="custom-control-input bg-primary"
              id="switch-scrollable"
              value={sortingData}
              checked={sortingData}
              onChange={handleSorting}
            />
            <label className="custom-control-label" htmlFor="switch-scrollable">
              {t("pindah")}
            </label>
          </div>
          <div className="d-flex align-items-center gap-2">
            <Link
              to="/kelas"
              className="btn iq-bg-light iq-bg-primary-hover text-dark border"
            >
              <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
            </Link>
            <Dropdown align="end">
              <Dropdown.Toggle
                variant="success"
                className="btn-primary"
                id="dropdown-basic"
              >
                <i className="ri-add-line"></i>
                {t("tambah")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleTopik}>
                  <i className="ri-list-check"></i> {t("topik")}
                </Dropdown.Item>
                {/* <Dropdown.Item>
                      <i className="ri-clipboard-line"></i> Survey
                    </Dropdown.Item> */}
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleDiskusi}>
                  <i className="ri-group-line"></i> {t("diskusi")}
                </Dropdown.Item>
                <Dropdown.Item onClick={handleMateri}>
                  <i className="ri-book-mark-line"></i> {t("materi")}
                </Dropdown.Item>
                {/* <Dropdown.Item onClick={() => setShowAddLiveClass(true)}>
                      <i className="ri-video-chat-line"></i> Live Class
                    </Dropdown.Item> */}
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleTugas}>
                  <i className="ri-clipboard-line"></i> {t("tugas")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionMateri;
