import React, { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  sendThreadDiskusi,
  updThreadDiskusi,
  uploadFileDiskusi,
} from "../../redux/actions/diskusiAction";
import { PageLoader } from "../../assets/images";

const FormDiskusi = React.forwardRef(
  (
    {
      diskusiId,
      parentChatId,
      getThread,
      editDiskusi,
      pesan,
      img,
      handleClearEdit,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const inputImage = useRef(null);
    const inputChat = useRef(null);
    const [submit, setSubmit] = useState(false);
    const [imageChat, setImageChat] = useState(img);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [inputFocus, setInputFocus] = useState(false);
    useEffect(() => {
      setImageChat(img || null);
    }, [img]);

    const handleSubmit = (e) => {
      e.preventDefault();
      const chat = inputChat.current.innerText;
      setSubmit(true);
      const params = {
        diskusi_id: diskusiId,
        thread_text: chat,
        thread_image: imageChat,
        parent_id: parentChatId,
      };
      if (editDiskusi) {
        params.diskusi_thread_id = editDiskusi;
        sendUpdate(params);
      } else {
        sendCreate(params);
      }
    };
    const sendCreate = (params) => {
      sendThreadDiskusi(params)
        .then(() => {
          setSubmit(false);
          getThread();
          clearImage();
          inputChat.current.innerText = "";
        })
        .catch(() => {
          setSubmit(false);
        });
    };
    const sendUpdate = (params) => {
      updThreadDiskusi(params)
        .then(() => {
          setSubmit(false);
          getThread();
          clearImage();
          inputChat.current.innerText = "";
          handleClearEdit();
        })
        .catch(() => {
          setSubmit(false);
        });
    };
    const handleChangeImage = ({ target: { files } }) => {
      if (files.length > 0) {
        var formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append(`files`, files[i]);
        }
        setLoadingUpload(true);
        uploadFileDiskusi(formData)
          .then((res) => {
            setLoadingUpload(false);
            setImageChat(res?.data?.data[0]?.path);
          })
          .catch((err) => {
            setLoadingUpload(false);
            console.log("err", err);
          });
      }
    };
    const clearImage = () => {
      setImageChat(null);
      inputImage.current.value = null;
    };
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) handleClearEdit();
    };
    return (
      <Fragment>
        <form
          id={`form-diskusi-${parentChatId}`}
          ref={ref}
          onSubmit={handleSubmit}
          className={`comment-text d-flex align-items-center mt-3 ${
            parentChatId ? "ms-5" : ""
          }`}
        >
          <div className="comment-button position-absolute ps-2 d-flex">
            <input
              type="file"
              name="image"
              ref={inputImage}
              accept="image/png, image/jpg, image/jpeg"
              className="d-none"
              onChange={handleChangeImage}
            />
            <button
              disabled={submit || loadingUpload}
              onClick={() =>
                imageChat ? clearImage() : inputImage.current?.click()
              }
              type="button"
              className="border-0 d-flex align-items-center justify-content-center p-0 rounded-pill overflow-hidden position-relative"
            >
              {loadingUpload ? (
                <img
                  src={PageLoader}
                  alt="loader"
                  style={{ height: "34px" }}
                  className="mx-auto"
                />
              ) : imageChat ? (
                <>
                  <img
                    src={imageChat}
                    alt="chat"
                    className="w-100 h-100 fit-image"
                  />
                  <div className="w-100 h-100 position-absolute content-close d-flex align-items-center justify-content-center">
                    <i className="ri-close-line mr-3 position-absolute text-danger"></i>
                  </div>
                </>
              ) : (
                <i className="ri-camera-line mr-3"></i>
              )}
            </button>
          </div>
          <div
            ref={inputChat}
            contentEditable
            className="w-full border px-5 form-control h-auto input-chat rounded"
            data-placeholder={t("tulis_pesan")}
            onKeyDown={handleKeyDown}
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
          >
            {pesan}
          </div>
          <div className="comment-button position-absolute pe-2 d-flex btn-send">
            <button
              disabled={submit || loadingUpload}
              type="submit"
              className="border-0 d-flex align-items-center justify-content-center p-0 rounded-pill"
            >
              {submit ? (
                <img
                  src={PageLoader}
                  alt="loader"
                  style={{ height: "34px" }}
                  className="mx-auto"
                />
              ) : (
                <i className="ri-send-plane-2-line mr-3"></i>
              )}
            </button>
          </div>
        </form>
        {editDiskusi ? (
          <div
            className={`d-flex text-muted gap-1 mt-1 font-12 ${
              parentChatId ? "ms-5" : ""
            }`}
          >
            <span className={inputFocus ? "d-block" : "d-none"}>
              tekan ESC untuk
            </span>{" "}
            <u
              className="cursor-pointer text-primary"
              onClick={handleClearEdit}
            >
              batal
            </u>
          </div>
        ) : null}
      </Fragment>
    );
  }
);

export default FormDiskusi;
