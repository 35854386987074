import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

import { getDetailWeekly } from "../../redux/actions/absenAction";

function ModalDetailWeekly({ show, handleClose, userId, pekan_id }) {
  const { t } = useTranslation();
  const [detail, setDetail] = useState([]);
  useEffect(() => {
    function getData() {
      getDetailWeekly({ user_id: userId, pekan_id }).then((res) =>
        setDetail(res)
      );
    }
    getData();
  }, [userId, pekan_id]);

  return (
    <div className="col-lg-12">
      <Modal
        size="lg"
        show={show}
        onHide={() => handleClose()}
        id="ModalDetailWeekly"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title">{t("detail_penilaian_guru")}</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <table id="datatable" className="table table-bordered">
              <thead className="table-primary text-white">
                <tr>
                  <th className="bg-transparent text-center" width="5%">
                    No
                  </th>
                  <th className="bg-transparent" width="20%">
                    {t("mata_pelajaran")}
                  </th>
                  <th className="bg-transparent text-center">{t("materials")}</th>
                  <th className="bg-transparent text-center">{t("assessment")}</th>
                </tr>
              </thead>
              <tbody>
                {detail?.length <= 0 ? (
                  <tr>
                    <td colSpan={4} className="text-center">
                      {t("data_kosong")}
                    </td>
                  </tr>
                ) : (
                  detail?.map((item, idx) => (
                    <tr key={idx + 1}>
                      <td>{idx + 1}</td>
                      <td>{item?.kelas_mapel?.mapel?.mapel_nama}</td>
                      <td>{item?.weekly_catatan_materi}</td>
                      <td>{item?.weekly_catatan_tugas}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalDetailWeekly;
