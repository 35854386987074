import React from "react";

const LoadingTopikCourse = () => {
  return (
    <div>
      <div className="iq-card py-4 px-3">
        <div className="placeholder-wave">
          <div className="bg-secondary square-48 placeholder rounded-circle col-5"></div>
          <div className="bg-secondary placeholder placeholder-lg col-8 ms-3"></div>
        </div>
      </div>
      <div className="iq-card py-4 px-3">
        <div className="placeholder-wave">
          <div className="bg-secondary square-48 placeholder rounded-circle col-5"></div>
          <div className="bg-secondary placeholder placeholder-lg col-8 ms-3"></div>
        </div>
      </div>
      <div className="iq-card py-4 px-3">
        <div className="placeholder-wave">
          <div className="bg-secondary square-48 placeholder rounded-circle col-5"></div>
          <div className="bg-secondary placeholder placeholder-lg col-8 ms-3"></div>
        </div>
      </div>
      <div className="iq-card py-4 px-3">
        <div className="placeholder-wave">
          <div className="bg-secondary square-48 placeholder rounded-circle col-5"></div>
          <div className="bg-secondary placeholder placeholder-lg col-8 ms-3"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingTopikCourse;
