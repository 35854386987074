import "moment/locale/id";

import moment from "moment";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { ActionData, FormDiskusi, RenderDiskusi } from "..";
import {
  delDiskusi,
  getDiskusiThread,
  updDiskusi,
  updStatusDiskusi,
} from "../../redux/actions/diskusiAction";
import { checkIsGuru, checkIsOrtu } from "../../utils/roleUser";
import ShareDiskusi from "../modal/ShareDiskusi";
import ItemFile from "./ItemFile";
import ItemFileVideo from "./ItemFileVideo";

moment.locale("id");

/**
 * Renders a single item of a discussion thread.
 *
 * @param {Object} props - The component props.
 * @param {string} props.judul - The title of the discussion item.
 * @param {string} props.desc - The description of the discussion item.
 * @param {Array} props.files - An array of files attached to the discussion item.
 * @param {Array} props.threads - An array of comments (threads) in the discussion item.
 * @param {string} props.updatedAt - The date and time when the discussion item was last updated.
 * @param {string} props.code - The unique code/id of the discussion item.
 * @param {string} props.status - The status of the discussion item (empty string for visible, "Hide" for hidden).
 * @param {string} props.sekolahId - The id of the sekolah.
 * @param {Array} props.dataVideo - An array of video files attached to the discussion item.
 * @param {Function} props.handleEdit - A callback function to handle the edit action of the discussion item.
 * @param {Array} props.h5p - An array of H5P files attached to the discussion item.
 * @param {Function} props.getDataTopik - A callback function to get the discussion data.
 * @param {Object} props.dataDiskusi - The data of the discussion item.
 * @param {integer} props.idTopik - The id of the topik item.
 * @param {integer} props.idMapel - The id of the topik item.
 * @returns {JSX.Element} The rendered JSX UI of the discussion item component.
 */
function ItemDiskusi({
  judul,
  desc,
  files,
  threads,
  updatedAt,
  code,
  status,
  dataVideo,
  handleEdit,
  h5p,
  getDataTopik,
  dataDiskusi,
  sekolahId,
  idTopik,
  idMapel,
}) {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [dataThread, setDataThread] = useState(threads);
  const { user } = useSelector(({ user }) => user);
  const [editDiskusi, setEditDiskusi] = useState(null);
  const [shareDiskusi, setShareDiskusi] = useState(false);

  const userOrtu = checkIsOrtu(user?.user_roleid);
  const userGuru = checkIsGuru(user?.user_roleid);

  const getThread = () => {
    setEditDiskusi(null);
    getDiskusiThread(code).then((res) => setDataThread(res?.data?.data));
  };

  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };

  const deleteFile = (code, type) => {
    let params = dataDiskusi;
    if (type === "file") {
      params.files = params?.files?.filter(
        (item) => item.filediskusi_id !== code
      );
    } else if (type === "h5p") {
      params.h5p_diskusi = params?.h5p_diskusi?.filter(
        (item) => item.h5p_diskusi_id !== code
      );
    } else if (type === "video") {
      params.video_diskusi = params?.video_diskusi?.filter(
        (item) => item.video_diskusi_id !== code
      );
    }
    updDiskusi(params.diskusi_id, params)
      .then(() => {
        getDataTopik();
      })
      .catch(() =>
        showAlert(t("gagal_hapus", { data: t("file_diskusi") }), false)
      );
  };

  const handleDeleteFile = (code, type) => {
    deleteFile(code, type);
  };

  const confirmDelete = () => {
    Swal.fire({
      title: t("konfirmasi_hapus"),
      text: t("are_u_sure_delete", {
        params: t("data_ini", { data: t("diskusi") }),
      }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendDeleteDiskusi();
    });
  };

  const handleDelete = () => {
    confirmDelete();
  };

  const sendDeleteDiskusi = () => {
    delDiskusi(code)
      .then(() => {
        getDataTopik();
      })
      .catch(() => {
        showAlert(t("failed_delete_data", { data: t("diskusi") }), false);
      });
  };

  const handleChangeStatus = () => {
    let status = dataDiskusi.status === "" ? "Hide" : null;
    Swal.fire({
      title: t("konfirmasi_edit"),
      text: t("are_u_sure_edit", {
        data: t("diskusi"),
        status: t(status ? "sembunyikan" : "tampilkan"),
      }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendStatusDiskusi(status);
    });
  };

  const sendStatusDiskusi = (status) => {
    const params = {
      diskusi_id: code?.toString(),
      status,
    };
    updStatusDiskusi(params)
      .then(() => {
        getDataTopik();
      })
      .catch(() => {
        showAlert(t("failed_edit_data", { data: t("diskusi") }), false);
      });
  };

  const handleShare = () => setShareDiskusi(code);

  return (
    <li>
      <div
        id="topik-item-detail"
        className="timeline-dots border-warning"
      ></div>
      <div className="d-flex align-items-md-center justify-content-between mb-1 flex-column flex-md-row">
        <div className="d-flex align-items-md-center gap-2 flex-column flex-md-row">
          <h6 className="m-0">{judul}</h6>
          {userGuru && (
            <ActionData
              handleDelete={handleDelete}
              handleEdit={() => handleEdit(code)}
              handleShare={handleShare}
              handleStatus={handleChangeStatus}
              showBtnDelete
              showBtnEdit
              showBtnShare
              showBtnStatus
              statusShow={status === ""}
            />
          )}
        </div>
        <p className="font-size-12 m-0">
          {updatedAt ? moment(updatedAt).format("DD MMMM YYYY") : ""}
        </p>
      </div>
      {desc && (
        <div className="d-inline-block w-100">
          <div
            dangerouslySetInnerHTML={{ __html: desc }}
            className="preview-html"
          ></div>
        </div>
      )}
      {dataVideo?.map((item) => (
        <ItemFileVideo
          key={item.video_diskusi_id}
          name={item.video_name}
          type={item.video_type}
          url={item.video_file}
          handleDeleteFile={() =>
            handleDeleteFile(item.video_diskusi_id, "video")
          }
          item={item}
        />
      ))}
      {files?.map((item) => (
        <ItemFile
          key={item.filediskusi_id}
          name={item.filename}
          type={item.type}
          url={item.path}
          handleDeleteFile={() => handleDeleteFile(item.filediskusi_id, "file")}
        />
      ))}
      {h5p?.map((item) => (
        <ItemFile
          key={item.h5p_diskusi_id}
          name={item.h5p_filename}
          type="html"
          url={item.h5p_file}
          handleDeleteFile={() => handleDeleteFile(item.h5p_diskusi_id, "h5p")}
        />
      ))}
      <hr />
      <ul className="post-comments p-0 m-0">
        {dataThread?.map((pesan) => (
          <RenderDiskusi
            userId={pesan?.detail?.user_id}
            avatar={pesan?.detail?.user_avatar}
            code={pesan?.diskusi_thread_id}
            key={pesan?.diskusi_thread_id}
            nama={pesan?.detail?.user_nama}
            subThread={pesan?.sub_thread}
            imgPesan={pesan?.thread_image}
            text={pesan?.thread_text}
            updatedAt={pesan?.updatedAt}
            diskusiId={code}
            getThread={getThread}
            userOrtu={userOrtu}
            userGuru={userGuru}
            handleEdit={() => {
              setEditDiskusi(pesan);
              inputRef.current?.scrollIntoView({ behavior: "smooth" });
            }}
          />
        ))}
      </ul>
      {!userOrtu && (
        <FormDiskusi
          ref={inputRef}
          diskusiId={code}
          getThread={getThread}
          editDiskusi={editDiskusi?.diskusi_thread_id}
          img={editDiskusi?.thread_image}
          pesan={editDiskusi?.thread_text}
          handleClearEdit={() => setEditDiskusi(null)}
        />
      )}
      {shareDiskusi && (
        <ShareDiskusi
          show={shareDiskusi !== null}
          diskusiId={shareDiskusi}
          sekolahId={sekolahId}
          handleClose={() => {
            setShareDiskusi(null);
          }}
          idTopik={idTopik}
          idMapel={idMapel}
        />
      )}
    </li>
  );
}

export default ItemDiskusi;
