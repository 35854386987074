import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { PageLoader } from "../../../assets/images";
import { getListSiswa } from "../../../redux/actions/pelajaranAction";
import { checkIsOrtu } from "../../../utils/roleUser";
import { useSelector } from "react-redux";

function Anggota({ detailKelas }) {
  const { t } = useTranslation();
  const [siswa, setSiswa] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { user } = useSelector(({ user }) => user);
  const { selectedAnak } = useSelector(({ anak }) => anak);
  useEffect(() => {
    setLoading(true);
    if (detailKelas?.kelasmapel_kelasdetailid) {
      let params = new URLSearchParams(location.search);
      let th = params.get("th");
      let smt = params.get("smt");
      let data = {};
      if (th) data["tahunpelajaran_id"] = th;
      if (smt) data["semester_id"] = smt;
      if (checkIsOrtu(user?.user_roleid))
        data["user_id"] = selectedAnak?.user_id;
      getListSiswa(detailKelas?.kelasmapel_kelasdetailid, data)
        .then((res) => {
          setSiswa(res?.data?.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [detailKelas, location.search, selectedAnak, user]);
  return (
    <div className="tab-pane fade" id="anggota" role="tabpanel">
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title d-flex align-items-center justify-content-between w-100">
            <h4 className="card-title">{t("siswa")}</h4>
            <Link
              to="/kelas"
              className="btn iq-bg-light iq-bg-primary-hover text-dark border"
            >
              <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
            </Link>
          </div>
        </div>
        <div className="iq-card-body">
          {loading ? (
            <div className="text-center">
              <img src={PageLoader} alt="loader" style={{ height: "100px" }} />
            </div>
          ) : (
            <ul className="request-list m-0 p-0">
              {siswa?.map((item) => (
                <li
                  className="d-flex align-items-center"
                  key={item.userkelas_userid}
                >
                  <div className="user-img img-fluid">
                    <img
                      src={item?.user?.user_avatar}
                      alt="story-img"
                      className="rounded-circle avatar-40"
                    />
                  </div>
                  <div className="media-support-info ms-3">
                    <h6>{item?.user?.user_nama}</h6>
                    {item?.user?.user_lastlogin ? (
                      <span className="text-primary">
                        <i className="ri-checkbox-blank-circle-fill text-success"></i>{" "}
                        <span className="text-capitalize">
                          {moment(item?.user?.user_lastlogin).fromNow()}
                        </span>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* <div className="d-flex align-items-center">
                  <Link to="/chat" className="me-3 btn btn-primary rounded">
                    <i className="ri-message-3-line"></i>Chat
                  </Link>
                </div> */}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default Anggota;
