import axios from "axios";

const ACCESS_TOKEN = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_BACKEND_API_URL_2;
const headers = {
  headers: {
    Authorization: ACCESS_TOKEN,
  },
};

export const getJenisTanggungan = async (user_id) => {
  let res = await axios.get(
    `${API_URL}/pembayaran/jenis_tanggungan?user_id=${user_id}`,
    headers
  );
  return res?.data;
};

export const getDataPembayaran = async (user_id, biaya_id) => {
  let res = await axios.get(
    `${API_URL}/pembayaran/data?user_id=${user_id}&biaya_id=${biaya_id}`,
    headers
  );
  return res?.data;
};

export const cetakTransaksi = async (idTransaksiPembayaran) => {
  let res = await axios.get(
    `${API_URL}/pembayaran/cetak/${idTransaksiPembayaran}`,
    headers
  );
  return res?.data;
};