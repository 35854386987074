import React from "react";
import { useTranslation } from "react-i18next";

import { generateRaporURL } from "../../utils/generateRaporURL";

function ButtonRaporMerdeka({
  tahun,
  semester,
  kelas,
  kelas_detail,
  user_id,
  sekolah,
  status,
}) {
  const { t } = useTranslation();
  const URL_ID = generateRaporURL(
    "identitas_merdeka",
    tahun,
    semester,
    kelas,
    kelas_detail,
    user_id,
    sekolah
  );
  const URL_MID = generateRaporURL(
    "mid_merdeka",
    tahun,
    semester,
    kelas,
    kelas_detail,
    user_id,
    sekolah,
    true
  );
  const URL_AKHIR = generateRaporURL(
    "akhir_merdeka",
    tahun,
    semester,
    kelas,
    kelas_detail,
    user_id,
    sekolah,
    true
  );
  if (status === "Tidak") {
    return (
      <div className="d-flex gap-1">
        <button className="btn btn-primary btn-sm" disabled>
          <i className="ri-printer-line"></i>
          {t("pelengkap")}
        </button>
        <button className="btn btn-primary btn-sm" disabled>
          <i className="ri-printer-line"></i>
          {t("rapor_mid")}
        </button>
        <button className="btn btn-primary btn-sm" disabled>
          <i className="ri-printer-line"></i>
          {t("rapor_akhir")}
        </button>
      </div>
    );
  }
  return (
    <div className="d-flex gap-1">
      <a
        href={URL_ID}
        className="btn btn-primary btn-sm"
        target="_blank"
        rel="noreferrer"
      >
        <i className="ri-printer-line"></i>
        {t("pelengkap")}
      </a>
      <a
        href={URL_MID}
        className="btn btn-primary btn-sm"
        target="_blank"
        rel="noreferrer"
      >
        <i className="ri-printer-line"></i>
        {t("rapor_mid")}
      </a>
      <a
        href={URL_AKHIR}
        className="btn btn-primary btn-sm"
        target="_blank"
        rel="noreferrer"
      >
        <i className="ri-printer-line"></i>
        {t("rapor_akhir")}
      </a>
    </div>
  );
}

export default ButtonRaporMerdeka;
