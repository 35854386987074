import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import { Wrapper } from "../../components";
import TambahBankSoal from "../../components/modal/TambahBankSoal";
import { delBankSoal, getBankSoal } from "../../redux/actions/bankSoalAction";

function BankSoal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dataSoal } = useSelector(({ bankSoal }) => bankSoal);
  const [openTambah, setOpenTambah] = useState(false);
  const [editSoal, setEditSoal] = useState(null);
  useEffect(() => {
    dispatch(getBankSoal());
  }, [dispatch]);
  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };
  const handleDelete = (code) => {
    Swal.fire({
      title: t("konfirmasi_hapus"),
      text: t("are_u_sure_delete", { params: t("bank_soal") }),
      icon: "question",
      confirmButtonText: "Yakin",
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: "Batal",
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendDelete(code);
    });
  };
  const sendDelete = (code) => {
    delBankSoal(code)
      .then(() => {
        dispatch(getBankSoal());
      })
      .catch(() => {
        showAlert(t("gagal_hapus", { data: t("bank_soal") }), false);
      });
  };
  return (
    <Wrapper title={`${t("bank_soal")}`}>
      <div className="container">
        <div className="row row-eq-height">
          <div className="col-lg-12">
            <div className="iq-card">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title d-flex">
                  <h4 className="card-title">{t("bank_soal")}</h4>
                </div>

                <div className="btn-group" role="group">
                  <Link
                    to="/"
                    className="btn iq-bg-light iq-bg-primary-hover text-dark border"
                  >
                    <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
                  </Link>
                  &nbsp;
                  <button
                    onClick={() => setOpenTambah(true)}
                    className="btn btn-primary text-center"
                  >
                    <i className="ri-add-line m-0"></i> {t("tambah")}
                  </button>
                </div>
              </div>
              <div className="col-lg-12 p-0">
                <div className="iq-card">
                  <div className="iq-card-body">
                    <div className="table-responsive table-hover">
                      <table
                        id="datatable"
                        className="table table-striped table-bordered"
                      >
                        <thead className="table-primary">
                          <tr>
                            <th className="bg-transparent text-white">No</th>
                            <th className="bg-transparent text-white">
                              {t("bank_soal")}
                            </th>
                            <th className="bg-transparent text-white">
                              {t("kelas")}
                            </th>
                            <th className="bg-transparent text-white">
                              {t("mata_pelajaran")}
                            </th>
                            <th className="bg-transparent text-white">
                              {t("tahun_pelajaran")}
                            </th>
                            <th
                              className="bg-transparent text-white"
                              width="15%"
                            >
                              {t("aksi")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataSoal?.length <= 0 ? (
                            <tr>
                              <td colSpan={6} className="text-center">
                                {t("data_kosong")}
                              </td>
                            </tr>
                          ) : (
                            dataSoal?.map((item, idx) => (
                              <tr key={item.bk_master_id}>
                                <td>{idx + 1}</td>
                                <td>{item.bk_master_nama}</td>
                                <td>{item.kelas.kelasdetail_nama}</td>
                                <td>{item.mapel?.mapel_nama}</td>
                                <td>
                                  {item.tahun_pelajaran.tahunpelajaran_nama}
                                </td>
                                <td>
                                  <Link
                                    to={`/banksoal/${item.bk_master_id}`}
                                    className="btn btn-primary btn-sm text-center"
                                  >
                                    <i className="ri-computer-line m-0"></i>
                                  </Link>
                                  &nbsp;
                                  <button
                                    onClick={() => {
                                      setEditSoal(item);
                                      setOpenTambah(true);
                                    }}
                                    className="btn btn-success btn-sm text-center"
                                  >
                                    <i className="ri-edit-line m-0"></i>
                                  </button>
                                  &nbsp;
                                  <button
                                    onClick={() =>
                                      handleDelete(item.bk_master_id)
                                    }
                                    className="btn btn-danger btn-sm text-center"
                                  >
                                    <i className="ri-delete-bin-line m-0"></i>
                                  </button>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TambahBankSoal
        show={openTambah}
        handleClose={() => {
          setOpenTambah(false);
          dispatch(getBankSoal());
        }}
        editSoal={editSoal}
      />
    </Wrapper>
  );
}

export default BankSoal;
