import React, { useEffect, useState } from "react";

function Countdown({ startTime, maxTime, handleSelesaiAuto, detail }) {
  const [countdown, setCountdown] = useState("00:00:00");

  useEffect(() => {
    let intervalCountdown;
    if (maxTime) {
      let dateNow = startTime
        ? new Date(startTime?.replace(" ", "T"))
        : new Date();
      let countDownDate = dateNow.setSeconds(
        dateNow.getSeconds() + (maxTime + 2)
      );
      intervalCountdown = setInterval(async () => {
        let now = new Date().getTime();
        let distance = countDownDate - now;
        let hours = Math.floor((distance / (1000 * 60 * 60)) % 24);
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);
        if (hours >= 0 && minutes >= 0 && seconds > 0) {
          hours = hours < 10 ? "0" + hours : hours;
          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;
          setCountdown(hours + ":" + minutes + ":" + seconds);
        }
        if (distance <= 0) {
          clearInterval(intervalCountdown);
          setCountdown("00:00:00");
          handleSelesaiAuto();
        }
      }, 1000);
    } else if (maxTime === 0 && detail !== null) {
      handleSelesaiAuto();
    }
    return () => {
      clearInterval(intervalCountdown);
    };
  }, [startTime, maxTime, handleSelesaiAuto, detail]);
  return (
    <div
      className="btn btn-dark text-warning d-flex align-items-center font-size-20 fw-bold w-fit float-right cursor-normal"
      style={{ height: 40 }}
    >
      <i className="ri-timer-line"></i>
      <span id="time">{countdown}</span>
    </div>
  );
}

export default Countdown;
