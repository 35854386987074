import React from "react";
import {
  MenjodohkanCard,
  PernyataanCard,
  PilihanGandaCard,
  PoolingCard,
  TrueFalseCard,
  UraianCard,
} from "../item";
import { useTranslation } from "react-i18next";

/**
 * Renders different types of question cards based on the `soalJenis` prop.
 * @param {Object} props - The component props.
 * @param {string} props.soalJenis - The type of question.
 * @param {Object} props.item - The data object for the question.
 * @param {number} props.idx - The index of the question in the list.
 * @param {Function} props.handleChangePoint - Callback function to handle point changes for an essay question.
 * @returns {JSX.Element} - The rendered question card component.
 */
const ListPengerjaan = ({ soalJenis, item, idx, handleChangePoint }) => {
  const { t } = useTranslation();

  switch (soalJenis) {
    case "pg":
      return (
        <PilihanGandaCard
          key={`${idx}-soal`}
          data={item}
          index={idx}
          penilaian={true}
        />
      );
    case "polling":
      return (
        <PoolingCard
          key={`${idx}-soal`}
          data={item}
          index={idx}
          penilaian={true}
        />
      );
    case "essay":
      return item.pengerjaan_soal === null ? (
        <p className="m-0 text-center text-muted">{t("belum_mengerjakan")}</p>
      ) : (
        <UraianCard
          key={`${idx}-soal`}
          data={item}
          index={idx}
          penilaian={true}
          handleChangePoint={({ target: { value } }) =>
            handleChangePoint(item?.pengerjaan_soal?.pengerjaan_soal_id, value)
          }
          files={item?.pengerjaan_soal?.pengerjaan_soal_file}
        />
      );
    case "true_false":
      return (
        <TrueFalseCard
          key={`${idx}-soal`}
          data={item}
          index={idx}
          penilaian={true}
        />
      );
    case "menjodohkan":
      return (
        <MenjodohkanCard
          key={`${idx}-soal`}
          data={item}
          index={idx}
          penilaian={true}
        />
      );
    case "pernyataan":
      return (
        <PernyataanCard
          key={`${idx}-soal`}
          data={item}
          index={idx}
          penilaian={true}
        />
      );
    default:
      return <></>;
  }
};

export default ListPengerjaan;
