import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import {
  getAbsensiKelas,
  getAbsensiSemester,
  getJenisAbsensi,
  getSiswaAbsensi,
  submitAbsensi,
} from "../../../redux/actions/absenAction";
import { Link } from "react-router-dom";

function AbsensiWalas() {
  const { t } = useTranslation();
  const [kelas, setKelas] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [semester, setSemester] = useState([]);
  const [jenisAbsensi, setJenisAbsensi] = useState([]);
  const [siswa, setSiswa] = useState([]);
  const [selectedKelas, setSelectedKelas] = useState("");
  const [params, setParams] = useState({
    semester_id: "",
    tahunpelajaran_id: "",
    kelasdetail_id: "",
    kehadiran_jenis_id: "",
    kehadiran_tanggal: "",
    kehadiran_type: "absensi_walas",
  });
  function checkProperties(obj) {
    for (var key in obj) {
      if (obj[key] === null || obj[key] === "") return false;
    }
    return true;
  }

  useEffect(() => {
    const date = new Date();
    const tgl = moment(date).format("YYYY-MM-DD");
    setParams((pref) => ({
      ...pref,
      kehadiran_tanggal: tgl,
    }));
    function getData() {
      getAbsensiKelas().then((res) => setKelas(res));
      getJenisAbsensi().then((res) => {
        setJenisAbsensi(res);
        if (res.length > 0) {
          setParams((pref) => ({
            ...pref,
            kehadiran_jenis_id: res[0].kehadiran_jenis_id,
          }));
        }
      });
    }
    getData();
  }, []);
  useEffect(() => {
    setSemester([]);
    function getData() {
      let check = kelas.filter(
        (item) => parseInt(item.id) === parseInt(selectedKelas)
      );
      let data = {
        kelasdetail_id: check[0]?.kelasdetail_id,
        tahunpelajaran_id: check[0]?.tahunpelajaran_id,
      };
      getAbsensiSemester(data).then((res) => setSemester(res));
    }
    if (selectedKelas) {
      getData();
    }
  }, [selectedKelas, kelas]);
  useEffect(() => {
    function getData() {
      getSiswaAbsensi("walikelas", params)
        .then((res) => {
          let allSiswa = res?.map((item) => ({
            userkelas_userid: item.userkelas_userid,
            kehadiran_nilai: item?.kehadiran_harian?.kehadiran_nilai || "H",
            kehadiran_keterangan:
              item?.kehadiran_harian?.kehadiran_keterangan || "",
            user: item?.user,
          }));
          setSiswa(allSiswa);
        })
        .catch(() => {
          showAlert("error", "Error", "Gagal mengambil data absensi");
          setSiswa([]);
        });
    }
    let check = checkProperties(params);
    if (check) {
      getData();
    }
  }, [params]);

  const handleChange = ({ target: { value, name } }) => {
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };

  const handleChangeKelas = ({ target: { value } }) => {
    let dataKelas = kelas?.filter(
      (item) => parseInt(item.id) === parseInt(value)
    );
    setParams((pref) => ({
      ...pref,
      kelasdetail_id: dataKelas.length > 0 ? dataKelas[0].kelasdetail_id : "",
      tahunpelajaran_id:
        dataKelas.length > 0 ? dataKelas[0].tahunpelajaran_id : "",
    }));
    setSelectedKelas(value);
  };
  const handleChangeParams = (value, name) => {
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };
  const handleChangeSiswa = (idx, name, value) => {
    let allSiswa = siswa;
    allSiswa[idx][name] = value;
    setSiswa([...allSiswa]);
  };
  const checkDataSiswa = () => {
    for (let i = 0; i < siswa.length; i++) {
      if (siswa[i].kehadiran_nilai === "") return false;
    }
    return true;
  };
  const showAlert = (type, title, text) => {
    Swal.fire({
      title: title,
      text: text,
      icon: type,
      imageWidth: 92,
      imageHeight: 92,
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };
  const handleSubmitAbsensi = () => {
    setSubmit(true);
    if (!checkDataSiswa()) {
      showAlert(
        "error",
        `${t("absensi")} ${t("gagal")}`,
        t("isi_semua_absensi")
      );
      setSubmit(false);
      return false;
    }
    let data = {
      ...params,
      siswa,
    };
    submitAbsensi("walikelas", data)
      .then((res) => {
        setSubmit(false);
        showAlert(
          "success",
          `${t("absensi")} ${t("berhasil")}`,
          t("berhasil_simpan", { params: t("absensi") })
        );
      })
      .catch((err) => {
        setSubmit(false);
        showAlert(
          "error",
          `${t("absensi")} ${t("gagal")}`,
          t("failed_save_data", { data: t("absensi") })
        );
      });
  };

  return (
    <div>
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title d-flex align-items-center justify-content-between w-100">
            <h4 className="card-title">Filter {t("kelas")}</h4>
            <Link
              to={`/`}
              className="btn iq-bg-light iq-bg-primary-hover text-dark border"
            >
              <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
            </Link>
          </div>
        </div>
        <div className="iq-card-body">
          <div className="row">
            <div className="col-12 col-md-4">
              <label htmlFor="kelasdetail_id" className="control-label">
                {t("kelas")} :
              </label>
              <select
                id="kelasdetail_id"
                className="form-control form-select"
                name="kelasdetail_id"
                onChange={handleChangeKelas}
                value={selectedKelas}
              >
                <option value="">
                  --{t("pilih_params", { params: t("kelas") })}--
                </option>
                {kelas?.map((e) => (
                  <option value={e.id} key={e.id}>
                    {e.kelasdetail_nama} ({e?.tahunpelajaran_nama})
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-md-4">
              <label htmlFor="semester" className="control-label">
                Semester :
              </label>
              <select
                className="form-control form-select"
                name="semester_id"
                id="semester_id"
                onChange={handleChange}
                value={params.semester_id}
              >
                <option value="">
                  --{t("pilih_params", { params: "Semester" })}--
                </option>
                {semester?.map((e) => (
                  <option value={e.semester_id} key={e.semester_id}>
                    Semester {e.semester_nama}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-md-4">
              <label htmlFor="kehadiran_tanggal" className="control-label">
                {t("tanggal")} :
              </label>
              <input
                type="date"
                className="form-control"
                id="kehadiran_tanggal"
                value={params.kehadiran_tanggal}
                name="kehadiran_tanggal"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title">
            <h4 className="card-title">{t("absensi_wali_kelas")}</h4>
          </div>
        </div>
        <div className="iq-card-body">
          <ul
            className="nav nav-pills-walas justify-content-center"
            id="pills-tab"
            role="tablist"
          >
            {jenisAbsensi?.map((item) => (
              <li className="nav-item" key={item.kehadiran_jenis_id}>
                <button
                  className={`nav-link-walas border-0 ${
                    params.kehadiran_jenis_id === item.kehadiran_jenis_id
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    handleChangeParams(
                      item.kehadiran_jenis_id,
                      "kehadiran_jenis_id"
                    );
                  }}
                >
                  {item.kehadiran_jenis_nama}
                </button>
              </li>
            ))}
          </ul>
          <div className="tab-content mt-3" id="myTabContent-5">
            {params.kehadiran_jenis_id ? (
              <div className="table-responsive">
                <table
                  id="datatable"
                  className="table table-hover table-bordered"
                >
                  <thead>
                    <tr>
                      <th scope="col" width="5%" className="text-center">
                        No
                      </th>
                      <th scope="col" width="30%">
                        {t("nama_siswa")}
                      </th>
                      <th
                        scope="col"
                        width="5%"
                        className="text-center iq-bg-primary"
                      >
                        {t("hadir")}
                      </th>
                      <th
                        scope="col"
                        width="5%"
                        className="text-center iq-bg-success"
                      >
                        {t("izin")}
                      </th>
                      <th
                        scope="col"
                        width="5%"
                        className="text-center iq-bg-danger"
                      >
                        Alpa
                      </th>
                      <th
                        scope="col"
                        width="5%"
                        className="text-center iq-bg-warning"
                      >
                        {t("sakit")}
                      </th>
                      <th scope="col" width="40%">
                        {t("catatan_guru")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {siswa?.length <= 0 ? (
                      <tr className="text-center">
                        <td colSpan={7}>{t("data_kosong")}</td>
                      </tr>
                    ) : (
                      siswa?.map((item, idx) => (
                        <tr key={item.userkelas_userid}>
                          <td className="text-center">{idx + 1}</td>
                          <td>
                            <b>{item?.user?.user_nama}</b>
                            {/* {item?.kehadiran_nilai === "I" && (
                              <span className="badge badge-pill badge-primary">
                                {t("izin")}
                              </span>
                            )} */}
                          </td>
                          <td className="text-center">
                            <div className="radio d-inline-block">
                              <input
                                className="rabsen"
                                type="radio"
                                name={`absensi-${idx}`}
                                id={`radio-${idx}`}
                                checked={item?.kehadiran_nilai === "H"}
                                onChange={() =>
                                  handleChangeSiswa(idx, "kehadiran_nilai", "H")
                                }
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <div className="radio d-inline-block">
                              <input
                                className="rabsen"
                                type="radio"
                                name={`absensi-${idx}`}
                                id={`radio-${idx}`}
                                checked={item?.kehadiran_nilai === "I"}
                                onChange={() =>
                                  handleChangeSiswa(idx, "kehadiran_nilai", "I")
                                }
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <div className="radio d-inline-block">
                              <input
                                className="rabsen"
                                type="radio"
                                name={`absensi-${idx}`}
                                id={`radio-${idx}`}
                                checked={item?.kehadiran_nilai === "A"}
                                onChange={() =>
                                  handleChangeSiswa(idx, "kehadiran_nilai", "A")
                                }
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <div className="radio d-inline-block">
                              <input
                                className="rabsen"
                                type="radio"
                                name={`absensi-${idx}`}
                                id={`radio-${idx}`}
                                checked={item?.kehadiran_nilai === "S"}
                                onChange={() =>
                                  handleChangeSiswa(idx, "kehadiran_nilai", "S")
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <textarea
                              className="textarea form-control h-auto"
                              placeholder="Catatan Guru"
                              value={item.kehadiran_keterangan}
                              onChange={({ target: { value } }) =>
                                handleChangeSiswa(
                                  idx,
                                  "kehadiran_keterangan",
                                  value
                                )
                              }
                              rows={3}
                            ></textarea>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                <button
                  type="button"
                  className="btn btn-primary float-right"
                  onClick={handleSubmitAbsensi}
                  disabled={submit}
                >
                  {t("save_changes")}
                </button>
              </div>
            ) : (
              <div className="py-2">
                <p className="text-center">
                  {params.kelasdetail_id === ""
                    ? t("pilih_kelas_dahulu")
                    : params.semester_id === ""
                    ? t("pilih_semester_dahulu")
                    : params.kehadiran_tanggal === ""
                    ? t("pilih_tgl_dahulu")
                    : params.kehadiran_jenis_id === ""
                    ? t("pilih_jenis_dahulu")
                    : ""}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AbsensiWalas;
