import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { checkIsGuru } from "../../utils/roleUser";

const MapelInfo = ({ mapel, pengajar, kelas, semester }) => {
  const { t } = useTranslation();
  const { user } = useSelector(({ user }) => user);
  const isGuru = checkIsGuru(user?.user_roleid);
  return (
    <div className="iq-card bg-white">
      <div className="iq-card-body">
        <div className="col-lg-12">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="w-bold">{mapel}</h3>
            {!isGuru && (
              <Link
                to="/kelas"
                className="btn btn-sm iq-bg-light iq-bg-primary-hover text-dark border bg-transparent"
              >
                <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
              </Link>
            )}
          </div>
          <div className="group-member d-flex align-items-center">
            <div className="iq-media-group">
              {pengajar?.slice(0, 1)?.map((item) => (
                <img
                  className="avatar-45 rounded-circle"
                  src={item?.user?.user_avatar}
                  alt={item?.user?.user_nama}
                  key={item.pengajar_id}
                />
              ))}
            </div>
            &nbsp;
            <div className="info ms-1">
              <div className="d-flex flex-wrap">
                {pengajar?.map((item, i) => (
                  <div
                    className="d-flex align-items-center"
                    key={crypto.randomUUID()}
                  >
                    {i !== 0 && (
                      <div className="w-5 h-5 rounded-circle bg-body-text mx-2"></div>
                    )}
                    {i === 0 ? (
                      <h6 className="m-0">{item?.user?.user_nama}</h6>
                    ) : (
                      <p className="m-0">{item?.user?.user_nama}</p>
                    )}
                  </div>
                ))}
              </div>
              <p className="mb-0">
                <i className="ri-lock-fill pe-2"></i>
                {t("kelas")} {kelas} (Semester {semester}.)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapelInfo;
