import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

function ModalMateriVideo({ dataVideo, show, handleClose }) {
  const { t } = useTranslation();
  const [url, seturl] = useState("");
  useEffect(() => {
    if (dataVideo.video_type === "link") {
      var url_string = dataVideo?.video_file;
      var url = new URL(url_string);
      var c = url.searchParams.get("v");
      seturl(c);
    } else {
      seturl("");
    }
  }, [dataVideo]);

  return (
    <div className="col-lg-12">
      <Modal size="lg" show={show} onHide={handleClose} id="ModalMateriVideo">
        <Modal.Header closeButton>
          <h5 className="modal-title">{dataVideo?.video_name}</h5>
        </Modal.Header>
        <Modal.Body>
          {dataVideo?.video_type === "link" ? (
            <div className="user-post">
              {url ? (
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item"
                    src={`https://www.youtube.com/embed/${url}`}
                    allowFullScreen
                    title="materi video"
                  ></iframe>
                </div>
              ) : (
                <p className="text-center">{t("video_tdk_ditemukan")}</p>
              )}
            </div>
          ) : (
            <video
              src={dataVideo?.video_file}
              controls
              className="w-100 h-auto"
            ></video>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalMateriVideo;
