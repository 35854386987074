import "moment/locale/id";

import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import {
  getPekanAbsensi,
  getWeeklyAbsensi,
  submitAbsensiWeekly,
} from "../../../redux/actions/absenAction";

moment.locale("id");

/**
 * React component that displays a form for submitting weekly attendance reports for students.
 *
 * @param {Object} detailKelas - The detailKelas object containing information about the class.
 * @returns {JSX.Element} The JSX element representing the form for submitting weekly attendance reports.
 */
function Weekly({ detailKelas }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  const [pekan, setPekan] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [siswa, setSiswa] = useState([]);
  const [selectedPekan, setSelectedPekan] = useState(null);
  const [params, setParams] = useState({
    semester_id: "",
    pekan_id: "",
    tahunpelajaran_id: "",
  });

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    let smt = params.get("smt");
    let tahun = params.get("th");
    setParams((pref) => ({
      ...pref,
      semester_id: smt || detailKelas?.semester_id,
      tahunpelajaran_id: tahun || "",
    }));
  }, [location.search, detailKelas]);

  const handleChange = ({ target: { value, name } }) => {
    if (name === "pekan_id" && value) {
      const cek = pekan?.filter((item) => item.pekan_id === value);
      setSelectedPekan(null);
      if (cek.length > 0) {
        setSelectedPekan(cek[0]);
      }
    }
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };

  function checkProperties(obj) {
    for (var key in obj) {
      const checkKey = key !== "semester_id" && key !== "tahunpelajaran_id";
      if (checkKey && (obj[key] === null || obj[key] === "")) return false;
    }
    return true;
  }

  const handleChangeSiswa = (idx, name, value) => {
    setSiswa((prevSiswa) => {
      const newSiswa = [...prevSiswa];
      newSiswa[idx][name] = value;
      return newSiswa;
    });
  };

  useEffect(() => {
    setPekan([]);
    const getPekan = () => {
      const data = {
        kelasdetail_id: detailKelas?.kelasmapel_kelasdetailid,
        semester_id: params.semester_id,
        tahunpelajaran_id: params.tahunpelajaran_id,
      };
      getPekanAbsensi(data).then((res) => setPekan(res));
    };
    if (detailKelas?.kelasmapel_kelasdetailid && params.semester_id) getPekan();
  }, [params, detailKelas?.kelasmapel_kelasdetailid]);

  useEffect(() => {
    function getData() {
      const data = {
        ...params,
        kelasdetail_id: detailKelas?.kelasmapel_kelasdetailid,
        weekly_type: "mapel",
        kelasmapel_id: id,
      };
      getWeeklyAbsensi(data).then((res) => {
        let allSiswa = res?.map((item) => ({
          userkelas_userid: item.userkelas_userid,
          weekly_catatan_guru: item?.weekly_mapel?.weekly_catatan_guru || "",
          weekly_catatan_materi:
            item?.weekly_mapel?.weekly_catatan_materi || "",
          weekly_catatan_tugas: item?.weekly_mapel?.weekly_catatan_tugas || "",
          user: item?.user,
        }));
        setSiswa(allSiswa);
      });
    }
    let check = checkProperties(params);
    if (check) {
      getData();
    }
  }, [detailKelas?.kelasmapel_kelasdetailid, id, params]);

  const showAlert = (type, title, text) => {
    Swal.fire({
      title: title,
      text: text,
      icon: type,
      imageWidth: 92,
      imageHeight: 92,
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };

  const handleSubmitAbsensi = () => {
    setSubmit(true);
    let data = {
      ...params,
      kelasdetail_id: detailKelas?.kelasmapel_kelasdetailid,
      kelasmapel_id: id,
      siswa: siswa?.map((item) => ({
        userkelas_userid: item.userkelas_userid,
        weekly_catatan_guru: item.weekly_catatan_guru,
        weekly_catatan_materi: item.weekly_catatan_materi,
        weekly_catatan_tugas: item.weekly_catatan_tugas,
      })),
    };
    submitAbsensiWeekly("mapel", data)
      .then(() => {
        setSubmit(false);
        showAlert(
          "success",
          t("laporan_berhasil"),
          t("berhasil_simpan", { params: t("laporan") })
        );
      })
      .catch((err) => {
        setSubmit(false);
        showAlert(
          "error",
          t("laporan_gagal"),
          t("failed_save_data", { data: t("laporan") })
        );
      });
  };

  const handleSiswaChange = ({ target: { value } }, idx, name) => {
    handleChangeSiswa(idx, name, value);
  };

  return (
    <div className="tab-pane fade" id="Weekly" role="tabpanel">
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title d-flex align-items-center justify-content-between w-100">
            <h4 className="card-title">{t("laporan_mingguan")}</h4>
            <Link
              to="/kelas"
              className="btn iq-bg-light iq-bg-primary-hover text-dark border"
            >
              <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
            </Link>
          </div>
        </div>
        <div className="iq-card-body">
          <div className="row">
            <div className="col-12 p-1">
              <div className="row">
                <label
                  htmlFor="semester"
                  className="control-label col-sm-3 align-self-center mb-0"
                >
                  {t("pekan")} :
                </label>
                <div className="col-sm-9 d-md-flex">
                  <select
                    className="form-control form-select"
                    name="pekan_id"
                    value={params.pekan_id}
                    onChange={handleChange}
                  >
                    <option value="">
                      --{t("pilih_params", { params: t("pekan") })}--
                    </option>
                    {pekan?.map((item) => (
                      <option value={item.pekan_id} key={item.pekan_id}>
                        {item.pekan_nama}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <hr />
          {selectedPekan && (
            <p>
              {t("tanggal")} :{" "}
              <span className="badge badge-pill badge-success">
                {" "}
                {moment(selectedPekan?.pekan_mulai?.substr(0, 10)).format(
                  "DD MMMM YYYY"
                )}
              </span>{" "}
              -{" "}
              <span className="badge badge-pill badge-success">
                {" "}
                {moment(selectedPekan?.pekan_akhir?.substr(0, 10)).format(
                  "DD MMMM YYYY"
                )}
              </span>
            </p>
          )}
          <div className="table-responsive mt-2">
            <table id="datatable" className="table table-bordered">
              <thead className="table-primary text-white">
                <tr>
                  <th className="bg-transparent text-center" width="5%">
                    No
                  </th>
                  <th className="bg-transparent" width="20%">
                    {t("nama_siswa")}
                  </th>
                  <th className="bg-transparent text-center">{t("materials")}</th>
                  <th className="bg-transparent text-center">{t("assessment")}</th>
                </tr>
              </thead>
              <tbody>
                {siswa?.length <= 0 ? (
                  <tr>
                    <td colSpan={4} className="text-center">
                      {t("data_params_kosong", { params: t("siswa") })}
                    </td>
                  </tr>
                ) : (
                  siswa?.map((item, idx) => (
                    <tr key={item.userkelas_userid + params?.pekan_id}>
                      <td>{idx + 1}</td>
                      <td>{item.user?.user_nama}</td>
                      <td>
                        <textarea
                          className="form-control"
                          rows="3"
                          placeholder="Activities"
                          value={item?.weekly_catatan_materi}
                          onChange={(e) =>
                            handleSiswaChange(e, idx, "weekly_catatan_materi")
                          }
                        ></textarea>
                      </td>
                      <td>
                        <textarea
                          className="form-control"
                          rows="3"
                          placeholder="Activities"
                          value={item?.weekly_catatan_tugas}
                          onChange={(e) =>
                            handleSiswaChange(e, idx, "weekly_catatan_tugas")
                          }
                        ></textarea>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <button
              type="button"
              disabled={submit}
              onClick={handleSubmitAbsensi}
              className="btn btn-primary float-right"
            >
              {t("save_changes")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Weekly;
