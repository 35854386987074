import React from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function ModalNoSoalTugasCourse({ show, handleClose, dataSoal, handleClick }) {
  const { t } = useTranslation();
  const stripHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };
  const checkJWB = (data) => {
    const pengerjaan = data?.pengerjaan_tugas;
    if (data?.soal_jenis === "pg" && pengerjaan !== null) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Modal
      size="md"
      show={show}
      onHide={handleClose}
      id="ModalNoSoalTugasCourse"
    >
      <Modal.Header closeButton>
        <h5 className="modal-title">{t("daftar_soal")}</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="wrap-no-soal">
          {dataSoal?.map((item, idx) => {
            let soal = stripHtml(item?.soal_pertanyaan);
            return (
              <OverlayTrigger
                key={item.soal_id}
                placement="top"
                overlay={<Tooltip>{soal}</Tooltip>}
              >
                <button
                  onClick={() => handleClick(idx)}
                  className={`btn ${
                    checkJWB(item) ? "btn-primary" : "btn-secondary"
                  }`}
                >
                  {idx + 1}
                </button>
              </OverlayTrigger>
            );
          })}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalNoSoalTugasCourse;
