import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { PageLoader } from "../../assets/images";
import {
  InputEditor,
  InputFileSoal,
  InputJenisSoal,
  InputPoint,
  InputWaktuSoal,
  RenderSoalInput,
  Wrapper,
} from "../../components";
import { getDetailSoalBS, updSoalBS } from "../../redux/actions/bankSoalAction";
import { uploadFileSoal } from "../../redux/actions/tugasAction";
import { checkParamsSoal } from "../../utils/checkParamsSoal";

/* eslint-disable jsx-a11y/anchor-is-valid */
function EditSoalBankSoal() {
  const { t } = useTranslation();
  const { id } = useParams();
  const editorRef = useRef(null);
  const navigate = useNavigate(-1);
  const [submit, setSubmit] = useState(false);
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const [params, setParams] = useState({
    soal_jenis: "pg",
    soal_pertanyaan: "",
    soal_file: "",
    soal_points: 0,
    soal_waktu: 10,
    bk_master_id: "",
    soal_file_type: "",
  });
  const [soalEssay, setsoalEssay] = useState({
    soal_jawaban: "",
    soal_file: null,
  });
  const [soalPilgan, setSoalPilgan] = useState([
    {
      soal_pilihan: "",
      soal_jawaban: 0,
      soal_file: null,
    },
    {
      soal_pilihan: "",
      soal_jawaban: 0,
      soal_file: null,
    },
    {
      soal_pilihan: "",
      soal_jawaban: 0,
      soal_file: null,
    },
    {
      soal_pilihan: "",
      soal_jawaban: 0,
      soal_file: null,
    },
    {
      soal_pilihan: "",
      soal_jawaban: 0,
      soal_file: null,
    },
  ]);
  const [soalPooling, setSoalPooling] = useState([
    {
      soal_pilihan: "",
      soal_jawaban: 0,
      soal_file: null,
    },
    {
      soal_pilihan: "",
      soal_jawaban: 0,
      soal_file: null,
    },
    {
      soal_pilihan: "",
      soal_jawaban: 0,
      soal_file: null,
    },
    {
      soal_pilihan: "",
      soal_jawaban: 0,
      soal_file: null,
    },
  ]);
  const [soalPernyataan, setSoalPernyataan] = useState({
    soal_pernyataan_nama: "",
    soal_pernyataan_true: "",
    soal_pernyataan_false: "",
    pilihan: [
      {
        soal_pernyataan_pertanyaan: "",
        soal_pernyataan_jawaban: "1",
      },
    ],
  });
  const [soalTrueFalse, setSoalTrueFalse] = useState({
    soal_pilihan_true: "",
    soal_file_true: null,
    soal_pilihan_false: "",
    soal_file_false: null,
    soal_jawaban: 0,
  });
  const [soalMenjodohkan, setSoalMenjodohkan] = useState({
    soal_menjodohkan_nama_pilihan: "",
    pernyataan: [
      {
        soal_menjodohkan_pernyataan: "",
        soal_menjodohkan_jawaban: "",
      },
    ],
    pilihan: [
      {
        soal_menjodohkan_pilihan_key: "A",
        soal_menjodohkan_pilihan_isi: "",
      },
    ],
  });
  const handleChange = ({ target: { value, name } }) => {
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };
  const handleChangeJWBPilgan = ({ target: { value, name } }) => {
    if (name !== "soal_jawaban") {
      let pilgan = soalPilgan;
      pilgan[name].soal_pilihan = value;
      setSoalPilgan([...pilgan]);
      return;
    }
    let pilgan = soalPilgan.map((item) => ({ ...item, soal_jawaban: 0 }));
    pilgan[value].soal_jawaban = 1;
    setSoalPilgan([...pilgan]);
  };
  const handleChangeJWBPooling = ({ target: { value, name } }) => {
    if (name !== "soal_jawaban") {
      let pooling = soalPooling;
      pooling[name].soal_pilihan = value;
      setSoalPooling([...pooling]);
      return;
    }
    let pooling = soalPooling;
    pooling[value].soal_jawaban = pooling[value].soal_jawaban ? 0 : 1;
    setSoalPooling([...pooling]);
  };
  const handleChangeJWBEssay = ({ target: { value, name } }) => {
    setsoalEssay((pref) => ({
      ...pref,
      [name]: value,
    }));
  };
  const handleChangeJWBPernyataan = ({ target: { value, name } }) => {
    setSoalPernyataan((pref) => ({
      ...pref,
      [name]: value,
    }));
  };
  const handleChangeJWBTF = ({ target: { value, name } }) => {
    setSoalTrueFalse((pref) => ({
      ...pref,
      [name]: value,
    }));
  };
  const handleAddPernyataanJodoh = () => {
    setSoalMenjodohkan((pref) => ({
      ...pref,
      pernyataan: [
        ...pref.pernyataan,
        {
          soal_menjodohkan_pernyataan: "",
          soal_menjodohkan_jawaban: "",
        },
      ],
    }));
  };
  const handleDelPernyataanJodoh = (id) => {
    let pernyataan = soalMenjodohkan.pernyataan.filter(
      (item, idx) => idx !== id
    );
    setSoalMenjodohkan((pref) => ({
      ...pref,
      pernyataan,
    }));
  };
  const changePernyataanJodoh = ({ target: { name, value } }) => {
    let pernyataan = soalMenjodohkan?.pernyataan;
    pernyataan[name].soal_menjodohkan_pernyataan = value;
    setSoalMenjodohkan((pref) => ({
      ...pref,
      pernyataan: [...pernyataan],
    }));
  };
  const changeJawabanJodoh = ({ target: { name, value } }) => {
    let pernyataan = soalMenjodohkan?.pernyataan;
    pernyataan[name].soal_menjodohkan_jawaban = value.toUpperCase();
    setSoalMenjodohkan((pref) => ({
      ...pref,
      pernyataan: [...pernyataan],
    }));
  };
  const handleChangeJenisSoal = ({ target: { value, name } }) => {
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };
  const handleAddPilihanJodoh = () => {
    setSoalMenjodohkan((pref) => ({
      ...pref,
      pilihan: [
        ...pref.pilihan,
        {
          soal_menjodohkan_pilihan_key: String.fromCharCode(
            pref.pilihan.length + 1 + 64
          ),
          soal_menjodohkan_pilihan_isi: "",
        },
      ],
    }));
  };
  const handleDelPilihanJodoh = (id) => {
    let pilihan = soalMenjodohkan.pilihan.filter((item, idx) => idx !== id);
    setSoalMenjodohkan((pref) => ({
      ...pref,
      pilihan,
    }));
  };
  const changePilihanIsiJodoh = ({ target: { name, value } }) => {
    let pilihan = soalMenjodohkan?.pilihan;
    pilihan[name].soal_menjodohkan_pilihan_isi = value;
    setSoalMenjodohkan((pref) => ({
      ...pref,
      pilihan: [...pilihan],
    }));
  };
  const handleAddSoalPernyataan = () => {
    setSoalPernyataan((pref) => ({
      ...pref,
      pilihan: [
        ...pref.pilihan,
        {
          soal_pernyataan_pertanyaan: "",
          soal_pernyataan_jawaban: "1",
        },
      ],
    }));
  };
  const handleDelSoalPernyataan = (id) => {
    let pilihan = soalPernyataan.pilihan.filter((item, idx) => idx !== id);
    setSoalPernyataan((pref) => ({
      ...pref,
      pilihan,
    }));
  };
  const changeSoalPernyataan = ({ target: { name, value } }) => {
    let pilihan = soalPernyataan?.pilihan;
    pilihan[name].soal_pernyataan_pertanyaan = value;
    console.log("pilihan", pilihan);
    setSoalPernyataan((pref) => ({
      ...pref,
      pilihan: [...pilihan],
    }));
  };
  const changeJawabanSoalPernyataan = ({ target: { name, value } }) => {
    let pilihan = soalPernyataan?.pilihan;
    pilihan[name].soal_pernyataan_jawaban = value;
    setSoalPernyataan((pref) => ({
      ...pref,
      pilihan: [...pilihan],
    }));
  };
  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    }).then((res) => {
      if (status && res.isConfirmed) navigate(-1);
    });
  };
  const handleSubmit = () => {
    let check = checkParamsSoal(
      params.soal_jenis,
      soalPilgan,
      soalPooling,
      soalTrueFalse,
      soalMenjodohkan,
      soalPernyataan
    );
    if (check !== true) {
      showAlert(t(check), false);
      return false;
    }
    Swal.fire({
      title: t("konfirmasi"),
      text: t("konfirm_simpan_soal", {
        point: params.soal_points,
        waktu: params.soal_waktu,
      }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#6fbd44",
      showCancelButton: true,
      cancelButtonColor: "#dc3545",
      cancelButtonText: t("batal"),
      reverseButtons: true,
    }).then((res) => {
      if (res.isConfirmed) sendSoal();
    });
  };

  const sendSoal = () => {
    setSubmit(true);
    let data = {
      ...params,
      soal_id: id,
      soal_pertanyaan: editorRef.current.getContent(),
      soal_pg: soalPilgan?.filter((item) => {
        return item.soal_pilihan?.length > 0 || item.soal_file?.length > 0;
      }),
      soal_polling: soalPooling?.filter((item) => {
        return item.soal_pilihan?.length > 0 || item.soal_file?.length > 0;
      }),
      soal_essay: soalEssay,
      soal_true_false: soalTrueFalse,
      soal_menjodohkan: soalMenjodohkan,
      soal_pernyataan: soalPernyataan,
    };
    updSoalBS(data)
      .then(() => {
        showAlert(t("berhasil_simpan", { params: t("bank_soal") }), true);
        setSubmit(false);
      })
      .catch(() => {
        setSubmit(false);
      });
  };
  const handleChangeFile = ({ target: { files } }) => {
    if (files.length > 0) {
      var formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`file`, files[i]);
      }
      setUploadFileLoading(true);
      uploadFileSoal(formData)
        .then((res) => {
          setUploadFileLoading(false);
          setParams((pref) => ({
            ...pref,
            soal_file: res?.data?.data[0]?.url,
            soal_file_type: res?.data?.data[0]?.type,
          }));
        })
        .catch((err) => {
          setUploadFileLoading(false);
          console.log("err", err);
        });
    }
  };
  const handleChangeFilePG = (index, { target: { files } }) => {
    if (files.length > 0) {
      var formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`file`, files[i]);
      }
      uploadFileSoal(formData)
        .then((res) => {
          let allSoalPilgan = soalPilgan;
          allSoalPilgan[index].soal_file = res?.data?.data[0]?.url;
          setSoalPilgan([...allSoalPilgan]);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  const handleDeleteFilePG = (index) => {
    let allSoalPilgan = soalPilgan;
    allSoalPilgan[index].soal_file = null;
    setSoalPilgan([...allSoalPilgan]);
  };
  const handleChangeFilePooling = (index, { target: { files } }) => {
    if (files.length > 0) {
      var formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`file`, files[i]);
      }
      uploadFileSoal(formData)
        .then((res) => {
          let allSoalPooling = soalPooling;
          allSoalPooling[index].soal_file = res?.data?.data[0]?.url;
          setSoalPooling([...allSoalPooling]);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  const handleDeleteFilePooling = (index) => {
    let allSoalPooling = soalPooling;
    allSoalPooling[index].soal_file = null;
    setSoalPooling([...allSoalPooling]);
  };
  const handleChangeFileEssay = ({ target: { files } }) => {
    if (files.length > 0) {
      var formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`file`, files[i]);
      }
      uploadFileSoal(formData)
        .then((res) => {
          setsoalEssay((pref) => ({
            ...pref,
            soal_file: res?.data?.data[0]?.url,
          }));
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  const handleChangeFileTrueFalse = (name, { target: { files } }) => {
    if (files.length > 0) {
      var formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`file`, files[i]);
      }
      uploadFileSoal(formData)
        .then((res) => {
          setSoalTrueFalse((pref) => ({
            ...pref,
            [name]: res?.data?.data[0]?.url,
          }));
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  const handleDeleteFileTrueFalse = (name) => {
    setSoalTrueFalse((pref) => ({
      ...pref,
      [name]: null,
    }));
  };
  useEffect(() => {
    getDetailSoalBS(id)
      .then(({ data: { data } }) => {
        if (data.soal_jenis === "pg") {
          setSoalPilgan(
            soalPilgan?.map((item, idx) => {
              let soal = data?.soal_pg[idx];
              return {
                soal_pilihan: soal?.soal_pilihan || "",
                soal_jawaban: soal?.soal_jawaban || 0,
                soal_file: soal?.soal_file || null,
              };
            })
          );
        } else if (data?.soal_jenis === "polling") {
          setSoalPooling(
            soalPooling?.map((item, idx) => {
              let soal = data?.soal_polling[idx];
              return {
                soal_pilihan: soal?.soal_pilihan || "",
                soal_jawaban: soal?.soal_jawaban || 0,
                soal_file: soal?.soal_file || null,
              };
            })
          );
        } else if (data?.soal_jenis === "essay") {
          setsoalEssay(data?.soal_essay);
        } else if (data?.soal_jenis === "true_false") {
          setSoalTrueFalse(data?.soal_true_false);
        } else if (data?.soal_jenis === "menjodohkan") {
          setSoalMenjodohkan(data?.soal_menjodohkan);
        } else if (data?.soal_jenis === "pernyataan") {
          setSoalPernyataan(data?.soal_pernyataan);
        }
        setParams((pref) => ({
          ...pref,
          soal_jenis: data?.soal_jenis,
          soal_pertanyaan: data?.soal_pertanyaan,
          soal_file: data?.soal_file,
          soal_points: data?.soal_points,
          soal_waktu: data?.soal_waktu,
          soal_file_type: data?.soal_file_type,
          bk_master_id: data?.bk_master_id,
        }));
      })
      .catch((err) => console.log("err", err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const handleDelFileSoal = () => {
    setParams((pref) => ({
      ...pref,
      soal_file: "",
      soal_file_type: "",
    }));
  };
  const handleDelFileEssay = () => {
    setsoalEssay((pref) => ({
      ...pref,
      soal_file: null,
    }));
  };
  const handleChangeNamaMenjodohkan = ({ target: { name, value } }) => {
    setSoalMenjodohkan((pref) => ({
      ...pref,
      [name]: value,
    }));
  };

  return (
    <Wrapper title={`${t("edit_soal")}`}>
      <div className="container">
        <div className="row row-eq-height">
          <div className="col-lg-12">
            <div className="row pl-0 pr-0">
              <div className="col-lg-6 col-sm-8 col-12 d-flex gap-2">
                <InputPoint
                  value={params.soal_points}
                  handleChange={handleChange}
                />
                <InputWaktuSoal
                  value={params.soal_waktu}
                  handleChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-sm-4 col-12 d-flex justify-content-lg-end">
                <InputJenisSoal
                  value={params.soal_jenis}
                  handleChange={handleChangeJenisSoal}
                />
              </div>
            </div>
            <div className="iq-card">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title d-flex">
                  <h4 className="card-title fw-bold">{t("soal")}</h4>
                </div>
              </div>
              <div className="col-lg-12 p-0">
                <div className="iq-card ">
                  <div className="iq-card-body">
                    <div className="row">
                      <InputFileSoal
                        handleChange={handleChangeFile}
                        handleDelFile={handleDelFileSoal}
                        soalFile={params?.soal_file}
                        soalFileType={params?.soal_file_type}
                        submit={uploadFileLoading}
                      />
                      <div
                        className={`col-12 ${
                          params?.soal_file ? "col-lg-9" : "col-lg-10"
                        } pb-3`}
                      >
                        <InputEditor
                          editorRef={editorRef}
                          value={params.soal_pertanyaan}
                        />
                      </div>
                    </div>
                  </div>
                  <RenderSoalInput
                    changeJawabanJodoh={changeJawabanJodoh}
                    changeJawabanSoalPernyataan={changeJawabanSoalPernyataan}
                    changePernyataanJodoh={changePernyataanJodoh}
                    changePilihanIsiJodoh={changePilihanIsiJodoh}
                    changeSoalPernyataan={changeSoalPernyataan}
                    handleAddPernyataanJodoh={handleAddPernyataanJodoh}
                    handleAddPilihanJodoh={handleAddPilihanJodoh}
                    handleAddSoalPernyataan={handleAddSoalPernyataan}
                    handleChangeFileEssay={handleChangeFileEssay}
                    handleChangeFilePG={handleChangeFilePG}
                    handleChangeFilePooling={handleChangeFilePooling}
                    handleChangeFileTrueFalse={handleChangeFileTrueFalse}
                    handleChangeJWBEssay={handleChangeJWBEssay}
                    handleChangeJWBPernyataan={handleChangeJWBPernyataan}
                    handleChangeJWBPilgan={handleChangeJWBPilgan}
                    handleChangeJWBPooling={handleChangeJWBPooling}
                    handleChangeJWBTF={handleChangeJWBTF}
                    handleChangeNamaMenjodohkan={handleChangeNamaMenjodohkan}
                    handleDelFileEssay={handleDelFileEssay}
                    handleDelPernyataanJodoh={handleDelPernyataanJodoh}
                    handleDelPilihanJodoh={handleDelPilihanJodoh}
                    handleDelSoalPernyataan={handleDelSoalPernyataan}
                    handleDeleteFilePG={handleDeleteFilePG}
                    handleDeleteFilePooling={handleDeleteFilePooling}
                    handleDeleteFileTrueFalse={handleDeleteFileTrueFalse}
                    soalEssay={soalEssay}
                    soalJenis={params.soal_jenis}
                    soalMenjodohkan={soalMenjodohkan}
                    soalPernyataan={soalPernyataan}
                    soalPilgan={soalPilgan}
                    soalPooling={soalPooling}
                    soalTrueFalse={soalTrueFalse}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-end mb-5">
              <Link
                to={`/banksoal/${params.bk_master_id}`}
                className="btn btn-dark text-center fw-bold mx-1 float-right"
              >
                {t("batal")}
              </Link>
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary text-center fw-bold  float-right"
              >
                <i className="ri-save-2-fill m-0"></i>
                {submit ? (
                  <img src={PageLoader} alt="loader" style={{ width: 15 }} />
                ) : (
                  t("simpan")
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default EditSoalBankSoal;
