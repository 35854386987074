import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

moment.locale("id");
const InfoHasilUjian = ({
  mapel,
  startDate,
  endDate,
  nilai,
  semester,
  kelas,
  jenis,
}) => {
  const { t } = useTranslation();
  return (
    <div className="col-lg-12">
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <h4 className="card-title">{t("pembahasan")}</h4>
        </div>
        <div className="col-lg-12 p-0">
          <div className="iq-card">
            <div className="iq-card-body">
              <div className="table-responsive table-hover">
                <table id="datatable" className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        <b>{t("ujian")}</b>
                      </td>
                      <td>{t("nama_ujian", { mapel: `${jenis} ${mapel}` })}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>{t("kelas")}</b>
                      </td>
                      <td>{`${kelas} - Semester ${semester}`}</td>
                    </tr>
                    <tr>
                      <td width="30%">
                        <b>{t("mulai_selesai")}</b>
                      </td>
                      <td width="70%">
                        {startDate
                          ? moment(startDate).format("DD MMM YYYY (HH:mm)")
                          : ""}{" "}
                        -{" "}
                        {endDate
                          ? moment(endDate).format("DD MMM YYYY (HH:mm)")
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>{t("nilai")}</b>
                      </td>
                      <td>
                        <span className="badge badge-primary">{nilai}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoHasilUjian;
