import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  ItemCourseVideo,
  ItemFile,
  ItemPlaylistCourse,
  LoadingTopikCourse,
  ProgressCourse,
  Wrapper,
} from "../../components";
import {
  getCourseDetail,
  getCourseTopik,
  getDetailMateriCourse,
  progressCourse,
} from "../../redux/actions/courseAction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";

function DetailCourse() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useSelector(({ user }) => user);
  const [detail, setDetail] = useState(null);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [loadingTopik, setLoadingTopik] = useState(false);
  const [loadingMateri, setLoadingMateri] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [detailMateri, setDetailMateri] = useState(null);
  const [dataTopik, setDataTopik] = useState([]);
  const [prefNext, setPrefNext] = useState({
    pref: null,
    next: null,
  });

  const getData = useCallback(() => {
    const params = {
      user_id: user?.user_id,
    };
    getCourseDetail(id, params).then((res) => {
      if (!res?.joined || res?.joined?.join_status === "pending") {
        navigate("/course/buy/" + id);
      }
      setDetail(res);
    });
    const data = {
      paginate: 0,
      user_id: user?.user_id,
    };
    setLoadingTopik(true);
    getCourseTopik(id, data)
      .then((res) => {
        setLoadingTopik(false);
        setDataTopik(res?.data);
      })
      .catch(() => setLoadingTopik(false));
  }, [id, navigate, user]);

  useEffect(() => {
    getData();
  }, [id, navigate, user, getData]);

  useEffect(() => {
    if (!selectedData) return;
    setLoadingMateri(true);
    getDetailMateriCourse(selectedData).then((res) => {
      setLoadingMateri(false);
      setDetailMateri(res?.data);
    });
  }, [selectedData]);

  useEffect(() => {
    if (!selectedData) return;
    const data = dataTopik
      ?.map((item) => [...item.materi, ...item.tugas])
      .flat();
    const dataSelected = data.findIndex(
      (item) => parseInt(item.materi_id) === parseInt(selectedData)
    );
    if (dataSelected !== -1) {
      let pref = null;
      if (dataSelected > 0) {
        const dataPref = data[dataSelected - 1];
        const typeData = dataPref?.materi_id === undefined ? "tugas" : "materi";
        pref = {
          type: typeData,
          id: dataPref[typeData + "_id"],
        };
      }
      let next = null;
      if (dataSelected + 1 < data.length) {
        const dataNext = data[dataSelected + 1];
        const typeData = dataNext?.materi_id === undefined ? "tugas" : "materi";
        next = {
          type: typeData,
          id: dataNext[typeData + "_id"],
        };
      }
      setPrefNext({
        pref: pref,
        next: next,
      });
    }
  }, [selectedData, dataTopik]);

  useEffect(() => {
    if (!selectedData) {
      let materi_id = null;
      const materi = dataTopik?.map((item) => item.materi).flat();
      if (detail?.joined?.latest_view) {
        let latestView = detail.joined.latest_view.materi_id;
        let idxMateri = materi?.findIndex(
          (item) => parseInt(item.materi_id) === parseInt(latestView)
        );
        if (idxMateri !== -1 && idxMateri + 1 !== materi.length) {
          materi_id = materi[idxMateri + 1].materi_id;
        } else {
          materi_id = latestView;
        }
      } else if (dataTopik.length > 0) {
        if (materi.length > 0) materi_id = materi[0].materi_id;
      }
      setSelectedData(materi_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail, dataTopik]);

  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };

  const handleChangeMateri = (nextSelected) => {
    const data = {
      user_id: user?.user_id,
      join_id: detail?.joined?.join_id,
      materi_id: selectedData,
      course_id: id,
    };
    setLoadingProgress(true);
    progressCourse(data)
      .then(() => {
        if (nextSelected) {
          if (nextSelected.type === "materi") {
            setSelectedData(nextSelected.id);
          } else {
            navigate("/course/tugas/" + nextSelected.id);
          }
        }
        setLoadingProgress(false);
        getData();
      })
      .catch(() => {
        showAlert(t("gagal_save_progress"), false);
        setLoadingProgress(false);
      });
  };

  const handleChangeSelected = ({ type, id }) => {
    if (type === "tugas") {
      navigate("/course/tugas/" + id);
    } else {
      setSelectedData(id);
    }
  };

  return (
    <Wrapper title={`${t("kursus")} ${detail?.course_title || ""}`}>
      <div className="container-course">
        <div className="row">
          <div className="col-lg-8">
            {detail?.joined ? (
              <div className="d-lg-none">
                <ProgressCourse
                  reviewUser={detail?.review_user}
                  joinStatus={detail?.joined?.join_status}
                  progress={detail?.joined?.join_progress}
                />
              </div>
            ) : null}
            <div className="iq-card">
              {loadingMateri || !detailMateri ? (
                <div className="iq-card-body text-center py-5">
                  <h4 className="text-muted fw-semibold mb-3">
                    {t("mengambil_data_kursus")}
                  </h4>
                  <Spinner
                    animation="border"
                    role="status"
                    variant="secondary"
                    className="mx-auto"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <div className="iq-card-body">
                  {/* HEADER */}
                  <h4 className="fw-bold mb-3">
                    {detailMateri?.topik?.topik_title}
                  </h4>
                  {/* DESC */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: detailMateri?.topik?.topik_deskripsi,
                    }}
                  ></div>
                  {/* VIDEO */}
                  <div className="w-100 ratio ratio-16x9 overflow-hidden">
                    <ItemCourseVideo
                      type={detailMateri?.materi_video_type}
                      video={detailMateri?.materi_video}
                    />
                  </div>
                  {/* DETAIL */}
                  <div className="d-flex align-items-center mt-3 mb-4">
                    <h4 className="font-size-16 fw-bold flex-1">
                      {detailMateri?.materi_title}
                    </h4>
                    <div className="d-flex gap-1">
                      <button
                        onClick={() => handleChangeMateri(prefNext.pref)}
                        disabled={prefNext?.pref === null || loadingProgress}
                        type="button"
                        className="btn btn-secondary rounded-pill"
                      >
                        <i className="ri-arrow-left-s-line"></i>
                        {t("sebelumnya")}
                      </button>
                      <button
                        onClick={() => handleChangeMateri(prefNext.next)}
                        disabled={loadingProgress}
                        type="button"
                        className="btn btn-primary rounded-pill"
                      >
                        {prefNext.next === null
                          ? t("selesai")
                          : prefNext.next.type === "materi"
                          ? t("selanjutnya")
                          : t("tugas")}{" "}
                        <i
                          className={
                            prefNext.next === null
                              ? "ri-save-line"
                              : "ri-arrow-right-s-line"
                          }
                        ></i>
                      </button>
                    </div>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: detailMateri?.materi_deskripsi,
                    }}
                  ></div>
                  <div className="row">
                    {detailMateri?.files?.map((item) => (
                      <div className="col-lg-6 col-12" key={item.filemateri_id}>
                        <ItemFile
                          name={item.filename}
                          type={item.type}
                          url={item.link}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-4">
            {detail?.joined ? (
              <div className="d-none d-lg-block">
                <ProgressCourse
                  reviewUser={detail?.review_user}
                  progress={detail?.joined?.join_progress}
                  joinStatus={detail?.joined?.join_status}
                />
              </div>
            ) : null}
            <div className="course-sidebar-scroll scroller" id="lessons">
              {loadingTopik && dataTopik.length <= 0 ? (
                <LoadingTopikCourse />
              ) : (
                dataTopik?.map((item, idx) => (
                  <ItemPlaylistCourse
                    topikJoin={item?.topik_join}
                    key={item.topik_id}
                    id={item.topik_id}
                    materi={item.materi}
                    tugas={item.tugas}
                    title={item.topik_title}
                    idx={idx + 1}
                    handlePlay={handleChangeSelected}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default DetailCourse;
