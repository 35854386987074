import { API, setAuthToken } from '../../config/api';
import { GET_BANK_SOAL } from '../constant';

export const getBankSoal = () => {
  return {
    type: GET_BANK_SOAL,
    payload: async () => {
      const token = localStorage.getItem("token");
      setAuthToken(token);
      let res = await API.get("bank-soal").catch((err) => {
        return Promise.reject(err);
      });
      return res?.data?.data;
    },
  };
};

export const getTahunPelajaranBS = () => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get("bank-soal/tahun-pelajaran");
};

export const getListKelasBS = () => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get("kelas/list-kelas");
};

export const getMapelBS = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`kelas/mata-pelajaran?kelasdetail_id=${code}`);
};

export const addBankSoal = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("bank-soal", params);
};

export const updBankSoal = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("bank-soal/update", params);
};

export const delBankSoal = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.delete(`bank-soal?bk_master_id=${code}`);
};

export const getListSoal = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`bank-soal/soal?bk_master_id=${code}`);
};

export const getDetailBankSoal = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`bank-soal/detail?bk_master_id=${code}`);
};

export const getSoalCeritaBS = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`bank-soal/soal/cerita?bk_master_id=${code}`);
};

export const addSoalBS = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`bank-soal/soal`, params);
};

export const getListSoalCeritaBS = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`bank-soal/soal/cerita/list-soal?bk_master_id=${code}`);
};

export const addSoalCeritaBS = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("bank-soal/soal/cerita", params);
};

export const getDetailSoalBS = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`bank-soal/soal/detail?soal_id=${code}`);
};

export const updSoalBS = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("bank-soal/soal/update", params);
};

export const delSoalBS = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.delete("bank-soal/soal?soal_id=" + code);
};

export const getDetailSoaCeritaBS = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`bank-soal/soal/cerita/detail?cerita_id=${code}`);
};

export const updSoalCeritaBS = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`bank-soal/soal/cerita/update`, params);
};

export const delCeritaBS = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.delete("bank-soal/soal/cerita?cerita_id=" + code);
};
