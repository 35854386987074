import React from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

function ModalDescUjian({ show, handleClose, desc }) {
  const { t } = useTranslation();
  return (
    <div className="col-lg-12">
      <Modal size="lg" show={show} onHide={handleClose} id="ModalDescUjian">
        <Modal.Header closeButton>
          <h5 className="modal-title">{t("info_ujian")}</h5>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: desc }} />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleClose}
          >
            {t("tutup")}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalDescUjian;
