import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Il404 } from '../assets/images';

function NotFound() {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6 text-center vh-100 d-flex flex-column justify-content-center">
          <img src={Il404} alt="not found" className="w-100" />
          <h5 className="mb-4">{t("page_not_found")}</h5>
          <div className="error-actions">
            <Link to="/" className="btn btn-primary btn-lg">
              Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
