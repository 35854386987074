import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import {
  InputEditor,
  ItemFile,
  ItemFileVideo,
  ModalDrive,
  ModalMateriH5P,
  SelectMultiple,
} from "..";
import { IcH5P, LoadingGreen, PageLoader } from "../../assets/images";
import { getTopik } from "../../redux/actions/topikAction";
import {
  addTugas,
  getDetailTugas,
  getTargetTugas,
  updTugas,
  uploadFileTugas,
} from "../../redux/actions/tugasAction";
import { checkURLFormat } from "../../utils/validateData";
import ModalTarget from "./ModalTarget";

function TambahTugas({
  dataTopik,
  dataSiswa,
  show,
  handleClose,
  editTugas,
  idMapel,
}) {
  let fileInput = useRef(null);
  let fileH5P = useRef(null);
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(null);
  const [optionTarget, setOptionTarget] = useState([]);
  const [openModalTarget, setOpenModalTarget] = useState(false);
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const [uploadFileH5PLoading, setUploadFileH5PLoading] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showDrive, setShowDrive] = useState(false);
  const [materiH5P, setMateriH5P] = useState(null);
  const [params, setParams] = useState({
    topik_id: "",
    tahunpelajaran_id: "",
    semester_id: "",
    title: "",
    start_date: "",
    end_date: "",
    deskripsi: "",
    enable_start_date: false,
    enable_end_date: false,
    list_siswa: [],
    allSiswa: false,
    nama_tugas: "",
    nilai_max: "",
    jenis_waktu_pengerjaan: "pertugas",
    waktu_pengerjaan: "0",
    nilai_raport: false,
    merdeka_target_id: "",
    max_remedial: 0,
    files: [],
    video_tugas: [],
    h5p_tugas: [],
    soal_urut: 0,
    jawaban_urut: 0,
    active_waktu_pengerjaan: false,
  });
  const [newVideo, setNewVideo] = useState({
    video_name: "",
    video_type: "link",
    video_file: "",
  });

  useEffect(() => {
    let search = new URLSearchParams(location.search);
    let tahunpelajaran_id = search.get("th");
    let semester_id = search.get("smt");
    setParams((pref) => ({
      ...pref,
      tahunpelajaran_id,
      semester_id,
    }));
  }, [location.search]);

  const showAlert = (text, status, lanjutkan, tugas_id) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    }).then((res) => {
      if (res.isConfirmed && lanjutkan) {
        if (editTugas) {
          navigate("/tugas/" + editTugas);
          return;
        }
        navigate("/tugas/" + tugas_id);
      }
    });
  };
  const handleChangeFile = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (files.length > 0) {
      let formData = new FormData();
      for (const element of files) {
        formData.append(`files`, element);
      }
      setUploadFileLoading(true);
      uploadFileTugas(formData)
        .then((res) => {
          const data = res?.data?.data || [];
          setUploadFileLoading(false);
          setParams((pref) => ({
            ...pref,
            files: [...pref.files, ...data],
          }));
        })
        .catch((err) => {
          setUploadFileLoading(false);
        });
    }
  };
  const handleChangeFileH5P = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (files.length > 0) {
      let formData = new FormData();
      for (const element of files) {
        formData.append(`files`, element);
      }
      setUploadFileH5PLoading(true);
      uploadFileTugas(formData)
        .then((res) => {
          const data = res?.data?.data || [];
          setUploadFileH5PLoading(false);
          setParams((pref) => ({
            ...pref,
            h5p_tugas: [
              ...pref.h5p_tugas,
              ...data.map((item) => ({
                h5p_filename: item.filename,
                h5p_file: item.path,
              })),
            ],
          }));
        })
        .catch((err) => {
          setUploadFileH5PLoading(false);
        });
    }
  };
  const handleDeleteFile = (idx) => {
    setParams((pref) => ({
      ...pref,
      files: pref?.files?.filter((item, i) => i !== idx),
    }));
  };
  const handleDeleteVideo = (idx) => {
    setParams((pref) => ({
      ...pref,
      video_tugas: pref?.video_tugas?.filter((item, i) => i !== idx),
    }));
  };
  const handleChangeVideo = ({ target: { name, value } }) => {
    setNewVideo((pref) => ({
      ...pref,
      [name]: value,
    }));
  };
  const handleCheckDataVideo = () => {
    if (newVideo?.video_name?.length <= 3) {
      showAlert(t("error_video_name"), false);
      return false;
    } else if (newVideo?.video_file?.length <= 0) {
      showAlert(t("error_video_link"), false);
      return false;
    } else if (!checkURLFormat(newVideo?.video_file)) {
      showAlert(t("error_url"), false);
      return false;
    }
    return true;
  };
  const handleSaveVideo = () => {
    let cek = handleCheckDataVideo();
    if (!cek) return;
    setParams((pref) => ({
      ...pref,
      video_tugas: [...pref.video_tugas, newVideo],
    }));
    setNewVideo({
      video_name: "",
      video_type: "link",
      video_file: "",
    });
  };
  const handleShowVideo = (value) => {
    setShowVideo(value);
    setNewVideo({
      video_name: "",
      video_type: "link",
      video_file: "",
    });
  };
  const handleChange = ({ target: { value, name } }) => {
    if (name === "jenis_waktu_pengerjaan" && value) {
      if (value === "persoal")
        setParams((pref) => ({
          ...pref,
          waktu_pengerjaan: "0",
        }));
    }
    if (name === "nilai_raport") {
      setParams((pref) => ({
        ...pref,
        merdeka_target_id: "",
      }));
    }
    if (name === "active_waktu_pengerjaan") {
      setParams((pref) => ({
        ...pref,
        waktu_pengerjaan: 0,
      }));
    }
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };
  const handleChangeChecked = ({ target: { checked, name } }) => {
    setParams((pref) => ({
      ...pref,
      [name]: checked,
      [name.slice(7, name.length)]: "",
    }));
  };
  const handleCheckData = () => {
    if (params.nilai_raport && params.merdeka_target_id === "") {
      showAlert(t("error_required", { data: t("target_pembelajaran") }));
      return false;
    }
    return true;
  };
  const handleSubmit = (lanjutkan) => {
    let cek = handleCheckData();
    if (!cek) return;
    setSubmit(true);
    let data = {
      topik_id: params.topik_id,
      title: params.title,
      deskripsi: editorRef.current.getContent(),
      list_siswa: params.allSiswa ? [] : params.list_siswa,
      allSiswa: params.allSiswa,
      jenis_waktu_pengerjaan: params.jenis_waktu_pengerjaan,
      waktu_pengerjaan: params.waktu_pengerjaan,
      nilai_raport: params.nilai_raport,
      merdeka_target_id: params.merdeka_target_id || "",
      max_remedial: params?.max_remedial,
      tahunpelajaran_id: params?.tahunpelajaran_id,
      semester_id: params?.semester_id,
      files: params.files,
      h5p_tugas: params.h5p_tugas,
      video_tugas: params.video_tugas,
      soal_urut: params.soal_urut ? 1 : 0,
      jawaban_urut: params.jawaban_urut ? 1 : 0,
      active_waktu_pengerjaan: params.active_waktu_pengerjaan,
    };
    if (params?.start_date?.length > 0) data.start_date = params.start_date;
    if (params?.end_date?.length > 0) data.end_date = params.end_date;
    if (editTugas) {
      updTugas(editTugas, data)
        .then((res) => {
          showAlert(
            t("berhasil_simpan", { params: t("tugas") }),
            true,
            lanjutkan
          );
          handleClose();
          setSubmit(false);
          const data = {
            tahunpelajaran_id: params?.tahunpelajaran_id,
            semester_id: params?.semester_id,
          };
          dispatch(getTopik(id, data));
        })
        .catch((err) => {
          setError(err?.response?.data?.error);
          setSubmit(false);
        });
    } else {
      addTugas(data)
        .then((res) => {
          showAlert(
            t("berhasil_simpan", { params: t("tugas") }),
            true,
            lanjutkan,
            res?.data?.data?.tugas_id
          );
          handleClose();
          setSubmit(false);
          const data = {
            tahunpelajaran_id: params?.tahunpelajaran_id,
            semester_id: params?.semester_id,
          };
          dispatch(getTopik(id, data));
        })
        .catch((err) => {
          setError(err?.response?.data?.error);
          setSubmit(false);
        });
    }
  };
  const handleChangeSiswa = (name, value) => {
    if (name === "all" && value) {
      setParams((pref) => ({
        ...pref,
        list_siswa: dataSiswa?.map((item) => item?.user?.user_id?.toString()),
        allSiswa: true,
      }));
      return;
    } else if (name === "all") {
      setParams((pref) => ({ ...pref, list_siswa: [], allSiswa: false }));
      return;
    }
    let cek = params?.list_siswa?.filter(
      (item) => item.toString() === value.toString()
    );
    if (cek?.length > 0) {
      setParams((pref) => ({
        ...pref,
        list_siswa: params?.list_siswa?.filter(
          (item) => item.toString() !== value.toString()
        ),
        allSiswa: false,
      }));
    } else {
      setParams((pref) => ({
        ...pref,
        list_siswa: [...pref.list_siswa, value?.toString()],
        allSiswa: false,
      }));
    }
  };
  useEffect(() => {
    handleChangeSiswa("all", true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const formatDate = (value) => {
    let result = value;
    if (value) {
      result = result?.substr(0, 19)?.replace("T", " ");
    }
    return result;
  };
  useEffect(() => {
    if (editTugas) {
      getDetailTugas(editTugas).then((res) => {
        let data = res?.data?.data;
        setParams((pref) => ({
          ...pref,
          topik_id: data?.topik_id,
          start_date: formatDate(data?.start_date),
          end_date: formatDate(data?.end_date),
          title: data?.title,
          deskripsi: data?.deskripsi,
          allSiswa: data?.allSiswa,
          enable_start_date: data?.start_date?.length > 0,
          enable_end_date: data?.end_date?.length > 0,
          list_siswa: data?.list_siswa,
          kelasmapel_kelasdetailid:
            data.topik.kelas_mapel.kelasmapel_kelasdetailid,
          kelasmapel_id: data.topik.kelasmapel_id,
          nama_tugas: data?.nama_tugas,
          nilai_max: data?.nilai_max,
          jenis_waktu_pengerjaan: data?.jenis_waktu_pengerjaan,
          waktu_pengerjaan: data?.waktu_pengerjaan,
          nilai_raport: data?.nilai_raport,
          merdeka_target_id: data?.merdeka_target_id,
          max_remedial: data?.max_remedial,
          files: data?.files,
          h5p_tugas: data?.h5p_tugas,
          video_tugas: data?.video_tugas,
          soal_urut: data?.soal_urut ? 1 : 0,
          jawaban_urut: data?.jawaban_urut ? 1 : 0,
          active_waktu_pengerjaan: data?.active_waktu_pengerjaan,
        }));
      });
    }
  }, [editTugas]);

  const getTarget = () => {
    let searchParams = new URLSearchParams(location.search);
    let th = searchParams.get("th");
    let smt = searchParams.get("smt");
    let data = {
      kelasmapel_id: idMapel,
    };
    if (th) data["tahunpelajaran_id"] = th;
    if (smt) data["semester_id"] = smt;
    getTargetTugas(data).then((res) => setOptionTarget(res?.data?.data));
  };

  useEffect(() => {
    getTarget();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectDrive = (file) => {
    if (file) {
      setParams((pref) => ({
        ...pref,
        files: [
          ...pref.files,
          {
            filename: file?.drive_file_name,
            path: file?.drive_file_url,
            type: file?.drive_file_type,
          },
        ],
      }));
    }
    setShowDrive(false);
  };

  return (
    <div className="col-lg-12">
      <Modal
        fullscreen={true}
        enforceFocus={false}
        show={show}
        onHide={() => {
          if (!submit) handleClose();
        }}
        id="tambahtugas"
        backdrop="static"
      >
        <Modal.Header>
          <div className="d-flex align-items-center">
            <button
              className="border-0 bg-white btn-close m-0 p-2 shadow-none"
              onClick={handleClose}
            ></button>
            <h4 className="modal-title">
              {editTugas ? "Edit" : t("tambah")} {t("tugas")}
            </h4>
          </div>
          <Dropdown align="end">
            <Dropdown.Toggle
              variant="success"
              className="btn-primary border-0"
              id="dropdown-basic"
              disabled={submit}
            >
              {submit ? (
                <img src={PageLoader} alt="loader" style={{ width: 15 }} />
              ) : (
                t("simpan")
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                disabled={submit}
                onClick={() => handleSubmit(false)}
              >
                {t("simpan")}
              </Dropdown.Item>
              <Dropdown.Item
                disabled={submit}
                onClick={() => handleSubmit(true)}
              >
                {t("simpan_lanjutkan")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Modal.Header>
        <Modal.Body className="p-0 overflow-x-hidden">
          <div className="row h-100">
            <div className="col-lg-7 offset-md-1 pt-4">
              <div className="iq-card w-modal mb-4">
                <div className="iq-card-body">
                  <div className="col-lg-12 mb-3">
                    <label htmlFor="title">
                      {t("judul")} <span className="text-danger">*</span> :
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      value={params.title}
                      name="title"
                      placeholder={t("judul")}
                      onChange={handleChange}
                      required
                    />
                    {error?.title && (
                      <div className="d-block invalid-feedback">
                        {error?.title}
                      </div>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="deskripsi">{t("desc")} :</label>
                    <InputEditor
                      editorRef={editorRef}
                      value={params.deskripsi}
                    />
                    {error?.deskripsi && (
                      <div className="d-block invalid-feedback">
                        {error?.deskripsi}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="iq-card w-modal mb-4">
                <div className="iq-card-body">
                  <div className="form-group row align-items-center">
                    <div className="d-flex flex-grow-1 align-items-center">
                      <div className="send-panel d-flex">
                        <div className="me-4 d-flex align-items-center justify-content-between flex-column">
                          <label
                            className="mb-0 rounded-circle border border-primary iq-bg-primary-hover text-primary square-48"
                            htmlFor="files"
                          >
                            <input
                              type="file"
                              name="files"
                              id="files"
                              accept="
                                image/jpeg, 
                                image/png, 
                                image/jpg, 
                                .pptx,
                                .xlsx,
                                .xls,
                                .doc,
                                .docx,
                                .ppt,
                                .pdf,
                                audio/*,
                                video/*
                                "
                              onChange={handleChangeFile}
                              multiple
                              ref={fileInput}
                              disabled={uploadFileLoading}
                              className="d-none"
                            />
                            <button
                              type="button"
                              onClick={() => {
                                fileInput?.current?.click();
                              }}
                              className="border-0 bg-transparent m-2 d-flex align-items-center"
                              disabled={uploadFileLoading}
                            >
                              {uploadFileLoading ? (
                                <img
                                  src={LoadingGreen}
                                  alt="loading"
                                  width={16}
                                  height={16}
                                />
                              ) : (
                                <i className="ri-upload-2-line m-0 text-primary"></i>
                              )}
                            </button>
                          </label>
                          <p className="fw-bold font-size-12 text-center m-0">
                            {t("unggah")}
                          </p>
                        </div>
                        <div className="me-4 d-flex align-items-center justify-content-between flex-column">
                          <label
                            className="mb-0 rounded-circle border border-primary iq-bg-primary-hover text-primary square-48"
                            htmlFor="h5p"
                          >
                            <input
                              type="file"
                              name="h5p"
                              id="h5p"
                              accept=".html"
                              onChange={handleChangeFileH5P}
                              multiple
                              ref={fileH5P}
                              disabled={uploadFileH5PLoading}
                              className="d-none"
                            />
                            <button
                              type="button"
                              onClick={() => {
                                fileH5P?.current?.click();
                              }}
                              className="border-0 bg-transparent m-2 d-flex align-items-center"
                              disabled={uploadFileH5PLoading}
                            >
                              {uploadFileH5PLoading ? (
                                <img
                                  src={LoadingGreen}
                                  alt="loading"
                                  width={16}
                                  height={16}
                                />
                              ) : (
                                <img
                                  src={IcH5P}
                                  alt="icon h5p"
                                  width={20}
                                  height="auto"
                                />
                              )}
                            </button>
                          </label>
                          <p className="fw-bold font-size-12 text-center m-0">
                            H5P
                          </p>
                        </div>
                        <div className="me-4 d-flex align-items-center justify-content-between flex-column">
                          <label className="mb-0 rounded-circle border border-primary iq-bg-primary-hover text-primary square-48">
                            <button
                              onClick={() => setShowDrive(true)}
                              className="m-2 bg-transparent border-0 text-primary"
                            >
                              <i className="ri-drive-line"></i>
                            </button>
                          </label>
                          <p className="fw-bold font-size-12 text-center m-0">
                            Drive
                          </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between flex-column">
                          <label className="mb-0 rounded-circle border border-primary iq-bg-primary-hover text-primary square-48">
                            <button
                              className="m-2 border-0 bg-transparent text-primary"
                              onClick={() => handleShowVideo(!showVideo)}
                            >
                              <i className="ri-youtube-line"></i>
                            </button>
                          </label>
                          <p className="fw-bold font-size-12 text-center m-0">
                            Video
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {showVideo && (
                    <div className="col-lg-12">
                      <div className="iq-card">
                        <div className="card-body border border-primary p-3">
                          <div className="row w-100">
                            <div className="col-12 mb-3 col-md-5">
                              <input
                                type="text"
                                className="form-control"
                                id="video_name"
                                value={newVideo.video_name}
                                name="video_name"
                                placeholder="Nama Video"
                                onChange={handleChangeVideo}
                              />
                            </div>
                            <div className="col-12 mb-3 col-md-6 d-flex align-items-center">
                              <div className="w-100">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="video_file"
                                  value={newVideo.video_file}
                                  name="video_file"
                                  placeholder="Link Video"
                                  onChange={handleChangeVideo}
                                />
                                <p className="font-12 text-muted mb-0 mt-2">
                                  {t("youtube_link_format")}:
                                  <span className="fw-bold">
                                    {" "}
                                    https://www.youtube.com/watch?v=xxxxxx
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="col-12 mb-3 col-md-1">
                              <button
                                onClick={handleSaveVideo}
                                type="button"
                                className="btn btn-primary d-flex align-items-center justify-content-center"
                              >
                                <i className="ri-add-line m-0"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <hr />
                  <div className="form-group row">
                    {params?.video_tugas?.map((item, i) => (
                      <div className="col-lg-12" key={crypto.randomUUID()}>
                        <ItemFileVideo
                          name={item.video_name}
                          type={item.video_type}
                          url={item.video_file}
                          item={item}
                          handleDeleteFile={() => handleDeleteVideo(i)}
                        />
                      </div>
                    ))}
                    {params?.files?.map((item, i) => (
                      <div className="col-lg-12" key={crypto.randomUUID()}>
                        <ItemFile
                          name={item.filename}
                          type={item.type}
                          url={item.path}
                          handleDeleteFile={() => handleDeleteFile(i)}
                        />
                      </div>
                    ))}
                    {params?.h5p_tugas?.map((item, i) => (
                      <div className="col-lg-12" key={crypto.randomUUID()}>
                        <ItemFile
                          name={item.h5p_filename}
                          type="html"
                          url={item.h5p_file}
                          handleDeleteFile={() => handleDeleteFile(i)}
                          handleH5P={() => setMateriH5P(item.h5p_file)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 offset-md-1">
              <div className="iq-card h-100">
                <div className="iq-card-body h-100">
                  <div className="col-lg-12 mb-3">
                    <label htmlFor="topik_id">
                      {t("topik")} <span className="text-danger">*</span> :
                    </label>
                    <select
                      className="form-control form-select"
                      name="topik_id"
                      id="topik_id"
                      value={params.topik_id}
                      onChange={handleChange}
                    >
                      <option value="">
                        {t("pilih_params", { params: t("topik") })}
                      </option>
                      {dataTopik?.map((item) => (
                        <option value={item.topik_id} key={item.topik_id}>
                          {item.topic}
                        </option>
                      ))}
                    </select>
                    {error?.topik_id && (
                      <div className="d-block invalid-feedback">
                        {error?.topik_id}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-12 mb-3">
                    <SelectMultiple
                      dataSiswa={dataSiswa}
                      selectedSiswa={params?.list_siswa}
                      selectedAll={params?.allSiswa}
                      handleChange={handleChangeSiswa}
                    />
                  </div>
                  <div className="col-lg-12 mb-3">
                    <div className="row">
                      <label htmlFor="start_date">{t("tgl_mulai")} :</label>
                      <div className="col-10">
                        <input
                          type="datetime-local"
                          className="form-control"
                          id="start_date"
                          value={params.start_date}
                          onChange={handleChange}
                          name="start_date"
                          disabled={!params.enable_start_date}
                        />
                        {error?.start_date && (
                          <div className="d-block invalid-feedback">
                            {error?.start_date}
                          </div>
                        )}
                      </div>
                      <div className="col-2 align-self-center">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="enable_start_date"
                            value={params.enable_start_date}
                            checked={params.enable_start_date}
                            onChange={handleChangeChecked}
                            name="enable_start_date"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="enable_start_date"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <div className="row">
                      <label htmlFor="end_date">{t("tgl_selesai")} :</label>
                      <div className="col-10">
                        <input
                          type="datetime-local"
                          className="form-control"
                          id="end_date"
                          name="end_date"
                          value={params.end_date}
                          onChange={handleChange}
                          disabled={!params.enable_end_date}
                        />
                        {error?.end_date && (
                          <div className="d-block invalid-feedback">
                            {error?.end_date}
                          </div>
                        )}
                      </div>
                      <div className="col-2 align-self-center">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="enable_end_date"
                            value={params.enable_end_date}
                            checked={params.enable_end_date}
                            onChange={handleChangeChecked}
                            name="enable_end_date"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="enable_end_date"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <div className="row">
                      <label htmlFor="soal_urut" className="col-12">
                        {t("acak")} :
                      </label>
                      <div className="col-12 d-flex">
                        <div className="custom-control custom-checkbox custom-control-inline">
                          <input
                            type="checkbox"
                            id="soal_urut"
                            name="soal_urut"
                            className="custom-control-input"
                            checked={params.soal_urut}
                            onChange={({ target: { checked } }) =>
                              handleChange({
                                target: {
                                  name: "soal_urut",
                                  value: checked,
                                },
                              })
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="soal_urut"
                          >
                            {t("soal")}
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox custom-control-inline">
                          <input
                            type="checkbox"
                            id="jawaban_urut"
                            name="jawaban_urut"
                            className="custom-control-input"
                            checked={params.jawaban_urut}
                            onChange={({ target: { checked } }) =>
                              handleChange({
                                target: {
                                  name: "jawaban_urut",
                                  value: checked,
                                },
                              })
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="jawaban_urut"
                          >
                            {t("jawaban")}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <div className="row">
                      <div className="col-12 d-flex gap-8">
                        <label htmlFor="active_waktu_pengerjaan">
                          {t("waktu_pengerjaan")} :
                        </label>
                        <div className="custom-control custom-switch ps-5">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="active_waktu_pengerjaan"
                            checked={params.active_waktu_pengerjaan}
                            onChange={({ target: { checked } }) =>
                              handleChange({
                                target: {
                                  value: checked,
                                  name: "active_waktu_pengerjaan",
                                },
                              })
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="active_waktu_pengerjaan"
                          ></label>
                        </div>
                      </div>
                      {params?.active_waktu_pengerjaan && (
                        <>
                          <div className="col-12 d-flex">
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="pertugas"
                                name="jenis_waktu_pengerjaan"
                                className="custom-control-input"
                                checked={
                                  params.jenis_waktu_pengerjaan === "pertugas"
                                }
                                onChange={({ target: { value } }) =>
                                  handleChange({
                                    target: {
                                      name: "jenis_waktu_pengerjaan",
                                      value: value ? "pertugas" : "",
                                    },
                                  })
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="pertugas"
                              >
                                {t("pertugas")}
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="persoal"
                                name="jenis_waktu_pengerjaan"
                                className="custom-control-input"
                                checked={
                                  params.jenis_waktu_pengerjaan === "persoal"
                                }
                                onChange={({ target: { value } }) =>
                                  handleChange({
                                    target: {
                                      name: "jenis_waktu_pengerjaan",
                                      value: value ? "persoal" : "",
                                    },
                                  })
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="persoal"
                              >
                                {t("persoal")}
                              </label>
                            </div>
                          </div>
                          {params.jenis_waktu_pengerjaan === "pertugas" ? (
                            <div className="col-12 mt-2 d-flex">
                              <input
                                type="number"
                                className="form-control"
                                id="judul"
                                placeholder="0"
                                value={params.waktu_pengerjaan}
                                onChange={handleChange}
                                name="waktu_pengerjaan"
                              />
                              <span className="ms-3 me-1 align-self-center">
                                {t("menit")}
                              </span>
                            </div>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <div className="row">
                      <div className="col-12 d-flex gap-8">
                        <label htmlFor="nilai_raport">
                          {t("nilai_rapor")} :
                        </label>
                        <div className="custom-control custom-switch ps-5">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="nilai_raport"
                            checked={params.nilai_raport}
                            onChange={({ target: { checked } }) =>
                              handleChange({
                                target: {
                                  value: checked,
                                  name: "nilai_raport",
                                },
                              })
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="nilai_raport"
                          ></label>
                        </div>
                      </div>
                      <div className="col-10">
                        <select
                          className="form-control form-select"
                          value={params.merdeka_target_id}
                          onChange={handleChange}
                          name="merdeka_target_id"
                          disabled={!params.nilai_raport}
                          required={params.nilai_raport}
                        >
                          <option value="">
                            --
                            {t("pilih_params", {
                              params: t("target_pembelajaran"),
                            })}
                            --
                          </option>
                          {optionTarget?.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.target_merdeka}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-2 align-self-center">
                        <button
                          type="button"
                          className="btn btn-primary ml-3 mr-1"
                          onClick={() => setOpenModalTarget(true)}
                        >
                          <i className="ri-add-line m-0"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 d-none">
                    <label htmlFor="max_remedial">{t("max_remidial")}:</label>
                    <select
                      className="form-control form-select"
                      name="max_remedial"
                      id="max_remedial"
                      value={params.max_remedial}
                      onChange={handleChange}
                    >
                      <option value="0">{t("tidak_ada")}</option>
                      <option value="1">{t("no_kali", { no: 1 })}</option>
                      <option value="2">{t("no_kali", { no: 2 })}</option>
                      <option value="3">{t("no_kali", { no: 3 })}</option>
                    </select>
                    {error?.max_remedial && (
                      <div className="d-block invalid-feedback">
                        {error?.max_remedial}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ModalTarget
        handleClose={() => {
          setOpenModalTarget(false);
          getTarget();
        }}
        show={openModalTarget}
        target={optionTarget}
        idMapel={idMapel}
        getTarget={getTarget}
      />
      <ModalDrive
        show={showDrive}
        handleClose={() => {
          setShowDrive(false);
        }}
        handleSave={handleSelectDrive}
      />
      {materiH5P && (
        <ModalMateriH5P
          url={materiH5P}
          handleClose={() => setMateriH5P(null)}
          show={true}
        />
      )}
    </div>
  );
}

export default TambahTugas;
