import { API, setAuthToken } from "../../config/api";

export const getAbsensiSemester = async (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await API.get(
    `/absensi/list-semester?${new URLSearchParams(params).toString()}`
  ).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data?.data;
};

export const getAbsensiKelas = async (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await API.get(
    `/absensi/list-kelas?${new URLSearchParams(params).toString()}`
  ).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data?.data;
};

export const getJenisAbsensi = async (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await API.get(
    `/absensi/list-jenis?${new URLSearchParams(params).toString()}`
  ).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data?.data;
};

export const getSiswaAbsensi = async (type, params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await API.get(
    `/absensi/list-siswa-${type || "walikelas"}?${new URLSearchParams(
      params
    ).toString()}`
  ).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data?.data;
};

export const getWeeklyAbsensi = async (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await API.get(
    `/weekly/pekan/list-siswa?${new URLSearchParams(params).toString()}`
  ).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data?.data;
};

export const getTopikAbsensi = async (mapel_id) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await API.get(`absensi/list-topik?kelasmapel_id=${mapel_id}`).catch(
    (err) => {
      return Promise.reject(err);
    }
  );
  return res?.data?.data;
};

export const getPekanAbsensi = async (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await API.get(
    `/weekly/pekan?${new URLSearchParams(params).toString()}`
  ).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data?.data;
};

export const submitAbsensi = async (type, params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`/absensi/store-${type || "walikelas"}`, params);
};

export const submitAbsensiWeekly = async (type, params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`/weekly/store-${type || "walas"}`, params);
};

export const submitPekan = async (edit, params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`/weekly/pekan${edit ? "/update" : ""}`, params);
};

export const submitStatusPekan = async (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("/weekly/pekan/update-status", params);
};

export const delPekan = async (id) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.delete("/weekly/pekan?pekan_id=" + id);
};

export const getDetailWeekly = async (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await API.get(
    `/weekly/detail-walas?${new URLSearchParams(params).toString()}`
  ).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data?.data;
};

export const getAbsensiSiswa = async (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await API.get(
    `/absensi/list-tanggal-siswa?${new URLSearchParams(params).toString()}`
  ).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data?.data;
};

export const updPublishPekan = async (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("weekly/pekan/publish", params);
};

export const getPekanAbsensiSiswa = async (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await API.get(
    `weekly/raport?${new URLSearchParams(params).toString()}`
  ).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data?.data;
};

export const getAbsensiSiswaByTopik = async (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await API.get(
    `absensi/list-topik-siswa?${new URLSearchParams(params).toString()}`
  ).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data?.data;
};

export const getDetailPekanAbsensiSiswa = async (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await API.get(
    `weekly/detail-group?${new URLSearchParams(params).toString()}`
  ).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data?.data;
};
