import React, { useEffect, useState } from "react";
import { getSlide } from "../../redux/actions/appAction";
import { Carousel } from "react-bootstrap";

const SlideHome = () => {
  const [dataSlide, setDataSlide] = useState([]);
  useEffect(() => {
    getSlide().then((res) => setDataSlide(res?.data));
  }, []);

  return (
    <div className="col-12">
      <div className="iq-card">
        <div className="iq-card-body">
          <Carousel controls={false}>
            {dataSlide?.map((item) => (
              <Carousel.Item key={item.slide_id}>
                <img
                  src={item.slide_url}
                  className="d-block w-100"
                  alt="slide"
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default SlideHome;
