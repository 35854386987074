import React from "react";
import { useTranslation } from "react-i18next";

const HeaderPengerjaan = ({
  soalCerita,
  title,
  handleCerita,
  handleNoSoal,
  idxSoal,
  jmlSoal,
}) => {
  const { t } = useTranslation();
  return (
    <div className="iq-card-header d-flex justify-content-between">
      <div className="iq-header-title d-flex gap-8">
        <h4 className="card-title fw-bold">{title}</h4>
        {soalCerita && (
          <button onClick={handleCerita} className="btn btn-primary text-white">
            {t("baca_cerita")}
          </button>
        )}
      </div>
      <div className="d-flex gap-2">
        <button
          onClick={handleNoSoal}
          type="button"
          className="btn btn-sm btn-primary font-weight-bold text-center"
        >
          <i className="ri-list-unordered m-1"></i>
        </button>
        <div className="text-dark font-size-20">
          <span className="font-size-20 fw-bold">
            {t("soal")} {idxSoal + 1}
          </span>{" "}
          / {jmlSoal}
        </div>
      </div>
    </div>
  );
};

export default HeaderPengerjaan;
