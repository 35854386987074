import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import {
  AUDIO_EXT,
  PNG_IMG_EXT,
  SVG_IMG_EXT,
  VIDEO_EXT,
} from "../../config/fileType";

function Soal({ soal_file, soal_file_type, soal_pertanyaan }) {
  const { t } = useTranslation();
  return (
    <Fragment>
      {soal_file && AUDIO_EXT.includes(soal_file_type) ? (
        <div className="col-12">
          <div className="image-block">
            <audio src={soal_file} controls className="w-100"></audio>
          </div>
        </div>
      ) : soal_file ? (
        <div className="col-lg-3">
          <div className="image-block">
            {SVG_IMG_EXT?.includes(soal_file_type) ||
            PNG_IMG_EXT?.includes(soal_file_type) ? (
              <img
                src={soal_file}
                className="img-fluid rounded w-100"
                alt="gambar"
              />
            ) : VIDEO_EXT?.includes(soal_file_type) ? (
              <video src={soal_file} controls className="w-100 h-auto"></video>
            ) : (
              <p className="m-0 text-muted text-italic">
                {t("format_tdk_diketahui")}
              </p>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      <div className={soal_file ? "col-lg-9" : "col-lg-12"}>
        <div
          className="font-size-20 h-100 p-3"
          dangerouslySetInnerHTML={{
            __html: soal_pertanyaan,
          }}
        ></div>
      </div>
    </Fragment>
  );
}

export default Soal;
