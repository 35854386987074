import { GET_PELAJARAN, GET_PROFILE, LOGOUT } from '../constant';

const initState = {
	pelajaran: [],
	loading: [],
	detailPelajaran: null,
};

const pelajaranReducer = (state = initState, action) => {
	switch (action?.type) {
		case GET_PELAJARAN + "_PENDING":
			return {
				...state,
				loading: true,
			};
		case GET_PELAJARAN + "_FULFILLED":
			return {
				...state,
				pelajaran: action.payload,
				loading: false,
			};
		case GET_PELAJARAN + "_REJECTED":
			return {
				...state,
				loading: false,
			};
		case GET_PROFILE + "_REJECTED":
			return {
				pelajaran: [],
				loading: [],
				detailPelajaran: null,
			};
		case LOGOUT:
			return {
				pelajaran: [],
				loading: [],
				detailPelajaran: null,
			};
		default: {
			return state;
		}
	}
};

export default pelajaranReducer;
