import React from 'react';
import { Accordion, Card, useAccordionButton } from 'react-bootstrap';

import { PageLoader } from '../../assets/images';
import { Wrapper } from '../../components';

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("totally custom!")
  );

  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      className="w-100 bg-transparent border-0 text-start px-0 accordion-title d-flex align-items-center position-relative"
    >
      {children}
    </button>
  );
}
function Panduan() {
  return (
    <Wrapper>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <Accordion
              defaultActiveKey="0"
              className="iq-accordion career-style faq-style"
            >
              <Card className="iq-card iq-accordion-block">
                <Card.Header className="bg-transparent border-0">
                  <CustomToggle eventKey="0">
                    It is a long established fact that a reader will be?
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    Many desktop publishing packages and web page editors now
                    use Lorem Ipsum as their default model text, and a search
                    for 'lorem ipsum' will uncover many web sites still in their
                    infancy.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="iq-card iq-accordion-block">
                <Card.Header className="bg-transparent border-0">
                  <CustomToggle eventKey="1">
                    Distracted by the readable content of a page whent?
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    It has survived not only five centuries, but also the leap
                    into electronic typesetting. Neque porro quisquam est, qui
                    dolorem ipsum quia dolor sit amet, consectetur.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="iq-card iq-accordion-block">
                <Card.Header className="bg-transparent border-0">
                  <CustomToggle eventKey="2">
                    What is user interface kit?
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    It has survived not only five centuries, but also the leap
                    into electronic typesetting. Neque porro quisquam est, qui
                    dolorem ipsum quia dolor sit amet, consectetur.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="iq-card iq-accordion-block">
                <Card.Header className="bg-transparent border-0">
                  <CustomToggle eventKey="3">
                    What is user interface kit?
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    It has survived not only five centuries, but also the leap
                    into electronic typesetting. Neque porro quisquam est, qui
                    dolorem ipsum quia dolor sit amet, consectetur.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="iq-card iq-accordion-block">
                <Card.Header className="bg-transparent border-0">
                  <CustomToggle eventKey="4">
                    What is user interface kit?
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    It has survived not only five centuries, but also the leap
                    into electronic typesetting. Neque porro quisquam est, qui
                    dolorem ipsum quia dolor sit amet, consectetur.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
          <div className="col-sm-12 text-center">
            <img src={PageLoader} alt="loader" style={{ height: 100 }} />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Panduan;
