import React from "react";
import { useTranslation } from "react-i18next";

const InfoProfile = ({ user, isSiswa, isGuru }) => {
  const { t } = useTranslation();
  return (
    <div className="tab-pane fade active show" id="profile" role="tabpanel">
      <h4>{t("info_dasar")}</h4>
      <hr className="my-2" />
      <div className="row">
        <div className="col-3">
          <h6>{isGuru ? "NIP" : isSiswa ? "NIS" : "ID"}</h6>
        </div>
        <div className="col-9">
          <p className="mb-0">{user?.user_no}</p>
        </div>
        {isSiswa && (
          <>
            <div className="col-3">
              <h6>NISN</h6>
            </div>
            <div className="col-9">
              <p className="mb-0">{user?.user_no_n}</p>
            </div>
          </>
        )}
        <div className="col-3">
          <h6>{t("nama_lengkap")}</h6>
        </div>
        <div className="col-9">
          <p className="mb-0">{user?.user_nama}</p>
        </div>
        <div className="col-3">
          <h6>{t("nama_panggilan")}</h6>
        </div>
        <div className="col-9">
          <p className="mb-0">{user?.user_panggilan}</p>
        </div>
        <div className="col-3">
          <h6>{t("tempat_lahir")}</h6>
        </div>
        <div className="col-9">
          <p className="mb-0">{user?.user_tempat_lahir}</p>
        </div>
        <div className="col-3">
          <h6>{t("tgl_lahir")}</h6>
        </div>
        <div className="col-9">
          <p className="mb-0">{user?.user_tgllahir}</p>
        </div>
        <div className="col-3">
          <h6>{t("jenis_kelamin")}</h6>
        </div>
        <div className="col-9">
          <p className="mb-0">
            {user?.user_kelamin === "P" ? "Perempuan" : "Laki-laki"}
          </p>
        </div>
        <div className="col-3">
          <h6>{t("agama")}</h6>
        </div>
        <div className="col-9">
          <p className="mb-0">{user?.user_agama}</p>
        </div>
      </div>
      <h4 className="mt-3">{t("info_kotak")}</h4>
      <hr className="my-2" />
      <div className="row mb-3">
        <div className="col-3">
          <h6>Email</h6>
        </div>
        <div className="col-9">
          <p className="mb-0">{user?.user_email}</p>
        </div>
        <div className="col-3">
          <h6>{t("ponsel")}</h6>
        </div>
        <div className="col-9">
          <p className="mb-0">{user?.user_ponsel}</p>
        </div>
      </div>
      <h4 className="mt-3">{t("alamat")}</h4>
      <hr className="my-2" />
      <div className="row">
        <div className="col-3">
          <h6>{t("alamat")}</h6>
        </div>
        <div className="col-9">
          <p className="mb-0">{user?.user_alamat}</p>
        </div>
        <div className="col-3">
          <h6>{t("provinsi")}</h6>
        </div>
        <div className="col-9">
          <p className="mb-0">{user?.provinsi?.wilayahindonesia_nama}</p>
        </div>
        <div className="col-3">
          <h6>{t("kab_kota")}</h6>
        </div>
        <div className="col-9">
          <p className="mb-0">{user?.kota?.wilayahindonesia_nama}</p>
        </div>
        <div className="col-3">
          <h6>{t("kecamatan")}</h6>
        </div>
        <div className="col-9">
          <p className="mb-0">{user?.kecamatan?.wilayahindonesia_nama}</p>
        </div>
        <div className="col-3">
          <h6>{t("desa")}</h6>
        </div>
        <div className="col-9">
          <p className="mb-0">{user?.desa?.wilayahindonesia_nama}</p>
        </div>
      </div>
    </div>
  );
};

export default InfoProfile;
