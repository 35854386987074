import React, { useState } from "react";
import { ProgressBar } from "react-bootstrap";

import { ModalReviewCourse } from "../modal";
import { useTranslation } from "react-i18next";
import { getCertificateCourse } from "../../redux/actions/courseAction";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { PageLoader } from "../../assets/images";

function ProgressCourse({ progress, joinStatus, reviewUser }) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  const [submit, setSubmit] = useState(false);
  const { user } = useSelector(({ user }) => user);

  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };
  const handleDownload = () => {
    if (!reviewUser) {
      setShowModal(true);
    } else {
      const data = {
        user_id: user?.user_id,
      };
      setSubmit(true);
      getCertificateCourse(id, data)
        .then((res) => {
          setSubmit(false);
          setShowModal(false);
          window.open(res?.url, "_blank");
        })
        .catch(() => {
          setSubmit(false);
          showAlert(t("gagal_mengambil_sertifikat"), false);
        });
    }
  };
  return (
    <>
      <div className="iq-card iq-card-block iq-card-stretch">
        <div className="iq-card-body p-4">
          <div className="d-flex justify-content-between mb-3">
            <h4 className="card-title font-weight-bold">Progress</h4>
            <span>{progress}/100</span>
          </div>
          <ProgressBar animated now={progress} />
          {joinStatus === "finish" ? (
            <>
              <hr className="mt-4" />
              <p className="text-muted text-center">{t("progres_finish")}</p>
              <button
                disabled={submit}
                onClick={handleDownload}
                type="button"
                className="btn btn-primary py-2 font-size-16 text-white fw-bold rounded-pill w-100"
              >
                {submit ? (
                  <img
                    src={PageLoader}
                    alt="loader"
                    style={{ height: 24 }}
                    className="mx-auto"
                  />
                ) : (
                  t("download_sertifikat")
                )}
              </button>
            </>
          ) : null}
        </div>
      </div>
      <ModalReviewCourse
        show={showModal}
        handleClose={() => setShowModal(false)}
      />
    </>
  );
}

export default ProgressCourse;
