import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { getBookDetail } from "../../redux/actions/libraryAction";
import { useSelector } from "react-redux";
import { PageLoader } from "../../assets/images";

const ModalDetailBuku = ({ show, handleClose, bookId }) => {
  const { t } = useTranslation();
  const [detail, setDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector(({ user }) => user);

  useEffect(() => {
    setLoading(true);
    getBookDetail(user.user_id, bookId)
      .then((res) => {
        setDetail(res?.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [bookId, user]);

  const isPhysicalBook = parseInt(detail?.is_physical_book) === 1;
  const isDigitalBook = parseInt(detail?.is_digital_book) === 1;
  return (
    <div className="col-lg-12">
      <Modal
        fullscreen={true}
        enforceFocus={false}
        show={show}
        onHide={handleClose}
        id="ModalDetailBuku"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title">{t("detail_buku")}</h5>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="justify-content-between">
              {loading ? (
                <div className="col-12 text-center">
                  <img src={PageLoader} alt="loader" height={80} width={60} />
                </div>
              ) : (
                <>
                  <Col md="4">
                    <img
                      src={
                        detail?.cover ||
                        require("../../assets/images/default_cover.webp")
                      }
                      className="img-fluid rounded shadow-md book-cover"
                      alt={detail?.title}
                      onError={(e) => {
                        e.target.src = require("../../assets/images/default_cover.webp");
                        e.target.onError = null;
                      }}
                    />
                  </Col>
                  <Col md="8">
                    <div className="blog-description">
                      <h3 className="fw-bold">{detail?.title}</h3>
                      <span className="d-flex align-items-center gap-1">
                        <i className="ri-heart-fill text-danger"></i>{" "}
                        <h4 className="font-size-14">
                          {detail?.book_like_count} Favorite
                        </h4>
                      </span>
                      <div className="d-flex">
                        <div className="me-5 mt-2">
                          <p className="mb-1 line-height font-14">
                            {t("fisik_buku")}
                          </p>
                          <h5
                            className={`font-size-16 fw-bold ${
                              isPhysicalBook ? "text-primary" : "text-danger"
                            }`}
                          >
                            {isPhysicalBook
                              ? t("tersedia")
                              : t("tidak_tersedia")}
                          </h5>
                        </div>
                        <div className="me-5 mt-2">
                          <p className="mb-1 line-height font-size-14">
                            E-Book
                          </p>
                          <h5
                            className={`font-size-16 fw-bold ${
                              isDigitalBook ? "text-primary" : "text-danger"
                            }`}
                          >
                            {isDigitalBook
                              ? t("tersedia")
                              : t("tidak_tersedia")}
                          </h5>
                        </div>
                        <div className="me-5 mt-2">
                          <p className="mb-1 line-height font-size-14">
                            {t("perpustakaan")}
                          </p>
                          <h5 className="font-size-16 text-primary fw-bold text-capitalize">
                            {detail?.library_data?.library}
                          </h5>
                        </div>
                      </div>
                      <hr />
                      <table className="col-12 table table-borderless mt-4">
                        <tbody>
                          <tr>
                            <th className="border-0 ps-0" scope="row">
                              {t("bahasa")}
                            </th>
                            <td className="border-0">
                              {detail?.language_data?.name}
                            </td>

                            <th className="border-0 ps-0" scope="row">
                              {t("kolasi")}
                            </th>
                            <td className="border-0">{detail?.kolasi}</td>
                          </tr>
                          <tr>
                            <th className="border-0 ps-0" scope="row">
                              {t("penulis")}
                            </th>
                            <td className="border-0">{detail?.author}</td>

                            <th className="border-0 ps-0" scope="row">
                              {t("mata_pelajaran")}
                            </th>
                            <td className="border-0">
                              {detail?.mapelData?.mapel_nama || "Umum"}
                            </td>
                          </tr>
                          <tr>
                            <th className="border-0 ps-0" scope="row">
                              {t("penerbit")}
                            </th>
                            <td className="border-0">{detail?.publisher}</td>

                            <th className="border-0 ps-0" scope="row">
                              {t("kelas")}
                            </th>
                            <td className="border-0">
                              {detail?.kelasData?.kelas_nama || "Umum"}
                            </td>
                          </tr>
                          <tr>
                            <th className="border-0 ps-0" scope="row">
                              {t("bentuk")}
                            </th>
                            <td className="border-0">
                              {detail?.bentuk_buku?.name || "-"}
                            </td>

                            <th className="border-0 ps-0" scope="row">
                              {t("rak")}
                            </th>
                            <td className="border-0">
                              {detail?.rak_buku?.rack}
                            </td>
                          </tr>
                          <tr>
                            <th className="border-0 ps-0" scope="row">
                              {t("kategori")}
                            </th>
                            <td className="border-0">
                              {detail?.category_data?.category}
                            </td>

                            <th className="border-0 ps-0" scope="row">
                              {t("tahun")}
                            </th>
                            <td className="border-0">{detail?.year}</td>
                          </tr>
                          <tr>
                            <th className="border-0 ps-0" scope="row">
                              {t("subjek")}
                            </th>
                            <td className="border-0">
                              {detail?.subjek_buku?.min_value} -{" "}
                              {detail?.subjek_buku?.name}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalDetailBuku;
