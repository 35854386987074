import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { PageLoader } from "../../assets/images";
import {
  BookPotraitCard,
  FilterLibrary,
  HeaderLibrary,
  ModalDetailBuku,
  ModalPreviewBook,
  SortLibrary,
  Wrapper,
} from "../../components";
import { getKelas } from "../../redux/actions/appAction";
import {
  getLibrary,
  getPerpus,
  getUserOverview,
  viewBook,
} from "../../redux/actions/libraryAction";
import { RESET_LIBRARY, VIEW_LIBRARY } from "../../redux/constant";
import Swal from "sweetalert2";

function Library() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { book } = useSelector(({ library }) => library);
  const { user } = useSelector(({ user }) => user);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [bookData, setBookData] = useState(null);
  const [dataPerpus, setDataPerpus] = useState([]);

  const [userOverview, setUserOverview] = useState({
    baca: 0,
    favorite: 0,
    pinjam: 0,
    kembali: 0,
  });
  const [page, setPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [params, setParams] = useState({
    kelas: "",
    mapel: "",
    bahasa: "",
    tahun1: "",
    tahun2: "",
    sort_by: "",
    filter: "",
    library: "",
  });
  const getUsertData = useCallback(() => {
    getUserOverview(user?.user_id).then((res) => setUserOverview(res?.data));
  }, [user?.user_id]);
  const getData = useCallback(
    (params) => {
      setLoading(true);
      const data = { ...params, limit: 9 };
      dispatch(getLibrary(user?.user_id, data)).then(() => setLoading(false));
    },
    [dispatch, user?.user_id]
  );
  useEffect(() => {
    if (user?.user_id && dataPerpus?.length > 0) {
      dispatch({ type: RESET_LIBRARY });
      getData({
        ...params,
        search,
        page: 1,
        category: selectedCategory,
        subjects: selectedSubject,
        types: selectedType,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    params.bahasa,
    params.filter,
    params.kelas,
    params.mapel,
    params.sort_by,
    params.library,
    params.tahun1,
    params.tahun2,
    getData,
    selectedCategory,
    dataPerpus,
    selectedSubject,
    selectedType,
  ]);
  useEffect(() => {
    if (user?.user_id) {
      getUsertData();
    }
  }, [user, getUsertData]);
  const handleChange = ({ target: { name, value } }) => {
    if (name === "library") {
      setSelectedCategory([]);
      setSelectedSubject([]);
      setSelectedType([]);
    }
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };
  const handleChangeSearch = ({ target: { value } }) => setSearch(value);

  useEffect(() => {
    dispatch(getKelas());
    if (user?.user_sekolahid) {
      getPerpus().then((res) => {
        const userPerpus = res?.data?.filter(
          (pref) => pref.school === user?.user_sekolahid
        )[0]?.id;
        if (userPerpus) {
          setParams((pref) => ({
            ...pref,
            library: userPerpus,
          }));
        }
        setDataPerpus(res?.data);
      });
    }
  }, [dispatch, user?.user_sekolahid]);

  const resetGetData = () => {
    dispatch({ type: RESET_LIBRARY });
    getData({ ...params, search, page: 1 });
  };

  const addPage = () => {
    setPage((prev) => prev + 1);
    getData({ ...params, search, page: page + 1 });
  };

  const handleSelectFilter = (val) => {
    const check = params.filter === val;
    if (check) {
      setParams((pref) => ({
        ...pref,
        filter: "",
      }));
    } else {
      setParams((pref) => ({
        ...pref,
        filter: val,
      }));
    }
  };

  const handleViewBook = (code) => {
    viewBook(user?.user_id, code).then(() => {
      dispatch({
        type: VIEW_LIBRARY,
        payload: { book: code },
      });
    });
  };

  const handlePreview = (fileURL, code) => {
    if (fileURL) {
      handleViewBook(code);
      setBookData(fileURL);
      setShowPreview(true);
      return;
    }
    Swal.fire({
      title: "Error",
      text: t("buku_tidak_ada"),
      icon: "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#f27474",
    });
  };

  const handleDetail = (data) => {
    setBookData(data);
    setShowDetail(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
    getUsertData();
  };

  return (
    <Wrapper title={t("perpustakaan")}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <HeaderLibrary
              baca={userOverview?.baca}
              favorite={userOverview?.favorite}
              kembali={userOverview?.kembali}
              pinjam={userOverview?.pinjam}
              selected={params.filter}
              handleSelectFilter={handleSelectFilter}
            />
          </div>
          <div className="col-lg-3 col-sm-4">
            <FilterLibrary
              bahasa={params.bahasa}
              kelas={params.kelas}
              tahun1={params.tahun1}
              tahun2={params.tahun2}
              search={search}
              handleChange={handleChange}
              handleChangeSearch={handleChangeSearch}
              getData={resetGetData}
              params={params}
              mapel={params.mapel}
              sortBy={params.sort_by}
              selectedCategory={selectedCategory}
              library={params.library}
              dataPerpus={dataPerpus}
            />
            <SortLibrary
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              selectedSubject={selectedSubject}
              setSelectedSubject={setSelectedSubject}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              library={params.library}
            />
          </div>
          <div className="col-lg-9 col-sm-8">
            <div className="row">
              {book?.data?.length <= 0 ? (
                <p className="text-center">
                  {t("data_params_kosong", { params: t("buku") })}
                </p>
              ) : (
                book?.data?.map((item) => (
                  <BookPotraitCard
                    cover={item.cover}
                    judul={item.title}
                    year={item.year}
                    author={item.author}
                    key={item.id}
                    isLike={item.user_like}
                    code={item.id}
                    view={item.book_read_count}
                    fileUrl={item.fileurl}
                    kategori={item.category_data?.category}
                    like={item.book_like_count}
                    params={{ ...params, search }}
                    getUsertData={getUsertData}
                    handlePreview={handlePreview}
                    handleDetail={handleDetail}
                  />
                ))
              )}
              {book &&
              book?.data?.length > 0 &&
              book?.current_page < book?.last_page ? (
                <div className="mt-2 mb-3 py-2 text-center">
                  <button
                    className="btn btn-primary rounded-pill px-4"
                    onClick={addPage}
                    disabled={loading}
                  >
                    {t("muat_lainnya")}
                  </button>
                </div>
              ) : loading ? (
                <div className="col-12 text-center">
                  <img
                    src={PageLoader}
                    alt="loading"
                    style={{ height: "80px" }}
                    className="mx-auto"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {showDetail && (
        <ModalDetailBuku
          show={showDetail}
          bookId={bookData}
          handleClose={() => setShowDetail(false)}
        />
      )}
      {showPreview ? (
        <ModalPreviewBook
          handleClose={handleClosePreview}
          pdfURL={bookData}
          showModal={showPreview}
        />
      ) : null}
    </Wrapper>
  );
}

export default Library;
