import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

const ActionSoalUjian = ({ handleDelete }) => {
  const { t } = useTranslation();
  const { id, idKelas } = useParams();
  return (
    <div className="d-flex mb-3 gap-8">
      <Link
        to={`/ujian/tambah-soal/${id}/${idKelas}`}
        className="btn btn-primary d-block flex-1 border-primary font-size-18 fw-bold"
        style={{
          borderRadius: "0.75rem",
          boxShadow: "0 3px 0 0 #64a963",
        }}
      >
        <i className="ri-add-line"></i> {t("tambah")} {t("soal")}
      </Link>
      <button
        className="btn btn-danger d-block flex-1 border-danger font-size-18 fw-bold"
        style={{
          borderRadius: "0.75rem",
          boxShadow: "0 3px 0 0 #db7564",
        }}
        onClick={handleDelete}
      >
        <i className="ri-delete-bin-line"></i> {t("hapus")} {t("ujian")}
      </button>
    </div>
  );
};

export default ActionSoalUjian;
