import { API, setAuthToken } from "../../config/api";

export const addDiskusi = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("diskusi", params);
};

export const updDiskusi = (code, params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("diskusi/" + code, params);
};

export const updStatusDiskusi = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("diskusi/update-status", params);
};

export const uploadFileDiskusi = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("diskusi/upload-file", params);
};

export const delDiskusi = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.delete("diskusi/" + code);
};

export const getDetailDiskusi = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`diskusi/${code}`);
};

export const sendShareDiskusi = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`diskusi/share`, params);
};

export const sendThreadDiskusi = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`diskusi/thread`, params);
};

export const updThreadDiskusi = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`diskusi/thread/update`, params);
};

export const getDiskusiThread = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`diskusi/thread?diskusi_id=${code}`);
};

export const delDiskusiThread = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.delete("diskusi/thread/" + code);
};
