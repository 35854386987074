import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const courseStatus = ["ongoing", "finish"];
const FooterBuyCourseM = ({
  joined,
  price,
  submitSubs,
  loading,
  handleSubscribe,
  courseId,
  handleUploadBukti,
}) => {
  const { t } = useTranslation();
  if (joined === null) {
    return (
      <div className="d-lg-none bg-white shadow-lg position-fixed left-0 bottom-0 w-100">
        <div className="container-fluid py-3 d-flex justify-content-between align-items-center">
          <h4 className="fw-bold m-0">{price}</h4>
          <button
            disabled={submitSubs || loading}
            onClick={handleSubscribe}
            type="button"
            className="btn btn-primary rounded-pill px-4 py-2"
          >
            {t("beli_course")}
          </button>
        </div>
      </div>
    );
  }
  if (courseStatus.includes(joined?.join_status)) {
    <Link
      to={`/course/${courseId}`}
      className="d-lg-none shadow-lg position-fixed left-0 bottom-0 w-100 bg-primary"
    >
      {t("lanjutkan_belajar")}
    </Link>;
  }
  return (
    <button
      disabled={joined?.invoice_doc?.invoice_document_status === "waiting"}
      onClick={handleUploadBukti}
      className={`d-lg-none border-0 py-3 shadow-lg position-fixed left-0 bottom-0 w-100 fw-semibold 
      ${
        joined?.invoice_doc === null
          ? "bg-primary"
          : joined?.invoice?.invoice_status === "waiting"
          ? "bg-secondary"
          : "bg-danger"
      }`}
    >
      {joined?.invoice_doc === null ? (
        t("upload_bukti")
      ) : joined?.invoice?.invoice_status === "waiting" ? (
        t("bukti_pending")
      ) : (
        <>
          {t("bukti_ditolak")}
          <p className="font-size-14 fw-normal fst-italic p-0 m-0">
            ({t("lihat_keterangan")})
          </p>
        </>
      )}
    </button>
  );
};

export default FooterBuyCourseM;
