import React from "react";
import { Link } from "react-router-dom";

import { formatRupiah } from "../../utils/formatRupiah";
import { useTranslation } from "react-i18next";

const RenderRating = ({ rating }) => {
  const stars = [];

  for (let i = 0; i < 5; i++) {
    const starClassName = `fa text-warning ${
      rating >= i + 1
        ? "fa-star"
        : rating >= i + 0.5
        ? "fa-star-half-o"
        : "fa-star-o"
    }`;
    stars.push(<i key={i} className={starClassName}></i>);
  }

  return <div className="font-size-18">{stars}</div>;
};
function CardCourse({
  id,
  thumbnail,
  title,
  author,
  total_lesson,
  level,
  price,
  type,
  headline,
  desc,
  total_time,
  idx,
  discount,
  rating,
  review,
  joinStatus,
}) {
  const { t } = useTranslation();
  return (
    <div
      className={`col-lg-4 col-md-6 mb-3 ${
        idx % 3 === 0 ? "tooltip-right" : "tooltip-left"
      }`}
    >
      <div className="card m-0 product-box">
        <Link
          to={`/course${
            joinStatus === "ongoing" || joinStatus === "finish" ? "" : "/buy"
          }/${id}`}
          className="card-body product-content"
        >
          <div className="row">
            <div className="col-md-4 col-4">
              <img
                src={thumbnail}
                className="card-img-fit thumbnail-course w-100"
                alt="#"
              />
            </div>
            <div className="col-md-8 col-8 d-flex flex-column justify-content-between">
              <h5 className="card-title elipsis-2-line">{title}</h5>
              <div className="w-100">
                <div className="row">
                  <div className="col-8">
                    <p className="text-muted font-size-13">
                      <i className="ri-user-2-fill"></i> {author}
                    </p>
                  </div>
                  <div className="col-4">
                    <p className="text-muted font-size-13 text-right">
                      <i className="ri-book-fill"></i> {total_lesson}
                    </p>
                  </div>
                </div>
                <hr className="mb-0 mt-0" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-8">
                  <div className="d-flex align-items-center gap-1">
                    <RenderRating rating={rating} />
                    <span className="font-size-14 fw-bold text-black-50">
                      ({review})
                    </span>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    {discount ? (
                      <h5 className="font-size-14 fw-bold mb-0 text-danger text-decoration-line-through">
                        {formatRupiah(price)}
                      </h5>
                    ) : null}
                    <h5 className="font-size-16 fw-bold mb-0">
                      {type === "premium"
                        ? discount
                          ? formatRupiah(price - (discount / 100) * price)
                          : formatRupiah(price)
                        : "Free"}
                    </h5>
                  </div>
                </div>
                <div className="col-4 text-end">
                  <p className="text-muted mb-1">Level</p>
                  <span
                    className={`badge badge-${
                      level === "intermediate"
                        ? "success"
                        : level === "advance"
                        ? "danger"
                        : "warning"
                    }`}
                  >
                    {t(level?.toLowerCase())}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div className="tooltiptext tooltip-course">
        <div className="p-3">
          <div className="card border">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <h5 className="card-title mb-1 fw-bold">{title}</h5>
                </div>
              </div>
              <hr className="mt-1" />
              <div className="row font-monserrat">
                <div className="col-12">
                  <div className="align-self-center">
                    <p className="m-0">{headline}</p>
                    <div
                      className="mb-2 preview-desc position-relative overflow-hidden"
                      dangerouslySetInnerHTML={{ __html: desc }}
                    ></div>
                    <div className="row">
                      <div className="col-8">
                        <i className="ri-timer-line"></i>
                        <span className="ms-2">
                          {total_time} {t("jam")}
                        </span>
                        <br />
                        <i className="ri-play-circle-line"></i>
                        <span className="ms-2">
                          {total_lesson} {t("lessons")}
                        </span>
                        <br />
                        <i className="ri-bar-chart-box-fill"></i>
                        <span className="ms-2">{level}</span>
                      </div>
                      <div className="col-4 align-self-center">
                        <Link
                          to={`/course/buy/${id}`}
                          className="btn btn-primary btn-detail-tooltip"
                        >
                          {t("detail")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardCourse;
