import React from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function ModalNoSoal({ show, handleClose, dataSoal, handleClick }) {
  const { t } = useTranslation();
  const stripHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };
  const checkJWB = (data) => {
    if (data?.soal?.soal_jenis === "pg" && data?.pengerjaan_soal_pg !== null) {
      return true;
    } else if (
      data?.soal?.soal_jenis === "polling" &&
      data?.pengerjaan_soal_polling?.length > 0
    ) {
      return true;
    } else if (
      data?.soal?.soal_jenis === "essay" &&
      data?.pengerjaan_soal_essay !== null
    ) {
      return true;
    } else if (
      data?.soal?.soal_jenis === "true_false" &&
      data?.pengerjaan_soal_true_false !== null
    ) {
      return true;
    } else if (
      data?.soal?.soal_jenis === "menjodohkan" &&
      data?.pengerjaan_soal_menjodohkan?.length > 0
    ) {
      return true;
    } else if (
      data?.soal?.soal_jenis === "pernyataan" &&
      data?.pengerjaan_soal_pernyataan?.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Modal size="md" show={show} onHide={handleClose} id="modalNoSoal">
      <Modal.Header closeButton>
        <h5 className="modal-title">{t("daftar_soal")}</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="wrap-no-soal">
          {dataSoal?.map((item, idx) => {
            let soal = stripHtml(item?.soal?.soal_pertanyaan);
            return (
              <OverlayTrigger
                key={item.soal_id}
                placement="top"
                overlay={<Tooltip>{soal}</Tooltip>}
              >
                <button
                  onClick={() => handleClick(idx)}
                  className={`btn ${
                    checkJWB(item) ? "btn-primary" : "btn-secondary"
                  }`}
                >
                  {idx + 1}
                </button>
              </OverlayTrigger>
            );
          })}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalNoSoal;
