import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import {
  ActionFilterSoalUjian,
  ActionSoalUjian,
  BobotSoalUjian,
  Cerita,
  InfoUjian,
  ListSoal,
  ModalUjian,
  PreviewSoal,
  Wrapper,
} from "../../components";
import FilterSoal from "../../components/modal/FilterSoal";
import { DEFAULT_FILTER_SOAL } from "../../config/defaultFilterSoal";
import {
  addSoalCeritaUjian,
  addSoalUjian,
  delCeritaUjian,
  delSoalUjian,
  delUjian,
  exportSoalUjian,
  getDetailUjian,
  getListSoal,
  getSoalCeritaUjian,
  publishUjian,
  updHidePembahasanUjian,
  updPointSoal,
  updWaktuSoal,
} from "../../redux/actions/ujianAction";

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
function SoalUjian() {
  const { t } = useTranslation();
  const { id, idKelas } = useParams();
  const navigate = useNavigate();
  const [detail, setDetail] = useState({});
  const [dataSoal, setDataSoal] = useState([]);
  const [dataSoalCerita, setDataSoalCerita] = useState([]);
  const [showModalUjian, setShowModalUjian] = useState(false);
  const [loadingPembahasan, setLoadingPembahasan] = useState(false);
  const [previewSoal, setPreviewSoal] = useState(false);
  const [showBobotSoal, setShowBobotSoal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [filter, setFilter] = useState(DEFAULT_FILTER_SOAL);
  const [search, setSearch] = useState("");

  const getDetail = useCallback(async (id, idKelas) => {
    const response = await getDetailUjian(id, idKelas);
    if (response) {
      setDetail(response?.data?.data);
    }
    getListSoal(id).then((res) => setDataSoal(res?.data?.data));
    getSoalCeritaUjian(id).then((res) => setDataSoalCerita(res?.data?.data));
  }, []);

  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };

  const handleDeleteUjian = () => {
    Swal.fire({
      title: t("konfirmasi_hapus"),
      text: t("are_u_sure_delete", { params: t("ujian") }),
      icon: "question",
      confirmButtonText: "Yakin",
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: "Batal",
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendDeleteUjian();
    });
  };

  const sendDeleteUjian = () => {
    const params = {
      kelasdetail_id: detail?.kelas_mapel?.kelasmapel_kelasdetailid,
      kelasmapel_id: detail?.kelas_mapel?.kelasmapel_id,
    };
    delUjian(id, params)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Berhasil dihapus",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => navigate("/ujian"));
      })
      .catch(() => {
        showAlert(t("gagal_hapus", { data: t("ujian") }), false);
      });
  };

  const handleDeleteSoal = (code) => {
    Swal.fire({
      title: t("konfirmasi_hapus"),
      text: t("are_u_sure_delete", { params: t("soal") }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: "Batal",
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendDeleteSoal(code);
    });
  };

  const sendDeleteSoal = (code) => {
    delSoalUjian(code)
      .then(() => {
        getDetail(id, idKelas);
      })
      .catch(() => {
        showAlert(t("gagal_hapus", { data: t("soal") }), false);
      });
  };

  const handleCopySoal = (data) => {
    Swal.fire({
      title: t("konfirmasi"),
      text: t("apakah_anda_yakin_menyalin", { data: t("soal") }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendCopySoal(data);
    });
  };

  const sendCopySoal = (data) => {
    addSoalUjian(data)
      .then(() => {
        getDetail(id, idKelas);
      })
      .catch(() => {
        showAlert(t("gagal_menyalin", { data: t("soal") }), false);
      });
  };

  const handleDeleteCerita = (code) => {
    Swal.fire({
      title: t("konfirmasi_hapus"),
      text: t("are_u_sure_delete", { params: t("soal_cerita") }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendDeleteCerita(code);
    });
  };

  const sendDeleteCerita = (code) => {
    delCeritaUjian(code)
      .then(() => {
        getDetail(id, idKelas);
      })
      .catch(() => {
        showAlert(t("gagal_hapus", { data: t("soal_cerita") }), false);
      });
  };

  const handleHidePembahasan = () => {
    const params = {
      ujian_id: id,
      hide_pembahasan: detail?.hide_pembahasan ? 0 : 1,
    };
    setLoadingPembahasan(true);
    updHidePembahasanUjian(params)
      .then(() => {
        setLoadingPembahasan(false);
        getDetail(id, idKelas);
      })
      .catch((err) => {
        setLoadingPembahasan(false);
        showAlert(
          t("pembahasan_gagal", {
            status: detail?.hide_pembahasan ? t("sembunyikan") : t("tampilkan"),
          }),
          false
        );
      });
  };

  useEffect(() => {
    if (id && idKelas) {
      getDetail(id, idKelas);
    }
  }, [id, idKelas]);

  const handlePublishUjian = () => {
    Swal.fire({
      title: t("konfirmasi_publish"),
      text: t("msg_publish_ujian"),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendPublishUjian();
    });
  };

  const sendPublishUjian = () => {
    publishUjian({ ujian_id: id })
      .then(() => {
        getDetail(id, idKelas);
      })
      .catch(() => {
        showAlert(t("gagal_publish"), false);
      });
  };

  const handleCopyCerita = (data) => {
    Swal.fire({
      title: t("konfirmasi"),
      text: t("apakah_anda_yakin_menyalin", { data: t("soal_cerita") }),
      icon: "question",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#ff6247",
      cancelButtonColor: "#6fbd44",
      cancelButtonText: t("batal"),
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) sendCopySoalCerita(data);
    });
  };

  const sendCopySoalCerita = (data) => {
    addSoalCeritaUjian({ ...data, detail: [] })
      .then(() => {
        getDetail(id, idKelas);
      })
      .catch(() => {
        showAlert(t("gagal_menyalin", { data: t("soal_cerita") }), false);
      });
  };

  const handleExport = () => {
    const params = {
      ujian_id: id,
    };
    setLoadingExport(true);
    exportSoalUjian(params)
      .then(() => {
        setLoadingExport(false);
        showAlert(t("soal_berhasil_export"), true);
        getDetail(id, idKelas);
      })
      .catch((err) => {
        setLoadingExport(false);
        showAlert(t("terjadi_kesalahan"), false);
      });
  };

  const handleChangeFilter = (name) => {
    if (name === "all") {
      setFilter(filter.length === 7 ? [] : DEFAULT_FILTER_SOAL);
      return;
    }
    let cek = filter?.filter((item) => item === name);
    if (cek.length <= 0) {
      setFilter((pref) => [...pref, name]);
    } else {
      setFilter((pref) => [...pref.filter((item) => item !== name)]);
    }
  };

  const checkFilter = (code) => {
    let cek = filter.filter((item) => item === code);
    return cek.length > 0;
  };

  const handleCloseModal = (status) => {
    setShowModalUjian(false);
    if (status) getDetail(id, idKelas);
  };

  const handleChangePoin = (soalId, point) => {
    const params = {
      soal_id: soalId,
      soal_points: point,
    };
    updPointSoal(params)
      .then(() => {
        getDetail(id, idKelas);
      })
      .catch(() => {
        showAlert(t("terjadi_kesalahan"), false);
      });
  };

  const handleChangeWaktu = (soalId, waktu) => {
    const params = {
      soal_id: soalId,
      soal_waktu: waktu,
    };
    updWaktuSoal(params)
      .then(() => {
        getDetail(id, idKelas);
      })
      .catch(() => {
        showAlert(t("terjadi_kesalahan"), false);
      });
  };

  return (
    <Wrapper title={t("ujian")}>
      <div className="container">
        <div className="row row-eq-height">
          <div className="col-lg-12">
            <InfoUjian
              updatedAt={detail?.user_soal?.updatedAt}
              creatorAvatar={detail?.user_soal?.user_created?.user_avatar}
              creatorName={detail?.user_soal?.user_created?.user_nama}
              endDate={detail?.ujian_selesai}
              jmlSiswa={detail?.jumlah_siswa}
              jmlSoal={detail?.jumlah_soal}
              kolaborasi={detail?.kolaborasi}
              mapel={detail?.kelas_mapel?.mapel?.mapel_nama}
              points={detail?.points}
              status={detail?.ujian_status}
              waktuMengerjakan={detail?.ujian_waktu_mengerjakan}
              setPreviewSoal={setPreviewSoal}
              setShowBobotSoal={setShowBobotSoal}
              setShowModalUjian={setShowModalUjian}
              handlePublish={handlePublishUjian}
            />
            {detail?.ujian_status !== "publish" && (
              <ActionSoalUjian handleDelete={handleDeleteUjian} />
            )}
            <ActionFilterSoalUjian
              handleExport={handleExport}
              handleHide={handleHidePembahasan}
              hidePembahasan={detail?.hide_pembahasan}
              kelasmapelId={detail?.kelasmapel_id}
              loadingExport={loadingExport}
              loadingPembahasan={loadingPembahasan}
              search={search}
              setSearch={setSearch}
              setShowFilter={setShowFilter}
              status={detail?.ujian_status}
            />
            {checkFilter("cerita") &&
              dataSoalCerita
                ?.filter((item) => {
                  if (search.length <= 0) return true;
                  return item.cerita_deskripsi
                    .toLowerCase()
                    .includes(search.toLowerCase());
                })
                ?.map((item, index) => (
                  <Cerita
                    key={crypto.randomUUID()}
                    data={item}
                    index={index}
                    handleCopy={() => handleCopyCerita(item)}
                    delCerita={handleDeleteCerita}
                    dataSoal={dataSoal}
                    publishTugas={detail?.ujian_status === "publish"}
                    pathEdit={"/ujian/edit-soal-cerita"}
                    creatorName={item?.user_created?.user_nama}
                    pathEnding={`/${idKelas}`}
                  />
                ))}
            {dataSoal?.length <= 0 && dataSoalCerita.length <= 0 ? (
              <p className="text-center">
                {t("data_params_kosong", { params: t("soal") })}
              </p>
            ) : (
              dataSoal
                .filter((item) => filter.includes(item.soal_jenis))
                ?.filter((item) => {
                  if (search.length <= 0) return true;
                  return item.soal_pertanyaan
                    .toLowerCase()
                    .includes(search.toLowerCase());
                })
                ?.map((item, index) => (
                  <ListSoal
                    handleCopy={handleCopySoal}
                    handleDelete={handleDeleteSoal}
                    index={index}
                    item={item}
                    soalJenis={item?.soal_jenis}
                    status={detail?.ujian_status}
                    key={item.soal_id}
                    pathEdit="/ujian/edit-soal"
                    creatorName={item?.user_created?.user_nama}
                    handlePoin={handleChangePoin}
                    handleWaktu={handleChangeWaktu}
                    hideWaktu={detail?.ujian_jenis_waktu === "perujian"}
                    pathEnding={`/${idKelas}`}
                  />
                ))
            )}
          </div>
          {showModalUjian && (
            <ModalUjian
              show={showModalUjian}
              handleClose={handleCloseModal}
              tahunPelajaran={detail?.tahunpelajaran_id}
              semesterId={detail?.semester_id}
              editUjian={id}
            />
          )}
          {previewSoal && (
            <PreviewSoal
              dataSoal={dataSoal}
              show={previewSoal}
              handleClose={() => setPreviewSoal(false)}
              dataSoalCerita={dataSoalCerita}
            />
          )}
          {showBobotSoal && (
            <BobotSoalUjian
              show={showBobotSoal}
              handleClose={() => setShowBobotSoal(false)}
              ujianId={id}
            />
          )}
          {showFilter && (
            <FilterSoal
              show={showFilter}
              handleClose={() => setShowFilter(false)}
              params={filter}
              handleChange={handleChangeFilter}
            />
          )}
        </div>
      </div>
    </Wrapper>
  );
}

export default SoalUjian;
