import moment from "moment";
import React from "react";

moment.locale("id");

function ItemDetailNotif({
  title,
  group,
  avatar,
  name,
  email,
  created_at,
  message,
}) {
  return (
    <div className="email-app-details">
      <div className="iq-card">
        <div className="iq-card-body p-0">
          <div className="">
            <div className="iq-email-to-list p-3">
              <div className=" ">
                <ul>
                  <div className="row">
                    <div className="col-2 col-md-1 col-lg-1">
                      <li>
                        <button className="bg-transparent border-0 p-0">
                          <h4 className="m-0 font-size-20">
                            <i className="ri-arrow-left-line"></i>
                          </h4>
                        </button>
                      </li>
                    </div>
                    <div className="col-10 col-md-11 col-lg-11">
                      <h5 className="mt-0 mb-2 fw-semibold">{title}</h5>
                      {group.length > 0 ? (
                        <span className="badge iq-bg-primary px-2 py-1">
                          {group[0]?.group_notif_name}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </ul>
              </div>
            </div>

            <hr className="m-0" />
            <div className="iq-inbox-subject p-3">
              <div className="iq-inbox-subject-info">
                <div className="iq-subject-info gap-2 align-items-center">
                  <img
                    src={avatar}
                    className="img-fluid rounded-circle"
                    alt={name}
                  />
                  <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center flex-1">
                    <div className="iq-subject-status ms-0">
                      <h6 className="mb-0 fw-medium">
                        {name},<a href={`mailto:${email}`}>{email}</a>
                      </h6>
                      {/* <div className="dropdown">
                        <button
                          className="dropdown-toggle bg-transparent border-0 p-0"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          to Me
                        </button>
                        <div
                          className="dropdown-menu font-size-12"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <table className="iq-inbox-details">
                            <tbody>
                              <tr>
                                <td>from:</td>
                                <td>Medium Daily Digest</td>
                              </tr>
                              <tr>
                                <td>reply-to:</td>
                                <td>noreply@gmail.com</td>
                              </tr>
                              <tr>
                                <td>to:</td>
                                <td>iqonicdesigns@gmail.com</td>
                              </tr>
                              <tr>
                                <td>date:</td>
                                <td>13 Dec 2019, 08:30</td>
                              </tr>
                              <tr>
                                <td>subject:</td>
                                <td>The Golden Rule</td>
                              </tr>
                              <tr>
                                <td>security:</td>
                                <td>Standard encryption</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div> */}
                    </div>
                    <span>
                      {moment(created_at).format("MMM DD, YYYY, HH:mm")}
                    </span>
                  </div>
                </div>
                <div
                  className="iq-inbox-body mt-3"
                  dangerouslySetInnerHTML={{
                    __html: message,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemDetailNotif;
