import React from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const ActionFilterSoalUjian = ({
  handleHide,
  loadingPembahasan,
  status,
  loadingExport,
  search,
  setSearch,
  setShowFilter,
  kelasmapelId,
  hidePembahasan,
  handleExport,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="d-flex justify-content-between align-items-md-center flex-column flex-md-row mb-3 gap-8">
      <div className="font-size-16 d-flex gap-8">
        <button
          onClick={handleHide}
          className="btn btn-sm iq-bg-light bg-white iq-bg-primary-hover text-dark border w-fit h-fit"
          disabled={loadingPembahasan}
        >
          <i
            className={`${
              hidePembahasan ? "ri-eye-line" : "ri-eye-off-line"
            } m-0`}
          ></i>{" "}
          {loadingPembahasan
            ? "Loading..."
            : hidePembahasan
            ? "Tampilkan Pembahasan"
            : "Sembunyikan Pembahasan"}
        </button>
        {status !== "publish" && (
          <Dropdown align="end">
            <Dropdown.Toggle
              variant="success"
              className="btn btn-sm iq-bg-light bg-white iq-bg-primary-hover text-dark border"
              id="dropdown-basic"
            >
              <i className="ri-folder-3-line m-0"></i>{" "}
              {loadingExport ? "Loading..." : t("bank_soal")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  navigate(`/ujian/import-bank-soal/${kelasmapelId}/${id}`);
                }}
              >
                {t("import_soal")}
              </Dropdown.Item>
              <Dropdown.Item disabled={loadingExport} onClick={handleExport}>
                {t("export_soal")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      <div className="d-flex gap-8">
        <div className="d-flex flex-1 gap-8">
          <input
            type="search"
            className="form-control form-control-md bg-white "
            placeholder="Search"
            value={search}
            onChange={({ target: { value } }) => setSearch(value)}
          />
          <button
            onClick={() => setShowFilter(true)}
            className="btn bg-warning text-dark fw-bolder h-100 d-flex align-items-center border"
          >
            <i className="ri-equalizer-line m-0"></i>&nbsp;Filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActionFilterSoalUjian;
