import "moment/locale/id";

import moment from "moment";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import {
  delPekan,
  submitPekan,
  submitStatusPekan,
} from "../../redux/actions/absenAction";

moment.locale("id");

function ModalPekan({
  show,
  handleClose,
  getPekan,
  kelasdetail_id,
  semester_id,
  tahunpelajaran_id,
  pekan,
}) {
  const { t } = useTranslation();
  const [submit, setSubmit] = useState(false);
  const [newPekan, setNewPekan] = useState({
    kelasdetail_id: "",
    semester_id: "",
    tahunpelajaran_id: "",
    pekan_nama: "",
    pekan_mulai: "",
    pekan_akhir: "",
    pekan_status: "1",
  });
  const [edit, setEdit] = useState(false);

  const handleChange = ({ target: { value, name } }) => {
    setNewPekan((pref) => ({
      ...pref,
      [name]: value,
    }));
  };

  const showAlert = (type, title, text) => {
    Swal.fire({
      title: title,
      text: text,
      icon: type,
      imageWidth: 92,
      imageHeight: 92,
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };

  const handleSubmit = () => {
    setSubmit(true);
    submitPekan(edit, newPekan)
      .then(() => {
        setEdit(false);
        setSubmit(false);
        setNewPekan((pref) => ({
          ...pref,
          pekan_nama: "",
          pekan_mulai: "",
          pekan_akhir: "",
          pekan_status: "1",
        }));
        showAlert(
          "success",
          edit
            ? t("edit_data_berhasil", { data: t("pekan") })
            : t("tambah_data_berhasil", { data: t("pekan") }),
          t("berhasil_simpan", { params: t("pekan") })
        );
        getPekan();
      })
      .catch((err) => {
        setSubmit(false);
        showAlert(
          "error",
          edit
            ? t("edit_data_gagal", { data: t("pekan") })
            : t("tambah_data_gagal", { data: t("pekan") }),
          t("data_gagal_simpan", { data: t("pekan") })
        );
      });
  };
  const handleEditPekan = (data) => {
    setEdit(true);
    setNewPekan((pref) => ({
      ...pref,
      pekan_nama: data.pekan_nama,
      pekan_mulai: data.pekan_mulai?.substr(0, 10),
      pekan_akhir: data.pekan_akhir?.substr(0, 10),
      pekan_status: data.pekan_status,
      pekan_id: data.pekan_id,
    }));
  };

  const handleUpdateStatus = (pekan_id, pekan_status) => {
    setSubmit(false);
    submitStatusPekan({ pekan_id, pekan_status })
      .then(() => {
        getPekan();
      })
      .catch(() => {
        showAlert(
          "error",
          t("edit_status_gagal", { data: t("pekan") }),
          t("failed_save_data", { data: "status " + t("pekan") })
        );
      });
  };

  const handleDeletePekan = (pekan_nama, pekan_id) => {
    Swal.fire({
      icon: "question",
      title: t("are_u_sure_delete", { params: pekan_nama }),
      showCancelButton: true,
      cancelButtonText: t("batal"),
      cancelButtonColor: "#3f9b4b",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#dc3545",
      reverseButtons: true,
    }).then((res) => {
      if (res.isConfirmed) {
        delPekan(pekan_id)
          .then(() => getPekan())
          .catch(() => {
            showAlert(
              "error",
              t("hapus_params_gagal", { params: t("pekan") }),
              t("failed_delete_data", { data: t("pekan") })
            );
          });
      }
    });
  };

  useEffect(() => {
    setNewPekan({
      kelasdetail_id,
      semester_id,
      tahunpelajaran_id,
      pekan_akhir: "",
      pekan_mulai: "",
      pekan_nama: "",
      pekan_status: "1",
    });
    setEdit(false);
  }, [kelasdetail_id, semester_id, tahunpelajaran_id]);

  return (
    <div className="col-lg-12">
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          if (!submit) handleClose();
        }}
        id="modalpekan"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title">
            {t("tambah")} {t("pekan")}
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="alert text-white bg-warning" role="alert">
            <div className="iq-alert-text">
              <i className="ri-alert-line m-0"></i> <b>{t("peringatan")} :</b>{" "}
              <p className="m-0">{t("silahkan_diisi_pekan")}.</p>
            </div>
          </div>
          <hr />
          <div></div>
          <div>
            <div className="form-group row">
              <label
                htmlFor="semester"
                className="control-label col-sm-3 align-self-center mb-0"
              >
                {t("pekan")} :
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  id="topik"
                  placeholder="Pekan"
                  name="pekan_nama"
                  value={newPekan.pekan_nama}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="semester"
                className="control-label col-sm-3 align-self-center mb-0"
              >
                {t("tgl_mulai")} :
              </label>
              <div className="col-sm-9">
                <input
                  type="date"
                  className="form-control"
                  id="pekan_mulai"
                  name="pekan_mulai"
                  value={newPekan.pekan_mulai}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="semester"
                className="control-label col-sm-3 align-self-center mb-0"
              >
                {t("tgl_akhir")} :
              </label>
              <div className="col-sm-9">
                <input
                  type="date"
                  className="form-control"
                  id="pekan_akhir"
                  name="pekan_akhir"
                  value={newPekan.pekan_akhir}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
              disabled={submit}
            >
              {t("simpan")}
            </button>
          </div>
          <hr />
          <div className="table-responsive">
            <table id="datatable" className="table table-bordered">
              <thead className="table-primary text-white">
                <tr>
                  <th className="bg-transparent" width="5%">
                    No
                  </th>
                  <th className="bg-transparent" width="20%">
                    {t("pekan")}
                  </th>
                  <th className="bg-transparent">{t("rentang_tgl")}</th>
                  <th className="bg-transparent" width="20%">
                    Status
                  </th>
                  <th className="text-center bg-transparent" width="15%">
                    {t("aksi")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {pekan?.length <= 0 ? (
                  <tr>
                    <td colSpan={5} className="text-center">
                      {t("data_params_kosong", { params: t("pekan") })}
                    </td>
                  </tr>
                ) : (
                  pekan?.map((item, idx) => (
                    <tr key={item.pekan_id}>
                      <td>{idx + 1}</td>
                      <td>{item.pekan_nama}</td>
                      <td>{`${moment(item.pekan_mulai?.substr(0, 10)).format(
                        "DD MMMM YYYY"
                      )} - ${moment(item.pekan_akhir?.substr(0, 10)).format(
                        "DD MMMM YYYY"
                      )}`}</td>
                      <td>
                        <select
                          value={item.pekan_status}
                          onChange={({ target: { value } }) =>
                            handleUpdateStatus(item.pekan_id, value)
                          }
                          className="form-control form-select"
                        >
                          <option value="1">{t("aktif")}</option>
                          <option value="0">{t("tdk_aktif")}</option>
                        </select>
                      </td>
                      <td className="text-center">
                        <button className="btn btn-success btn-sm ms-1">
                          <i
                            className="ri-edit-2-line m-0"
                            onClick={() => handleEditPekan(item)}
                          ></i>
                        </button>
                        <button
                          className="btn btn-danger btn-sm ms-1"
                          onClick={() =>
                            handleDeletePekan(item.pekan_nama, item.pekan_id)
                          }
                        >
                          <i className="ri-delete-bin-line m-0"></i>
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalPekan;
