import React, { useCallback, useEffect, useState } from "react";

import { FilterCourse, ListCourseUser } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { getCourseUser } from "../../redux/actions/courseAction";
import { useTranslation } from "react-i18next";

const filterData = [
  {
    label: "urutkan",
    name: "sort",
    data: [
      { label: "proses_pembayaran", value: "payment" },
      { label: "kursus_saya", value: "my_courses" },
    ],
  },
  {
    label: "tipe",
    name: "type",
    data: [
      { label: "free", value: "free" },
      { label: "premium", value: "premium" },
    ],
  },
  {
    label: "tingkat",
    name: "level",
    data: [
      { label: "beginner", value: "beginner" },
      { label: "intermediate", value: "intermediate" },
      { label: "advance", value: "advance" },
    ],
  },
];

function KursusSaya() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector(({ user }) => user);
  const {
    courseUser,
    loadingCourseUser,
    nextCourseUser,
    coursePayment,
    loadingCoursePayment,
  } = useSelector(({ course }) => course);
  const [params, setParams] = useState({
    sort: "",
    category: "",
    level: [],
    type: [],
  });
  const [page, setPage] = useState(1);

  const getData = useCallback(
    () => {
      const data = {
        ...params,
        limit: 6,
        page: page,
        status: "my_course",
      };
      dispatch(getCourseUser(user?.user_id, data));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, params, user, page]
  );

  const getDataPayment = useCallback(
    () => {
      const data = {
        ...params,
        status: "payment",
      };
      dispatch(getCourseUser(user?.user_id, data));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, params, user]
  );

  useEffect(() => {
    if (user?.user_id) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params, user, page]);

  useEffect(() => {
    if (user?.user_id) {
      getDataPayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params, user]);

  const handleChangeFilter = ({ target: { name, value } }) => {
    if (name === "sort" || name === "category") {
      setParams((pref) => ({
        ...pref,
        [name]: value === pref[name] ? "" : value,
        page: 1,
      }));
    } else {
      let data = params[name];
      setParams((pref) => ({
        ...pref,
        [name]: !data.includes(value)
          ? [...pref[name], value]
          : data.filter((item) => item !== value),
        page: 1,
      }));
    }
  };

  const handleNext = () => {
    setPage((pref) => pref + 1);
  };

  const filterMobile = (data) => {
    setParams(data);
  };
  return (
    <>
      <FilterCourse
        handleChange={handleChangeFilter}
        params={params}
        filterData={filterData}
        filterMobile={filterMobile}
        submit={loadingCourseUser}
        title={t("kursus_saya")}
      />
      <section
        className={
          params.sort === "" || params.sort === "payment" ? "visible" : "d-none"
        }
      >
        <ListCourseUser
          data={coursePayment}
          loading={loadingCoursePayment}
          page={1}
          next={false}
          title={"proses_pembayaran"}
        />
      </section>
      <section
        className={
          params.sort === "" || params.sort === "my_courses"
            ? "visible"
            : "d-none"
        }
      >
        <ListCourseUser
          data={courseUser}
          handleNext={handleNext}
          loading={loadingCourseUser}
          next={nextCourseUser}
          page={params.page}
          title={"kursus_saya"}
        />
      </section>
    </>
  );
}

export default KursusSaya;
