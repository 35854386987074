import { useTranslation } from 'react-i18next';

import { AUDIO_EXT, PNG_IMG_EXT, SVG_IMG_EXT, VIDEO_EXT } from '../../config/fileType';

const TrueFalseImportSoal = ({data, index, checked, handleChange}) => {
  const {t} = useTranslation();
    return (
		<div className="iq-card"> 
			<div className="iq-card-header d-flex justify-content-between">
				<div className="iq-header-title d-flex align-items-center">
          <div className="checkbox d-inline-block me-3">
              <input
              className="rabsen"
              style={{ width: 25 }}
              type="checkbox"
              name="cerita"
              id={data.soal_id}
              checked={checked}
              onChange={({target:{checked}}) => handleChange(data.soal_id, checked)}
              />
          </div>
					<h4 className="card-title">{t('soal')} {index + 1}</h4>&nbsp;
            <span className="badge badge-pill badge-primary h-100 align-self-center">
              <i className="ri-checkbox-line"></i> {t('benar_salah')}
            </span>
				</div>
        
            <div className="d-flex border rouxnded">
              <i className="ri-flag-2-fill ms-1 text-dark font-size-18 "></i>
              <select
                  className="form-control form-control-sm bg-transparent border-0 font-size-14 fw-bold me-0"
                  value={data?.soal_points}
                  disabled
              >
                  <option className="bg-white fw-bold" value="0">0 {t('poin')}</option>
                  <option className="bg-white fw-bold" value="1">1 {t('poin')}</option>
                  <option className="bg-white fw-bold" value="2">2 {t('poin')}</option>
                  <option className="bg-white fw-bold" value="3">3 {t('poin')}</option>
                  <option className="bg-white fw-bold" value="4">4 {t('poin')}</option>
                  <option className="bg-white fw-bold" value="5">5 {t('poin')}</option>
                  <option className="bg-white fw-bold" value="6">6 {t('poin')}</option>
                  <option className="bg-white fw-bold" value="7">7 {t('poin')}</option>
                  <option className="bg-white fw-bold" value="8">8 {t('poin')}</option>
                  <option className="bg-white fw-bold" value="9">9 {t('poin')}</option>
                  <option className="bg-white fw-bold" value="10">10 {t('poin')}</option>
              </select>
            </div>
			</div>
			<div className="iq-card-body">
				<div className="d-flex">
          {data?.soal_file && <div className="me-3">
            {SVG_IMG_EXT?.includes(data.soal_file_type) || PNG_IMG_EXT?.includes(data.soal_file_type) ? (
                <img
                  src={data.soal_file}
                  className="img-fluid rounded iq-bg-dark" width="120" height="120"
                  alt="foto soal"
                />
            ) : VIDEO_EXT?.includes(data.soal_file_type) ? (
                <video
                src={data.soal_file}
                controls
                className="w-100 h-auto"
                ></video>
            ) : AUDIO_EXT?.includes(data.soal_file_type) ? (
                <audio src={data.soal_file} controls></audio>
            ) : (
                <p className="m-0 text-muted text-italic">
                {t('format_tdk_diketahui')}
                </p>
            )}
          </div>}
          <div className="w-100" dangerouslySetInnerHTML={{__html: data?.soal_pertanyaan}} />
				</div>
          <hr />
          <p className="mb-1">{t('jawab')} :</p>
          <div className="row font-size-16">
            <div className="col-lg-6 col-12 d-flex">
              <span className="ri-checkbox-blank-circle-fill " style={{ color: data?.soal_true_false?.soal_jawaban ?"#28a745" :"red" }}></span>&nbsp;
              <div className="w-100">
                  {
                      data?.soal_true_false?.soal_file_false && <img
                      src={data?.soal_true_false?.soal_file_false}
                      className="img-fluid rounded iq-bg-dark mb-2" width="120" height="120"
                      alt="foto jawaban"
                  />}
                  <p className="mb-1"> {data?.soal_true_false?.soal_pilihan_true ?? '-'}</p>
              </div>
            </div>
            <div className="col-lg-6 col-12 d-flex">
              <span className="ri-checkbox-blank-circle-fill " style={{ color: !data?.soal_true_false?.soal_jawaban ?"#28a745":"red" }}></span>&nbsp;
              <div className="w-100">
                  {
                      data?.soal_true_false?.soal_file_true && <img
                      src={data?.soal_true_false?.soal_file_true}
                      className="img-fluid rounded iq-bg-dark mb-2" width="120" height="120"
                      alt="foto jawaban"
                  />}
                  <p className="mb-1"> {data?.soal_true_false?.soal_pilihan_false ?? '-'}</p>
              </div>
            </div>
          </div>
			</div>
		</div>
    )
}

export default TrueFalseImportSoal;