import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { InputPoint, InputWaktuSoal } from "../../input";
import {
  AUDIO_EXT,
  PNG_IMG_EXT,
  SVG_IMG_EXT,
  VIDEO_EXT,
} from "../../../config/fileType";

const PernyataanCard = ({
  data,
  index,
  delSoal,
  handleCopySoal,
  penilaian,
  pembahasan,
  pathEdit,
  publishTugas,
  creatorName,
  handleChangePoint,
  handleChangeWaktu,
  disabledPoint,
  disabledWaktu,
  hideWaktu,
  pathEnding,
}) => {
  const { t } = useTranslation();
  return (
    <div className="iq-card">
      <div className="iq-card-header d-flex justify-content-between">
        <div className="iq-header-title d-flex">
          <h4 className="card-title">
            {t("soal")} {data?.soal_no || index + 1}
          </h4>
          &nbsp;
          {!penilaian && !pembahasan && (
            <span className="badge badge-pill badge-danger h-100 align-self-center">
              <i className="ri-checkbox-line"></i> {t("pernyataan")}
            </span>
          )}
        </div>
        {!penilaian && !pembahasan ? (
          publishTugas ? null : (
            <div className="btn-group" role="group">
              <Link
                to={`${pathEdit || "/edit-soal"}/${data?.soal_id}${pathEnding}`}
                className="btn btn-sm iq-bg-light iq-bg-primary-hover text-dark border d-flex"
              >
                <i className="ri-edit-line m-0"></i>
                <span className="d-none d-md-block">{t("ubah")}</span>
              </Link>
              &nbsp;
              <button
                onClick={handleCopySoal}
                className="btn btn-sm iq-bg-light iq-bg-success-hover text-dark border"
              >
                <i className="ri-file-copy-line m-0"></i>
              </button>
              &nbsp;
              <button
                onClick={() => delSoal(data?.soal_id)}
                className="btn btn-sm iq-bg-light iq-bg-danger-hover text-dark border"
              >
                <i className="ri-delete-bin-line m-0"></i>
              </button>
            </div>
          )
        ) : (
          <InputPoint
            value={data?.pengerjaan_soal?.pengerjaan_points}
            disabled
            bordered
          />
        )}
      </div>
      <div className="iq-card-body">
        <div className="d-flex">
          {data?.soal_file && (
            <div className="me-3">
              {SVG_IMG_EXT?.includes(data.soal_file_type) ||
              PNG_IMG_EXT?.includes(data.soal_file_type) ? (
                <img
                  src={data.soal_file}
                  className="img-fluid rounded iq-bg-dark"
                  width="120"
                  height="120"
                  alt="foto soal"
                />
              ) : VIDEO_EXT?.includes(data.soal_file_type) ? (
                <video
                  src={data.soal_file}
                  controls
                  className="w-100 h-auto"
                ></video>
              ) : AUDIO_EXT?.includes(data.soal_file_type) ? (
                <audio src={data.soal_file} controls></audio>
              ) : (
                <p className="m-0 text-muted text-italic">
                  {t("format_tdk_diketahui")}
                </p>
              )}
            </div>
          )}
          <div
            className="w-100"
            dangerouslySetInnerHTML={{ __html: data?.soal_pertanyaan }}
          />
        </div>
        {penilaian || pembahasan ? (
          <>
            <hr />
            <p className="mb-1">{t("jawaban")} :</p>
            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive">
                  <table
                    id="datatable"
                    className="table table-bordered table-hover"
                  >
                    <thead className="table-primary text-white">
                      <tr>
                        <th width="75%" className="bg-transparent">
                          {data?.soal_pernyataan?.soal_pernyataan_nama}
                        </th>
                        <th width="15%" className="bg-transparent">
                          {data?.soal_pernyataan?.soal_pernyataan_true}
                        </th>
                        <th width="15%" className="bg-transparent">
                          {data?.soal_pernyataan?.soal_pernyataan_false}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.soal_pernyataan?.pilihan?.map((item) => {
                        let cek =
                          data?.pengerjaan_soal?.pengerjaan_soal_pernyataan?.filter(
                            (jwban) =>
                              jwban.soal_pernyataan_pilihan_id ===
                              item.soal_pernyataan_pilihan_id
                          );
                        return (
                          <tr key={item.soal_pernyataan_pilihan_id}>
                            <td>{item.soal_pernyataan_pertanyaan}</td>
                            <td className="text-center">
                              <span
                                className="ri-checkbox-blank-circle-fill "
                                style={{
                                  color:
                                    cek?.length > 0 && cek[0].pengerjaan_jawaban
                                      ? "#28a745"
                                      : "red",
                                }}
                              ></span>
                            </td>
                            <td className="text-center">
                              <span
                                className="ri-checkbox-blank-circle-fill "
                                style={{
                                  color:
                                    cek?.length > 0 &&
                                    !cek[0].pengerjaan_jawaban
                                      ? "#28a745"
                                      : "red",
                                }}
                              ></span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <hr />
            <p className="mb-1">{t("pembahasan")} :</p>
            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive">
                  <table
                    id="datatable"
                    className="table table-bordered table-hover"
                  >
                    <thead className="table-primary text-white">
                      <tr>
                        <th width="75%" className="bg-transparent">
                          {data?.soal_pernyataan?.soal_pernyataan_nama}
                        </th>
                        <th width="15%" className="bg-transparent">
                          {t("jawaban")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.soal_pernyataan?.pilihan?.map((item) => {
                        return (
                          <tr key={item.soal_pernyataan_pilihan_id}>
                            <td>{item.soal_pernyataan_pertanyaan}</td>
                            <td className="text-center">
                              {item.soal_pernyataan_jawaban
                                ? data.soal_pernyataan.soal_pernyataan_true
                                : data.soal_pernyataan.soal_pernyataan_false}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <hr />
            <p className="mb-1">{t("jawaban")} :</p>
            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive">
                  <table
                    id="datatable"
                    className="table table-bordered table-hover"
                  >
                    <thead className="table-primary text-white">
                      <tr>
                        <th width="75%" className="bg-transparent">
                          {data?.soal_pernyataan?.soal_pernyataan_nama}
                        </th>
                        <th width="15%" className="bg-transparent">
                          {data?.soal_pernyataan?.soal_pernyataan_true}
                        </th>
                        <th width="15%" className="bg-transparent">
                          {data?.soal_pernyataan?.soal_pernyataan_false}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.soal_pernyataan?.pilihan?.map((item, idx) => {
                        return (
                          <tr key={item.soal_pernyataan_pilihan_id}>
                            <td>{item.soal_pernyataan_pertanyaan}</td>
                            <td className="text-center">
                              <span
                                className="ri-checkbox-blank-circle-fill "
                                style={{
                                  color: item.soal_pernyataan_jawaban
                                    ? "#28a745"
                                    : "red",
                                }}
                              ></span>
                            </td>
                            <td className="text-center">
                              <span
                                className="ri-checkbox-blank-circle-fill "
                                style={{
                                  color: !item.soal_pernyataan_jawaban
                                    ? "#28a745"
                                    : "red",
                                }}
                              ></span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
        {!penilaian && !pembahasan && (
          <>
            <hr />
            <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between">
              <div className="d-flex gap-2 mb-1">
                <InputPoint
                  value={data?.soal_points}
                  disabled={disabledPoint}
                  handleChange={handleChangePoint}
                  bordered
                />
                {!hideWaktu && (
                  <InputWaktuSoal
                    value={data?.soal_waktu}
                    disabled={disabledWaktu}
                    handleChange={handleChangeWaktu}
                    bordered
                  />
                )}
              </div>
              {creatorName && (
                <p className="fst-italic mb-1">
                  {t("dibuat")}: {creatorName}
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PernyataanCard;
