import React, { Fragment, useRef, useState } from "react";
import { ItemPesanDiskusi } from "../item";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { delDiskusiThread } from "../../redux/actions/diskusiAction";
import { FormDiskusi } from "../input";

const RenderDiskusi = ({
  code,
  avatar,
  nama,
  imgPesan,
  text,
  updatedAt,
  subThread,
  diskusiId,
  getThread,
  userId,
  userOrtu,
  handleEdit,
  userGuru,
}) => {
  const formRef = useRef(null);
  const { t } = useTranslation();
  const [replyChat, setReplyChat] = useState(null);
  const [editPesan, setEditPesan] = useState(null);
  const handleDelete = (thread_id) => {
    Swal.fire({
      icon: "question",
      title: t("are_u_sure_delete", { params: t("pesan_ini") }),
      showCancelButton: true,
      cancelButtonText: t("batal"),
      cancelButtonColor: "#3f9b4b",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#dc3545",
      reverseButtons: true,
    }).then((res) => {
      if (res.isConfirmed) sendDelete(thread_id);
    });
  };

  const sendDelete = (thread_id) => {
    delDiskusiThread(thread_id).then(() => getThread());
  };
  return (
    <Fragment>
      <ItemPesanDiskusi
        code={code}
        img={avatar}
        nama={nama}
        imgPesan={imgPesan}
        pesan={text}
        date={updatedAt}
        parentChatId={code}
        userId={userId}
        userOrtu={userOrtu}
        userGuru={userGuru}
        handleBalas={(parent_id) => setReplyChat(parent_id)}
        handleDelete={(thread_id) => handleDelete(thread_id)}
        handleEdit={handleEdit}
      />
      {subThread?.map((pesan) => (
        <ItemPesanDiskusi
          key={pesan?.diskusi_thread_id}
          code={pesan?.diskusi_thread_id}
          img={pesan?.detail?.user_avatar}
          nama={pesan?.detail?.user_nama}
          imgPesan={pesan?.thread_image}
          pesan={pesan?.thread_text}
          date={pesan?.updatedAt}
          parentChatId={code}
          subThread={true}
          userId={pesan?.detail?.user_id}
          userOrtu={userOrtu}
          userGuru={userGuru}
          handleBalas={(code) => {
            setReplyChat(code);
            setEditPesan(null);
          }}
          handleDelete={(thread_id) => handleDelete(thread_id)}
          handleEdit={() => {
            setEditPesan(pesan);
          }}
        />
      ))}
      {(replyChat || editPesan) && !userOrtu ? (
        <FormDiskusi
          key={code}
          ref={formRef}
          diskusiId={diskusiId}
          parentChatId={replyChat || editPesan?.parent_id}
          getThread={getThread}
          editDiskusi={editPesan?.diskusi_thread_id}
          img={editPesan?.thread_image}
          pesan={editPesan?.thread_text}
          handleClearEdit={() => setEditPesan(null)}
        />
      ) : null}
    </Fragment>
  );
};

export default RenderDiskusi;
