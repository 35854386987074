import React from "react";
import { InputMultiple } from "../input";

const COLORS = [
  {
    bg: "bg-success",
    border: "border-success",
    shadow: "1f6e74",
  },
  {
    bg: "bg-primary",
    border: "border-primary",
    shadow: "1e6b28",
  },
  {
    bg: "bg-warning",
    border: "border-warning",
    shadow: "c9870f",
  },
  {
    bg: "bg-danger",
    border: "border-danger",
    shadow: "b31a00",
  },
  {
    bg: "bg-info",
    border: "border-info",
    shadow: "7d44a0",
  },
];
function ItemSoalMultiple({
  handleChange,
  pooling,
  handleChangeFile,
  handleDeleteFile,
  loadingUpload,
  preview,
  kerjakan,
  dipilih,
  handlePilih,
}) {
  return (
    <div
      className={`row ${
        !preview && !kerjakan ? "px-3 pb-3" : "py-4"
      } justify-content-center`}
    >
      {pooling?.map((item, i) => (
        <InputMultiple
          key={i}
          bg={COLORS[i].bg}
          border={COLORS[i].border}
          shadow={COLORS[i].shadow}
          handleChange={(e) =>
            kerjakan ? handlePilih(item.soal_polling_id) : handleChange(e)
          }
          name={i}
          soal_jawaban={item.soal_jawaban}
          soal_pilihan={item.soal_pilihan}
          handleChangeFile={handleChangeFile}
          handleDeleteFile={handleDeleteFile}
          soalFile={item.soal_file}
          loadingUpload={loadingUpload}
          preview={preview}
          kerjakan={kerjakan}
          dipilih={
            dipilih?.filter((data) => data === item.soal_polling_id)?.length > 0
          }
        />
      ))}
    </div>
  );
}

export default ItemSoalMultiple;
