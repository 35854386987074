import { GET_BANK_SOAL, LOGOUT } from '../constant';

const initState = {
  dataSoal: [],
};

const bankSoalReducer = (state = initState, action) => {
  switch (action?.type) {
    case GET_BANK_SOAL + "_PENDING":
      return state;
    case GET_BANK_SOAL + "_FULFILLED":
      return {
        dataSoal: action?.payload,
      };
    case GET_BANK_SOAL + "_REJECTED":
      return state;
    case LOGOUT:
      return {
        dataSoal: [],
      };
    default: {
      return state;
    }
  }
};

export default bankSoalReducer;
