import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const CeritaImportSoal = ({data, index, dataSoal, checked, handleChange}) => {
    const {t} = useTranslation();
    const stripHtml = (html) => {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    };
    return (
        <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title d-flex align-items-center">
                    <div className="checkbox d-inline-block me-3">
                        <input
                        className="rabsen"
                        style={{ width: 25 }}
                        type="checkbox"
                        name="cerita"
                        id={data.cerita_id}
                        checked={checked}
                        onChange={({target:{checked}}) => handleChange(data.cerita_id, checked)}
                        />
                    </div>
                    <h4 className="card-title">{t('cerita')} {index + 1}</h4>&nbsp;
                    <span className="badge badge-pill badge-primary h-100 align-self-center">
                        <i className="ri-checkbox-line"></i> {t('cerita')}
                    </span>
                </div>
            </div>
            <div className="iq-card-body">
                <div className="d-flex">
                    {data?.cerita_file && 
                    <div className="me-3">
                        <img
                        src={data.cerita_file}
                        className="img-fluid rounded iq-bg-dark" width="120" height="120"
                        alt="gambar"
                        />
                    </div>}
                    <div className="w-100" dangerouslySetInnerHTML={{__html: data?.cerita_deskripsi}} />
                </div>
                <hr />
                <div>
                    <p className='m-0'>{t('soal_terkait')}:</p>
                    <div className="d-flex">
                        {
                            data?.detail?.sort((a, b)=> parseFloat(a.soal_id) - parseFloat(b.soal_id))?.map((item) => {
                                let idxSoal = dataSoal?.findIndex(data => data.soal_id === item.soal_id);
                                return (
                                <OverlayTrigger
                                    key={item.soal_id}
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip-2">{stripHtml(item.soal.soal_pertanyaan).substring(0, 100)}</Tooltip>}
                                >
                                    <div className="btn btn-primary me-1">{t('soal')} {idxSoal + 1}</div>
                                </OverlayTrigger>
                            )})
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CeritaImportSoal;