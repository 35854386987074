import React, { useEffect, useRef } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { ItemCourseVideo } from "../item";
import DescBuyCourse from "./DescBuyCourse";
import PlaylistBuyCourse from "./PlaylistBuyCourse";
import ReviewBuyCourse from "./ReviewBuyCourse";

const PreviewBuyCourse = ({
  heading,
  title,
  desc,
  author_img,
  total_lesson,
  author_name,
  total_rating,
  total_subs,
  total_time,
  topik,
  video,
  video_type,
  marked,
  handleMarked,
  sertifikat,
  loadingTopik,
  nextTopik,
  handleNextTopik,
  handlePlay,
  handleMoreVideo,
}) => {
  const courseDescRef = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    let windowWidth = window.innerWidth;
    const handleScroll = () => {
      const iframeEl = document.querySelector("#iframe-course");
      if (windowWidth < 992)
        return iframeEl.classList.remove("sticky-iframe-course");
      const courseDesc = courseDescRef.current;
      if (courseDesc) {
        const { top } = courseDesc.getBoundingClientRect();
        if (top > 30) return iframeEl.classList.remove("sticky-iframe-course");
        if (iframeEl && !iframeEl.classList.contains("sticky-iframe-course")) {
          iframeEl.classList.add("sticky-iframe-course");
        }
      }
    };
    const handleResize = () => {
      windowWidth = window.innerWidth;
    };
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="iq-card iq-card-block iq-card-stretch">
      <div className="iq-card-body">
        <div className="d-lg-none">
          <h3 className="mb-2 fw-bold">{heading}</h3>
          <p className="text-dark">{title}</p>
          <div className="d-flex align-items-center gap-3 mb-3">
            <button
              onClick={handleMarked}
              type="button"
              className={`btn btn-sm ${
                marked ? "btn-primary" : "btn-sm bg-grey"
              } rounded-pill`}
            >
              <i
                className={`ri-bookmark-fill ${
                  marked ? "text-white" : "text-secondary"
                }`}
              ></i>
              {t("tandai")}
            </button>
            <button type="button" className="btn btn-sm bg-grey rounded-pill">
              <i className="ri-share-fill text-secondary"></i>Bagaikan
            </button>
          </div>
        </div>
        <div className="w-100 ratio ratio-16x9 overflow-hidden">
          <ItemCourseVideo type={video_type} video={video} />
        </div>
        <div id="info-buy-course" ref={courseDescRef}>
          <Tabs defaultActiveKey="desc" className="mt-3 mb-4">
            <Tab
              eventKey="lesson"
              title={t("lessons")}
              tabClassName="lesson-tab"
            >
              <PlaylistBuyCourse
                topik={topik}
                handleNext={handleNextTopik}
                loadingTopik={loadingTopik}
                nextTopik={nextTopik}
                handlePlay={handlePlay}
                handleMoreVideo={handleMoreVideo}
              />
            </Tab>
            <Tab eventKey="desc" title={t("desc")}>
              <DescBuyCourse
                desc={desc}
                img={author_img}
                lesson={total_lesson}
                name={author_name}
                rating={total_rating}
                subscriber={total_subs}
                time={total_time}
                sertifikat={sertifikat}
              />
            </Tab>
            <Tab eventKey="review" title={t("ulasan")}>
              <ReviewBuyCourse />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default PreviewBuyCourse;
