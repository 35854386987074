import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Cerita = ({
  data,
  index,
  delCerita,
  penilaian,
  pathEdit,
  handleCopy,
  dataSoal,
  publishTugas,
  creatorName,
}) => {
  const { t } = useTranslation();
  const stripHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };
  return (
    <div className="iq-card">
      <div className="iq-card-header d-flex justify-content-between">
        <div className="iq-header-title d-flex">
          <h4 className="card-title">
            {t("cerita")} {index + 1}
          </h4>
          &nbsp;
          {!penilaian && (
            <span className="badge badge-pill badge-primary h-100 align-self-center">
              <i className="ri-checkbox-line"></i> {t("cerita")}
            </span>
          )}
        </div>
        {!penilaian && !publishTugas && (
          <div className="btn-group" role="group">
            <Link
              to={`${pathEdit || "/edit-soal-cerita"}/${data?.cerita_id}`}
              className="btn btn-sm iq-bg-light iq-bg-primary-hover text-dark border d-flex gap-2"
            >
              <i className="ri-edit-line m-0"></i>
              <span className="d-none d-md-block">{t("ubah")}</span>
            </Link>
            &nbsp;
            <button
              onClick={handleCopy}
              href="kelas-detail-guru.html"
              className="btn btn-sm iq-bg-light iq-bg-success-hover text-dark border"
            >
              <i className="ri-file-copy-line m-0"></i>
            </button>
            &nbsp;
            <button
              onClick={() => delCerita(data?.cerita_id)}
              className="btn btn-sm iq-bg-light iq-bg-danger-hover text-dark border"
            >
              <i className="ri-delete-bin-line m-0"></i>
            </button>
          </div>
        )}
      </div>
      <div className="iq-card-body">
        <div className="d-flex">
          {data?.cerita_file && (
            <div className="me-3">
              <img
                src={data.cerita_file}
                className="img-fluid rounded iq-bg-dark"
                width="120"
                height="120"
                alt="gambar"
              />
            </div>
          )}
          <div
            className="w-100"
            dangerouslySetInnerHTML={{ __html: data?.cerita_deskripsi }}
          />
        </div>
        <hr />
        <div>
          <p className="m-0">{t("soal_terkait")}:</p>
          <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between">
            <div className="d-flex flex-1 flex-row justify-content-start mb-1">
              {data?.detail
                ?.sort((a, b) => parseFloat(a.soal_id) - parseFloat(b.soal_id))
                ?.map((item) => {
                  let idxSoal = dataSoal?.findIndex(
                    (data) => data.soal_id === item.soal_id
                  );
                  return (
                    <OverlayTrigger
                      key={item.soal_id}
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          {stripHtml(item.soal.soal_pertanyaan).substring(
                            0,
                            100
                          )}
                        </Tooltip>
                      }
                    >
                      <div className="btn btn-primary me-1">
                        {t("soal")} {parseInt(idxSoal) + 1 || ""}
                      </div>
                    </OverlayTrigger>
                  );
                })}
            </div>
            {creatorName && (
              <p className="fst-italic mb-1">
                {t("dibuat")}: {creatorName}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cerita;
