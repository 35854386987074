import "moment/locale/id";

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

import { PageLoader } from "../../../assets/images";
import {
  getSiswaAbsensi,
  submitAbsensi,
} from "../../../redux/actions/absenAction";
import { checkIsGuru } from "../../../utils/roleUser";

moment.locale("id");
function Absensi({ detailKelas }) {
  const { t } = useTranslation();
  const location = useLocation();
  const { topik } = useSelector(({ topik }) => topik);
  const { user } = useSelector(({ user }) => user);
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [params, setParams] = useState({
    topik_id: "",
    kehadiran_tanggal: "",
    semester_id: "",
  });
  const [siswa, setSiswa] = useState([]);
  useEffect(() => {
    const date = new Date();
    const tgl = moment(date).format("YYYY-MM-DD");
    setParams((pref) => ({
      ...pref,
      kehadiran_tanggal: tgl,
    }));
  }, []);

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    let smt = params.get("smt");
    setParams((pref) => ({
      ...pref,
      semester_id: smt,
    }));
  }, [location.search]);

  const handleChange = ({ target: { value, name } }) => {
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };
  const handleChangeSiswa = (idx, name, value) => {
    let allSiswa = siswa;
    allSiswa[idx][name] = value;
    setSiswa([...allSiswa]);
  };
  function checkProperties(obj) {
    for (var key in obj) {
      if (key !== "semester_id" && (obj[key] === null || obj[key] === ""))
        return false;
    }
    return true;
  }
  const checkDataSiswa = () => {
    for (let i = 0; i < siswa.length; i++) {
      if (siswa[i].kehadiran_nilai === "") return false;
    }
    return true;
  };
  const showAlert = (type, title, text) => {
    Swal.fire({
      title: title,
      text: text,
      icon: type,
      imageWidth: 92,
      imageHeight: 92,
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };
  useEffect(() => {
    function getData() {
      getSiswaAbsensi("guru", {
        ...params,
        kelasdetail_id: detailKelas?.detailKelas?.kelasdetail_id,
      }).then((res) => {
        let allSiswa = res?.map((item) => ({
          userkelas_userid: item.userkelas_userid,
          kehadiran_nilai: item?.kehadiran_topik_siswa?.kehadiran_nilai || "H",
          kehadiran_catatan:
            item?.kehadiran_topik_siswa?.kehadiran_catatan || "",
          user: item?.user,
        }));
        setSiswa(allSiswa);
        setLoading(false);
      });
    }
    const check = checkProperties(params);
    if (check) {
      setLoading(true);
      getData();
    }
  }, [params, detailKelas?.detailKelas?.kelasdetail_id]);
  const handleSubmitAbsensi = () => {
    setSubmit(true);
    if (!checkDataSiswa()) {
      showAlert(
        "error",
        `${t("absensi")} ${t("gagal")}`,
        t("isi_semua_absensi")
      );
      setSubmit(false);
      return false;
    }
    let data = {
      ...params,
      kelasdetail_id: detailKelas?.detailKelas?.kelasdetail_id,
      siswa,
    };
    submitAbsensi("guru", data)
      .then((res) => {
        setSubmit(false);
        showAlert(
          "success",
          `${t("absensi")} ${t("berhasil")}`,
          t("berhasil_simpan", { params: t("absensi") })
        );
      })
      .catch((err) => {
        setSubmit(false);
        showAlert(
          "error",
          `${t("absensi")} ${t("gagal")}`,
          t("failed_save_data", { data: t("absensi") })
        );
      });
  };

  return (
    <div className="tab-pane fade" id="absensi" role="tabpanel">
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title d-flex align-items-center justify-content-between w-100">
            <h4 className="card-title">{t("absensi")}</h4>
            <Link
              to="/kelas"
              className="btn iq-bg-light iq-bg-primary-hover text-dark border"
            >
              <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
            </Link>
          </div>
        </div>
        {checkIsGuru(user?.user_roleid) && (
          <div className="iq-card-body">
            <div className="row">
              <div className="col-lg-12 pe-3">
                <div className="row mb-3">
                  <label
                    htmlFor="semester"
                    className="control-label col-sm-2 align-self-center mb-0"
                  >
                    {t("topik")} :
                  </label>
                  <div className="col-sm-10">
                    <select
                      className="form-control form-select"
                      onChange={handleChange}
                      name="topik_id"
                      value={params.topik_id}
                    >
                      <option disabled value="">
                        --{t("pilih_params", { params: t("topik") })}--
                      </option>
                      {topik?.map((item) => (
                        <option key={item.topik_id} value={item.topik_id}>
                          {item.topic}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row ">
                  <label
                    htmlFor="semester"
                    className="control-label col-sm-2 align-self-center mb-0"
                  >
                    {t("tanggal")} :
                  </label>
                  <div className="col-sm-10 ">
                    <input
                      type="date"
                      className="form-control"
                      id="kehadiran_tanggal"
                      value={params.kehadiran_tanggal}
                      name="kehadiran_tanggal"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <hr className="m-0" />
        <div className="iq-card-body">
          <div className="table-responsive">
            <table id="datatable" className="table table-hover table-bordered">
              <thead>
                <tr>
                  <th
                    scope="col"
                    width="5%"
                    className="text-center align-middle"
                  >
                    No
                  </th>
                  <th scope="col" width="30%" className="align-middle">
                    {t("nama_siswa")}
                  </th>
                  <th
                    scope="col"
                    width="5%"
                    className="text-center align-middle"
                    style={{ background: "#1bbc9b", color: "white" }}
                  >
                    {t("hadir")}
                  </th>
                  <th
                    scope="col"
                    width="5%"
                    className="text-center align-middle"
                    style={{ background: "#578ebe", color: "white" }}
                  >
                    {t("izin")}
                  </th>
                  <th
                    scope="col"
                    width="5%"
                    className="text-center align-middle"
                    style={{ background: "#e26a6a", color: "white" }}
                  >
                    Alpa
                  </th>
                  <th
                    scope="col"
                    width="5%"
                    className="text-center align-middle"
                    style={{ background: "#f4d03f", color: "white" }}
                  >
                    {t("sakit")}
                  </th>
                  <th scope="col" width="40%" className="align-middle">
                    {t("catatan")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="7" className="text-center">
                      <img
                        src={PageLoader}
                        alt="loader"
                        style={{ height: "34px" }}
                        className="mx-auto"
                      />
                    </td>
                  </tr>
                ) : siswa.length > 0 ? (
                  siswa?.map((item, i) => (
                    <tr key={item.userkelas_userid}>
                      <td className="text-center align-middle">{i + 1}</td>
                      <td className="align-middle">
                        <b>{item?.user?.user_nama}</b>
                      </td>
                      <td className="text-center align-middle">
                        <input
                          className="rabsen"
                          type="radio"
                          name={`absensi-${i}`}
                          id={`radio-${i}`}
                          checked={item?.kehadiran_nilai === "H"}
                          onChange={() =>
                            handleChangeSiswa(i, "kehadiran_nilai", "H")
                          }
                        />
                      </td>
                      <td className="text-center align-middle">
                        <input
                          className="rabsen"
                          type="radio"
                          name={`absensi-${i}`}
                          id={`radio-${i}`}
                          checked={item?.kehadiran_nilai === "I"}
                          onChange={() =>
                            handleChangeSiswa(i, "kehadiran_nilai", "I")
                          }
                        />
                      </td>
                      <td className="text-center align-middle">
                        <input
                          className="rabsen"
                          type="radio"
                          name={`absensi-${i}`}
                          id={`radio-${i}`}
                          checked={item?.kehadiran_nilai === "A"}
                          onChange={() =>
                            handleChangeSiswa(i, "kehadiran_nilai", "A")
                          }
                        />
                      </td>
                      <td className="text-center align-middle">
                        <input
                          className="rabsen"
                          type="radio"
                          name={`absensi-${i}`}
                          id={`radio-${i}`}
                          checked={item?.kehadiran_nilai === "S"}
                          onChange={() =>
                            handleChangeSiswa(i, "kehadiran_nilai", "S")
                          }
                        />
                      </td>
                      <td className="align-middle">
                        <textarea
                          className="textarea form-control"
                          placeholder={t("catatan_guru")}
                          value={item.kehadiran_catatan}
                          onChange={({ target: { value } }) =>
                            handleChangeSiswa(i, "kehadiran_catatan", value)
                          }
                        ></textarea>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="py-2" colSpan={7}>
                      <p className="text-center m-0">
                        {params.topik_id === ""
                          ? t("pilih_topik_dahulu")
                          : // : params.semester_id === ""
                          // ? t("pilih_semester_dahulu")
                          params.kehadiran_tanggal === ""
                          ? t("pilih_tgl_dahulu")
                          : ""}
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <button
              type="button"
              className="btn btn-primary float-right"
              onClick={handleSubmitAbsensi}
              disabled={submit}
            >
              {t("save_changes")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Absensi;
