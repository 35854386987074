import React, { useCallback, useEffect, useState } from "react";

import { CardCourse } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { getCourseMarked } from "../../redux/actions/courseAction";
import { useTranslation } from "react-i18next";
import { PageLoader } from "../../assets/images";

function KursusSimpan() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { courseMarked, loadingCourseMarked, nextCourseMarked } = useSelector(
    ({ course }) => course
  );
  const { user } = useSelector(({ user }) => user);
  const [page, setPage] = useState(1);
  const getData = useCallback(() => {
    const params = {
      page,
      limit: 6,
      user_id: user?.user_id,
    };
    dispatch(getCourseMarked(params));
  }, [dispatch, page, user]);

  useEffect(() => {
    getData();
  }, [dispatch, getData, user, page]);

  const handleNext = () => {
    setPage((pref) => pref + 1);
  };

  return (
    <div className="row mb-5">
      {loadingCourseMarked && page === 1 ? (
        <div className="col-12 text-center">
          <img
            src={PageLoader}
            alt="loader"
            style={{ height: "60px" }}
            className="mx-auto"
          />
        </div>
      ) : (
        courseMarked?.map((item, idx) => (
          <CardCourse
            key={item.course_id}
            author={item.course_mentor?.mentor?.mentor_name}
            desc={item.course_deskripsi}
            discount={item.course_diskon}
            headline={item.course_headline}
            id={item.course_id}
            idx={idx + 1}
            level={item.course_level}
            price={item.course_price}
            rating={item.total_rating}
            review={item.review_count}
            thumbnail={item.course_thumbnail}
            title={item.course_title}
            total_lesson={item.topik_count}
            total_time={item.total_materi_time}
            type={item.course_type}
          />
        ))
      )}
      <div className="col-12">
        {nextCourseMarked && (
          <div className="col-12">
            <button
              disabled={loadingCourseMarked}
              onClick={handleNext}
              className="btn btn-primary text-white fw-semibold rounded-pill p-12 w-100 my-4"
            >
              {loadingCourseMarked ? t("loading") : t("muat_lainnya")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default KursusSimpan;
