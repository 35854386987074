import axios from "axios";

import { setAuthToken } from "../../config/api";
import { GET_LIBRARY } from "../constant";

const ACCESS_TOKEN = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_BACKEND_API_URL_2;
const headers = {
  headers: {
    Authorization: ACCESS_TOKEN,
  },
};

export const getBookCategory = async (library) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await axios.get(
    `${API_URL}/library/categories?library=${library}`,
    headers
  );
  return res?.data;
};

export const getLibrary = (user_id, params) => {
  return {
    type: GET_LIBRARY,
    payload: async () => {
      const token = localStorage.getItem("token");
      setAuthToken(token);
      const paramsURL = new URLSearchParams(params).toString();
      let res = await axios
        .get(`${API_URL}/library/datas${paramsURL ? `?${paramsURL}` : ""}`, {
          headers: {
            Authorization: ACCESS_TOKEN,
            UserId: user_id,
          },
        })
        .catch((err) => {
          return Promise.reject(err);
        });
      return res?.data;
    },
  };
};

export const likeBook = async (user_id, book_id) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await axios.post(
    `${API_URL}/library/like`,
    { book_id },
    {
      headers: {
        Authorization: ACCESS_TOKEN,
        UserId: user_id,
      },
    }
  );
  return res?.data;
};

export const getBahasaLibrary = async (user_id) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await axios.get(`${API_URL}/library/languages`, headers);
  return res?.data;
};

export const getUserOverview = async (user_id) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await axios.get(
    `${API_URL}/library/user_overview/${user_id}`,
    headers
  );
  return res?.data;
};

export const getMapelLibrary = async (sekolah_id) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await axios.get(`${API_URL}/library/mapel/${sekolah_id}`, headers);
  return res?.data;
};

export const getKelasLibrary = async (sekolah_id) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await axios.get(
    `${API_URL}/library/classes/${sekolah_id}`,
    headers
  );
  return res?.data;
};
export const getBookDetail = async (user_id, book_id) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await axios.get(`${API_URL}/library/detail/${book_id}`, {
    headers: {
      Authorization: ACCESS_TOKEN,
      UserId: user_id,
    },
  });
  return res?.data;
};
export const viewBook = async (user_id, book_id) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await axios.post(
    `${API_URL}/library/view_book`,
    { book_id },
    {
      headers: {
        Authorization: ACCESS_TOKEN,
        UserId: user_id,
      },
    }
  );
  return res?.data;
};

export const getPerpus = async () => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await axios.get(`${API_URL}/library/library`, headers);
  return res?.data;
};

export const getBookSubject = async (library) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await axios.get(
    `${API_URL}/library/subjects?library=${library}`,
    headers
  );
  return res?.data;
};

export const getBookType = async (library) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await axios.get(
    `${API_URL}/library/types?library=${library}`,
    headers
  );
  return res?.data;
};
