import React, { useCallback, useEffect, useState } from 'react';
import { Breadcrumb, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getDrive, getDriveDetail } from '../../redux/actions/driveAction';
import { CardFile, CardFolder } from '../card';

function ModalDrive({ show, handleClose, handleSave }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dataDrive, detailDrive } = useSelector(({ drive }) => drive);
  const [folderSelect, setFolderSelect] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const getData = useCallback(
    (folderSelect) => {
      if (folderSelect) {
        dispatch(getDriveDetail(folderSelect?.drive_folder_id));
        return;
      }
      dispatch(getDrive());
    },
    [dispatch]
  );
  useEffect(() => {
    getData(folderSelect);
    setSelectedFile(null);
  }, [getData, folderSelect]);
  useEffect(() => {
    setSelectedFile(null);
  }, [show]);

  return (
    <div className="col-lg-12">
      <Modal
        enforceFocus={false}
        fullscreen={true}
        show={show}
        onHide={handleClose}
        id="ModalDrive"
        backdrop="static"
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <h4 className="modal-title">{t("add")} Drive</h4>
          <button
            className="border-0 bg-white btn-close m-0 p-2 shadow-none"
            onClick={handleClose}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <Breadcrumb className="px-3 py-2 iq-bg-primary drive-breadcrumb">
            <Breadcrumb.Item onClick={() => setFolderSelect(null)}>
              Drive
            </Breadcrumb.Item>
            {folderSelect && (
              <Breadcrumb.Item active>
                {folderSelect?.drive_folder_name}
              </Breadcrumb.Item>
            )}
          </Breadcrumb>
          {folderSelect ? (
            <div className="iq-card-body">
              <ul className="profile-img-gallary d-flex flex-wrap p-0 m-0 text-center">
                {detailDrive?.length <= 0 ? (
                  <li className="w-100">
                    <p className="py-3 text-muted text-center m-0">
                      {t("folder_kosong")}
                    </p>
                  </li>
                ) : (
                  detailDrive?.map((item) => (
                    <CardFile
                      typeFile={item.drive_file_type}
                      link={item.drive_file_url}
                      nama={item.drive_file_name}
                      key={item.drive_file_id}
                      hideAction={true}
                      handleSelect={() => setSelectedFile(item)}
                      selected={
                        item.drive_file_url === selectedFile?.drive_file_url
                      }
                    />
                  ))
                )}
              </ul>
            </div>
          ) : (
            <div className="iq-card-body">
              <h5 className="mt-2 mb-2">Folder</h5>
              <ul className="profile-img-gallary d-flex flex-wrap p-0 m-0 mb-5 text-center">
                {dataDrive?.folder?.length <= 0 ? (
                  <li className="w-100">
                    <p className="py-3 text-muted text-center m-0">
                      {t("data_params_kosong", { params: "folder" })}
                    </p>
                  </li>
                ) : (
                  dataDrive?.folder?.map((item) => (
                    <CardFolder
                      key={item.drive_folder_id}
                      name={item.drive_folder_name}
                      onDoubleClick={() => setFolderSelect(item)}
                      hideAction={true}
                    />
                  ))
                )}
              </ul>
              <h5 className="mt-2 mb-2">File</h5>
              <ul className="profile-img-gallary d-flex flex-wrap p-0 m-0 text-center">
                {dataDrive?.file?.length <= 0 ? (
                  <li className="w-100">
                    <p className="py-3 text-muted text-center m-0">
                      {t("data_params_kosong", { params: "file" })}
                    </p>
                  </li>
                ) : (
                  dataDrive?.file?.map((item) => (
                    <CardFile
                      typeFile={item.drive_file_type}
                      link={item.drive_file_url}
                      nama={item.drive_file_name}
                      key={item.drive_file_id}
                      hideAction={true}
                      handleSelect={() => setSelectedFile(item)}
                      selected={
                        item.drive_file_url === selectedFile?.drive_file_url
                      }
                    />
                  ))
                )}
              </ul>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("tutup")}
          </Button>
          <Button variant="primary" onClick={() => handleSave(selectedFile)}>
            {t("save_changes")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalDrive;
