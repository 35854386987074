import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

/**
 * Renders a modal dialog for filtering data.
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Determines whether the modal dialog should be shown or hidden.
 * @param {function} props.handleClose - Callback function to be called when the modal dialog is closed.
 * @param {Array} props.filterData - An array of objects representing the filter options.
 * @param {Object} props.params - An object representing the current filter parameters.
 * @param {function} props.filterMobile - Callback function to be called when the filter is applied on mobile devices.
 * @returns {JSX.Element} The rendered component.
 */
function ModalFilter({ show, handleClose, filterData, params, filterMobile }) {
  const { t } = useTranslation();
  const [data, setData] = useState(params);

  /**
   * Handles the change event of the filter options.
   * @param {Object} event - The change event.
   */
  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "sort") {
      setData((prevState) => ({
        ...prevState,
        [name]: value === prevState[name] ? "" : value,
        page: 1,
      }));
    } else {
      const inputData = data[name];
      setData((prevState) => ({
        ...prevState,
        [name]: !inputData.includes(value)
          ? [...prevState[name], value]
          : inputData.filter((item) => item !== value),
        page: 1,
      }));
    }
  };

  return (
    <Modal size="md" show={show} onHide={handleClose} centered id="ModalFilter">
      <Modal.Body>
        <div className="row">
          {filterData?.map((item) => (
            <div className="col-12" key={item.label}>
              <div className="form-group">
                <label className="fw-bold font-size-16 ps-2">
                  {t(item.label)}:
                </label>
                {item.data?.map((option) => (
                  <div
                    className="custom-control custom-checkbox mt-1"
                    key={option.value}
                  >
                    <label className="font-size-16">
                      <input
                        name={item.name}
                        type="checkbox"
                        className="checkbox-2x me-2"
                        value={option.value}
                        checked={
                          Array.isArray(data[item.name])
                            ? data[item.name].includes(option.value)
                            : data[item.name] === option.value
                        }
                        onChange={handleChange}
                      />{" "}
                      {t(option.label)}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <button
                onClick={() => {
                  filterMobile(data);
                  handleClose();
                }}
                className="btn btn-primary mt-2 w-100 rounded-pill"
              >
                {t("filter_kursus")}
              </button>
              <button
                onClick={handleClose}
                className="btn btn-secondary mt-2 w-100 rounded-pill"
              >
                {t("tutup")}
              </button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalFilter;
