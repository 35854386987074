import React from "react";

function ItemSelectSiswa({ img, selected, nama, handleChange, code, padding }) {
  return (
    <li
      className={`d-flex user-select-none align-items-center px-3 ${
        padding || "py-3"
      }`}
    >
      {img && (
        <div className="user-img img-fluid me-3">
          <img src={img} alt="story-img" className="rounded-circle avatar-40" />
        </div>
      )}
      <div className="media-support-info">
        <h6 className="d-inline-block">{nama}</h6>
      </div>
      <div className="iq-card-header-toolbar d-flex align-items-center">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            name={code}
            checked={selected}
            onChange={({ target: { name } }) => handleChange(name, code)}
            className="custom-control-input"
            id={code}
          />
          <label
            className="custom-control-label cursor-pointer"
            htmlFor={code}
          ></label>
        </div>
      </div>
    </li>
  );
}

export default ItemSelectSiswa;
