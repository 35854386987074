import {
  GET_COURSE,
  GET_COURSE_MARKED,
  GET_COURSE_PAYMENT,
  GET_COURSE_USER,
  LOGOUT,
} from "../constant";

const initState = {
  course: [],
  loadingCourse: [],
  nextCourse: true,
  courseMarked: [],
  loadingCourseMarked: [],
  nextCourseMarked: true,
  courseUser: [],
  loadingCourseUser: [],
  nextCourseUser: true,
  coursePayment: [],
  loadingCoursePayment: [],
};

const courseReducer = (state = initState, action) => {
  switch (action?.type) {
    case GET_COURSE + "_PENDING":
      return {
        ...state,
        loadingCourse: true,
      };
    case GET_COURSE + "_FULFILLED":
      return {
        ...state,
        course:
          action.payload.current_page === 1
            ? action.payload.data
            : [...state.course, ...action.payload.data],
        loadingCourse: false,
        nextCourse: action.payload.last_page > action.payload.current_page,
      };
    case GET_COURSE + "_REJECTED":
      return {
        ...state,
        loadingCourse: false,
      };
    case GET_COURSE_MARKED + "_PENDING":
      return {
        ...state,
        loadingCourseMarked: true,
      };
    case GET_COURSE_MARKED + "_FULFILLED":
      return {
        ...state,
        courseMarked:
          action.payload.current_page === 1
            ? action.payload.data
            : [...state.courseMarked, ...action.payload.data],
        loadingCourseMarked: false,
        nextCourseMarked:
          action.payload.last_page > action.payload.current_page,
      };
    case GET_COURSE_MARKED + "_REJECTED":
      return {
        ...state,
        loadingCourseMarked: false,
      };
    case GET_COURSE_USER + "_PENDING":
      return {
        ...state,
        loadingCourseUser: true,
      };
    case GET_COURSE_USER + "_FULFILLED":
      return {
        ...state,
        courseUser:
          action.payload.current_page === 1
            ? action.payload.data
            : [...state.courseUser, ...action.payload.data],
        loadingCourseUser: false,
        nextCourseUser: action.payload.last_page > action.payload.current_page,
      };
    case GET_COURSE_USER + "_REJECTED":
      return {
        ...state,
        loadingCourseUser: false,
      };
    case GET_COURSE_PAYMENT + "_PENDING":
      return {
        ...state,
        loadingCoursePayment: true,
      };
    case GET_COURSE_PAYMENT + "_FULFILLED":
      return {
        ...state,
        coursePayment: action.payload.data,
        loadingCoursePayment: false,
      };
    case GET_COURSE_PAYMENT + "_REJECTED":
      return {
        ...state,
        loadingCoursePayment: false,
      };
    case LOGOUT:
      return {
        course: [],
        loadingCourse: [],
        nextCourse: true,
        courseMarked: [],
        loadingCourseMarked: [],
        nextCourseMarked: true,
        courseUser: [],
        loadingCourseUser: [],
        nextCourseUser: true,
        coursePayment: [],
        loadingCoursePayment: [],
      };
    default: {
      return state;
    }
  }
};

export default courseReducer;
