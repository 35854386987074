import React from 'react';
import { Link } from 'react-router-dom';

function BlogCard({ profile, name, created_at, img, title, desc }) {
	return (
		<div className="col-sm-6 col-md-6 col-lg-6">
			<div className="iq-card iq-card-block iq-card-stretch iq-card-height product">
				<div className="iq-card-body">
					<div className="d-flex align-items-center justify-content-between pb-3">
						<div className="d-flex align-items-center">
							<img className="img-fluid rounded-circle avatar-30" src={profile} alt="" />
							<div className="media-body ms-2">
								<p className="mb-0 line-height">Posted By</p>
								<h6>
									<a href="/">{name}</a>
								</h6>
							</div>
						</div>
						<div className="d-block line-height">
							<span className="categry text-primary ps-3 mb-2 position-relative">{created_at}</span>
						</div>
					</div>
					<div className="image-block position-relative">
						<img src={img} className="img-fluid w-100 rounded" alt="product-img" />
					</div>
					<div className="product-description mt-3">
						<h5 className="mb-1">
							<Link to="/blog/1">{title}</Link>
						</h5>
						<p className="mb-0">{desc}</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BlogCard;
