import "moment/locale/id";

import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";

import { ItemDetailNotif, Wrapper } from "../../components";
import {
  getDetailNotif,
  getDetailNotifGroup,
} from "../../redux/actions/notifAction";

moment.locale("id");

/* eslint-disable jsx-a11y/anchor-is-valid */
function Notifikasi() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { notif, notifGroup } = useSelector(({ notif }) => notif);
  const [activeTab, setActiveTab] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (notif?.length > 0 && searchParams.get("tab") === null) {
      setActiveTab(notif[0].group_notif_id);
    } else {
      setActiveTab(searchParams.get("tab"));
    }
  }, [notif, searchParams]);

  useEffect(() => {
    setSearch("");
    dispatch(getDetailNotifGroup(activeTab, ""));
  }, [dispatch, activeTab]);

  const handleSearch = () => {
    dispatch(getDetailNotifGroup(activeTab, search));
  };

  return (
    <Wrapper title={t("notif")}>
      <div className="container relative">
        <div className="row">
          <div className="col-lg-3">
            <div className="iq-card">
              <div className="iq-card-body">
                <div className="">
                  <div className="iq-email-list">
                    <div className="iq-email-ui nav flex-column nav-pills">
                      {notif?.map((item) => (
                        <li
                          key={item.group_notif_id}
                          className={`nav-link ${
                            activeTab === item.group_notif_id ? "active" : ""
                          }`}
                        >
                          <button
                            onClick={() => setActiveTab(item.group_notif_id)}
                            className="bg-transparent border-0 text-start"
                          >
                            {item.group_notif_id === "MATERI" ? (
                              <i className="ri-book-mark-line"></i>
                            ) : item.group_notif_id === "TUGAS" ? (
                              <i className="ri-book-mark-line"></i>
                            ) : item.group_notif_id === "ABSENSI" ? (
                              <i className="ri-book-mark-line"></i>
                            ) : (
                              <i className="ri-group-line"></i>
                            )}
                            {item.group_notif_name}
                            {item.not_read ? (
                              <span className="badge badge-primary ml-2">
                                {item.not_read}
                              </span>
                            ) : null}
                          </button>
                        </li>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-9 mail-box-detail position-relative">
            <div className="iq-card">
              <div className="iq-card-body p-0">
                <div className="">
                  <div className="iq-email-to-list p-3">
                    <div className="d-flex justify-content-between flex-column flex-md-row gap-8">
                      <div className="iq-email-search d-flex w-100">
                        <form
                          onSubmit={handleSearch}
                          className="mr-3 position-relative w-100"
                        >
                          <div className="form-group mb-0">
                            <input
                              className="form-control w-100"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Search"
                              onChange={({ target: { value } }) =>
                                setSearch(value)
                              }
                              value={search}
                            />
                            <button
                              type="submit"
                              className="search-link bg-transparent border-0"
                            >
                              <i className="ri-search-line"></i>
                            </button>
                          </div>
                        </form>
                      </div>
                      <Link
                        to={`/`}
                        className="btn iq-bg-light iq-bg-primary-hover text-dark border"
                      >
                        <i className="ri-arrow-left-line m-0"></i>{" "}
                        {t("kembali")}
                      </Link>
                    </div>
                  </div>
                  <div className="iq-email-listbox">
                    <div className="tab-content">
                      <ul className="iq-email-sender-list mt-0">
                        {notifGroup.length <= 0 ? (
                          <p className="text-center mt-2">
                            {search === ""
                              ? t("notif_tdk_ditemukan")
                              : "Tidak ada data notif"}
                          </p>
                        ) : (
                          notifGroup?.map((item) => (
                            <Fragment key={item.notif_id}>
                              <li
                                className={!item.notif_read ? "iq-unread" : ""}
                              >
                                <button
                                  onClick={() => {
                                    window?.scrollTo({
                                      top: 0,
                                      behavior: "smooth",
                                    });
                                    dispatch(
                                      getDetailNotif(item.notif_id, activeTab)
                                    );
                                  }}
                                  className="d-flex bg-transparent border-0 cursor-pointer"
                                >
                                  <p className="iq-email-title m-0">
                                    {item.user_from.user_nama}
                                  </p>
                                  <div className="iq-email-content">
                                    <p className="iq-email-subject m-0 text-end">
                                      {item.notif_title}
                                    </p>
                                    <p className="iq-email-date m-0">
                                      {moment(item.createdAt).format(
                                        "DD MMM YYYY"
                                      )}
                                    </p>
                                  </div>
                                </button>
                              </li>
                              <ItemDetailNotif
                                avatar={item.user_from.user_avatar}
                                created_at={item.createdAt}
                                email={item.user_from.user_email}
                                group={notif?.filter(
                                  (item) => item.group_notif_id === activeTab
                                )}
                                message={item.notif_message}
                                name={item.user_from.user_nama}
                                title={item.notif_title}
                              />
                            </Fragment>
                          ))
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Notifikasi;
