import React, { useEffect, useState } from "react";
import {
  getShareVerifikasi,
  verifyShare,
} from "../../redux/actions/shareAction";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const VerifyBagikanModul = ({ idMapel, getDataTopik }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [dataShare, setDataShare] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = () => {
    let searchParams = new URLSearchParams(location.search);
    let th = searchParams.get("th");
    let smt = searchParams.get("smt");
    const params = {
      tahunpelajaran_id: th,
      semester_id: smt,
    };
    getShareVerifikasi(idMapel, params).then((res) =>
      setDataShare(res?.data?.data)
    );
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, idMapel]);

  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };

  const handleVerify = (share_id, share_status) => {
    setLoading(true);
    const data = {
      share_id,
      share_status,
    };
    verifyShare(data)
      .then(() => {
        setLoading(false);
        getData();
        getDataTopik();
      })
      .catch(() => {
        setLoading(false);
        showAlert(t("failed_proccess"), false);
      });
  };

  return dataShare?.map((item) => (
    <div className="iq-card iq-bg-warning" key={item.share_id}>
      <div className="iq-card-body">
        Guru {item.user_nama} telah membagikan {item.share_type} mata pelajaran{" "}
        {item.mapel_nama}, kelas {item.kelasdetail_nama} (semster{" "}
        {item.semester_nama}), tahun pelajaran {item.tahunpelajaran_nama}.{" "}
        <div className="d-flex gap-1 mt-2">
          <button
            onClick={() => handleVerify(item.share_id, "approve")}
            disabled={loading}
            type="button"
            className="btn btn-sm btn-primary border-primary"
          >
            Terima modul
          </button>
          <button
            onClick={() => handleVerify(item.share_id, "reject")}
            disabled={loading}
            type="button"
            className="btn btn-sm btn-light border-secondary"
          >
            Tolak
          </button>
        </div>
      </div>
    </div>
  ));
};

export default VerifyBagikanModul;
