import React, { Fragment, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { IcFileAudio, IcH5P } from "../../assets/images/file";
import {
  SmallDOC,
  SmallJPG,
  SmallPDF,
  SmallPNG,
  SmallPPT,
  SmallVideo,
  SmallXLS,
} from "../../assets/images/small";
import {
  AUDIO_EXT,
  EXCEL_EXT,
  MS_EXT,
  PNG_IMG_EXT,
  PPT_EXT,
  SVG_IMG_EXT,
  VIDEO_EXT,
} from "../../config/fileType";
import { checkIsGuru } from "../../utils/roleUser";
import { ModalFile, ModalMateriH5P, ModalMateriVideo } from "../modal";

function ItemFile({ url, name, type, handleDeleteFile, canDelete }) {
  const { t } = useTranslation();
  const [showPreview, setShowPreview] = useState(false);
  const { user } = useSelector(({ user }) => user);
  const [materiH5P, setMateriH5P] = useState(null);
  const [materiVideo, setMateriVideo] = useState(false);

  const getIcon = (type) => {
    if (PNG_IMG_EXT.includes(type)) {
      return SmallPNG;
    } else if (SVG_IMG_EXT.includes(type)) {
      return SmallJPG;
    } else if (VIDEO_EXT.includes(type)) {
      return SmallVideo;
    } else if (type === "application/pdf") {
      return SmallPDF;
    } else if (MS_EXT.includes(type)) {
      return SmallDOC;
    } else if (AUDIO_EXT.includes(type)) {
      return IcFileAudio;
    } else if (PPT_EXT.includes(type)) {
      return SmallPPT;
    } else if (EXCEL_EXT.includes(type)) {
      return SmallXLS;
    } else if (type === "html") {
      return IcH5P;
    } else {
      return SmallDOC;
    }
  };

  const handleClick = () => {
    if (
      PNG_IMG_EXT.includes(type) ||
      SVG_IMG_EXT.includes(type) ||
      PPT_EXT.includes(type) ||
      EXCEL_EXT.includes(type) ||
      MS_EXT.includes(type) ||
      type === "application/pdf"
    ) {
      setShowPreview(true);
    } else if (VIDEO_EXT.includes(type)) {
      setMateriVideo(true);
    } else {
      window.open(url);
    }
  };

  return (
    <Fragment>
      <div className="iq-bg-primary rounded p-2 pointer my-1 d-flex align-items-md-center justify-content-between flex-md-row gap-8 item-file">
        {type === "html" ? (
          <div
            onClick={() => setMateriH5P(url)}
            className="flex-1 d-flex cursor-pointer align-items-center wrap-content"
          >
            <img
              src={getIcon(type)}
              alt="icon"
              className="img-fluid"
              width={24}
            />
            <span className="elipsis-1-line d-block ms-2">{name}</span>
          </div>
        ) : (
          <button
            onClick={handleClick}
            className="flex-1 d-flex border-0 bg-transparent text-primary px-0 align-items-center wrap-content"
          >
            <img
              src={getIcon(type)}
              alt="icon"
              className="img-fluid"
              width={24}
            />
            <span className="elipsis-1-line d-block ms-2">{name}</span>
          </button>
        )}
        {(checkIsGuru(user?.user_roleid) || canDelete) && (
          <Fragment>
            <div className="d-flex">
              {handleDeleteFile && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="button-tooltip-2">{t("hapus")}</Tooltip>
                  }
                >
                  <button
                    type="button"
                    className="btn btn-danger btn-sm float-right"
                    onClick={handleDeleteFile}
                  >
                    <i className="ri-delete-bin-line m-0"></i>
                  </button>
                </OverlayTrigger>
              )}
            </div>
          </Fragment>
        )}
      </div>
      <ModalFile
        show={showPreview}
        url={url}
        type={type}
        handleClose={() => setShowPreview(false)}
      />
      {materiVideo && (
        <ModalMateriVideo
          dataVideo={{
            video_file: url,
            video_type: type,
            video_name: name,
          }}
          handleClose={() => setMateriVideo(false)}
          show={true}
        />
      )}

      {materiH5P && (
        <ModalMateriH5P
          url={materiH5P}
          handleClose={() => setMateriH5P(null)}
          show={true}
        />
      )}
    </Fragment>
  );
}

export default ItemFile;
