export function formatRupiah(number) {
  return number
    ? new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(number)
    : "Rp 0";
}

export function formatAngka(number) {
  return new Intl.NumberFormat("id-ID").format(number);
}
