import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FilterCourse, ListCourse } from "../../components";
import { getCourse, getCourseCategory } from "../../redux/actions/courseAction";
import { useTranslation } from "react-i18next";

const filterData = [
  {
    label: "urutkan",
    name: "sort",
    data: [
      { label: "new", value: "new" },
      { label: "popular", value: "popular" },
    ],
  },
  {
    label: "tipe",
    name: "type",
    data: [
      { label: "free", value: "free" },
      { label: "premium", value: "premium" },
    ],
  },
  {
    label: "tingkat",
    name: "level",
    data: [
      { label: "beginner", value: "beginner" },
      { label: "intermediate", value: "intermediate" },
      { label: "advance", value: "advance" },
    ],
  },
];
function Kursus() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector(({ user }) => user);
  const { course, loadingCourse, nextCourse } = useSelector(
    ({ course }) => course
  );
  const [search, setSearch] = useState("");
  const [dataCategory, setDataCategory] = useState([]);
  const [params, setParams] = useState({
    sort: "new",
    category: "",
    level: [],
    type: [],
    page: 1,
  });

  useEffect(() => {
    document.title = `${t("kursus")} | Smartclass Bakti Mulya 400`;
    getCourseCategory().then((res) => setDataCategory(res?.data));
  }, [t]);

  const getData = useCallback(
    () => {
      const data = {
        ...params,
        user_id: user?.user_id,
        search,
        limit: 6,
      };
      dispatch(getCourse(data));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, params, search, user]
  );

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params]);

  const handleNext = () => {
    setParams((pref) => ({
      ...pref,
      page: pref.page + 1,
    }));
  };

  const handleChangeFilter = ({ target: { name, value } }) => {
    if (name === "sort" || name === "category") {
      setParams((pref) => ({
        ...pref,
        [name]: value === pref[name] ? "" : value,
        page: 1,
      }));
    } else {
      let data = params[name];
      setParams((pref) => ({
        ...pref,
        [name]: !data.includes(value)
          ? [...pref[name], value]
          : data.filter((item) => item !== value),
        page: 1,
      }));
    }
  };

  const handleChangeSearch = ({ target: { value } }) => {
    setSearch(value);
  };

  const handleSearch = (e) => {
    e?.preventDefault();
    setParams((pref) => ({
      ...pref,
      page: 1,
    }));
    getData();
  };

  const filterMobile = (data) => {
    setParams(data);
  };

  return (
    <>
      <div className="col-lg-12 py-2 d-block d-md-none">
        <div className="iq-search-bar d-block px-1">
          <form
            onSubmit={handleSearch}
            className="searchbox align-items-center d-flex"
          >
            <input
              value={search}
              onChange={handleChangeSearch}
              type="text"
              className="text search-input"
              placeholder={t("placeholder_search")}
            />
            <button
              disabled={loadingCourse}
              type="submit"
              className="border-0 bg-transparent position-absolute end-0 me-2"
            >
              <i className="ri-search-line"></i>
            </button>
          </form>
        </div>
      </div>
      <FilterCourse
        handleChange={handleChangeFilter}
        handleSearch={handleSearch}
        params={params}
        search={search}
        handleChangeSearch={handleChangeSearch}
        submit={loadingCourse}
        dataCategory={dataCategory}
        showAdvanceFilter
        filterData={filterData}
        filterMobile={filterMobile}
      />
      <ListCourse
        data={course}
        loading={loadingCourse}
        onClick={handleNext}
        showBtn={true}
        next={nextCourse}
        page={params.page}
      />
    </>
  );
}

export default Kursus;
