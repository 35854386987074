import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import { PageLoader } from "../../assets/images";
import { addliveClass } from "../../redux/actions/liveClassAction";
import SelectMultiple from "../input/SelectMultiple";
import { InputEditor } from "../input";

function TambahLiveClass({ dataTopik, dataSiswa, show, handleClose }) {
  const { t } = useTranslation();
  const editorRef = useRef(null);
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(null);
  const [params, setParams] = useState({
    topik_id: "",
    title: "",
    start_date: "",
    end_date: "",
    deskripsi: "",
    enable_start_date: false,
    enable_end_date: false,
    list_siswa: [],
    allSiswa: false,
  });
  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };
  const handleChange = ({ target: { value, name } }) => {
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };
  const handleChangeChecked = ({ target: { checked, name } }) => {
    setParams((pref) => ({
      ...pref,
      [name]: checked,
      [name.slice(7, name.length)]: "",
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    let data = {
      topik_id: params.topik_id,
      title: params.title,
      deskripsi: editorRef.current.getContent(),
      files: params.files,
      list_siswa: params.allSiswa ? [] : params.list_siswa,
      allSiswa: params.allSiswa,
      selectedSiswa: params.selectedSiswa,
    };
    if (params?.start_date?.length > 0) data.start_date = params.start_date;
    if (params?.end_date?.length > 0) data.end_date = params.end_date;
    addliveClass(data)
      .then(() => {
        showAlert(t("berhasil_simpan", { params: t("live_class") }), true);
        handleClose();
        setSubmit(false);
      })
      .catch((err) => {
        setError(err?.response?.data?.error);
        setSubmit(false);
      });
  };
  const handleChangeSiswa = (name, value) => {
    if (name === "all" && value) {
      setParams((pref) => ({
        ...pref,
        list_siswa: dataSiswa?.map((item) => item?.user?.user_id?.toString()),
        allSiswa: true,
      }));
      return;
    } else if (name === "all") {
      setParams((pref) => ({ ...pref, list_siswa: [], allSiswa: false }));
      return;
    }
    let cek = params?.list_siswa?.filter(
      (item) => item.toString() === value.toString()
    );
    if (cek?.length > 0) {
      setParams((pref) => ({
        ...pref,
        list_siswa: params?.list_siswa?.filter(
          (item) => item.toString() !== value.toString()
        ),
        allSiswa: false,
      }));
    } else {
      setParams((pref) => ({
        ...pref,
        list_siswa: [...pref.list_siswa, value?.toString()],
        allSiswa: false,
      }));
    }
  };
  useEffect(() => {
    handleChangeSiswa("all", true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="col-lg-12">
      <Modal
        enforceFocus={false}
        fullscreen={true}
        show={show}
        onHide={handleClose}
        id="tambahmateri"
        backdrop="static"
      >
        <Modal.Header>
          <div className="d-flex align-items-center">
            <button
              className="border-0 bg-white btn-close m-0 p-2 shadow-none"
              onClick={handleClose}
            ></button>
            <h4 className="modal-title">{t("tambah")} Live Class</h4>
          </div>
          <button
            type="submit"
            onClick={handleSubmit}
            className="btn btn-primary"
            disabled={submit}
          >
            {submit ? (
              <img src={PageLoader} alt="loader" style={{ width: 15 }} />
            ) : (
              t("simpan")
            )}
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 overflow-x-hidden">
          <div className="row h-100">
            <div className="col-lg-7 offset-md-1 pt-4">
              <div className="iq-card w-modal mb-4">
                <div className="iq-card-body">
                  <div className="w-100 mb-3">
                    <label htmlFor="title" className="col-12">
                      {t("judul")} <span className="text-danger">*</span> :
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      value={params.title}
                      name="title"
                      placeholder={t("judul")}
                      onChange={handleChange}
                    />
                    {error?.title && (
                      <div className="d-block invalid-feedback">
                        {error?.title}
                      </div>
                    )}
                  </div>
                  <div className="w-100 mb-3">
                    <label htmlFor="email">{t("desc")} :</label>
                    <InputEditor
                      editorRef={editorRef}
                      value={params.deskripsi}
                    />
                    {error?.deskripsi && (
                      <div className="d-block invalid-feedback">
                        {error?.deskripsi}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 offset-md-1">
              <div className="iq-card h-100">
                <div className="iq-card-body h-100">
                  <div className="w-100 mb-3">
                    <label htmlFor="topik_id">
                      {t("topik")} <span className="text-danger">*</span> :
                    </label>
                    <select
                      className="form-control form-select"
                      name="topik_id"
                      id="topik_id"
                      value={params.topik_id}
                      onChange={handleChange}
                      required
                    >
                      <option value="">
                        {t("pilih_params", { params: t("topik") })}
                      </option>
                      {dataTopik?.topik?.map((item) => (
                        <option value={item.topik_id} key={item.topik_id}>
                          {item.topic}
                        </option>
                      ))}
                    </select>
                    {error?.topik_id && (
                      <div className="d-block invalid-feedback">
                        {error?.topik_id}
                      </div>
                    )}
                  </div>
                  <div className="w-100 mb-3">
                    <SelectMultiple
                      dataSiswa={dataSiswa}
                      selectedSiswa={params?.list_siswa}
                      selectedAll={params?.allSiswa}
                      siswa={params?.selectedSiswa}
                      handleChange={handleChangeSiswa}
                    />
                    {error?.list_siswa && (
                      <div className="d-block invalid-feedback">
                        {error?.list_siswa}
                      </div>
                    )}
                  </div>
                  <div className="w-100 mb-3">
                    <div className="row">
                      <label htmlFor="start_date" className="col-12">
                        {t("tgl_mulai")} :
                      </label>
                      <div className="col-10">
                        <input
                          type="datetime-local"
                          className="form-control"
                          id="start_date"
                          value={params.start_date}
                          onChange={handleChange}
                          name="start_date"
                          disabled={!params.enable_start_date}
                        />
                        {error?.start_date && (
                          <div className="d-block invalid-feedback">
                            {error?.start_date}
                          </div>
                        )}
                      </div>
                      <div className="col-2 align-self-center">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="enable_start_date"
                            value={params.enable_start_date}
                            checked={params.enable_start_date}
                            onChange={handleChangeChecked}
                            name="enable_start_date"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="enable_start_date"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 mb-3">
                    <div className="row">
                      <label htmlFor="email" className="col-12">
                        {t("tgl_selesai")} :
                      </label>
                      <div className="col-10">
                        <input
                          type="datetime-local"
                          className="form-control"
                          id="end_date"
                          name="end_date"
                          value={params.end_date}
                          onChange={handleChange}
                          disabled={!params.enable_end_date}
                        />
                        {error?.end_date && (
                          <div className="d-block invalid-feedback">
                            {error?.end_date}
                          </div>
                        )}
                      </div>
                      <div className="col-2 align-self-center">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="enable_end_date"
                            value={params.enable_end_date}
                            checked={params.enable_end_date}
                            onChange={handleChangeChecked}
                            name="enable_end_date"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="enable_end_date"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default TambahLiveClass;
