import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

const ActionItemTugas = ({ tugasId, showBtnDelete, handleDelete }) => {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <div className="d-flex">
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="button-tooltip-2">{t("preview")}</Tooltip>}
      >
        <Link
          to={`/tugas/${tugasId}${location.search}`}
          className="btn btn-success btn-sm float-right"
        >
          <i className="ri-computer-line m-0"></i>
        </Link>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="button-tooltip-2">{t("nilai")}</Tooltip>}
      >
        <Link
          to={`/nilai/${tugasId}`}
          className="btn btn-warning btn-sm float-right ms-1"
        >
          <i className="ri-file-2-line m-0"></i>
        </Link>
      </OverlayTrigger>
      {showBtnDelete && (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="button-tooltip-2">{t("hapus")}</Tooltip>}
        >
          <button
            className="btn btn-danger btn-sm float-right ms-1"
            onClick={() => handleDelete(tugasId)}
          >
            <i className="ri-delete-bin-line m-0"></i>
          </button>
        </OverlayTrigger>
      )}
    </div>
  );
};

export default ActionItemTugas;
