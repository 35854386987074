import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";

import { PageLoader } from "../../assets/images";
import {
  InputEditor,
  InputFileSoalCerita,
  InputJenisSoal,
  Wrapper,
} from "../../components";
import {
  addSoalCerita,
  getDetailTugas,
  getListSoalCerita,
  uploadFileSoal,
} from "../../redux/actions/tugasAction";

function AddSoalCerita() {
  const { t } = useTranslation();
  const { id } = useParams();
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const [submit, setSubmit] = useState(false);
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const [soal, setSoal] = useState([]);
  const [selectedSoal, setSelectedSoal] = useState([]);
  const [detailTugas, setDetailTugas] = useState(null);
  const [params, setParams] = useState({
    tugas_id: "",
    cerita_deskripsi: "",
    cerita_file: "",
    detail: [],
  });
  useEffect(() => {
    if (id) {
      getDetailTugas(id).then((res) => setDetailTugas(res?.data?.data));
    }
  }, [id]);
  const handleChangeSelect = (data) => {
    setSelectedSoal(data);
    setParams((pref) => ({
      ...pref,
      detail: data?.map((item) => ({ soal_id: item.value })),
    }));
  };
  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    }).then(() => {
      if (status) {
        navigate(
          `/tugas/${id}?th=${detailTugas?.topik?.tahunpelajaran_id}&smt=${detailTugas?.topik?.semester_id}`
        );
      }
    });
  };
  const handleSubmit = () => {
    setSubmit(true);
    const data = {
      ...params,
      cerita_deskripsi: editorRef?.current?.getContent(),
    };
    addSoalCerita(data)
      .then(() => {
        showAlert(t("berhasil_simpan", { params: t("soal") }), true);
        setSubmit(false);
      })
      .catch(() => {
        showAlert(t("data_gagal_simpan", { params: t("soal") }), false);
        setSubmit(false);
      });
  };
  const handleChangeFile = ({ target: { files } }) => {
    if (files.length > 0) {
      var formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`file`, files[i]);
      }
      setUploadFileLoading(true);
      uploadFileSoal(formData)
        .then((res) => {
          setUploadFileLoading(false);
          setParams((pref) => ({
            ...pref,
            cerita_file: res?.data?.data[0]?.url,
          }));
        })
        .catch((err) => {
          setUploadFileLoading(false);
        });
    }
  };
  const stripHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };
  useEffect(() => {
    getListSoalCerita(id).then(({ data }) =>
      setSoal(
        data?.data?.map((item) => {
          let soal = stripHtml(item.soal_pertanyaan);
          return {
            value: item.soal_id,
            label: soal.length > 200 ? soal.substring(0, 200) + "..." : soal,
          };
        })
      )
    );
    setParams((pref) => ({
      ...pref,
      tugas_id: id,
    }));
  }, [id]);

  const handleDelFile = () => {
    setParams((pref) => ({
      ...pref,
      cerita_file: "",
    }));
  };

  return (
    <Wrapper title={t("tambah_soal_cerita")}>
      <div className="container">
        <div className="row row-eq-height">
          <div className="col-lg-12">
            <div className="row pl-0 pr-0 justify-content-end">
              <div className="col-lg-6 col-sm-4 col-12 d-flex justify-content-lg-end">
                <InputJenisSoal
                  value="cerita"
                  handleChange={({ target: { value } }) =>
                    navigate(`/tambah-soal/${id}/${value}`)
                  }
                />
              </div>
            </div>
            <div className="iq-card">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title d-flex">
                  <h4 className="card-title fw-bold">{t("soal")}</h4>
                </div>
              </div>
              <div className="col-lg-12 p-0">
                <div className="iq-card ">
                  <div className="iq-card-body">
                    <div className="row">
                      <InputFileSoalCerita
                        file={params.cerita_file}
                        handleChange={handleChangeFile}
                        handleDelFile={handleDelFile}
                        loading={uploadFileLoading}
                      />
                      <div
                        className={`col-12 px-2 ${
                          params?.cerita_file ? "col-lg-9" : "col-lg-10"
                        } pb-3`}
                      >
                        <Select
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            colors: {
                              ...theme.colors,
                              primary: "#3f9b4b",
                            },
                          })}
                          value={selectedSoal}
                          onChange={handleChangeSelect}
                          isMulti
                          options={soal}
                          className="mb-2 z-index-2"
                        />
                        <InputEditor editorRef={editorRef} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-end mb-5">
              <Link
                to={`/tugas/${id}?th=${detailTugas?.topik?.tahunpelajaran_id}&smt=${detailTugas?.topik?.semester_id}`}
                className="btn btn-dark text-center fw-bold mx-1 float-right"
              >
                {t("batal")}
              </Link>
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary text-center fw-bold  float-right"
              >
                <i className="ri-save-2-fill m-0"></i>
                {submit ? (
                  <img src={PageLoader} alt="loader" style={{ width: 15 }} />
                ) : (
                  t("simpan")
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default AddSoalCerita;
