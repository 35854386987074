import { API, setAuthToken } from "../../config/api";

export const getShareKelas = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`share/list-kelas?kelasmapel_id=${code}`);
};

export const getShareMapel = (data) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  const stringParams = data ? new URLSearchParams(data).toString() : "";
  return API.get(
    `share/mata-pelajaran${stringParams ? "?" + stringParams : ""}`
  );
};

export const getShareTopik = (kelasMapelId, data) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  const stringParams = data ? new URLSearchParams(data).toString() : "";
  return API.get(
    `share/topik/${kelasMapelId}${stringParams ? "?" + stringParams : ""}`
  );
};

export const getShareVerifikasi = (id, params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  const stringParams = params ? new URLSearchParams(params).toString() : "";
  return API.get(`share/${id}${stringParams ? "?" + stringParams : ""}`);
};

export const verifyShare = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("/share/approval", params);
};
