import React, { Fragment, useEffect, useState } from "react";

import { CardReviewCourse } from "../card";
import { useParams } from "react-router-dom";
import { getCourseReview } from "../../redux/actions/courseAction";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function ReviewBuyCourse() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [review, setReview] = useState([]);
  const [nextReview, setNextReview] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const data = {
      page: page,
      limit: 5,
    };
    setLoading(true);
    getCourseReview(id, data)
      .then((res) => {
        const dataReview = page === 1 ? res?.data : [...review, ...res?.data];
        setReview(dataReview);
        setNextReview(page < res?.last_page);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, page]);

  return (
    <Fragment>
      {loading && review.length <= 0 ? (
        <div className="my-5 text-center">
          <Spinner
            animation="border"
            role="status"
            variant="secondary"
            className="mx-auto"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : null}
      {review.length <= 0 && !loading ? (
        <p className="text-muted">
          {t("data_params_kosong", { params: t("ulasan") })}
        </p>
      ) : null}
      {review.map((item) => (
        <CardReviewCourse
          key={item.review_id}
          img={item.user.user_foto}
          name={item.user.user_name}
          rating={item.review_rating}
          review={item.review_deskripsi}
        />
      ))}

      {nextReview ? (
        <button
          disabled={loading}
          onClick={() => setPage((pref) => pref + 1)}
          className="btn btn-primary text-white fw-semibold rounded-pill p-12 w-100 my-4"
        >
          {loading ? t("loading") + "..." : t("muat_lainnya")}
        </button>
      ) : null}
    </Fragment>
  );
}

export default ReviewBuyCourse;
