import React from "react";
import { useTranslation } from "react-i18next";

function InputJenisSoal({ value, handleChange, disabled }) {
  const { t } = useTranslation();
  return (
    <div
      className="iq-card iq-card-body p-1 d-flex align-items-center"
      style={{ backgroundColor: "rgb(38 38 38)" }}
    >
      <i className="ri-award-fill ms-2 font-size-18 text-white"></i>
      <select
        className="form-control form-control-sm bg-transparent text-white border-0 font-size-14 fw-bold cursor-pointer"
        id="soal_jenis"
        name="soal_jenis"
        value={value}
        onChange={handleChange}
        disabled={disabled}
      >
        <option className="bg-white fw-bold text-black" value="pg">
          {t("pilihan_ganda")}
        </option>
        <option className="bg-white fw-bold text-black" value="polling">
          {t("pemilihan")}
        </option>
        <option className="bg-white fw-bold text-black" value="essay">
          {t("isian_uraian")}
        </option>
        <option className="bg-white fw-bold text-black" value="true_false">
          {t("benar_salah")}
        </option>
        <option className="bg-white fw-bold text-black" value="menjodohkan">
          {t("menjodohkan")}
        </option>
        <option className="bg-white fw-bold text-black" value="pernyataan">
          {t("pernyataan")}
        </option>
        <option className="bg-white fw-bold text-black" value="cerita">
          {t("cerita")}
        </option>
      </select>
      <label htmlFor="type" className="m-0">
        <i className="ri-arrow-down-s-line ms-2 font-size-18 text-white"></i>
      </label>
    </div>
  );
}

export default InputJenisSoal;
