import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { PageLoader } from "../../assets/images";
import { KelasCard, Wrapper } from "../../components";
import { getAnak, setAnak } from "../../redux/actions/anakAction";
import {
  getKelas,
  getSekolah,
  getSemester,
  getTahunPelajaran,
} from "../../redux/actions/appAction";
import { getPelajaran } from "../../redux/actions/pelajaranAction";
import { checkIsGuru, checkIsOrtu, checkIsSiswa } from "../../utils/roleUser";

function Kelas() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { kelas } = useSelector(({ app }) => app);
  const { user } = useSelector(({ user }) => user);
  const { dataAnak, selectedAnak } = useSelector(({ anak }) => anak);
  const [semester, setSemester] = useState([]);
  const [tahunPelajaran, setTahunPelajaran] = useState([]);
  const [sekolah, setSekolah] = useState([]);
  const { pelajaran, loading } = useSelector(({ pelajaran }) => pelajaran);
  const dispatch = useDispatch();
  const [params, setParams] = useState({
    kelasdetail_id: "",
    sekolah_id: "",
    semester_id: "",
    tahunpelajaran_id: "",
  });
  useEffect(() => {
    if (!checkIsOrtu(user?.user_roleid)) {
      getSekolah().then((res) => setSekolah(res));
    } else {
      dispatch(getAnak()).then((res) => {
        const check = res?.value?.filter((item) => item?.user_id === selectedAnak?.user_id);
        if (
          (selectedAnak === null || selectedAnak === undefined) &&
          check?.length <= 0 &&
          res?.value
        ) {
          dispatch(setAnak(res?.value[0]));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user.user_roleid]);

  useEffect(() => {
    let params = {
      ...location?.state,
    };
    if (checkIsOrtu(user?.user_roleid)) {
      params.user_id = selectedAnak?.user_id || "";
    }
    dispatch(getPelajaran(params));
    setParams(location?.state);
  }, [dispatch, location?.state, selectedAnak, user]);

  useEffect(() => {
    if (!checkIsOrtu(user?.user_roleid)) {
      dispatch(getKelas());
    } else if (selectedAnak) {
      dispatch(getKelas({ user_id: selectedAnak?.user_id ?? "" }));
    }
  }, [dispatch, selectedAnak, user?.user_roleid]);

  useEffect(() => {
    setSemester([]);
    function getData() {
      let data = {
        kelasdetail_id: params?.kelasdetail_id,
        sekolah_id: params?.sekolah_id,
      };
      if (checkIsOrtu(user?.user_roleid)) data.user_id = selectedAnak?.user_id;
      getSemester(data).then((res) => setSemester(res));
    }
    if (params?.kelasdetail_id) getData();
  }, [
    params?.kelasdetail_id,
    selectedAnak?.user_id,
    user?.user_roleid,
    params?.sekolah_id,
  ]);

  useEffect(() => {
    setTahunPelajaran([]);
    function getData() {
      getTahunPelajaran(params.sekolah_id).then((res) =>
        setTahunPelajaran(res)
      );
    }
    if (params?.sekolah_id) getData();
  }, [params?.sekolah_id]);

  const handleChangeAnak = (e) => {
    let val = e?.target?.value;
    let anak = dataAnak?.filter(
      (item) => parseInt(item.user_id) === parseInt(val)
    );
    if (anak) return dispatch(setAnak(anak[0]));
    dispatch(setAnak(null));
  };
  return (
    <Wrapper title={t("kelas")}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="iq-card">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title d-flex align-items-center justify-content-between w-100">
                  <h4 className="card-title">{t("kelas_saya")}</h4>
                  <div className="btn-group">
                    <Link
                      to="/"
                      className="btn iq-bg-light iq-bg-primary-hover text-dark border"
                    >
                      <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
                    </Link>
                    <Link to="/kalender" className="btn btn-warning text-white">
                      <i className="ri-calendar-2-line"></i> {t("kalender")}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="iq-card-body">
                <form className="form-horizontal">
                  <div className="row">
                    {checkIsOrtu(user?.user_roleid) ? (
                      <div className="col-lg-4">
                        <label htmlFor="anak" className="control-label">
                          {t("anak")} :
                        </label>
                        <select
                          onChange={handleChangeAnak}
                          id="anak"
                          className="form-control form-select"
                          value={selectedAnak?.user_id}
                        >
                          <option value="">{t('pilih_params', {params: t('anak')})}</option>
                          {dataAnak?.map((item) => (
                            <option key={item.user_id} value={item.user_id}>
                              {item.user_nama}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : null}
                    {checkIsGuru(user?.user_roleid) ? (
                      <div className="col-lg-3">
                        <label htmlFor="sekolah" className="control-label">
                          {t("sekolah")} :
                        </label>
                        <select
                          value={params?.sekolah_id}
                          onChange={({ target: { value } }) => {
                            navigate("/kelas", {
                              replace: true,
                              state: {
                                ...params,
                                sekolah_id: value,
                              },
                            });
                          }}
                          id="sekolah"
                          className="form-control form-select"
                        >
                          <option value="">
                            --{t("pilih_params", { params: t("sekolah") })}--
                          </option>
                          {sekolah?.map((item) => (
                            <option
                              value={item.sekolah_id}
                              key={item.sekolah_id}
                            >
                              {item.sekolah_nama}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : null}
                    {checkIsGuru(user?.user_roleid) ? (
                      <div className="col-lg-3">
                        <label
                          htmlFor="tahun_pelajaran"
                          className="control-label"
                        >
                          {t("tahun_pelajaran")} :
                        </label>
                        <select
                          value={params?.tahunpelajaran_id}
                          onChange={({ target: { value } }) => {
                            navigate("/kelas", {
                              replace: true,
                              state: {
                                ...params,
                                tahunpelajaran_id: value,
                              },
                            });
                          }}
                          id="tahun_pelajaran"
                          className="form-control form-select"
                        >
                          <option value="">
                            --
                            {t("pilih_params", {
                              params: t("tahun_pelajaran"),
                            })}
                            --
                          </option>
                          {tahunPelajaran?.map((item) => (
                            <option
                              value={item.tahunpelajaran_id}
                              key={item.tahunpelajaran_id}
                            >
                              {item.tahunpelajaran_nama}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : null}
                    <div
                      className={
                        checkIsSiswa(user?.user_roleid)
                          ? "col-lg-6"
                          : checkIsOrtu(user?.user_roleid)
                          ? "col-lg-4"
                          : "col-lg-3"
                      }
                    >
                      <label htmlFor="kelas" className="control-label">
                        {t("kelas")} :
                      </label>
                      <select
                        value={params?.kelasdetail_id}
                        onChange={({ target: { value } }) => {
                          navigate("/kelas", {
                            replace: true,
                            state: {
                              ...params,
                              kelasdetail_id: value,
                            },
                          });
                        }}
                        className="form-control form-select"
                        id="kelas"
                      >
                        <option value="">
                          --{t("pilih_params", { params: t("kelas") })}--
                        </option>
                        {kelas?.map((item) => (
                          <option
                            value={item.kelasdetail_id}
                            key={item.kelasdetail_id}
                          >
                            {item.kelasdetail_nama}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div
                      className={
                        checkIsSiswa(user?.user_roleid)
                          ? "col-lg-6"
                          : checkIsOrtu(user?.user_roleid)
                          ? "col-lg-4"
                          : "col-lg-3"
                      }
                    >
                      <label htmlFor="semester" className="control-label">
                        Semester :
                      </label>
                      <select
                        value={params?.semester_id}
                        onChange={({ target: { value } }) => {
                          navigate("/kelas", {
                            replace: true,
                            state: {
                              ...params,
                              semester_id: value,
                            },
                          });
                        }}
                        className="form-control form-select"
                      >
                        <option value="">
                          --{t("pilih_params", { params: "Semester" })}--
                        </option>
                        {semester?.map((item) => (
                          <option
                            value={item.semester_id}
                            key={item.semester_id}
                          >
                            Semester {item.semester_nama}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {loading ? (
            <div className="col-sm-12 text-center">
              <img src={PageLoader} alt="loader" style={{ height: "100px" }} />
            </div>
          ) : (
            pelajaran?.map((item, i) => (
              <KelasCard
                key={i}
                guru={item?.pengajar?.map((item) => item?.user?.user_nama)}
                img={item.kelasmapel_avatar}
                kelas={item?.mapel?.mapel_nama}
                link={`/pelajaran/${item.kelasmapel_id}?th=${
                  params?.tahunpelajaran_id || ""
                }&smt=${params?.semester_id || ""}`}
                member={item.list_siswa.slice(0, 4)}
                pertemuan={item.pertemuan}
                siswa={item.jumlah_siswa}
                thumbnail={item.kelasmapel_image}
                tugas={item.tugas}
                namaKelas={item.detailKelas?.kelasdetail_nama}
                semester={item.semester_nama}
              />
            ))
          )}
        </div>
      </div>
    </Wrapper>
  );
}

export default Kelas;
