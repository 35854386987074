import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PageLoader } from "../../assets/images";

moment.locale("id");
/**
 * Renders a card with task assessment details.
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the task assessment.
 * @param {boolean} props.detail - Indicates whether to show detailed information or not.
 * @param {string} props.tugasId - The ID of the task.
 * @param {string} props.startDate - The start date of the task assessment.
 * @param {string} props.endDate - The end date of the task assessment.
 * @param {boolean} props.submit - Indicates whether the assessment can be submitted or not.
 * @param {string} props.merdekaKode - The code for the target learning.
 * @param {string} props.merdekaTarget - The description of the target learning.
 * @param {string} props.catatan - The notes for the assessment.
 * @param {function} props.handleCatatan - A callback function to handle editing the notes.
 * @param {function} props.handleSubmit - A callback function to handle submitting the assessment.
 * @returns {JSX.Element} The rendered component.
 */
const InfoPenilaianTugas = ({
  title,
  detail,
  tugasId,
  startDate,
  endDate,
  submit,
  merdekaKode,
  merdekaTarget,
  catatan,
  handleCatatan,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  const formattedStartDate = startDate
    ? moment(startDate).format("DD MMMM YYYY (HH:mm:00)")
    : "";
  const formattedEndDate = endDate
    ? moment(endDate).format("DD MMMM YYYY (HH:mm:00)")
    : "";

  const handleEditNotes = () => {
    handleCatatan();
  };

  const handleAssessmentSubmit = () => {
    handleSubmit();
  };

  return (
    <div className="col-lg-12">
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title d-flex">
            <h4 className="card-title">
              {t("koreksi")} ({title})
            </h4>
          </div>
          <div className="btn-group d-none d-sm-block" role="group">
            <Link
              to={`/nilai/${tugasId}`}
              className="btn iq-bg-light iq-bg-primary-hover text-dark border"
            >
              <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
            </Link>
            <button
              onClick={handleEditNotes}
              className="btn btn-primary text-center"
            >
              <i className="ri-edit-line m-0"></i> {t("catatan")}
            </button>
            {merdekaTarget ? (
              <button
                onClick={handleAssessmentSubmit}
                className="btn btn-warning text-center text-white"
                disabled={submit}
              >
                <i className="ri-edit-line m-0"></i>{" "}
                {submit ? (
                  <img src={PageLoader} alt="loader" style={{ width: 15 }} />
                ) : (
                  t("kirim_nilai")
                )}
              </button>
            ) : null}
          </div>
        </div>
        <div className="col-lg-12 p-0">
          <div className="iq-card">
            <div className="iq-card-body">
              <div className="table-responsive table-hover">
                <table id="datatable" className="table table-bordered">
                  <tbody>
                    <tr>
                      <td width="30%">
                        <b>{t("mulai_selesai")}</b>
                      </td>
                      <td width="70%">
                        {formattedStartDate} - {formattedEndDate}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>{t("target_pembelajaran")}</b>
                      </td>
                      <td>{`${merdekaKode || ""} - ${merdekaTarget || ""}`}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>{t("catatan")}</b>
                      </td>
                      <td>{catatan}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoPenilaianTugas;
