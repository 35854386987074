import React from "react";
import { useTranslation } from "react-i18next";

function ItemSummaryPlaylist({ title, time, handlePlay, lock }) {
  const { t } = useTranslation();
  return (
    <button
      onClick={handlePlay}
      className={`btn ${
        lock ? "btn-secondary" : "bg-primary"
      } rounded-pill d-flex w-100 align-items-center gap-2 py-3`}
    >
      <i
        className={`${
          lock ? "ri-lock-line" : "ri-play-circle-line"
        } font-size-20 text-white`}
      ></i>
      <h6 className="font-size-14 text-truncate text-white flex-1 text-start">
        {title}
      </h6>
      {time ? (
        <h6 className="font-size-14 flex-shrink-0 text-white">
          {time} {t("menit")}
        </h6>
      ) : null}
    </button>
  );
}

export default ItemSummaryPlaylist;
