import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import { Logo1 } from "../../assets/images";
import ExamColaboration from "./ExamColaboration";

moment.locale("id");
const InfoUjian = ({
  status,
  mapel,
  jmlSiswa,
  points,
  jmlSoal,
  kolaborasi,
  endDate,
  waktuMengerjakan,
  creatorAvatar,
  creatorName,
  updatedAt,
  setShowBobotSoal,
  setShowModalUjian,
  setPreviewSoal,
  handlePublish,
}) => {
  const { t } = useTranslation();
  return (
    <div className="iq-card">
      <div className="iq-card-body">
        <div className="d-flex">
          <div className="me-3">
            <img
              src={Logo1}
              className="img-fluid rounded p-2 iq-bg-dark"
              width="120"
              height="120"
              alt="logo"
            />
          </div>
          <div className="w-100">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center gap-8">
                <span>{t("ujian")}</span>
                {status === "publish" && (
                  <span className="badge badge-pill badge-primary">
                    {t("soal_dipublish")}
                  </span>
                )}
              </div>
              <div className="btn-group" role="group">
                <Link
                  to="/ujian"
                  className="btn btn-sm iq-bg-light iq-bg-primary-hover text-dark d-flex align-items-center gap-8 border py-2"
                >
                  <i className="ri-arrow-left-line m-0"></i>
                  <span className="d-none d-md-block">{t("kembali")}</span>
                </Link>
                {status !== "publish" && (
                  <button
                    onClick={handlePublish}
                    className="btn btn-sm iq-bg-light iq-bg-primary-hover text-dark d-flex align-items-center gap-8 border py-2"
                  >
                    <i className="ri-upload-line"></i>
                    <span className="d-none d-md-block">
                      {t("publish_ujian")}
                    </span>
                  </button>
                )}
              </div>
            </div>
            <h4 className="fw-bold mb-1">
              {t("soal_ujian")} {mapel ?? "-"}
            </h4>
            <div className="row">
              <div className="col-12 col-md-3">
                <i className="ri-user-line"></i> {jmlSiswa || 0} {t("siswa")}
              </div>
              <div className="col-12 col-md-3">
                <i className="ri-flag-2-line"></i> {points} point
              </div>
              <div className="col-12 col-md-6 d-flex gap-2 flex-wrap align-items-center">
                <i className="ri-function-line"></i> {t("kolaborasi")} :{" "}
                <ExamColaboration data={kolaborasi} />
              </div>
              <div className="col-12 col-md-3">
                <i className="ri-timer-line"></i> {t("due")}{" "}
                {endDate ? moment(endDate).format("DD MMM (HH:mm)") : ""}
              </div>
              <div className="col-12 col-md-3">
                <i className="ri-list-check "></i> {jmlSoal ?? 0} {t("soal")}
              </div>
              <div className="col-12 col-md-6">
                <i className="ri-todo-line"></i> {t("waktu_mengerjakan")}{" "}
                {t(waktuMengerjakan)}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 d-flex justify-content-between flex-column flex-md-row">
          {creatorName && updatedAt ? (
            <div className="group-member d-flex align-items-center">
              <div className="iq-media-group">
                <img
                  className="img-fluid avatar-40 rounded-circle"
                  src={creatorAvatar}
                  alt={creatorName}
                />
              </div>
              &nbsp;
              <div className="info">
                <h6>{creatorName} </h6>
                <span className="mb-0 font-size-12">
                  {updatedAt ? moment(updatedAt).format("DD MMMM YYYY") : ""}
                </span>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div>
            <div className="btn-group mt-3" role="group">
              <button
                onClick={() => setShowBobotSoal(true)}
                className="btn btn-sm iq-bg-secondary iq-bg-primary-hover text-dark align-self-end fw-bolder"
              >
                <i className="ri-percent-line"></i> {t("bobot")}
              </button>
              <button
                onClick={() => setPreviewSoal(true)}
                className="btn btn-sm iq-bg-secondary iq-bg-primary-hover text-dark align-self-end fw-bolder"
              >
                <i className="ri-computer-line m-0"></i> {t("preview")}
              </button>
              {status !== "publish" && (
                <button
                  onClick={() => setShowModalUjian(true)}
                  className="btn btn-sm iq-bg-secondary iq-bg-primary-hover text-dark align-self-end fw-bolder bg-transparent"
                >
                  <i className="ri-edit-line m-0"></i> Edit
                </button>
              )}
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoUjian;
