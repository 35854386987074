import { API, setAuthToken } from '../../config/api';
import { GET_DRIVE, GET_DRIVE_DETAIL } from '../constant';

export const getDrive = () => {
  return {
    type: GET_DRIVE,
    payload: async () => {
      const token = localStorage.getItem("token");
      setAuthToken(token);
      let res = await API.get("drive").catch((err) => {
        return Promise.reject(err);
      });
      return res?.data?.data;
    },
  };
};
export const getDriveDetail = (id) => {
  return {
    type: GET_DRIVE_DETAIL,
    payload: async () => {
      const token = localStorage.getItem("token");
      setAuthToken(token);
      let res = await API.get(`drive/folder/file?drive_folder_id=${id}`).catch(
        (err) => {
          return Promise.reject(err);
        }
      );
      return res?.data?.data;
    },
  };
};

export const addFolderDrive = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("drive/folder", params);
};

export const updFolderDrive = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("drive/folder/update", params);
};

export const delFolderDrive = (id) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.delete(`drive/folder?drive_folder_id=${id}`);
};

export const addFileDrive = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("drive/file", params);
};

export const updFileDrive = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("drive/file/update", params);
};

export const delFileDrive = (id) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.delete(`drive/file?drive_file_id=${id}`);
};
