import {
  GET_LIBRARY,
  LIKE_LIBRARY,
  LOGOUT,
  RESET_LIBRARY,
  VIEW_LIBRARY,
} from "../constant";

const initState = {
  book: null,
};

const libraryReducer = (state = initState, action) => {
  switch (action?.type) {
    case GET_LIBRARY + "_PENDING":
      return state;
    case GET_LIBRARY + "_FULFILLED":
      const lastBook = state.book?.data || [];
      let newBookData = action.payload;
      if (action?.payload?.current_page !== 1) {
        newBookData.data = [...lastBook, ...action.payload.data];
      }
      return {
        ...state,
        book: newBookData,
      };
    case LIKE_LIBRARY:
      let updatedBookData = state.book;
      const userLike = action.payload.hasOwnProperty("deleted_at")
        ? action.payload.deleted_at === null
        : true;
      updatedBookData.data = state.book.data.map((book) => ({
        ...book,
        user_like: book.id === action.payload.book ? userLike : book.user_like,
      }));
      return {
        ...state,
        book: updatedBookData,
      };
    case VIEW_LIBRARY:
      let bookData = state.book;
      bookData.data = state.book.data.map((book) => ({
        ...book,
        book_read_count:
          book?.id === action.payload.book
            ? book?.book_read_count + 1
            : book?.book_read_count,
      }));
      return {
        ...state,
        book: bookData,
      };
    case GET_LIBRARY + "_REJECTED":
      return state;
    case RESET_LIBRARY:
      return {
        ...state,
        book: null,
      };
    case LOGOUT:
      return {
        book: [],
      };
    default: {
      return state;
    }
  }
};

export default libraryReducer;
