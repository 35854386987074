import React from "react";

function Loading({ message }) {
  return (
    <div id="loading">
      <div id="loading-center"></div>
      {message ? (
        <div className="position-absolute d-flex w-100 h-100 align-items-center top-0 justify-content-center pt-5">
          <p className="m-0 mt-5">{message}</p>
        </div>
      ) : null}
    </div>
  );
}

export default Loading;
