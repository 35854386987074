import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

moment.locale("id");
const InfoPenilaianUjian = ({
  mapel,
  className,
  handleSubmit,
  startDate,
  endDate,
  duration,
  question,
  desc,
  submit,
  jenisUjian,
}) => {
  const { t } = useTranslation();
  return (
    <div className="col-lg-12">
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title d-flex gap-2">
            <h4 className="card-title">
              {t("nama_ujian", {
                mapel: `${jenisUjian?.toUpperCase()} ${mapel}`,
              })}
            </h4>
            <span className="badge badge-pill badge-info align-self-center"></span>
          </div>
          <div className="btn-group" role="group">
            <Link
              to="/ujian"
              className="btn btn-sm iq-bg-light iq-bg-primary-hover text-dark d-flex align-items-center gap-8 border py-2"
            >
              <i className="ri-arrow-left-line m-0"></i>
              <span className="d-none d-md-block">{t("kembali")}</span>
            </Link>
            <button
              disabled={submit}
              onClick={handleSubmit}
              className="btn btn-sm btn-warning text-white d-flex align-items-center gap-8 border border-warning py-2"
            >
              <i className="ri-upload-line"></i>
              <span className="d-none d-md-block">{t("kirim_nilai")}</span>
            </button>
          </div>
        </div>
        <div className="col-lg-12 p-0">
          <div className="iq-card">
            <div className="iq-card-body">
              <div className="table-responsive table-hover">
                <table id="datatable" className="table table-bordered">
                  <tbody>
                    <tr>
                      <td width="30%">
                        <b>{t("kelas")}</b>
                      </td>
                      <td width="70%">{className}</td>
                    </tr>
                    <tr>
                      <td width="30%">
                        <b>{t("mulai_selesai")}</b>
                      </td>
                      <td width="70%">
                        {startDate
                          ? moment(startDate).format("DD MMM YYYY (HH:mm)")
                          : "--:--"}{" "}
                        -{" "}
                        {endDate
                          ? moment(endDate).format("DD MMM YYYY (HH:mm)")
                          : "--:--"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>{t("durasi")}</b>
                      </td>
                      <td>
                        {duration} {t("menit")} ({`${question} ${t("soal")}`})
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b className="text-capitalize">{t("deskripsi")}</b>
                      </td>
                      <td>
                        <div dangerouslySetInnerHTML={{ __html: desc }}></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoPenilaianUjian;
