import React from "react";
import { useTranslation } from "react-i18next";

const HeaderLibrary = ({
  pinjam,
  kembali,
  favorite,
  baca,
  handleSelectFilter,
  selected,
}) => {
  const { t } = useTranslation();
  return (
    <div className="iq-card">
      <div className="iq-card-header d-flex justify-content-between">
        <div className="iq-header-title d-flex align-items-center justify-content-between w-100">
          <h4 className="card-title">{t("perpustakaan")}</h4>
          <button className="btn iq-bg-light iq-bg-primary-hover text-dark border">
            <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
          </button>
        </div>
      </div>
      <div className="iq-card-body">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-3">
            <button
              onClick={() => handleSelectFilter("sedang_dipinjam")}
              className={`${
                selected === "sedang_dipinjam" ? "selected" : ""
              } library-sort iq-card d-block w-100`}
            >
              <div className="iq-card-body align-items-center gap-2 d-flex justify-content-between">
                <div className="flex-1 d-flex align-items-center gap-2 justify-content-start">
                  <span className="badge badge-pill badge-warning font-weight-normal">
                    <i className="ri-git-repository-commits-fill fa-1-5x"></i>
                  </span>
                  {pinjam} {t("sedang_dipinjam")}
                </div>
                {selected === "sedang_dipinjam" && (
                  <div className="btn-close-filter-library">
                    <i className="ri-close-fill"></i>
                  </div>
                )}
              </div>
            </button>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <button
              onClick={() => handleSelectFilter("telah_dipinjam")}
              className={`${
                selected === "telah_dipinjam" ? "selected" : ""
              } library-sort iq-card d-block w-100`}
            >
              <div className="iq-card-body align-items-center gap-2 d-flex">
                <div className="flex-1 d-flex align-items-center gap-2 justify-content-start">
                  <span className="badge badge-pill badge-primary font-weight-normal">
                    <i className="ri-checkbox-circle-fill fa-1-5x"></i>
                  </span>
                  {kembali} {t("telah_dipinjam")}
                </div>
                {selected === "telah_dipinjam" && (
                  <div className="btn-close-filter-library">
                    <i className="ri-close-fill"></i>
                  </div>
                )}
              </div>
            </button>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <button
              onClick={() => handleSelectFilter("favorite")}
              className={`${
                selected === "favorite" ? "selected" : ""
              } library-sort iq-card d-block w-100`}
            >
              <div className="iq-card-body align-items-center gap-2 d-flex">
                <div className="flex-1 d-flex align-items-center gap-2 justify-content-start">
                  <span className="badge badge-pill badge-danger font-weight-normal">
                    <i className="ri-heart-fill fa-1-5x"></i>
                  </span>
                  {favorite} {t("favorite")}
                </div>
                {selected === "favorite" && (
                  <div className="btn-close-filter-library">
                    <i className="ri-close-fill"></i>
                  </div>
                )}
              </div>
            </button>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <button
              onClick={() => handleSelectFilter("dibaca")}
              className={`${
                selected === "dibaca" ? "selected" : ""
              } library-sort iq-card d-block w-100`}
            >
              <div className="iq-card-body align-items-center gap-2 d-flex">
                <div className="flex-1 d-flex align-items-center gap-2 justify-content-start">
                  <span className="badge badge-pill badge-info font-weight-normal">
                    <i className="ri-book-read-fill fa-1-5x"></i>
                  </span>
                  {baca} {t("dibaca")}
                </div>
                {selected === "dibaca" && (
                  <div className="btn-close-filter-library">
                    <i className="ri-close-fill"></i>
                  </div>
                )}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderLibrary;
