import React from "react";
import { useTranslation } from "react-i18next";

function InputPoint({ value, handleChange, disabled, bordered, maxPoint }) {
  let POINT = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  if (maxPoint && maxPoint < POINT.length) {
    POINT = POINT.slice(0, maxPoint + 1);
  }
  const { t } = useTranslation();
  if (disabled && bordered) {
    return (
      <div className="d-flex border rounded align-items-center gap-1 px-2">
        <i className="ri-flag-2-fill text-dark font-size-18 "></i>
        <span className="text-dark fw-bold font-size-14">
          {value} {t("point")}
        </span>
      </div>
    );
  }
  if (bordered) {
    return (
      <div className="d-flex border rounded">
        <i className="ri-flag-2-fill ms-1 text-dark font-size-18 "></i>
        <select
          className="form-control form-control-sm bg-transparent border-0 font-size-14 fw-bold me-0 form-select text-black"
          value={value}
          onChange={handleChange}
          disabled={disabled}
          name="soal_points"
        >
          {POINT.map((item) => (
            <option
              className="bg-white fw-bold text-black"
              value={item}
              key={item}
            >
              {item === 0
                ? `${t("tdk_dinilai")} (0 ${t("poin")})`
                : `${item} ${t("poin")}`}
            </option>
          ))}
        </select>
      </div>
    );
  }
  return (
    <div
      className="iq-card iq-card-body p-1 d-flex align-items-center"
      style={{ backgroundColor: "rgb(38 38 38)" }}
    >
      <i className="ri-flag-2-fill ms-2 font-size-18 text-white"></i>
      <select
        className="form-control form-control-sm bg-transparent text-white border-0 font-size-14 fw-bold cursor-pointer"
        id="soal_points"
        name="soal_points"
        value={value}
        onChange={handleChange}
        disabled={disabled}
      >
        {POINT.map((item) => (
          <option
            className="bg-white fw-bold text-black"
            value={item}
            key={item}
          >
            {item === 0
              ? `${t("tdk_dinilai")} (0 ${t("poin")})`
              : `${item} ${t("poin")}`}
          </option>
        ))}
      </select>
      <label htmlFor="point" className="m-0">
        <i className="ri-arrow-down-s-line ms-2 font-size-18 text-white"></i>
      </label>
    </div>
  );
}

export default InputPoint;
