import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

import { EXCEL_EXT, MS_EXT, PPT_EXT } from "../../config/fileType";
import { PageLoader } from "../../assets/images";
import { Document, Page } from "react-pdf";

function ModalFile({ url, show, type, handleClose }) {
  const { t } = useTranslation();
  const [randomId, setRandomId] = useState(Math.random());
  const [fileLoaded, setFileLoaded] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [docScale, setDocScale] = useState(1);
  useEffect(() => {
    setFileLoaded(false);
  }, [url]);
  const onLoadSuccess = ({ numPages }) => {
    setTotalPage(numPages);
    setFileLoaded(true);
  };
  const handleZoomIn = () => {
    setDocScale(docScale + 0.1);
  };
  const handleZoomOut = () => {
    setDocScale(docScale - 0.1);
  };
  return (
    <div className="col-lg-12">
      <Modal
        size="lg"
        fullscreen
        show={show}
        onHide={handleClose}
        id="ModalFile"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title">{t("preview")}</h5>
          <div className="btn-group ms-auto">
            <button
              className="btn btn-secondary d-flex align-items-center justify-content-center"
              onClick={() => {
                setRandomId(Math.random());
                setFileLoaded(false);
              }}
            >
              <i className="fa fa-refresh"></i> Reload
            </button>
            <a
              className="btn btn-secondary d-flex align-items-center justify-content-center"
              href={url}
              download
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-download"></i>
            </a>
            {type === "application/pdf" && (
              <>
                <button className="btn btn-secondary" onClick={handleZoomIn}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#ffffff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-zoom-in"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                    <path d="M7 10l6 0" />
                    <path d="M10 7l0 6" />
                    <path d="M21 21l-6 -6" />
                  </svg>
                </button>
                <button className="btn btn-secondary" onClick={handleZoomOut}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#ffffff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-zoom-out"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                    <path d="M7 10l6 0" />
                    <path d="M21 21l-6 -6" />
                  </svg>
                </button>
              </>
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          {type === "application/pdf" ? (
            <div
              key={randomId}
              className="d-flex flex-column align-items-center justify-content-center bg-secondary"
            >
              <Document
                file={`${process.env.REACT_APP_BACKEND_API_URL_2}/pdf?pdf=${url}&type=aws`}
                className={`d-flex flex-column align-items-center justify-content-center w-100`}
                onLoadSuccess={onLoadSuccess}
              >
                {Array.from(new Array(totalPage), (el, index) => (
                  <Page
                    pageNumber={index + 1}
                    key={`page_${index + 1}`}
                    scale={docScale}
                  />
                ))}
              </Document>
            </div>
          ) : PPT_EXT.includes(type) ||
            MS_EXT.includes(type) ||
            EXCEL_EXT.includes(type) ? (
            <div className="embed-responsive embed-responsive-ppt">
              <iframe
                key={randomId}
                className="embed-responsive-item ppt bg-grey"
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
                allowFullScreen
                title="materi video"
                onLoad={() => setFileLoaded(true)}
              />
            </div>
          ) : (
            <img
              src={url}
              alt="preview file"
              className="w-100 h-100 contain-image bg-grey"
              onLoad={() => setFileLoaded(true)}
            />
          )}
          {!fileLoaded && (
            <div className="position-absolute top-0 start-0 w-100 h-100 bg-gray d-flex text-center align-items-center bg-overlay">
              <img
                src={PageLoader}
                alt="loader"
                style={{ height: "55px" }}
                className="mx-auto"
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalFile;
