import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import { PageLoader } from "../../assets/images";
import {
  addBankSoal,
  getListKelasBS,
  getMapelBS,
  getTahunPelajaranBS,
  updBankSoal,
} from "../../redux/actions/bankSoalAction";
import { InputEditor } from "../input";

function TambahBankSoal({ show, handleClose, editSoal }) {
  const { t } = useTranslation();
  const editorRef = useRef(null);
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(null);
  const [optionTh, setOptionTh] = useState([]);
  const [optionKelas, setOptionKelas] = useState([]);
  const [optionMapel, setOptionMapel] = useState([]);
  const [params, setParams] = useState({
    tahun_pelajaran_id: "",
    mapel_id: "",
    kelasdetail_id: "",
    bk_master_nama: "",
    bk_master_deskripsi: "",
    bk_master_id: "",
  });
  useEffect(() => {
    if (editSoal) {
      setParams((pref) => ({
        ...pref,
        tahun_pelajaran_id: editSoal?.tahun_pelajaran_id,
        mapel_id: editSoal?.mapel_id,
        kelasdetail_id: editSoal?.kelas_id,
        bk_master_nama: editSoal?.bk_master_nama,
        bk_master_deskripsi: editSoal?.bk_master_deskripsi,
        bk_master_id: editSoal?.bk_master_id,
      }));
    } else {
      setParams({
        tahun_pelajaran_id: "",
        mapel_id: "",
        kelasdetail_id: "",
        bk_master_nama: "",
        bk_master_deskripsi: "",
        bk_master_id: "",
      });
    }
  }, [editSoal]);

  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };
  const handleChange = ({ target: { value, name } }) => {
    if (name === "kelasdetail_id") {
      setParams((pref) => ({
        ...pref,
        mapel_id: "",
      }));
    }
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    const data = {
      ...params,
      bk_master_deskripsi: editorRef.current.getContent(),
    };
    if (editSoal) {
      updBankSoal(data)
        .then(() => {
          showAlert(t("berhasil_simpan", { params: t("bank_soal") }), true);
          handleClose();
          setSubmit(false);
        })
        .catch((err) => {
          setError(err?.response?.data?.error);
          setSubmit(false);
        });
    } else {
      addBankSoal(data)
        .then(() => {
          showAlert(t("berhasil_simpan", { params: t("bank_soal") }), true);
          handleClose();
          setSubmit(false);
        })
        .catch((err) => {
          setError(err?.response?.data?.error);
          setSubmit(false);
        });
    }
  };
  useEffect(() => {
    getTahunPelajaranBS().then((res) => setOptionTh(res?.data?.data));
    getListKelasBS().then((res) => setOptionKelas(res?.data?.data));
  }, []);
  useEffect(() => {
    if (params.kelasdetail_id) {
      getMapelBS(params.kelasdetail_id).then((res) =>
        setOptionMapel(res?.data?.data)
      );
    } else {
      setOptionMapel([]);
    }
  }, [params.kelasdetail_id]);

  return (
    <div className="col-lg-12">
      <Modal
        enforceFocus={false}
        fullscreen={true}
        show={show}
        onHide={handleClose}
        id="tambahmateri"
        backdrop="static"
      >
        <Modal.Header>
          <div className="d-flex align-items-center">
            <button
              className="border-0 bg-white btn-close m-0 p-2 shadow-none"
              onClick={handleClose}
            ></button>
            <h4 className="modal-title">
              {params.bk_master_id ? "Edit" : t("tambah")} {t("bank_soal")}
            </h4>
          </div>
          <button
            type="submit"
            onClick={handleSubmit}
            className="btn btn-primary"
            disabled={submit}
          >
            {submit ? (
              <img src={PageLoader} alt="loader" style={{ width: 15 }} />
            ) : (
              t("simpan")
            )}
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 overflow-x-hidden">
          <div className="row h-100">
            <div className="col-lg-7 offset-md-1 pt-4">
              <div className="iq-card w-modal mb-4">
                <div className="iq-card-body">
                  <div className="w-100 mb-3">
                    <label htmlFor="bk_master_nama" className="col-12">
                      {t("judul")} <span className="text-danger">*</span> :
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="bk_master_nama"
                      value={params.bk_master_nama}
                      name="bk_master_nama"
                      placeholder={t("judul")}
                      onChange={handleChange}
                    />
                    {error?.title && (
                      <div className="d-block invalid-feedback">
                        {error?.title}
                      </div>
                    )}
                  </div>
                  <div className="w-100 mb-3">
                    <label htmlFor="bk_master_deskripsi">{t("desc")} :</label>
                    <InputEditor
                      editorRef={editorRef}
                      value={params.bk_master_deskripsi}
                    />
                    {error?.bk_master_deskripsi && (
                      <div className="d-block invalid-feedback">
                        {error?.bk_master_deskripsi}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 offset-md-1">
              <div className="iq-card h-100">
                <div className="iq-card-body h-100">
                  <div className="w-100 mb-3">
                    <label htmlFor="tahun_pelajaran_id">
                      {t("tahun_pelajaran")} :
                    </label>
                    <select
                      className="form-control form-select"
                      name="tahun_pelajaran_id"
                      id="tahun_pelajaran_id"
                      value={params.tahun_pelajaran_id}
                      onChange={handleChange}
                    >
                      <option value="">
                        {t("pilih_params", { params: t("tahun_pelajaran") })}
                      </option>
                      {optionTh?.map((item) => (
                        <option
                          value={item.tahunpelajaran_id}
                          key={item.tahunpelajaran_id}
                        >
                          {item.tahunpelajaran_nama}
                        </option>
                      ))}
                    </select>
                    {error?.tahun_pelajaran_id && (
                      <div className="d-block invalid-feedback">
                        {error?.tahun_pelajaran_id}
                      </div>
                    )}
                  </div>
                  <div className="w-100 mb-3">
                    <label htmlFor="kelasdetail_id">{t("kelas")} :</label>
                    <select
                      className="form-control form-select"
                      name="kelasdetail_id"
                      id="kelasdetail_id"
                      value={params.kelasdetail_id}
                      onChange={handleChange}
                    >
                      <option value="">
                        {t("pilih_params", { params: t("kelas") })}
                      </option>
                      {optionKelas?.map((item) => (
                        <option
                          value={item.kelasdetail_id}
                          key={item.kelasdetail_id}
                        >
                          {item.kelasdetail_nama}
                        </option>
                      ))}
                    </select>
                    {error?.kelasdetail_id && (
                      <div className="d-block invalid-feedback">
                        {error?.kelasdetail_id}
                      </div>
                    )}
                  </div>
                  <div className="w-100 mb-3">
                    <label htmlFor="mapel_id">{t("mata_pelajaran")} :</label>
                    <select
                      className="form-control form-select"
                      name="mapel_id"
                      id="mapel_id"
                      value={params.mapel_id}
                      onChange={handleChange}
                    >
                      <option value="">
                        {t("pilih_params", { params: t("mata_pelajaran") })}
                      </option>
                      {optionMapel?.map((item) => (
                        <option
                          value={item?.mapel?.mapel_id}
                          key={item?.mapel?.mapel_id}
                        >
                          {item?.mapel?.mapel_nama}
                        </option>
                      ))}
                    </select>
                    {error?.mapel_id && (
                      <div className="d-block invalid-feedback">
                        {error?.mapel_id}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default TambahBankSoal;
