import React from "react";
import { useTranslation } from "react-i18next";

import { generateRaporURL } from "../../utils/generateRaporURL";

function ButtonRaporP4({
  tahun,
  semester,
  kelas,
  kelas_detail,
  user_id,
  sekolah,
  status,
}) {
  const { t } = useTranslation();
  const URL = generateRaporURL(
    "p4",
    tahun,
    semester,
    kelas,
    kelas_detail,
    user_id,
    sekolah
  );
  if (status === "Tidak") {
    return (
      <button className="btn btn-primary btn-sm" disabled>
        <i className="ri-printer-line"></i>
        {t("rapor")}
      </button>
    );
  }
  return (
    <a
      href={URL}
      className="btn btn-primary btn-sm"
      target="_blank"
      rel="noreferrer"
    >
      <i className="ri-printer-line"></i>
      {t("rapor")}
    </a>
  );
}

export default ButtonRaporP4;
