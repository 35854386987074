const BASE_URL = "https://raport.baktimulya400.sch.id/v2/";
const slug = {
  identitas_merdeka: "laporan_merdeka/identitas_siswa/",
  mid_merdeka: "rapor/cetak_uts/",
  akhir_merdeka: "rapor/cetak/",
  mid_cambridge: "rapor/cetak_cambridge_mid/",
  akhir_cambridge: "rapor/cetak_cambridge/",
  p4: "laporan_p4/cetak/",
  porto_tk: "rapor/cetak_porto_tk/",
  akhir_tk: "rapor/cetak_tk/",
};

export const generateRaporURL = (
  slug_name,
  tahun,
  semester,
  kelas,
  kelas_detail,
  user_id,
  sekolah_id,
  encode
) => {
  const URL = BASE_URL + slug[slug_name];
  const data = `${tahun}-${semester}-${kelas}-${kelas_detail}-${user_id}-${sekolah_id}`;
  if (encode) return URL + encodeURI(btoa(data));
  return URL + data;
};
