import axios from "axios";
import { API, setAuthToken } from "../../config/api";
import { GET_TOPIK, SET_DETAIL_TOPIK, SET_TOPIK } from "../constant";

const ACCESS_TOKEN = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_BACKEND_API_URL_2;
const headers = {
  headers: {
    Authorization: ACCESS_TOKEN,
  },
};

export const getTopik = (id_mapel, params) => {
  return {
    type: GET_TOPIK,
    payload: async () => {
      const token = localStorage.getItem("token");
      setAuthToken(token);
      let res = await API.get(
        `kelas/matapelajaran/${id_mapel}/topik?${new URLSearchParams(
          params
        ).toString()}`
      ).catch((err) => {
        return Promise.reject(err);
      });
      return res?.data?.data;
    },
  };
};

export const setTopik = (topik) => {
  return {
    type: SET_TOPIK,
    payload: topik,
  };
};

export const addTopik = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("topik", params);
};

export const updTopik = (code, params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("topik/" + code, params);
};

export const updStatusTopik = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("topik/update-status", params);
};

export const delTopik = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.delete("topik/" + code);
};

export const delMultipleTopik = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("topik/delete_multi", params);
};

export const uploadFile = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("topik/upload-file", params);
};

export const getDetailTopik = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`topik/${code}`);
};

export const getListTopik = (code) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.get(`topik/list/${code}`);
};

export const sortTopik = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("topik/index", params);
};

export const sortDetailTopik = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post("topik/index_detail", params);
};

export const setDetailTopik = (params) => {
  return {
    type: SET_DETAIL_TOPIK,
    payload: params,
  };
};

export const getTopikOption = async (id_mapel, params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let res = await API.get(
    `kelas/matapelajaran/${id_mapel}/topik/dropdown?${new URLSearchParams(
      params
    ).toString()}`
  ).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data?.data;
};

export const sendShareTopik = (params) => {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  return API.post(`topik/share`, params);
};

export const convertTopik = async (params) => {
  let res = await axios
    .post(`${API_URL}/generate-word`, params, headers)
    .catch((err) => {
      return Promise.reject(err);
    });
  return res?.data;
};
