import "moment/locale/id";

import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

import {
  ContentPenilaian,
  InfoPenilaianUjian,
  SideContentPenilaian,
  Wrapper,
} from "../../components";
import {
  getDetailUjianNilai,
  getPengerjaanUjianSiswa,
  kirimNilaiRaportUjian,
  updPointsUjian,
} from "../../redux/actions/nilaiUjianAction";

moment.locale("id");

/**
 * The `PenilaianUjian` function is a React component that handles the assessment of an exam.
 * It displays information about the exam, allows the user to select a student, and shows the student's answers and scores.
 * The function also provides functionality to update the scores and submit the final grades.
 *
 * Example Usage:
 * <PenilaianUjian />
 *
 * @returns {JSX.Element} The rendered JSX elements representing the exam assessment interface.
 */
function PenilaianUjian() {
  const { t } = useTranslation();
  const { id, idKelas } = useParams();
  const [detail, setDetail] = useState(null);
  const [selectedSiswa, setSelectedSiswa] = useState(null);
  const [activeTab, setActiveTab] = useState("soal_essay");
  const [loading, setLoading] = useState(false);
  const [hasilPengerjaan, setHasilPengerjaan] = useState(null);
  const [submitKirimNilai, setSubmitKirimNilai] = useState(false);

  const getDetail = useCallback((id, idKelas) => {
    getDetailUjianNilai(id, idKelas).then((res) => setDetail(res?.data?.data));
  }, []);

  const getDataSoal = useCallback((id, userId) => {
    getPengerjaanUjianSiswa(id, userId)
      ?.then((res) => {
        setLoading(false);
        setHasilPengerjaan(res?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  }, []);

  useEffect(() => {
    getDetail(id, idKelas);
  }, [id, idKelas, getDetail]);

  useEffect(() => {
    setLoading(true);
    if (id && selectedSiswa) {
      getDataSoal(id, selectedSiswa?.user_id);
    } else {
      setHasilPengerjaan(null);
      setLoading(false);
    }
  }, [id, selectedSiswa, getDataSoal]);

  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };

  const handleChangePoint = (pengerjaan_soal_id, pengerjaan_points) => {
    updPointsUjian({ pengerjaan_soal_id, pengerjaan_points })
      .then(() => {
        getDataSoal(id, selectedSiswa?.user_id);
        getDetail(id, idKelas);
      })
      .catch((err) =>
        showAlert(t("failed_save_data", { data: t("poin") }), false)
      );
  };

  const handleKirimNilai = () => {
    setSubmitKirimNilai(true);
    kirimNilaiRaportUjian({ ujian_id: id })
      .then(() => {
        setSubmitKirimNilai(false);
        showAlert(t("data_berhasil_dikirim", { data: t("nilai") }), true);
      })
      .catch((error) => {
        let res = error?.response;
        if (res.status === 500) {
          showAlert(res?.data?.error, false);
        } else {
          showAlert(t("data_gagal_dikirim", { data: t("nilai") }), false);
        }
        setSubmitKirimNilai(false);
      });
  };

  const handleTab = (tab) => {
    setActiveTab(tab);
  };

  const handleSelectSiswa = (dataSiswa) => {
    setSelectedSiswa(dataSiswa);
  };

  return (
    <Wrapper title={t("ujian")}>
      <div className="container">
        <div className="row row-eq-height">
          <InfoPenilaianUjian
            className={detail?.kelasdetail_nama}
            desc={detail?.ujian_deskripsi}
            duration={
              detail?.ujian_jenis_waktu === "perujian"
                ? detail?.ujian_waktu_pengerjaan
                : ""
            }
            startDate={detail?.ujian_mulai}
            endDate={detail?.ujian_selesai}
            handleSubmit={handleKirimNilai}
            mapel={detail?.mapel_nama}
            question={detail?.jumlah_soal}
            submit={submitKirimNilai}
            jenisUjian={detail?.ujian_jenis}
          />
          <SideContentPenilaian
            handleSelect={handleSelectSiswa}
            jmlAnswer={
              detail?.siswas?.filter(
                (item) => item?.user?.pengerjaan_ujian !== null
              )?.length
            }
            jmlSiswa={detail?.siswas?.length}
            objectPengerjaan="pengerjaan_ujian"
            selectedSiswa={selectedSiswa?.user_id}
            siswa={detail?.siswas}
          />
          <ContentPenilaian
            activeTab={activeTab}
            handleChangePoint={handleChangePoint}
            handleTab={handleTab}
            hasilPengerjaan={hasilPengerjaan}
            loading={loading}
            selectedSiswa={selectedSiswa?.user_id}
            mulai={selectedSiswa?.mulai_mengerjakan}
            selesai={selectedSiswa?.selesai_mengerjakan}
          />
        </div>
      </div>
    </Wrapper>
  );
}

export default PenilaianUjian;
