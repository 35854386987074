import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

import { PageLoader } from '../../assets/images';
import { sortTopik } from '../../redux/actions/topikAction';

function SortTopik({ dataTopik, show, handleClose, kelasmapel_id }) {
  const { t } = useTranslation();
  const [submit, setSubmit] = useState(false);
  const [topik, setTopik] = useState([]);
  const handleSubmit = () => {
    setSubmit(true);
    sortTopik({
      kelasmapel_id,
      topik_list: topik,
    })
      .then(() => {
        setSubmit(false);
        handleClose();
      })
      .catch((err) => {
        setSubmit(false);
        handleClose();
      });
  };
  useEffect(() => {
    if (dataTopik.length > 0) {
      setTopik(
        dataTopik?.map((item, idx) => ({
          topik_id: item.topik_id,
          topic: item.topic,
          topik_index: idx + 1,
        }))
      );
    }
  }, [dataTopik]);

  const handleChange = (val, idx) => {
    let idxTopik = topik?.findIndex((item) => item.topik_id === idx);
    let dataTopik = topik;
    dataTopik[idxTopik].topik_index = parseInt(val);
    setTopik([...dataTopik]);
  };

  return (
    <div className="col-lg-12">
      <Modal
        size="md"
        show={show}
        onHide={() => {
          if (!submit) handleClose();
        }}
        id="SortTopik"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title">{t("urutan_topik")}</h5>
        </Modal.Header>
        <Modal.Body>
          <ul className="todo-task-lists m-0 p-0">
            <li className="d-flex align-items-center iq-bg-primary p-2">
              <div className="media-support-info">
                <h6 className="d-inline-block fw-bold">{t("list_topik")}</h6>
              </div>
              <div className="iq-card-header-toolbar d-flex align-items-center">
                <h6 className="d-inline-block fw-bold">{t("urutan")}</h6>
              </div>
            </li>
            {topik?.length <= 0 ? (
              <li className="text-center py-2">Data topik tidak ditemukan</li>
            ) : (
              topik?.map((item, i) => (
                <li
                  className="d-flex align-items-center p-2"
                  key={item.topik_id}
                >
                  <div>{i + 1}. </div>
                  <div className="media-support-info ms-3">
                    <h6 className="d-inline-block">{item.topic}</h6>
                  </div>
                  <input
                    type="number"
                    className="form-control w-25 float-right"
                    id="topik"
                    value={item.topik_index}
                    placeholder="0"
                    onChange={({ target: { value } }) =>
                      handleChange(value, item.topik_id)
                    }
                  />
                </li>
              ))
            )}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleClose}
          >
            {t("tutup")}
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={submit}
          >
            {submit ? (
              <img src={PageLoader} alt="loader" style={{ width: 15 }} />
            ) : (
              t("save_changes")
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SortTopik;
