import axios from "axios";
import {
  GET_COURSE,
  GET_COURSE_MARKED,
  GET_COURSE_PAYMENT,
  GET_COURSE_USER,
} from "../constant";
const ACCESS_TOKEN = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_BACKEND_API_URL_2;
const headers = {
  headers: {
    Authorization: ACCESS_TOKEN,
  },
};

export const getCourse = (params) => {
  return {
    type: GET_COURSE,
    payload: async () => {
      const url = `${API_URL}/course?${new URLSearchParams(params).toString()}`;
      let res = await axios.get(url, headers).catch((err) => {
        return Promise.reject(err);
      });
      return res?.data;
    },
  };
};

export const getCourseUser = (user_id, params) => {
  return {
    type: params?.status === "payment" ? GET_COURSE_PAYMENT : GET_COURSE_USER,
    payload: async () => {
      const url = `${API_URL}/course/user/${user_id}?${new URLSearchParams(
        params
      ).toString()}`;
      let res = await axios.get(url, headers).catch((err) => {
        return Promise.reject(err);
      });
      return res?.data;
    },
  };
};

export const getMoreCourse = async (params) => {
  const url = `${API_URL}/course?${new URLSearchParams(params).toString()}`;
  let res = await axios.get(url, headers).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data;
};

export const getCourseDetail = async (id, params) => {
  const data = new URLSearchParams(params).toString();
  const url = `${API_URL}/course/detail/${id}?${data}`;
  let res = await axios.get(url, headers).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data;
};

export const getCourseMateri = async (id) => {
  let res = await axios
    .get(`${API_URL}/course/materi/${id}`, headers)
    .catch((err) => {
      return Promise.reject(err);
    });
  return res?.data;
};

export const getCourseTopik = async (id, params) => {
  const data = new URLSearchParams(params).toString();
  const url = `${API_URL}/course/topik/${id}?${data}`;
  let res = await axios.get(url, headers).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data;
};

export const markedCourse = async (params) => {
  const url = `${API_URL}/course/marked`;
  let res = await axios.post(url, params, headers).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data?.data;
};

export const getCourseMarked = (params) => {
  return {
    type: GET_COURSE_MARKED,
    payload: async () => {
      const data = new URLSearchParams(params).toString();
      const url = `${API_URL}/course/marked?${data}`;
      let res = await axios.get(url, headers).catch((err) => {
        return Promise.reject(err);
      });
      return res?.data;
    },
  };
};

export const getCourseReview = async (id, params) => {
  const data = new URLSearchParams(params).toString();
  const url = `${API_URL}/course/review/${id}?${data}`;
  let res = await axios.get(url, headers).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data;
};

export const buyCourse = async (params) => {
  const url = `${API_URL}/course/buy`;
  let res = await axios.post(url, params, headers).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data?.data;
};

export const uploadDocCourse = async (params) => {
  const url = `${API_URL}/course/buy/upload_doc`;
  let res = await axios.post(url, params, headers).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data?.data;
};

export const getCourseCategory = async () => {
  const url = `${API_URL}/course/category`;
  let res = await axios.get(url, headers).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data;
};

export const getDetailMateriCourse = async (id) => {
  const url = `${API_URL}/course/materi/detail/${id}`;
  let res = await axios.get(url, headers).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data;
};

export const progressCourse = async (params) => {
  const url = `${API_URL}/course/progress`;
  let res = await axios.post(url, params, headers).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data?.data;
};

export const getDetailTugasCourse = async (id, params) => {
  const data = new URLSearchParams(params).toString();
  const url = `${API_URL}/course/tugas/detail/${id}?${data}`;
  let res = await axios.get(url, headers).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data;
};

export const mulaiTugasCourse = async (id, params) => {
  const data = new URLSearchParams(params).toString();
  const url = `${API_URL}/course/tugas/kerjakan/${id}?${data}`;
  let res = await axios.get(url, headers).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data;
};

export const submitJawbanTugasCourse = async (id, params) => {
  const url = `${API_URL}/course/tugas/jawab/${id}`;
  let res = await axios.post(url, params, headers).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data;
};

export const simpanTugasCourse = async (id, params) => {
  const url = `${API_URL}/course/tugas/simpan/${id}`;
  let res = await axios.post(url, params, headers).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data;
};

export const reviewCourse = async (params) => {
  const url = `${API_URL}/course/review`;
  let res = await axios.post(url, params, headers).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data;
};

export const getCertificateCourse = async (id, params) => {
  const data = new URLSearchParams(params).toString();
  const url = `${API_URL}/course/certificate/${id}?${data}`;
  let res = await axios.get(url, headers).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data;
};

export const kerjakanUlangCourse = async (params) => {
  const url = `${API_URL}/course/tugas/ulang`;
  let res = await axios.post(url, params, headers).catch((err) => {
    return Promise.reject(err);
  });
  return res?.data;
};
