import React from "react";
import { InputPilgan } from "../input";

const COLORS = [
  {
    bg: "bg-success",
    border: "border-success",
    shadow: "1f6e74",
  },
  {
    bg: "bg-primary",
    border: "border-primary",
    shadow: "1e6b28",
  },
  {
    bg: "bg-warning",
    border: "border-warning",
    shadow: "c9870f",
  },
  {
    bg: "bg-danger",
    border: "border-danger",
    shadow: "b31a00",
  },
  {
    bg: "bg-info",
    border: "border-info",
    shadow: "2a8fd7",
  },
];
function ItemSoalPilgan({
  handleChange,
  pilgan,
  handleChangeFile,
  handleDeleteFile,
  loadingUpload,
  preview,
  kerjakan,
  dipilih,
  handlePilih,
}) {
  return (
    <div
      className={`row ${
        !preview && !kerjakan ? "px-3 pb-3" : "py-4"
      } justify-content-center`}
    >
      {pilgan?.map((item, i) => (
        <div className="col-12 col-md" key={i}>
          <InputPilgan
            handleChange={(e) =>
              kerjakan ? handlePilih(item?.soal_pg_id) : handleChange(e)
            }
            id={i}
            correct={item?.soal_jawaban || false}
            answer={item?.soal_pilihan}
            name={i}
            bg={COLORS[i].bg}
            border={COLORS[i].border}
            shadow={COLORS[i].shadow}
            handleChangeFile={handleChangeFile}
            handleDeleteFile={handleDeleteFile}
            soalFile={item?.soal_file}
            loadingUpload={loadingUpload}
            preview={preview}
            kerjakan={kerjakan}
            dipilih={dipilih === item?.soal_pg_id}
          />
        </div>
      ))}
    </div>
  );
}

export default ItemSoalPilgan;
