import React from "react";
import { InputEssay } from "../input";

function ItemSoalEssay({
  handleChange,
  essay,
  handleChangeFile,
  handleDelFile,
  loadingUpload,
  preview,
  kerjakan,
  handleChangeJwb,
  jawaban,
}) {
  return (
    <div
      className={`row ${
        !preview && !kerjakan ? "px-3 pb-3" : "py-4"
      } justify-content-center`}
    >
      <InputEssay
        handleChange={(e) => (kerjakan ? handleChangeJwb(e) : handleChange(e))}
        essay={essay}
        handleChangeFile={handleChangeFile}
        handleDelFile={handleDelFile}
        loadingUpload={loadingUpload}
        kerjakan={kerjakan}
        jawaban={jawaban}
        soalFile={essay?.soal_file}
        soalJawaban={essay?.soal_jawaban}
        preview={preview}
      />
    </div>
  );
}

export default ItemSoalEssay;
