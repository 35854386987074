import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

import { PageLoader } from "../../assets/images";
import { uploadDocCourse } from "../../redux/actions/courseAction";
import Swal from "sweetalert2";
import moment from "moment";
moment.locale("id");
function UploadBuktiCourse({ show, handleClose, invoiceId, files }) {
  const fileRef = useRef();
  const { t } = useTranslation();
  const [submit, setSubmit] = useState(false);
  const [fileName, setFileName] = useState("");

  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };

  const handleSubmit = () => {
    if (fileRef.current) {
      const selectedFile = fileRef.current.files[0];
      var formData = new FormData();
      formData.append(`file`, selectedFile);
      formData.append("invoice_id", invoiceId);
      setSubmit(true);
      uploadDocCourse(formData)
        .then(() => {
          setSubmit(false);
          handleClose(true);
        })
        .catch(() => {
          setSubmit(false);
          showAlert(t("data_gagal_dikirim", { data: "file" }), false);
        });
    }
  };
  const handleChange = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (files.length > 0) {
      setFileName(files[0].name);
    } else {
      setFileName("");
    }
  };

  return (
    <div className="col-lg-12">
      <Modal
        centered
        size="md"
        show={show}
        onHide={() => {
          if (!submit) handleClose();
        }}
        id="UploadBuktiCourse"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title">{t("upload_bukti")}</h5>
        </Modal.Header>
        <Modal.Body>
          {files?.length > 0 && (
            <>
              <h6 className="mb-2 fw-semibold text-dark">
                {t("riwayat_pembayaran")}
              </h6>
              <ul className="list-group list-group-numbered mb-3">
                {files?.map((item, idx) => (
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start"
                    key={item.invoice_document_id}
                  >
                    <div className="ms-2 flex-1">
                      <div className="d-flex justify-content-between">
                        <a
                          href={item.invoice_document}
                          className="fw-bold"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t("lihat_bukti_bayar")} ({idx + 1})
                        </a>
                        <small>
                          {moment(item.updatedAt?.substr(0, 16)).fromNow()}
                        </small>
                      </div>
                      {item.invoice_document_status === "reject" ? (
                        <p className="m-0 text-danger fst-italic">
                          {item.invoice_note}
                        </p>
                      ) : item.invoice_document_status === "waiting" ? (
                        <p className="m-0 text-danger fst-italic">
                          {t("bukti_pending")}
                        </p>
                      ) : null}
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
          <div className="form-group">
            <label htmlFor="fileBukti" className="fw-semibold">
              File
            </label>
            <div className="custom-file">
              <input
                onChange={handleChange}
                ref={fileRef}
                type="file"
                className="custom-file-input"
                id="fileBukti"
                accept="image/png, image/jpg, image/jpeg"
              />
              <label className="custom-file-label" htmlFor="fileBukti">
                {fileName ? fileName : t("pilih_params", { params: "file" })}
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={submit || !fileName}
          >
            {submit ? (
              <img src={PageLoader} alt="loader" style={{ width: 15 }} />
            ) : (
              "Submit"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UploadBuktiCourse;
