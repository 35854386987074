import { API } from "../../config/api";
import { LOGIN, LOGIN_GOOGLE, LOGOUT } from "../constant";

export const login = (params) => {
  return {
    type: LOGIN,
    payload: async () => {
      let res = await API.post("/auth/login", params).catch((err) => {
        return Promise.reject(err);
      });
      let result = res?.data?.data;
      if (res.status === 200) {
        localStorage.setItem("token", result?.token);
        localStorage.setItem("auth_user", JSON.stringify(result?.user));
      }
      return result;
    },
  };
};

export const loginGoogle = (params) => {
  return {
    type: LOGIN_GOOGLE,
    payload: async () => {
      let res = await API.post("/auth/login/google", params).catch((err) => {
        return Promise.reject(err);
      });
      let result = res?.data?.data;
      if (res.status === 200) {
        localStorage.setItem("token", result?.token);
        localStorage.setItem("auth_user", JSON.stringify(result?.user));
      }
      return result;
    },
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};
