import "moment/locale/id";

import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

import {
  CatatanTugas,
  ContentPenilaian,
  InfoPenilaianTugas,
  SideContentPenilaian,
  Wrapper,
} from "../../components";
import {
  getDetailTugasNilai,
  getPengerjaanSiswa,
  kirimNilaiRaport,
  updPoints,
} from "../../redux/actions/nilaiAction";

moment.locale("id");

/**
 * The `Koreksi` function is a React component that handles the correction of student assignments.
 * It retrieves the details of the assignment and the student's answers, allows the teacher to grade the answers,
 * and provides functionality to send the grades to the students. It also includes a modal component for adding notes to the assignment.
 *
 * @returns {JSX.Element} The component for correcting student assignments.
 */
function Koreksi() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [detail, setDetail] = useState(null);
  const [selectedSiswa, setSelectedSiswa] = useState(null);
  const [activeTab, setActiveTab] = useState("soal_essay");
  const [loading, setLoading] = useState(false);
  const [hasilPengerjaan, setHasilPengerjaan] = useState(null);
  const [showCatatan, setShowCatatan] = useState(false);
  const [submitKirimNilai, setSubmitKirimNilai] = useState(false);

  /**
   * Fetches the details of the assignment based on the provided `id`.
   *
   * @param {string} id - The ID of the assignment.
   */
  const getDetail = useCallback((id) => {
    getDetailTugasNilai(id).then((res) => setDetail(res?.data?.data));
  }, []);

  /**
   * Fetches the student's answers for the assignment.
   *
   * @param {string} id - The ID of the assignment.
   * @param {string} selectedSiswa - The ID of the selected student.
   */
  const getDataSoal = useCallback((id, selectedSiswa) => {
    getPengerjaanSiswa(id, selectedSiswa)
      ?.then((res) => {
        setLoading(false);
        setHasilPengerjaan(res?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  }, []);

  useEffect(() => {
    getDetail(id);
  }, [id, getDetail]);

  useEffect(() => {
    setLoading(true);
    if (id && selectedSiswa) {
      getDataSoal(id, selectedSiswa?.user_id);
    } else {
      setHasilPengerjaan(null);
      setLoading(false);
    }
  }, [id, selectedSiswa, getDataSoal]);

  /**
   * Shows an alert message.
   *
   * @param {string} text - The text of the alert message.
   * @param {boolean} status - The status of the alert (true for success, false for error).
   */
  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };

  /**
   * Handles the change of grade for a student's answer.
   *
   * @param {string} pengerjaan_soal_id - The ID of the student's answer.
   * @param {number} pengerjaan_points - The new grade for the answer.
   */
  const handleChangePoint = (pengerjaan_soal_id, pengerjaan_points) => {
    updPoints({ pengerjaan_soal_id, pengerjaan_points })
      .then(() => {
        getDataSoal(id, selectedSiswa?.user_id);
        getDetail(id);
      })
      .catch((err) =>
        showAlert(t("failed_save_data", { data: t("poin") }), false)
      );
  };

  /**
   * Handles the submission of grades to be sent to the students.
   */
  const handleKirimNilai = () => {
    setSubmitKirimNilai(true);
    kirimNilaiRaport({ tugas_id: id })
      .then(() => {
        setSubmitKirimNilai(false);
        showAlert(t("data_berhasil_dikirim", { data: t("nilai") }), true);
      })
      .catch((error) => {
        let res = error?.response;
        if (res.status === 500) {
          showAlert(res?.data?.error, false);
        } else {
          showAlert(t("data_gagal_dikirim", { data: t("nilai") }), false);
        }
        setSubmitKirimNilai(false);
      });
  };

  /**
   * Handles the switch between different types of questions (essay or other).
   *
   * @param {string} tab - The selected tab.
   */
  const handleTab = (tab) => {
    setActiveTab(tab);
  };

  /**
   * Handles the selection of a student to view their answers.
   *
   * @param {Object} user - The data of the selected student.
   */
  const handleSelectSiswa = (user) => {
    setSelectedSiswa(user);
  };

  /**
   * Handles the addition of notes to the assignment.
   */
  const handleCatatan = () => {
    setShowCatatan(true);
  };

  return (
    <Wrapper title={`${t("koreksi")} (${detail?.title})`}>
      <div className="container">
        <div className="row row-eq-height">
          <InfoPenilaianTugas
            catatan={detail?.catatan_nilai}
            merdekaKode={detail?.merdeka_target?.kode}
            merdekaTarget={detail?.merdeka_target?.target_merdeka}
            endDate={detail?.end_date}
            startDate={detail?.start_date}
            submit={submitKirimNilai}
            title={detail?.title}
            tugasId={id}
            handleCatatan={handleCatatan}
            handleSubmit={handleKirimNilai}
          />
          <SideContentPenilaian
            handleSelect={handleSelectSiswa}
            jmlAnswer={
              detail?.siswas?.filter(
                (item) => item?.user?.pengerjaan_tugas !== null
              )?.length
            }
            jmlSiswa={detail?.siswas?.length}
            objectPengerjaan="pengerjaan_tugas"
            selectedSiswa={selectedSiswa?.user_id}
            siswa={detail?.siswas}
          />
          <ContentPenilaian
            activeTab={activeTab}
            handleChangePoint={handleChangePoint}
            handleTab={handleTab}
            hasilPengerjaan={hasilPengerjaan}
            loading={loading}
            selectedSiswa={selectedSiswa}
          />
        </div>
      </div>
      {showCatatan && (
        <CatatanTugas
          catatan={detail?.catatan_nilai}
          handleClose={() => {
            setShowCatatan(false);
            getDetail(id);
          }}
          show={showCatatan}
          tugasId={id}
        />
      )}
    </Wrapper>
  );
}

export default Koreksi;
