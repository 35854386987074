import "moment/locale/id";

import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { getNilai } from "../../../redux/actions/nilaiAction";
import { checkIsGuru, checkIsOrtu } from "../../../utils/roleUser";

moment.locale("id");

function Nilai() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { topik } = useSelector(({ topik }) => topik);
  const { nilai } = useSelector(({ nilai }) => nilai);
  const [selectedTopik, setSelectedTopik] = useState("");
  const { user } = useSelector(({ user }) => user);
  const { selectedAnak } = useSelector(({ anak }) => anak);
  const handleGetNilai = (value) => {
    let params = {
      topik_id: value,
    };
    if (checkIsOrtu(user.user_roleid)) params.user_id = selectedAnak?.user_id;
    dispatch(getNilai(params));
  };
  const handleChange = ({ target: { value } }) => {
    setSelectedTopik(value);
    handleGetNilai(value);
  };
  const isOrtu = checkIsOrtu(user?.user_roleid);
  return (
    <div className="tab-pane fade" id="nilai" role="tabpanel">
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title w-100 d-flex align-items-center justify-content-between">
            <h4 className="card-title">
              {checkIsGuru(user?.user_roleid) ? "Penilaian" : "Nilai"}
            </h4>
            <Link
              to="/kelas"
              className="btn iq-bg-light iq-bg-primary-hover text-dark border"
            >
              <i className="ri-arrow-left-line m-0"></i> {t("kembali")}
            </Link>
          </div>
        </div>
        <div className="iq-card-body">
          <form className="form-horizontal" action="/action_page.php">
            <div className="row ">
              <div className="col-lg-12 pe-3">
                <div className="row ">
                  <label
                    htmlFor="semester"
                    className="control-label col-sm-2 align-self-center mb-0"
                  >
                    {t("topik")} :
                  </label>
                  <div className="col-sm-10 ">
                    <select
                      className="form-control form-select"
                      value={selectedTopik}
                      onChange={handleChange}
                    >
                      <option disabled value="">
                        --{t("pilih_params", { params: t("topik") })}--
                      </option>
                      {topik?.map((item) => (
                        <option key={item.topik_id} value={item.topik_id}>
                          {item.topic}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <hr className="m-0" />
        <div className="iq-card-body">
          <div className="table-responsive">
            <table
              id="datatable"
              className="table table-striped table-bordered table-hover"
            >
              <thead className="table-primary text-white">
                <tr>
                  <th className="bg-transparent">No</th>
                  <th className="bg-transparent">{t("tugas")}</th>
                  <th className="bg-transparent">
                    {t("tgl_mulai")}/{t("akhir")}
                  </th>
                  <th className="bg-transparent">
                    {checkIsGuru(user?.user_roleid)
                      ? t("mengerjakan")
                      : t("nilai")}
                  </th>
                  {!isOrtu ? (
                    <th className="bg-transparent">{t("aksi")}</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {!selectedTopik ? (
                  <tr>
                    <td colSpan={isOrtu ? 4 : 5} className="text-center">
                      {t("pilih_topik_menampilkan")}
                    </td>
                  </tr>
                ) : nilai?.length <= 0 ? (
                  <tr>
                    <td colSpan={isOrtu ? 4 : 5} className="text-center">
                      {t("data_params_kosong", { params: t("tugas") })}
                    </td>
                  </tr>
                ) : (
                  nilai?.map((item, idx) => (
                    <tr key={item.tugas_id}>
                      <td>{idx + 1}</td>
                      <td>{item.title}</td>
                      <td>
                        {item.start_date &&
                          moment(item.start_date).format("DD MMMM YYYY")}{" "}
                        -{" "}
                        {item.end_date &&
                          moment(item.end_date).format("DD MMMM YYYY")}
                      </td>
                      <td>
                        {checkIsGuru(user?.user_roleid) ? (
                          `${item.jumlah_siswa} / ${item.jumlah_tugas_selesai}`
                        ) : (
                          <b className="text-primary">
                            {item.pengerjaan_nilai !== null
                              ? item.pengerjaan_nilai
                              : "-"}
                          </b>
                        )}
                      </td>
                      {!isOrtu ? (
                        <td className="text-center">
                          {checkIsGuru(user?.user_roleid) ? (
                            <Link
                              to={`/koreksi/${item.tugas_id}`}
                              className="btn btn-primary btn-sm"
                            >
                              <i className="ri-file-2-line"></i>
                              {t("nilai")}
                            </Link>
                          ) : (
                            <button
                              disabled={item.hide_pembahasan}
                              onClick={() =>
                                navigate(`/pembahasan/${item.tugas_id}`)
                              }
                              className="btn btn-primary btn-sm"
                            >
                              <i className="ri-file-2-line"></i>
                              {t("pembahasan")}
                            </button>
                          )}
                        </td>
                      ) : null}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nilai;
