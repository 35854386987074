import React from "react";

import { Wrapper } from "../../components";
import { Nav, Tab } from "react-bootstrap";
import KalenderSection from "./section/KalenderSection";
import { JadwalSection } from "./section/JadwalSection";
import { useTranslation } from "react-i18next";

function Kalender() {
  const { t } = useTranslation();
  return (
    <Wrapper title={t("kalender")}>
      <div className="container">
        <div className="row row-eq-height">
          <div className="col-12">
            <Tab.Container defaultActiveKey="kalender" fills>
              <div className="iq-card p-2">
                <Nav variant="pills">
                  <Nav.Item className="flex-1">
                    <Nav.Link
                      className="text-center fw-semibold"
                      eventKey="kalender"
                    >
                      {t("kalender")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="flex-1">
                    <Nav.Link
                      className="text-center fw-semibold"
                      eventKey="jadwal"
                    >
                      {t("jadwal_pelajaran")}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="kalender">
                  <KalenderSection />
                </Tab.Pane>
                <Tab.Pane eventKey="jadwal">
                  <JadwalSection />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Kalender;
