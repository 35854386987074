import React from "react";
import {
  MenjodohkanCard,
  PernyataanCard,
  PilihanGandaCard,
  PoolingCard,
  TrueFalseCard,
  UraianCard,
} from "../item";

/**
 * Renders different types of question cards based on the `soalJenis` prop.
 * @param {Object} props - The component props.
 * @param {string} props.soalJenis - The type of question card to render.
 * @param {number} props.index - The index of the question card in the list.
 * @param {Object} props.item - The data of the question card.
 * @param {function} props.handleDelete - A callback function to handle the deletion of the question card.
 * @param {function} props.handleCopy - A callback function to handle the copying of the question card.
 * @param {string} props.status - The status of the question card (e.g., "publish").
 * @param {string} props.pathEdit - The path for editing the question card.
 * @param {string} props.pathEnding - The ending path for editing the question card.
 * @returns {JSX.Element} - The rendered question card component.
 */
const ListSoal = ({
  soalJenis,
  index,
  item,
  handleDelete,
  handleCopy,
  status,
  pathEdit,
  creatorName,
  handlePoin,
  handleWaktu,
  disabledPoint,
  disabledWaktu,
  hideWaktu,
  pathEnding,
}) => {
  const cardProps = {
    key: `${index}-soal`,
    data: item,
    index: index,
    delSoal: handleDelete,
    pathEdit: pathEdit,
    pathEnding: pathEnding ?? "",
    publishTugas: status === "publish",
    creatorName: creatorName,
    disabledWaktu: disabledWaktu,
    disabledPoint: disabledPoint,
    hideWaktu: hideWaktu,
    handleCopySoal: () => handleCopy(item),
    handleChangePoint: ({ target: { value } }) =>
      handlePoin(item.soal_id, value),
    handleChangeWaktu: ({ target: { value } }) =>
      handleWaktu(item.soal_id, value),
  };

  switch (soalJenis) {
    case "pg":
      return <PilihanGandaCard {...cardProps} />;
    case "polling":
      return <PoolingCard {...cardProps} />;
    case "essay":
      return <UraianCard {...cardProps} />;
    case "true_false":
      return <TrueFalseCard {...cardProps} />;
    case "menjodohkan":
      return <MenjodohkanCard {...cardProps} />;
    case "pernyataan":
      return <PernyataanCard {...cardProps} />;
    default:
      return <></>;
  }
};

export default ListSoal;
