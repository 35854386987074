import React from "react";
import {
  AUDIO_EXT,
  PNG_IMG_EXT,
  SVG_IMG_EXT,
  VIDEO_EXT,
} from "../../config/fileType";
import { useTranslation } from "react-i18next";

const InputFileSoal = ({
  soalFile,
  soalFileType,
  handleDelFile,
  handleChange,
  submit,
}) => {
  const { t } = useTranslation();
  if (soalFile) {
    return (
      <div className="col-12 col-lg-3 pr-3">
        <div className="user-images position-relative overflow-hidden">
          {SVG_IMG_EXT?.includes(soalFileType) ||
          PNG_IMG_EXT?.includes(soalFileType) ? (
            <img
              src={soalFile}
              className="img-fluid rounded w-100"
              alt="foto soal"
            />
          ) : VIDEO_EXT?.includes(soalFileType) ? (
            <video src={soalFile} controls className="w-100 h-auto"></video>
          ) : AUDIO_EXT?.includes(soalFileType) ? (
            <audio src={soalFile} controls className="w-100"></audio>
          ) : (
            <p className="m-0 text-muted text-italic">
              {t("format_tdk_diketahui")}
            </p>
          )}
          <button
            className="border-0 rounded position-absolute image-edit-btn iq-bg-primary font-size-18 right-10 top-10"
            onClick={handleDelFile}
          >
            <i className="ri-delete-bin-fill"></i>
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="col-12 col-lg-2 pr-3">
      <div className="send-panel row">
        <div className="col-lg-12 col-6">
          <label
            className="p-3 iq-bg-primary rounded w-100 "
            htmlFor="image_file"
          >
            <input
              type="file"
              onChange={handleChange}
              id="image_file"
              className="d-none"
              name="soal_file"
              accept="image/png, image/jpg, image/jpeg"
              disabled={submit}
            />
            <div className="btn d-flex align-items-center justify-content-center text-primary flex-column h-auto w-100 p-0">
              <i className="ri-image-fill font-size-40"></i>{" "}
              {submit ? `${t("loading")}...` : "Image"}
            </div>
          </label>
        </div>
        <div className="col-lg-12 col-6">
          <label
            className="p-3 iq-bg-primary rounded w-100"
            htmlFor="audio_file"
          >
            {" "}
            <input
              type="file"
              id="audio_file"
              className="d-none"
              onChange={handleChange}
              name="soal_file"
              accept="audio/*"
              disabled={submit}
            />
            <div className="btn d-flex align-items-center justify-content-center text-primary flex-column h-auto w-100 p-0">
              <i className="ri-mic-fill font-size-40"></i>{" "}
              {submit ? `${t("loading")}...` : "Audio"}
            </div>
          </label>
        </div>
        <div className="col-lg-12 col-6">
          <label
            className="p-3 iq-bg-primary rounded w-100"
            htmlFor="video_file"
          >
            {" "}
            <input
              type="file"
              id="video_file"
              className="d-none"
              onChange={handleChange}
              name="soal_file"
              accept="video/*"
              disabled={submit}
            />
            <div className="btn d-flex align-items-center justify-content-center text-primary flex-column h-auto w-100 p-0">
              <i className="ri-vidicon-fill font-size-40"></i>{" "}
              {submit ? `${t("loading")}...` : "Video"}
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default InputFileSoal;
