import React from 'react';

import { Blog01, Blog03, Blog04, Blog05, Blog06, Blog07, Blog08, User02, User03 } from '../../assets/images';
import { Wrapper } from '../../components';

/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
function DetailBlog() {
  return (
    <Wrapper>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="iq-card iq-card-block iq-card-stretch iq-card-height blog blog-detail">
              <div className="iq-card-body">
                <div className="image-block">
                  <img
                    src={Blog01}
                    className="img-fluid rounded w-100"
                    alt="blog-img"
                  />
                </div>
                <div className="blog-description mt-3">
                  <h5 className="mb-3 pb-3 border-bottom">
                    There are many variations of passages.
                  </h5>
                  <div className="blog-meta d-flex align-items-center mb-3">
                    <div className="date me-4">
                      <i className="ri-calendar-2-fill text-primary pe-2"></i>2
                      Weeks ago
                    </div>
                    <div className="like me-4">
                      <i className="ri-thumb-up-line text-primary pe-2"></i>20
                      like
                    </div>
                    <div className="comments me-4">
                      <i className="ri-chat-3-line text-primary pe-2"></i>82
                      comments
                    </div>
                    <div className="share">
                      <i className="ri-share-forward-line text-primary pe-2"></i>
                      share
                    </div>
                  </div>
                  <p>
                    Voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi architecto beatae vitae dicta sunt explicabo. Nemo
                    enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                    aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt.
                  </p>
                </div>

                <hr />
                <ul className="post-comments p-0 m-0">
                  <li className="mb-2">
                    <div className="d-flex flex-wrap">
                      <div className="user-img">
                        <img
                          src={User02}
                          alt="userimg"
                          className="avatar-35 rounded-circle img-fluid"
                        />
                      </div>
                      <div className="comment-data-block ms-3">
                        <h6>Monty Carlo</h6>
                        <p className="mb-0">Lorem ipsum dolor sit amet</p>
                        <div className="d-flex flex-wrap align-items-center comment-activity">
                          <a href="javascript:void();">like</a>
                          <a href="javascript:void();">reply</a>
                          <a href="javascript:void();">translate</a>
                          <span> 5 min </span>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex flex-wrap">
                      <div className="user-img">
                        <img
                          src={User03}
                          alt="userimg"
                          className="avatar-35 rounded-circle img-fluid"
                        />
                      </div>
                      <div className="comment-data-block ms-3">
                        <h6>Paul Molive</h6>
                        <p className="mb-0">Lorem ipsum dolor sit amet</p>
                        <div className="d-flex flex-wrap align-items-center comment-activity">
                          <a href="javascript:void();">like</a>
                          <a href="javascript:void();">reply</a>
                          <a href="javascript:void();">translate</a>
                          <span> 5 min </span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <form
                  className="comment-text d-flex align-items-center mt-3"
                  action="javascript:void(0);"
                >
                  <input type="text" className="form-control rounded" />
                  <div className="comment-attagement d-flex">
                    <a href="javascript:void();">
                      <i className="ri-link me-3"></i>
                    </a>
                    <a href="javascript:void();">
                      <i className="ri-user-smile-line me-3"></i>
                    </a>
                    <a href="javascript:void();">
                      <i className="ri-camera-line me-3"></i>
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="iq-card iq-card-block iq-card-stretch iq-card-height blog-post">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="iq-card-title">New Blog</h4>
                </div>
              </div>
              <div className="iq-card-body">
                <ul className="list-inline p-0 mb-0 mt-2">
                  <li className="mb-3">
                    <div className="d-flex align-items-top pb-3 border-bottom">
                      <div className="col-md-5">
                        <div className="image-block">
                          <img
                            src={Blog03}
                            className="img-fluid rounded w-100"
                            alt="blog-img"
                          />
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="blog-description ps-2">
                          <div className="date mb-1">
                            <a href="#" tabIndex="-1">
                              4 Weeks ago
                            </a>
                          </div>
                          <h6 className="mb-2">
                            All the Lorem Ipsum generators
                          </h6>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="mb-3">
                    <div className="d-flex align-items-top pb-3 border-bottom">
                      <div className="col-md-5">
                        <div className="image-block">
                          <img
                            src={Blog04}
                            className="img-fluid rounded w-100"
                            alt="blog-img"
                          />
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="blog-description ps-2">
                          <div className="date mb-1">
                            <a href="#" tabIndex="-1">
                              3 Weeks ago
                            </a>
                          </div>
                          <h6 className="mb-2">
                            All the Lorem Ipsum generators
                          </h6>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="mb-3">
                    <div className="d-flex align-items-top pb-3 border-bottom">
                      <div className="col-md-5">
                        <div className="image-block">
                          <img
                            src={Blog05}
                            className="img-fluid rounded w-100"
                            alt="blog-img"
                          />
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="blog-description ps-2">
                          <div className="date mb-1">
                            <a href="#" tabIndex="-1">
                              2 Weeks ago
                            </a>
                          </div>
                          <h6 className="mb-2">
                            All the Lorem Ipsum generators
                          </h6>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="mb-3">
                    <div className="d-flex align-items-top pb-3 border-bottom">
                      <div className="col-md-5">
                        <div className="image-block">
                          <img
                            src={Blog06}
                            className="img-fluid rounded w-100"
                            alt="blog-img"
                          />
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="blog-description ps-2">
                          <div className="date mb-1">
                            <a href="#" tabIndex="-1">
                              1 Week ago
                            </a>
                          </div>
                          <h6 className="mb-2">
                            All the Lorem Ipsum generators
                          </h6>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="mb-3">
                    <div className="d-flex align-items-top pb-3 border-bottom">
                      <div className="col-md-5">
                        <div className="image-block">
                          <img
                            src={Blog07}
                            className="img-fluid rounded w-100"
                            alt="blog-img"
                          />
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="blog-description ps-2">
                          <div className="date mb-1">
                            <a href="#" tabIndex="-1">
                              3 days ago
                            </a>
                          </div>
                          <h6 className="mb-2">
                            All the Lorem Ipsum generators
                          </h6>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-top">
                      <div className="col-md-5">
                        <div className="image-block">
                          <img
                            src={Blog08}
                            className="img-fluid rounded w-100"
                            alt="blog-img"
                          />
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="blog-description ps-2">
                          <div className="date mb-1">
                            <a href="#" tabIndex="-1">
                              2 Days ago
                            </a>
                          </div>
                          <h6 className="mb-2">
                            All the Lorem Ipsum generators
                          </h6>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default DetailBlog;
