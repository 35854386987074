import React from "react";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function CardMyCourse({
  thumbnail,
  title,
  level,
  totalLesson,
  courseId,
  progress,
  joinStatus,
  lastView,
}) {
  const { t } = useTranslation();
  return (
    <div className="col-lg-4 col-md-6">
      <Link
        to={
          progress === "pending"
            ? `/course/buy/${courseId}`
            : `/course/${courseId}`
        }
        className="card iq-mb-3 product-box"
      >
        <div className="card-body product-content">
          <div className="row">
            <div className="col-4">
              <img
                src={thumbnail}
                className="card-img-fit thumbnail-course"
                alt="#"
              />
            </div>
            <div className="col-8">
              <h5 className="card-title elipsis-2-line">{title}</h5>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-8">
              <div className="text-black">
                {t(level)} • {totalLesson} {t("lessons")}
              </div>
              {joinStatus === "waiting" ? (
                <Badge bg="warning">{t("bukti_pending")}</Badge>
              ) : joinStatus === "reject" ? (
                <Badge bg="danger">{t("reject_lihat")}</Badge>
              ) : null}
            </div>
            <div className="col-4">
              <button
                className={`btn btn-sm float-right
                ${lastView ? "btn-primary" : "btn-outline-secondary"}`}
              >
                {progress === "pending"
                  ? t("detail")
                  : lastView
                  ? t("tonton_ulang")
                  : t("lanjutkan")}
              </button>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default CardMyCourse;
