import React from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { SmallFolder } from "../../assets/images/small";

function CardFolder({
  name,
  onDoubleClick,
  handleEdit,
  handleHapus,
  hideAction,
}) {
  const { t } = useTranslation();
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      ref={ref}
      className="bg-transparent border-0"
    >
      {children}
    </button>
  ));
  return (
    <li
      onDoubleClick={onDoubleClick}
      className="col-md-2 col-6 pb-3 iq-bg-dark-hover"
    >
      <button className="bg-transparent border-0 w-100">
        <img src={SmallFolder} alt="icon folder" className="img-fluid" />
        <div className="d-flex align-items-center justify-content-between">
          <h6
            className="mt-0 elipsis-1-line text-center flex-1 mt-1"
            title={name}
          >
            {name}
          </h6>
          {!hideAction && (
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle}>
                <i className="fw-bold ri-more-2-fill"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                <Dropdown.Item onClick={handleEdit}>
                  <i className="ri-pencil-fill me-2"></i>
                  {t("ganti_nama")}
                </Dropdown.Item>
                <Dropdown.Item onClick={handleHapus}>
                  <i className="ri-delete-bin-6-fill me-2"></i>
                  {t("hapus")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </button>
    </li>
  );
}

export default CardFolder;
