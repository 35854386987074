import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import { delTarget, submitTargetTugas } from "../../redux/actions/tugasAction";
import { useLocation } from "react-router-dom";

function ModalTarget({ show, handleClose, target, idMapel, getTarget }) {
  const location = useLocation();
  const { t } = useTranslation();
  const [submit, setSubmit] = useState(false);
  const [newTarget, setNewTarget] = useState({
    id: "",
    kode: "",
    status_target: "1",
    target_merdeka: "",
  });
  const [edit, setEdit] = useState(false);

  const handleChange = ({ target: { value, name } }) => {
    setNewTarget((pref) => ({
      ...pref,
      [name]: value,
    }));
  };

  const showAlert = (type, title, text) => {
    Swal.fire({
      title: title,
      text: text,
      icon: type,
      imageWidth: 92,
      imageHeight: 92,
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };

  const handleSubmit = () => {
    setSubmit(true);
    let searchParams = new URLSearchParams(location.search);
    let th = searchParams.get("th");
    let smt = searchParams.get("smt");
    const params = {
      ...newTarget,
      kelasmapel_id: idMapel,
      tahunpelajaran_id: th,
      semester_id: smt,
    };
    submitTargetTugas(edit, params)
      .then(() => {
        getTarget();
        setEdit(false);
        setSubmit(false);
        setNewTarget((pref) => ({
          ...pref,
          id: "",
          kode: "",
          status_target: "Aktif",
          target_merdeka: "",
        }));
        showAlert(
          "success",
          edit
            ? t("edit_params_status", {
                params: "Target",
                status: t("berhasil"),
              })
            : t("add_params_status", {
                params: "Target",
                status: t("berhasil"),
              }),
          t("berhasil_simpan", { params: "Target" })
        );
      })
      .catch((err) => {
        setSubmit(false);
        showAlert(
          "error",
          edit
            ? t("edit_params_status", { params: "Target", status: t("gagal") })
            : t("add_params_status", { params: "Target", status: t("gagal") }),
          t("failed_save_data", { data: "Target" })
        );
      });
  };
  const handleEditTarget = (data) => {
    setEdit(true);
    setNewTarget((pref) => ({
      ...pref,
      id: data?.id,
      kode: data?.kode,
      status_target: data?.status_target,
      target_merdeka: data?.target_merdeka,
    }));
  };

  const handleDeleteTarget = (target_merdeka, id) => {
    Swal.fire({
      icon: "question",
      title: t("are_u_sure_delete", { params: target_merdeka }),
      showCancelButton: true,
      cancelButtonText: t("batal"),
      cancelButtonColor: "#3f9b4b",
      confirmButtonText: t("yakin"),
      confirmButtonColor: "#dc3545",
      reverseButtons: true,
    }).then((res) => {
      if (res.isConfirmed) {
        delTarget(id)
          .then(() => {
            getTarget();
          })
          .catch(() => {
            showAlert(
              "error",
              t("hapus_params_gagal", { params: "Target" }),
              t("failed_delete_data", { data: "Target" })
            );
          });
      }
    });
  };

  useEffect(() => {
    setNewTarget({
      id: "",
      kode: "",
      status_target: "Aktif",
      target_merdeka: "",
    });
    setEdit(false);
  }, []);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        if (!submit) handleClose();
      }}
      id="ModalTarget"
    >
      <Modal.Header closeButton>
        <h5 className="modal-title">
          {t("tambah")} {t("target_pembelajaran")}
        </h5>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="form-group row">
            <label
              htmlFor="kode"
              className="control-label col-sm-3 align-self-center mb-0"
            >
              {t("code")} :
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                id="kode"
                placeholder="Kode"
                name="kode"
                value={newTarget.kode}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-12">
              <div className="row">
                <label htmlFor="status_target" className="col-3">
                  Status :
                </label>
                <div className="col-9">
                  <select
                    className="form-control form-select"
                    name="status_target"
                    value={newTarget?.status_target}
                    onChange={handleChange}
                  >
                    <option value="Aktif">{t("aktif")}</option>
                    <option value="Tidak Aktif">{t("tdk_aktif")}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-12">
              <div className="row">
                <label htmlFor="target_merdeka" className="col-3">
                  {t("target_pembelajaran")} :
                </label>
                <div className="col-9">
                  <textarea
                    className="textarea form-control"
                    name="target_merdeka"
                    id="target_merdeka"
                    value={newTarget?.target_merdeka}
                    onChange={handleChange}
                    placeholder={t("target_pembelajaran")}
                    rows={4}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={submit}
          >
            {t("simpan")}
          </button>
        </div>
        <hr />
        <div className="table-responsive">
          <table id="datatable" className="table table-bordered">
            <thead className="table-primary text-white">
              <tr>
                <th className="bg-transparent" width="5%">
                  No
                </th>
                <th className="bg-transparent" width="20%">
                  {t("code")}
                </th>
                <th className="bg-transparent">{t("target_pembelajaran")}</th>
                <th className="bg-transparent" width="20%">
                  Status
                </th>
                <th className="text-center bg-transparent" width="15%">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {target?.length <= 0 ? (
                <tr>
                  <td colSpan={5} className="text-center">
                    {t("data_params_kosong", { params: "target" })}
                  </td>
                </tr>
              ) : (
                target?.map((item, idx) => (
                  <tr key={item.id}>
                    <td>{idx + 1}</td>
                    <td>{item.kode}</td>
                    <td>{item.target_merdeka}</td>
                    <td>
                      <span className="badge badge-primary">
                        {item.status_target}
                      </span>
                    </td>
                    <td className="text-center">
                      <button className="btn btn-success btn-sm ms-1">
                        <i
                          className="ri-edit-2-line m-0"
                          onClick={() => handleEditTarget(item)}
                        ></i>
                      </button>
                      <button
                        className="btn btn-danger btn-sm ms-1"
                        onClick={() =>
                          handleDeleteTarget(item.target_merdeka, item.id)
                        }
                      >
                        <i className="ri-delete-bin-line m-0"></i>
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalTarget;
