import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function FiturCard({ link, icon, name, disabled }) {
  const { t } = useTranslation();
  if (disabled) {
    return (
      <Fragment>
        {disabled && (
          <span className="badge badge-danger position-absolute">
            {t("segera_hadir")}
          </span>
        )}
        <button
          disabled={true}
          className="iq-card align-items-center disabled card-fitur d-block p-2 h-100 d-flex justify-content-center flex-column w-100 iq-bg-secondary"
        >
          <div className="iq-card-body py-1">
            <div className="icon bg-grey p-2 d-inline-block text-secondary">
              <i className={icon}></i>
            </div>
            <h4 className="text-center fw-bold mt-2 mb-0 text-break">{name}</h4>
          </div>
        </button>
      </Fragment>
    );
  }
  return (
    <Link
      to={link}
      className="iq-card text-center card-fitur d-block p-2 h-100 d-flex justify-content-center flex-column"
    >
      <div className="iq-card-body py-1">
        <div className="icon iq-bg-primary p-2 d-inline-block">
          <i className={icon}></i>
        </div>
        <h4 className="text-center fw-bold mt-2 mb-0 text-break">{name}</h4>
      </div>
    </Link>
  );
}

export default FiturCard;
