import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import Select from "react-select";

import { PageLoader } from "../../assets/images";
import { getSemester, getTahunPelajaran } from "../../redux/actions/appAction";
import { sendShareTopik } from "../../redux/actions/topikAction";
import { getShareKelas, getShareMapel } from "../../redux/actions/shareAction";

function ShareKelas({ show, handleClose, selectedTopik, sekolahId, idMapel }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [submit, setSubmit] = useState(false);
  // const [optionSekolah, setOptionSekolah] = useState([]);
  const [optionTahunAjar, setOptionTahunAjar] = useState([]);
  const [optionSemester, setOptionSemester] = useState([]);
  const [optionMapel, setOptionMapel] = useState([]);
  const [optionKelas, setOptionKelas] = useState([]);
  const [errors, setErrors] = useState(null);
  const [selectedKelas, setSelectedKelas] = useState([]);
  const [params, setParams] = useState({
    kelasmapel_id: "",
    semester_id: "",
    sekolah_id: "",
    tahunpelajaran_id: "",
    kelasdetail_id: [],
    topik_id: selectedTopik,
  });
  useEffect(() => {
    getShareKelas(idMapel).then((res) =>
      setOptionKelas(
        res?.data?.data?.map((item) => ({
          value: item.kelasdetail_id,
          label: item.kelasdetail_nama,
        }))
      )
    );
  }, [idMapel]);

  useEffect(() => {
    setOptionTahunAjar([]);
    getTahunPelajaran().then((res) => setOptionTahunAjar(res));
  }, [dispatch]);
  useEffect(() => {
    if (
      params.kelasdetail_id &&
      params.tahunpelajaran_id &&
      params.semester_id &&
      idMapel
    ) {
      let data = {
        kelasdetail_id: params.kelasdetail_id,
        kelasmapel_id: idMapel,
        sekolah_id: sekolahId,
        tahunpelajaran_id: params.tahunpelajaran_id,
        semester_id: params.semester_id,
      };
      getShareMapel(data).then((res) => {
        setOptionMapel(res?.data?.data);
      });
    } else {
      setOptionMapel([]);
      setParams((pref) => ({
        ...pref,
        kelasmapel_id: "",
      }));
    }
  }, [
    params.kelasdetail_id,
    params.tahunpelajaran_id,
    params.semester_id,
    idMapel,
    sekolahId,
  ]);

  const showAlert = (text, status) => {
    Swal.fire({
      title: status ? t("berhasil") : t("gagal"),
      text: text,
      icon: status ? "success" : "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6fbd44",
    });
  };
  const handleChange = ({ target: { name, value } }) => {
    setParams((pref) => ({
      ...pref,
      [name]: value,
    }));
  };

  const handleChangeKelas = (data) => {
    const selectedID = data.map((item) => item.value);
    getSemester({ kelasdetail_id: selectedID, sekolah_id: sekolahId }).then(
      (res) => setOptionSemester(res)
    );
    setSelectedKelas(data);
    setParams((pref) => ({
      ...pref,
      kelasdetail_id: selectedID,
    }));
  };

  const handleSubmit = () => {
    setErrors(null);
    setSubmit(true);
    sendShareTopik(params)
      .then(() => {
        setSubmit(false);
        showAlert(t("berhasil_dibagikan", { params: t("topik") }), true);
        handleClose();
      })
      .catch((err) => {
        setErrors(err?.response?.data?.error);
        setSubmit(false);
        showAlert(t("failed_share_kelas"), false);
      });
  };

  return (
    <div className="col-lg-12">
      <Modal
        size="md"
        show={show}
        onHide={() => {
          if (!submit) handleClose();
        }}
        id="SortTopik"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title">
            {t("bagikan_data", { data: t("modul") })}
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <div className="col-lg-12">
              <label htmlFor="tahunpelajaran_id">{t("tahun_ajar")} :</label>
              <select
                className="form-control form-select"
                id="tahunpelajaran_id"
                name="tahunpelajaran_id"
                onChange={handleChange}
                value={params.tahunpelajaran_id}
              >
                <option value="">
                  {t("pilih_params", {
                    params: t("tahun_ajar"),
                  })}
                </option>
                {optionTahunAjar?.map((item) => (
                  <option
                    value={item.tahunpelajaran_id}
                    key={item.tahunpelajaran_id}
                  >
                    {t("tahun_ajar")} {item.tahunpelajaran_nama}
                  </option>
                ))}
              </select>
              {errors?.tahunpelajaran_id && (
                <span className="invalid-feedback d-block">
                  {errors?.tahunpelajaran_id}
                </span>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12">
              <label htmlFor="kelasdetail_id">{t("kelas")} :</label>
              <Select
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                    ...theme.colors,
                    primary: "#3f9b4b",
                  },
                })}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "#3f9b4b" : "#f1f1f1",
                    height: 45,
                  }),
                }}
                value={selectedKelas}
                onChange={handleChangeKelas}
                isMulti
                options={optionKelas}
                className="mb-2 z-index-2"
              />
              {errors?.kelasdetail_id && (
                <span className="invalid-feedback d-block">
                  {errors?.kelasdetail_id}
                </span>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12">
              <label htmlFor="semester_id">Semester :</label>
              <select
                className="form-control form-select"
                id="semester_id"
                name="semester_id"
                onChange={handleChange}
                value={params.semester_id}
              >
                <option value="">Pilih Semester</option>
                {optionSemester?.map((item) => (
                  <option value={item.semester_id} key={item.semester_id}>
                    Semester {item.semester_nama}
                  </option>
                ))}
              </select>
              {errors?.semester_id && (
                <span className="invalid-feedback d-block">
                  {errors?.semester_id}
                </span>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12">
              <label htmlFor="kelasmapel_id">{t("mata_pelajaran")} :</label>
              <select
                className="form-control form-select"
                id="kelasmapel_id"
                name="kelasmapel_id"
                onChange={handleChange}
                value={params.kelasmapel_id}
              >
                <option value="">
                  {t("pilih_params", {
                    params: t("mata_pelajaran"),
                  })}
                </option>
                {optionMapel?.map((item) => (
                  <option value={item.kelasmapel_id} key={item.kelasmapel_id}>
                    {item?.mapel?.mapel_nama}
                  </option>
                ))}
              </select>
              {errors?.kelasmapel_id && (
                <span className="invalid-feedback d-block">
                  {errors?.kelasmapel_id}
                </span>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleClose}
          >
            {t("tutup")}
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={submit}
          >
            {false ? (
              <img src={PageLoader} alt="loader" style={{ width: 15 }} />
            ) : (
              t("share")
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ShareKelas;
