import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ItemSelectSiswa } from "../item";
import useClickOutside from "../../hooks/useClickOutside";

export default function SelectKolaborasi({
  dataOption,
  dataSelected,
  handleChange,
}) {
  const { t } = useTranslation();
  const [openPopup, setOpenPopup] = useState(false);
  const [selected, setSelected] = useState([]);
  const cekSelected = (id, kelasId) => {
    const check = dataSelected?.filter((item) => {
      return (
        item?.user_id?.toString() === id &&
        item?.kelasdetail_id?.toString() === kelasId
      );
    }).length;
    return check > 0;
  };
  const renderValue = () => {
    const result = dataSelected
      .map((selected) => {
        const selectedOption = dataOption.find(
          (item) =>
            item.user_id.toString() === selected.user_id.toString() &&
            selected.kelasdetail_id.toString() ===
              item?.kelasdetail_id.toString()
        );
        return `${selectedOption?.kelas_nama} | ${selectedOption?.user_nama}`;
      })
      .filter(Boolean);
    setSelected(result);
  };
  useEffect(() => {
    if (dataSelected.length > 0) {
      renderValue();
    } else {
      setSelected([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSelected, dataOption]);
  const dropdownRef = useClickOutside(() => {
    setOpenPopup(false);
  });

  const handleChangeData = (value) => {
    const valueString = value?.user_id?.toString();
    const newList = [...dataSelected];
    const index = newList.findIndex(
      (item) => item?.user_id?.toString() === valueString
    );
    if (index !== -1) {
      newList.splice(index, 1);
      handleChange(newList);
    } else {
      handleChange([...dataSelected, value]);
    }
  };

  return (
    <div ref={dropdownRef} className="w-100 position-relative">
      <div className="w-100">
        <p className="mb-2">
          {t("pilih_params", { params: t("kolaborasi") })}{" "}
          <span className="text-danger">*</span> :
        </p>
        <div
          onClick={() => setOpenPopup(!openPopup)}
          className="d-flex align-items-center justify-content-between select-multiple form-control cursor-pointer user-select-none"
        >
          <div className="d-flex flex-1 overflow-hidden">
            <div className="value">
              {selected?.length > 0
                ? selected?.join(", ")
                : t("pilih_params", { params: t("kolaborasi") })}
            </div>
          </div>
          <i
            className="fa fa-chevron-down"
            aria-hidden="true"
            style={{ fontSize: 10 }}
          ></i>
        </div>
      </div>
      <div
        className={`w-100 bg-white py-3 px-1 mt-2 popup-select-multiple ${
          openPopup ? "position-absolute" : "d-none"
        }`}
        style={{ zIndex: 10000 }}
      >
        <h5 className="fw-bold mb-2 px-3">
          {t("pilih_params", { params: t("kolaborasi") })}
        </h5>
        <ul
          className="todo-task-lists m-0 p-0 overflow-auto"
          style={{ maxHeight: 200 }}
        >
          {dataOption?.length <= 0 ? (
            <li className="text-center">
              {t("data_params_kosong", { params: t("kolaborasi") })}
            </li>
          ) : (
            dataOption?.map((item) => (
              <ItemSelectSiswa
                key={item?.user_id}
                img={item?.user_avatar}
                nama={`${item.kelas_nama} | ${item?.user_nama}`}
                selected={cekSelected(
                  item?.user_id?.toString(),
                  item.kelasdetail_id?.toString()
                )}
                handleChange={() => handleChangeData(item)}
                code={item?.user_id}
              />
            ))
          )}
        </ul>
      </div>
    </div>
  );
}
