import { GET_KELAS, GET_PROFILE, LOGOUT } from '../constant';

const initState = {
  kelas: [],
};

const appReducer = (state = initState, action) => {
  switch (action?.type) {
    case GET_KELAS + "_PENDING":
      return state;
    case GET_KELAS + "_FULFILLED":
      return {
        ...state,
        kelas: action.payload,
      };
    case GET_KELAS + "_REJECTED":
      return state;
    case GET_PROFILE + "_REJECTED":
      return {
        semester: [],
        kelas: [],
      };
    case LOGOUT:
      return {
        semester: [],
        kelas: [],
      };
    default: {
      return state;
    }
  }
};

export default appReducer;
