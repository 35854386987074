/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Wrapper } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { getAnak, setAnak } from "../../redux/actions/anakAction";
import { useTranslation } from "react-i18next";
import {
  cetakTransaksi,
  getDataPembayaran,
  getJenisTanggungan,
} from "../../redux/actions/pembayaranAction";
import { PageLoader } from "../../assets/images";
import { formatAngka } from "../../utils/formatRupiah";

function Pembayaran() {
  const { t } = useTranslation();
  const { dataAnak, selectedAnak } = useSelector(({ anak }) => anak);
  const dispatch = useDispatch();
  const [optionJenis, setOptionJenis] = useState([]);
  const [selectedJenis, setSelectedJenis] = useState("");
  const [dataPembayaran, setDataPembayaran] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCetak, setLoadingCetak] = useState(false);
  useEffect(() => {
    dispatch(getAnak()).then((res) => {
      const check = res?.value?.filter((item) => item?.user_id === selectedAnak?.user_id);
      if ((selectedAnak === null || selectedAnak === undefined) && check?.length <= 0 && res?.value?.length > 0) {
        dispatch(setAnak(res?.value[0]));
      }
    });
  }, [dispatch]);
  useEffect(() => {
    if (selectedAnak) {
      getJenisTanggungan(selectedAnak?.user_id).then((res) => {
        setOptionJenis(res?.data);
      });
    }
  }, [selectedAnak]);
  useEffect(() => {
    if (selectedAnak?.user_id && selectedJenis) {
      setLoading(true);
      getDataPembayaran(selectedAnak?.user_id, selectedJenis)
        .then((res) => {
          setDataPembayaran(res?.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [selectedAnak, selectedJenis]);

  const handleChangeAnak = (e) => {
    let val = e?.target?.value;
    let anak = dataAnak?.filter(
      (item) => parseInt(item.user_id) === parseInt(val)
    );
    if (anak) dispatch(setAnak(anak[0]));
    dispatch(setAnak(null));
  };
  const handleChangeJenis = (e) => {
    let val = e?.target?.value;
    setSelectedJenis(val);
  };
  const handlePrint = (id) => {
    setLoadingCetak(true);
    cetakTransaksi(id)
      .then((res) => {
        setLoadingCetak(false);
        window.open(res?.data, "_blank");
      })
      .catch((err) => {
        setLoadingCetak(false);
        console.log(err);
      });
  };
  return (
    <Wrapper title={t("pembayaran")}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 p-0">
            <div className="iq-card">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                  <h4 className="card-title">Pembayaran</h4>
                </div>
              </div>
              <div className="iq-card-body">
                <form className="form-horizontal" action="/action_page.php">
                  <div className="row">
                    <div className="col-lg-6">
                      <label htmlFor="anak" className="control-label">
                        {t("anak")} :
                      </label>
                      <select
                        onChange={handleChangeAnak}
                        id="anak"
                        className="form-control form-select"
                        value={selectedAnak?.user_id}
                      >
                        <option value="">{t('pilih_params', {params: t('anak')})}</option>
                        {dataAnak?.map((item) => (
                          <option key={item.user_id} value={item.user_id}>
                            {item.user_nama}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-6">
                      <label
                        htmlFor="jenis_pembayaran"
                        className="control-label"
                      >
                        {t("jenis_pembayaran")} :
                      </label>
                      <select
                        onChange={handleChangeJenis}
                        id="jenis_pembayaran"
                        className="form-control form-select"
                        value={selectedJenis}
                      >
                        <option value="">
                          {t("pilih_params", { params: t("jenis_pembayaran") })}
                        </option>
                        {optionJenis?.map((item) => (
                          <option key={item.id_biaya} value={item.id_biaya}>
                            {item.nama_biaya}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="iq-card">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                  <h4 className="card-title">Biaya</h4>
                </div>
              </div>
              <div className="iq-card-body">
                <table className="table table-hover">
                  <thead className="table-primary">
                    <tr className="text-white">
                      <th className="bg-transparent" scope="col">
                        Kategori
                      </th>
                      <th className="bg-transparent" scope="col">
                        Tanggungan
                      </th>
                      <th className="bg-transparent" scope="col">
                        Terbayar
                      </th>
                      <th className="bg-transparent" scope="col">
                        Kurang
                      </th>
                      <th className="bg-transparent" scope="col">
                        Lebih
                      </th>
                      <th className="bg-transparent" scope="col">
                        Status
                      </th>
                      <th className="bg-transparent text-center" scope="col">
                        Aksi
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={7} className="text-center">
                          <img
                            src={PageLoader}
                            alt="loader"
                            height={30}
                            width="auto"
                          />
                        </td>
                      </tr>
                    ) : dataPembayaran?.length <= 0 ? (
                      <tr>
                        <td colSpan={7} className="text-center">
                          {t("data_params_kosong", { params: t("pembayaran") })}
                        </td>
                      </tr>
                    ) : (
                      dataPembayaran?.map((item) => (
                        <tr>
                          <td>{item?.deskripsi}</td>
                          <td>{formatAngka(item?.total_biaya)}</td>
                          <td>{formatAngka(item?.terbayar)}</td>
                          <td>{formatAngka(item?.total_biaya - item?.terbayar)}</td>
                          <td>{formatAngka(item?.terbayar - item?.total_biaya)}</td>
                          <td>
                            <span
                              className={`badge badge-${
                                item.status === "Disetujui"
                                  ? "success"
                                  : "danger"
                              }`}
                            >
                              {item.status === "Disetujui"
                                ? "Lunas"
                                : "Belum Lunas"}
                            </span>
                          </td>
                          <td className="text-center">
                            <button
                              onClick={() => handlePrint(item?.id)}
                              type="button"
                              className="btn btn-link"
                              disabled={loadingCetak}
                            >
                              <i className="ri-printer-line"></i> Print
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Pembayaran;
